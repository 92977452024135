import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import React, { Component } from 'react';
import cx from 'classnames';

import EyeSelect from './EyeSelect';
import providers from '../constants/providers';

class SelectProvider extends Component {
  valueRenderer = (model) => {
    const { t } = this.props;
    if (isEmpty(model)) {
      return t('common:select');
    }

    return model.label;
  };

  render() {
    const {
      t,
      disabled,
      hideLabel,
      placeholder,
      labelClassName,
      wrapperClassName,
      selectWrapperClassName,
      ...rest
    } = this.props;

    return (
      <div
        className={cx('flex', 'no-scroll', 'align-i-center', wrapperClassName, {
          disabled,
        })}
      >
        {!hideLabel && (
          <span className={`filter-label mr ${labelClassName}`}>
            {t('common:providers')}
          </span>
        )}
        <EyeSelect
          {...rest}
          wrapperClassName={selectWrapperClassName}
          options={providers}
          menuPlacement="auto"
          placeholder={t(placeholder)}
          valueRenderer={this.valueRenderer}
        />
      </div>
    );
  }
}

SelectProvider.defaultProps = {
  disabled: false,
  hideLabel: false,
  labelClassName: undefined,
  placeholder: 'common:all',
  wrapperClassName: undefined,
  selectWrapperClassName: undefined,
};

SelectProvider.propTypes = {
  t: PropTypes.func.isRequired,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  selectWrapperClassName: PropTypes.string,
  placeholder: PropTypes.string,
};

const enhance = compose(withTranslation());

export default enhance(SelectProvider);
