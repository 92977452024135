import React from 'react';
import * as PropTypes from 'prop-types';
import { Container } from '@eyemobile/core';
import { ReactComponent as DoubleBlueArrow } from '../assets/blue_right_arrow.svg';

const TransferDeviceModalItem = ({ device, removeDevice }) => {
  return (
    <div className="eye-card ml0 mr0 mt0 mb-lg pr-lg pl-lg">
      <div className="flex align-i-center justify-between fw">
        <Container column>
          <span>{device.model.label}</span>
          <span className="mt-sm">{device.provider.label}</span>
          <span className="mt-sm">S/N {device.address}</span>
          <Container alignItems="center" className="mt-sm">
            <Container marginRight="5px">{device.origin.label}</Container>
            <DoubleBlueArrow />
            <Container marginLeft="5px">{device.destination.label}</Container>
          </Container>
        </Container>
        <div className="flex">
          <i
            className="ic-close-red ml"
            onClick={() => removeDevice(device.address)}
          />
        </div>
      </div>
    </div>
  );
};

TransferDeviceModalItem.propTypes = {
  device: PropTypes.object,
  removeDevice: PropTypes.func,
};

TransferDeviceModalItem.defaultProps = {
  device: undefined,
  removeDevice: undefined,
};

export default TransferDeviceModalItem;
