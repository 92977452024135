import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { default as MuiChip } from '@material-ui/core/Chip';

const PARTNER_STATUS = {
  0: 'INATIVO',
  1: 'ATIVO',
};

const Chip = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 10,
    color: ({ status }) =>
      status ? theme.palette.primary[500] : theme.palette.danger[500],
    backgroundColor: ({ status }) =>
      status ? theme.palette.primary[50] : theme.palette.danger[50],
  },
}))(MuiChip);

export default function PartnerStatus({ status, ...props }) {
  return <Chip label={PARTNER_STATUS[status]} status={status} {...props} />;
}
