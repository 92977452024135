import React from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Dictionary from '../../components/Dictionary';

const CompanyTab = ({ show, proposal }) => {
  if (!show) {
    return null;
  }

  const company =
    proposal && proposal.fields && proposal.fields.company
      ? proposal.fields.company
      : null;

  const dictionary1 = [
    {
      label: 'CNPJ',
      value: company ? company.document : '',
    },
    {
      label: 'Razão social',
      value: company ? company.corporate_name : '',
    },
    {
      label: 'Nome fantasia',
      value: company ? company.trading_name : '',
    },
    {
      label: 'Logradouro',
      value: company ? company.address : '',
    },
    {
      label: 'Número',
      value: company ? company.number : '',
    },
    {
      label: 'Complemento',
      value: company ? company.address2 : '',
    },
    {
      label: 'Bairro',
      value: company ? company.district : '',
    },
    {
      label: 'Cidade',
      value: company ? company.city : '',
    },
    {
      label: 'Estado',
      value: company ? company.state : '',
    },
    {
      label: 'CEP',
      value: company ? company.zipcode : '',
    },
    {
      label: 'Inscrição estadual',
      value: company ? company.ie : '',
    },
  ];

  const partner =
    proposal && proposal.fields && proposal.fields.partner
      ? proposal.fields.partner
      : null;

  const dictionary2 = [
    {
      label: 'CPF',
      value: partner ? partner.document : '',
    },
    {
      label: 'Nome',
      value: partner ? partner.name : '',
    },
    {
      label: 'Email',
      value: partner ? partner.email : '',
    },
    {
      label: 'Telefone',
      value: partner ? partner.phone : '',
    },
    {
      label: 'Data de aniversário',
      value: partner ? partner.birthday : '',
    },
  ];

  const address =
    proposal && proposal.fields && proposal.fields.address
      ? proposal.fields.address
      : null;

  const dictionary3 = [
    {
      label: 'Logradouro',
      value: address ? address.address : '',
    },
    {
      label: 'Número',
      value: address ? address.number : '',
    },
    {
      label: 'Complemento',
      value: address ? address.address2 : '',
    },
    {
      label: 'Bairro',
      value: address ? address.district : '',
    },
    {
      label: 'Cidade',
      value: address ? address.city : '',
    },
    {
      label: 'Estado',
      value: address ? address.state : '',
    },
    {
      label: 'CEP',
      value: address ? address.zipcode : '',
    },
  ];

  const finances =
    proposal && proposal.fields && proposal.fields.finances
      ? proposal.fields.finances
      : null;

  const dictionary4 = [
    {
      label: 'Nome',
      value: finances ? finances.name : '',
    },
    {
      label: 'Email',
      value: finances ? finances.email : '',
    },
    {
      label: 'Telefone',
      value: finances ? finances.phone : '',
    },
  ];

  const training =
    proposal && proposal.fields && proposal.fields.training
      ? proposal.fields.training
      : null;

  const dictionary5 = [
    {
      label: 'Nome',
      value: training ? training.name : '',
    },
    {
      label: 'Email',
      value: training ? training.email : '',
    },
    {
      label: 'Telefone',
      value: training ? training.phone : '',
    },
  ];

  const receivable =
    proposal && proposal.fields && proposal.fields.receivable
      ? proposal.fields.receivable
      : null;

  const dictionary6 = [
    {
      label: 'Prazo de recebimento',
      value: receivable ? receivable.deadline : '',
    },
  ];

  return (
    <Fade in>
      <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
        <Grid container direction="row" spacing={6}>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Empresa</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={proposal === null}
                dictionary={dictionary1}
                align="right"
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Responsável legal</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={proposal === null}
                dictionary={dictionary2}
                align="right"
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Endereço</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={proposal === null}
                dictionary={dictionary3}
                align="right"
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Financeiro</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={proposal === null}
                dictionary={dictionary4}
                align="right"
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Treinamento</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={proposal === null}
                dictionary={dictionary5}
                align="right"
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Recebíveis</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={proposal === null}
                dictionary={dictionary6}
                align="right"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default CompanyTab;
