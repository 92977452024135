export const PORT = process.env.REACT_APP_API_PORT || 8080;
export const CURRENT_ENV = process.env.REACT_APP_SERVER_ENV || 'development';

const eyenotifierHosts = {
  development: null,
  staging: null,
  production: 'https://notifications.eyemobile.com.br',
};

export const TRANSFER_FEE = 3.67;
export const PRODUCTION_PAY_TYPE_ID = 68;
export const defaultBank =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Banks/generic_bank.svg';
export const srcBank =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Banks/';
export const userPlaceholder =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/ic_cabeca_de_mudo.svg';
export const avatar =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/placeholder_cabeca_de_mudo.jpg';
export const participationPlaceholder =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/Placeholder_Lista_Participacao.svg';
export const eventFilesFolder =
  '//s3-sa-east-1.amazonaws.com/eyemobile-cloud/files/events/';
export const pointTemplatesFolder =
  '//s3-sa-east-1.amazonaws.com/eyemobile-cloud/files/points/templates/';
export const placeHolderEvent =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/placeholder_enterprise_profile.jpg';
export const eyenotifierHost = eyenotifierHosts[CURRENT_ENV];
export const marketingEmailGuide =
  '//eyemobile-public.s3-sa-east-1.amazonaws.com/design/MailMarketing/Boas+Práticas+Email+Marketing.pdf';

export const pagarmeContract =
  'https://dashboard.pagar.me/extra/Contrato_de_solucao_de%20pagamentos.pdf';

export const states = [
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
];

export const transactionTypeIcons = {
  0: 'ic-timeline-purchase',
  1: 'ic-timeline-money-withdrawal',
  2: 'ic-timeline-money-supply',
  3: 'ic-timeline-cash-back',
  4: 'ic-timeline-sale',
  5: 'ic-list-tag-activated',
  6: 'ic-timeline-tag-return',
  7: 'ic-timeline-load-credit',
  8: 'ic-timeline-transfer-to',
  9: 'ic-list-transactions-transfer',
  10: 'ic-list-transactions-transfer',
  11: 'ic-timeline-backstage-purchase',
  12: 'ic-timeline-backstage-sale',
  13: 'ic-timeline-backstage-new-tag',
  14: 'ic-timeline-backstage-tag-return',
  15: 'ic-timeline-order',
};

export const brazilStates = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
];
