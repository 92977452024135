import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useFormik, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';

import Dialog from '../../components/Dialog';
import DialogTitle from '../../components/DialogTitle';
import DialogContent from '../../components/DialogContent';
import DialogActions from '../../components/DialogActions';
import Autocomplete from '../../components/Autocomplete';
import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import CheckBoxField from '../../components/CheckBoxField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const PayTypeSchema = Yup.object().shape({
  tenant: Yup.object()
    .shape({ id: Yup.number().required() })
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  name: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Nome deve conter até 255 caracteres'),
  totalize: Yup.boolean().required('Campo obrigatório'),
  tef: Yup.number().required('Campo obrigatório'),
  tef_method: Yup.number()
    .nullable()
    .when('tef', {
      is: 1,
      then: Yup.number().required('Campo obrigatório'),
    }),
  tef_installments_active: Yup.boolean().required('Campo obrigatório'),
  print_receipt: Yup.boolean().required('Campo obrigatório'),
  print_receipt_number: Yup.number()
    .optional()
    .when('print_receipt', {
      is: true,
      then: Yup.number().required('Campo obrigatório'),
    }),
});

export default function PayTypeFormDialog({
  open,
  payType,
  onClose,
  onSubmit,
}) {
  const formRef = React.useRef();

  const fetchTenants = async (search) => {
    let options = [];
    try {
      const response = await gateway.get('/billing/tenants', {
        params: {
          search,
          offset: 0,
          limit: 100,
        },
      });

      options = response.data.data.map((tenant) => ({
        ...tenant,
        value: tenant.id,
        label: tenant.name,
      }));
    } catch (err) {
      console.error(err);
      showError();
    }

    return options;
  };

  const formik = useFormik({
    initialValues: {
      tenant: payType ? payType.tenant : null,
      name: payType ? payType.name : '',
      image: payType ? payType.image : null,
      totalize: payType ? payType.totalize : true,
      tef: payType ? payType.tef : 0,
      tef_method: payType ? payType.tef_method : null,
      tef_installments_active: payType
        ? payType.tef_installments_active
        : false,
      print_receipt: payType ? payType.print_receipt : false,
      print_receipt_number: payType ? payType.print_receipt_number : 1,
    },
    validationSchema: PayTypeSchema,
    onSubmit: async (values, formikBag) => {
      formikBag.setSubmitting(true);

      try {
        if (payType) {
          await gateway.post(`/billing/pay_types/${payType.id}`, {
            ...values,
          });
        } else {
          await gateway.post(`/billing/pay_types`, {
            ...values,
            tenant_id: values.tenant.id.toString(),
            image: null,
          });
        }
        onSubmit();
      } catch (err) {
        console.error(err);

        if (
          err.response.status === 400 &&
          err.response.data &&
          err.response.data.message
        ) {
          showError(err.response.data.message);
        } else if (payType) {
          showError('Falha ao editar forma de pagamento');
        } else {
          showError('Falha ao adicionar forma de pagamento');
        }
      } finally {
        formikBag.setSubmitting(false);
      }

      formikBag.setSubmitting(true);
      onSubmit(values, formikBag);
    },
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    isSubmitting,
    values,
    errors,
    touched,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider ref={formRef} value={formik}>
      <Dialog onClose={onClose} open={open} maxWidth="md">
        <DialogTitle onClose={onClose}>
          {payType
            ? 'Editar forma de pagamento'
            : 'Adicionar forma de pagamento'}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={6}>
            {!payType && (
              <Grid item container direction="column" spacing={2}>
                <Grid item container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">Selecione o cliente</Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="column" spacing={1}>
                  <Grid item>
                    <Field
                      name="tenant"
                      label="Cliente"
                      placeholder="Cliente"
                      component={Autocomplete}
                      fetch={fetchTenants}
                      loadingText="Carregando..."
                      noOptionsText="Nenhum cliente encontrado"
                      error={errors.tenant && touched.tenant}
                      helperText={
                        errors.tenant && touched.tenant ? errors.tenant : ''
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue('tenant', value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item container direction="column" spacing={2}>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">Forma de pagamento</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" spacing={1}>
                <Grid item container direction="row" spacing={2}>
                  <Grid item sm={8} xs={12}>
                    <Field
                      name="name"
                      label="Nome"
                      placeholder="Nome"
                      component={TextField}
                      fullWidth
                      error={errors.name && touched.name}
                      helperText={
                        errors.name && touched.name ? errors.name : ''
                      }
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name="tef"
                      label="TEF"
                      placeholder="TEF"
                      component={SelectField}
                      options={[
                        { label: 'TRANSAÇÃO SEM CARTÃO', value: 0 },
                        { label: 'EYEPAY', value: 1 },
                        { label: 'EYEPASS', value: 2 },
                        { label: 'CASHLESS', value: 3 },
                        { label: 'LINK', value: 4 },
                        { label: 'CARTÃO PRESENTE', value: 5 },
                        { label: 'PIX', value: 6 },
                      ]}
                      error={errors.tef && touched.tef}
                    />
                  </Grid>
                </Grid>
                {values.tef === 1 && (
                  <Grid item>
                    <Field
                      name="tef_method"
                      label="Método"
                      placeholder="Método"
                      component={SelectField}
                      options={[
                        { label: 'CRÉDITO', value: 1 },
                        { label: 'DÉBITO', value: 2 },
                        { label: 'VOUCHER', value: 3 },
                      ]}
                      error={errors.tef_method && touched.tef_method}
                    />
                  </Grid>
                )}
                {values.tef_method === 1 && (
                  <Grid item>
                    <Field
                      name="tef_installments_active"
                      label="Habilitar parcelamento"
                      component={CheckBoxField}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Field
                    name="totalize"
                    label="Incorporar ao total do evento"
                    component={CheckBoxField}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">Recibos</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" spacing={1}>
                <Grid item>
                  <Field
                    name="print_receipt"
                    label="Imprimir recibo"
                    component={CheckBoxField}
                  />
                </Grid>
                {values.print_receipt && (
                  <Grid item>
                    <Field
                      name="print_receipt_number"
                      label="Número de recibos"
                      placeholder="Número de recibos"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      component={TextField}
                      fullWidth
                      error={
                        errors.print_receipt_number &&
                        touched.print_receipt_number
                      }
                      helperText={
                        errors.print_receipt_number &&
                        touched.print_receipt_number
                          ? errors.print_receipt_number
                          : ''
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          onClose={onClose}
          onSubmit={handleSubmit}
          submitting={isSubmitting}
        />
      </Dialog>
    </FormikProvider>
  );
}
