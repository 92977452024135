import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import { css } from '@emotion/core';

import selectStyles from '../styles/select.styles';
import { arrowRenderer } from '../utils/util';
import Spin from './Spin';

const OptimizedOption = ({
  children,
  innerProps,
  style,
  isFocused,
  ...rest
}) => {
  const { onMouseMove, onMouseOver, ...restInner } = innerProps;

  return (
    <components.Option
      innerProps={restInner}
      {...rest}
      style={{
        ...style,
        background: 'transparent',
      }}
      css={css`
        &:hover {
          background-color: rgba(255, 0, 247, 0.08);
        }
      `}
    >
      {children}
    </components.Option>
  );
};

const EyeSelect = ({
  id,
  dark,
  style,
  center,
  onChange,
  placeholder,
  white,
  valueRenderer,
  containerClassName,
  containerStyle,
  transparent,
  options,
  selected,
  className,
  isClearable,
  simpleValue,
  isMulti,
  disabled,
  optimized,
  fetch,
  ...rest
}) => {
  const { t } = useTranslation();

  const [fetchOptions, setFetchOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const doFetch = async () => {
      const results = await fetch();
      setIsLoading(false);

      if (!Array.isArray(results)) {
        throw new Error(
          'Results object must be an array, ' +
            'maybe you forgot to insert .then(res => res.data) on the fetch prop',
        );
      }

      setFetchOptions(results);
    };

    if (fetch) {
      doFetch();
      return;
    }

    setIsLoading(false);
  }, []);

  const customValueRenderer = (option) => {
    if (isEmpty(option)) {
      return t('common:select');
    }

    return option.label;
  };

  const selectedValue = useMemo(() => {
    if (!isMulti && selected !== null) {
      return (fetchOptions || options).find(
        (opt) => opt.value === (selected.value || selected),
      );
    }

    return selected;
  }, [selected, fetchOptions, options]);

  return (
    <div
      id={id}
      className={clsx('flex-block', 'relative', containerClassName)}
      style={containerStyle}
    >
      <Select
        {...rest}
        style={style}
        isMulti={isMulti}
        isLoading={isLoading}
        styles={selectStyles}
        isDisabled={disabled}
        placeholder={placeholder}
        isClearable={isClearable}
        value={selectedValue || ''}
        classNamePrefix="react-select"
        options={fetchOptions || options}
        valueRenderer={valueRenderer || customValueRenderer}
        className={clsx(
          { dark },
          { white },
          className,
          { transparent },
          'react-select-container ',
          center && 'center-placeholder',
        )}
        components={{
          LoadingIndicator: () => (
            <Spin fontSize={20} color={dark || white ? '#FFFFFF' : '#7D1DD1'} />
          ),
          DropdownIndicator: () =>
            arrowRenderer((white || dark) && !transparent),
          IndicatorSeparator: null,
          NoOptionsMessage: () => (
            <div className="flex grow">
              <span className="text-center fw mt mb">
                {t('common:noResultFound')}
              </span>
            </div>
          ),
          Placeholder: ({ ...props }) => (
            <components.Placeholder {...props}>
              <span>{t(placeholder)}</span>
            </components.Placeholder>
          ),
          Option: optimized ? OptimizedOption : components.Option,
        }}
        onChange={(e) => {
          if (onChange) {
            if (simpleValue) {
              onChange(e ? e.value : undefined);
            } else {
              onChange(e);
            }
          }
        }}
      />
    </div>
  );
};

EyeSelect.defaultProps = {
  id: '',
  onChange: () => {},
  required: false,
  placeholder: '',
  noResultsText: null,
  className: '',
  style: {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid #a9a9a9',
    borderRadius: '0',
  },
  containerClassName: '',
  valueRenderer: null,
  onInputChange: () => {},
  delaySearch: 1000,
  selected: null,
  simpleValue: false,
  options: [],
  dark: false,
  white: false,
  center: false,
  whiteArrow: false,
  transparent: false,
  isClearable: true,
  isMulti: false,
  selectClassName: '',
  disabled: false,
  containerStyle: undefined,
  optimized: false,
  fetch: undefined,
};

EyeSelect.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  noResultsText: PropTypes.string,
  containerClassName: PropTypes.string,
  style: PropTypes.object,
  selected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  options: PropTypes.arrayOf(PropTypes.object),
  valueRenderer: PropTypes.func,
  onInputChange: PropTypes.func,
  delaySearch: PropTypes.number,
  simpleValue: PropTypes.bool,
  dark: PropTypes.bool,
  white: PropTypes.bool,
  center: PropTypes.bool,
  whiteArrow: PropTypes.bool,
  transparent: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  selectClassName: PropTypes.string,
  disabled: PropTypes.bool,
  containerStyle: PropTypes.object,
  optimized: PropTypes.bool,
  fetch: PropTypes.func,
};

export default EyeSelect;
