import React from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import moment from 'moment';

import Dictionary from '../../components/Dictionary';

const ContractTab = ({ show, contract }) => {
  if (!show) {
    return null;
  }

  const dictionary = [
    {
      label: 'Total (recorrência)',
      value: contract
        ? Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(contract.recurrency_total)
        : '',
    },
    {
      label: 'Duração',
      value: contract && contract.duration ? `${contract.duration} meses` : '',
    },
    {
      label: 'Inicia em',
      value: contract
        ? moment(contract.starts_at).format('DD/MM/YYYY HH:mm')
        : '',
    },
    {
      label: 'Termina em',
      value: contract
        ? moment(contract.ends_at).format('DD/MM/YYYY HH:mm')
        : '',
    },
    {
      label: 'Cancelado em',
      value:
        contract && contract.cancelled_at
          ? moment(contract.cancelled_at).format('DD/MM/YYYY HH:mm')
          : '',
    },
    {
      label: 'Criado em',
      value: contract
        ? moment(contract.created_at).format('DD/MM/YYYY HH:mm')
        : '',
    },
  ];

  return (
    <Fade in>
      <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
        <Dictionary loading={contract === null} dictionary={dictionary} />
      </Box>
    </Fade>
  );
};

export default ContractTab;
