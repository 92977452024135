import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const AntTabs = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: '1px solid #EDEFF0',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    padding: 0,
    height: 72,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(4),
    opacity: 0.5,
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  selected: {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  wrapper: {
    alignItems: 'flex-start',
    fontSize: 16,
  },
}))((props) => <Tab disableRipple {...props} />);

export default function CustomizedTabs({ isLoading, children }) {
  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (isLoading) {
      return;
    }

    setTab(newValue);
  };

  return (
    <>
      <AntTabs value={tab} onChange={handleChange} aria-label="ant example">
        {React.Children.map(children, (child) => (
          <AntTab key={child.props.label} label={child.props.label} />
        ))}
      </AntTabs>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          show: index === tab,
        });
      })}
    </>
  );
}
