import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';

export default withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: fade(theme.palette.text.primary, 0.1),
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}))(
  ({
    classes,
    onClose,
    onSubmit,
    submitting,
    submitLabel,
    confirmButtonProps,
    ...other
  }) => {
    return (
      <DialogActions className={classes.root} {...other}>
        <Button
          color="text"
          variant="outlined"
          onClick={onClose}
          className={classes.cancel}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => !submitting && onSubmit(e)}
          className={classes.confirm}
          endIcon={
            submitting ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <CheckIcon />
            )
          }
          {...confirmButtonProps}
        >
          {submitLabel || 'Salvar'}
        </Button>
      </DialogActions>
    );
  },
);
