import React from 'react';
import TextField from '@material-ui/core/TextField';
import { default as MuiAutocomplete } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Autocomplete({
  name,
  field,
  label,
  placeholder,
  fetch,
  defaultOptions = [],
  onChange,
  minLenght = 3,
  variant,
  helperText,
  error,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState(defaultOptions);
  const [search, setSearch] = React.useState('');

  const handleFetch = async () => {
    setLoading(true);

    try {
      const data = await fetch(search);
      if (open) {
        setOptions(data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOptions(defaultOptions);
    setSearch('');
  };

  React.useEffect(() => {
    let timer;

    if (open) {
      timer = setTimeout(async () => {
        if (search.length >= minLenght) {
          await handleFetch();
        }
      }, 500);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [open, search]);

  return (
    <MuiAutocomplete
      {...field}
      {...rest}
      open={open}
      fullWidth
      onOpen={handleOpen}
      onClose={handleClose}
      getOptionSelected={(option, selected) => option.value === selected.value}
      getOptionLabel={(option) => (option ? option.label : '')}
      options={options}
      loading={loading}
      defaultValue={[]}
      onChange={(e, value) => {
        onChange({
          target: {
            name: field ? field.name : name,
            value,
          },
        });
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            variant={variant || 'filled'}
            fullWidth
            helperText={helperText}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress
                      color="primary"
                      size={24}
                      style={{ position: 'absolute', right: 64, top: 16 }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        );
      }}
    />
  );
}
