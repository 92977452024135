import { css } from 'emotion';

export const tableWrapper = css`
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  color: #737689;
  font-size: 14px;
  overflow: auto;
  border-spacing: 0;

  & > .header {
    font-size: 14px !important;
  }

  th {
    text-transform: uppercase !important;
    color: #8b2ad5;
    font-size: 14px !important;
  }

  th:first-child {
    padding: 10px;
  }

  th:not(:first-child) {
    padding: 10px;
  }

  th:last-child {
    padding: 10px 15px 10px 10px;
  }

  th {
    text-transform: uppercase;
    font-size: 13px;
  }

  td:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 10px;
  }

  td:not(:first-child) {
    padding: 10px;
  }

  td:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  & > tbody td {
    font-size: 15px;
    color: #737689;
  }

  & > tbody tr:nth-child(odd) {
    background-color: #e5e9ef;
  }

  tr {
    height: 27px;
  }

  @media (min-width: 768px) {
    th {
      position: sticky;
      top: 0;
      background-color: #f5f7fa;
    }
  }
`;
