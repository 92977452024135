import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/filter';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Field, Formik } from 'formik';
import Container from '@eyemobile/core/Container';

import EyeModal from './EyeModal';
import EyeModalContent from './EyeModalContent';
import EyeButton from './EyeButton';
import { showError, showInfo, showSuccess } from '../utils/toast';
import DeviceModalItem from './DeviceModalItem';
import gateway from '../utils/gateway';
import FormikSelect from './FormikSelect';
import providers from '../constants/providers';
import useFocus from '../utils/useFocus';
import Input from './FormikInput';

import { ReactComponent as DeviceGroup } from '../assets/ic_device_group.svg';
import { ReactComponent as DeviceStatus } from '../assets/ic_device_status.svg';

const DeviceSchema = Yup.object().shape({
  distributionCenter: Yup.object()
    .required('common:fieldRequired')
    .nullable(),
  provider: Yup.object()
    .required('common:fieldRequired')
    .nullable(),
  model: Yup.object()
    .required('common:fieldRequired')
    .nullable(),
  devicesGroup: Yup.object().nullable(),
  allAddress: Yup.string()
    .required('common:fieldRequired')
    .nullable(),
});

const AddDevicesModal = ({ visible, onClose, onSave }) => {
  const { t } = useTranslation();

  const [devices, setDevices] = useState([]);
  const [errorDevices, setErrorDevices] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [setFocus, focusProps] = useFocus();
  const [addError, setAddError] = useState(null);

  const validateDuplicate = async (address) => {
    const res = await gateway.get('/legacy/devices/validateDuplicate', {
      params: { address },
    });

    if (res.status !== 200) {
      return false;
    }
    return res.data;
  };

  const addDevice = async (values) => {
    const {
      model,
      provider,
      distributionCenter,
      devicesGroup,
      deviceStatus,
      allAddress,
      note,
    } = values;
    setIsChecking(true);

    try {
      const splitAddress = allAddress
        .split(',')
        .map((address) => address.trim());

      for (const address of splitAddress) {
        if (!address) {
          showError('formErrors:youNeedToFillSerialNumber');
          setIsChecking(false);
          return;
        }

        const deviceCheck = devices.find((d) => d.address === address);
        if (deviceCheck) {
          showError(`O dispositivo ${address} já está adicionado!`);
          continue;
        }

        try {
          await validateDuplicate(address);
        } catch (error) {
          if (error.response && error.response.status === 400) {
            setErrorDevices((prevDevices) => [...prevDevices, address]);
            continue;
          } else {
            throw error;
          }
        }

        const device = {
          model,
          provider,
          address,
          note,
          distributionCenterId: distributionCenter.value,
          devicesGroupId: devicesGroup ? devicesGroup.value : null,
          deviceStatusId: deviceStatus ? deviceStatus.value : null,
        };
        setDevices((prevDevices) => [
          ...prevDevices,
          { ...device, key: address },
        ]);
      }
    } catch (error) {
      console.error('Erro durante inserção em massa dos dispositivos:', error);
    } finally {
      setIsChecking(false);
    }
  };

  const removeDevice = (address) => {
    const newDevices = filter(devices, (d) => {
      return d.address !== address;
    });

    setDevices(newDevices);
  };

  const handleSave = async () => {
    setIsSaving(true);

    if (devices.length === 0) {
      showInfo('formErrors:atLeastOneDeviceMustBeAdded');
      setIsSaving(false);
      return;
    }

    try {
      const devicesNormalized = devices.map((device) => ({
        ...device,
        provider: device.provider.value,
        model: device.model.value,
      }));

      await gateway.post('/legacy/devices/', { devices: devicesNormalized });
      showSuccess();
      setIsSaving(false);
      setDevices([]);
      setErrorDevices([]);

      if (onSave) {
        await onSave();
      }
      onClose();
    } catch (e) {
      setIsSaving(false);
      console.error(e.response.data);
      setAddError(e.response.data);
    }
  };

  const handleClose = () => {
    setDevices([]);
    setAddError(null);
    setErrorDevices([]);
    onClose();
  };

  return (
    <EyeModal visible={visible} customWidth="auto" padding="25px 65px">
      <EyeModalContent
        width="100%"
        onConfirm={handleSave}
        onCancel={handleClose}
        confirmType="button"
        confirmLoading={isSaving}
        cancelLabel="common:close"
        title={t('common:addDevice')}
        disabled={isSaving || isChecking}
        childrenClassName="flex-column justify-center mt-lg"
      >
        <Formik
          initialValues={{
            distributionCenter: undefined,
            provider: undefined,
            allAddress: '',
            model: undefined,
            devicesGroup: undefined,
            deviceStatus: undefined,
          }}
          validationSchema={DeviceSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const {
              distributionCenter,
              provider,
              model,
              devicesGroup,
              deviceStatus,
            } = values;

            try {
              setSubmitting(true);
              await addDevice(values);
              setFocus(true);
              resetForm({
                distributionCenter,
                provider,
                model,
                devicesGroup,
                deviceStatus,
                allAddress: '',
                note: '',
              });
            } catch (e) {
              console.error(e);
              setSubmitting(false);
              showError();
            }
          }}
          render={({ handleSubmit, submitForm }) => {
            return (
              <form onSubmit={handleSubmit} className="fw">
                <Container column alignItems="center">
                  <div className="flex align-i-center">
                    <div className="ic-blue-round-device mr" />
                    <Field
                      name="model"
                      render={({
                        field,
                        form: { setFieldValue, touched, errors },
                      }) => {
                        return (
                          <FormikSelect
                            hideLabel
                            isMulti={false}
                            onBlur={field.onBlur}
                            selected={field.value}
                            containerClassName="fw"
                            placeholder="common:models"
                            touched={touched}
                            field={field}
                            errors={errors}
                            onChange={(value) => {
                              setFieldValue(field.name, value);
                            }}
                            fetch={() => {
                              return gateway
                                .get('/legacy/deviceModels/lookup')
                                .then(({ data }) => data);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex align-i-center mt-lg">
                    <i className="ic-blue-round-currency mr" />
                    <Field
                      name="provider"
                      render={({
                        field,
                        form: { setFieldValue, touched, errors },
                      }) => {
                        return (
                          <FormikSelect
                            hideLabel
                            isMulti={false}
                            onBlur={field.onBlur}
                            selected={field.value}
                            containerClassName="fw"
                            options={providers}
                            placeholder="common:providers"
                            touched={touched}
                            field={field}
                            errors={errors}
                            onChange={(value) => {
                              setFieldValue(field.name, value);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex align-i-center mt-lg">
                    <i className="ic-blue-round-dcs mr" />
                    <Field
                      name="distributionCenter"
                      render={({
                        field,
                        form: { setFieldValue, touched, errors },
                      }) => {
                        return (
                          <FormikSelect
                            hideLabel
                            onBlur={field.onBlur}
                            isMulti={false}
                            selected={field.value}
                            containerClassName="fw"
                            placeholder="common:distributionCenters"
                            touched={touched}
                            field={field}
                            errors={errors}
                            onChange={(value) => {
                              setFieldValue(field.name, value);
                            }}
                            fetch={() => {
                              return gateway
                                .get('/legacy/distributionCenter/lookup')
                                .then(({ data }) => data);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex align-i-center mt-lg">
                    <DeviceGroup className="mr" />
                    <Field
                      name="devicesGroup"
                      render={({
                        field,
                        form: { setFieldValue, touched, errors },
                      }) => {
                        return (
                          <FormikSelect
                            hideLabel
                            onBlur={field.onBlur}
                            isMulti={false}
                            selected={field.value}
                            containerClassName="fw"
                            placeholder="common:group"
                            touched={touched}
                            field={field}
                            errors={errors}
                            onChange={(value) => {
                              setFieldValue(field.name, value);
                            }}
                            fetch={() => {
                              return gateway
                                .get('/legacy/devicesGroup/lookup')
                                .then(({ data }) => data);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex align-i-center mt-lg">
                    <DeviceStatus className="mr" />
                    <Field
                      name="deviceStatus"
                      render={({
                        field,
                        form: { setFieldValue, touched, errors },
                      }) => (
                        <FormikSelect
                          hideLabel
                          onBlur={field.onBlur}
                          isMulti={false}
                          selected={field.value}
                          containerClassName="fw"
                          placeholder="common:status"
                          touched={touched}
                          field={field}
                          errors={errors}
                          onChange={(value) => {
                            setFieldValue(field.name, value);
                          }}
                          fetch={() => {
                            return gateway
                              .get('/legacy/deviceStatus/lookup')
                              .then(({ data }) => data);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="flex align-i-center mt-lg">
                    <div className="ic-blue-round-device mr" />
                    <Field
                      name="note"
                      component={Input}
                      style={{ width: '300px', paddingLeft: '10px' }}
                      placeholder={t('common:note')}
                      className="input-default uppercase-placeholder"
                      wrapperStyle={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                      }}
                      normalizeValue={(value) => {
                        return value.replace(/[^a-zA-Zà-úÀ-ú0-9 ]/g, '');
                      }}
                    />
                  </div>
                  <div className="flex flex-column align-i-center mt-lg">
                    <div className="flex align-i-center fw">
                      <i className="ic-blue-round-serialNumber mr" />
                      <Field
                        {...focusProps}
                        name="allAddress"
                        component={Input}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            submitForm();
                          }
                        }}
                        style={{ width: '300px', paddingLeft: '10px' }}
                        placeholder={t('common:serialNumber')}
                        className="input-default uppercase-placeholder"
                        wrapperStyle={{
                          display: 'flex',
                          flex: '1',
                          flexDirection: 'column',
                        }}
                        normalizeValue={(value) => {
                          return value.replace(/[^a-zA-Zà-úÀ-ú0-9, ]/g, '');
                        }}
                      />
                    </div>
                    <span
                      className="red mt ml-lg"
                      style={{ maxWidth: '300px' }}
                    >
                      {addError}
                      {errorDevices.map((address, index) => (
                        <div key={index}>
                          Dispositivo {address} já cadastrado.
                        </div>
                      ))}
                    </span>
                  </div>
                  <div className="flex justify-center fw mt-lg">
                    <EyeButton
                      type="button"
                      className="btn-solid-green"
                      label={t('common:add')}
                      clickHandler={submitForm}
                      isLoading={isChecking}
                      disabled={isChecking || isSaving}
                    />
                  </div>
                </Container>
              </form>
            );
          }}
        />
        {devices && devices.length > 0 && (
          <Container width="100%" marginLeft="15px">
            <span className="filter-label">
              {t('common:devicesCount', { count: devices.length })}
            </span>
          </Container>
        )}
        <div
          className={clsx(
            'flex flex-column vertical-scroll fw',
            devices && devices.length > 0 && 'mt-md',
          )}
          style={{ maxHeight: '385px', padding: '5px 10px' }}
        >
          {devices.map((device) => (
            <DeviceModalItem
              key={device.key}
              device={device}
              removeDevice={removeDevice}
            />
          ))}
        </div>
      </EyeModalContent>
    </EyeModal>
  );
};

AddDevicesModal.defaultProps = {
  visible: false,
  onClose: undefined,
  onSave: undefined,
};

AddDevicesModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default AddDevicesModal;
