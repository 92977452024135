import React from 'react';
import * as PropTypes from 'prop-types';

function EyeRadioButtonRow({
  t,
  checked,
  clickAction,
  onChangeAction,
  label,
  containerClassName,
  labelClassName,
  hideRadioLabel,
}) {
  return (
    <label className={`eye-radio-btn-container mb0 ${containerClassName}`}>
      <input
        className="eye-radio-btn"
        type="radio"
        onChange={onChangeAction}
        checked={checked}
        onClick={clickAction}
      />
      <div className="checkmark" />
      {!hideRadioLabel && (
        <div className="flex">
          <span className={`avenir-medium-gray-md ml ${labelClassName}`}>
            {t(label)}
          </span>
        </div>
      )}
    </label>
  );
}

EyeRadioButtonRow.defaultProps = {
  label: '',
  checked: false,
  clickAction: () => {},
  onChangeAction: () => {},
  containerClassName: '',
  labelClassName: '',
  hideRadioLabel: false,
};

EyeRadioButtonRow.propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  hideRadioLabel: PropTypes.bool,
  clickAction: PropTypes.func,
  onChangeAction: PropTypes.func,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default EyeRadioButtonRow;
