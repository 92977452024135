import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import WizardConnector from './WizardConnector';
import WizardIcon from './WizardIcon';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 260,
    padding: 0,
    backgroundColor: 'transparent',
  },
}));

export default function Wizard({ children, context = {} }) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [ctx, setContext] = React.useState(context);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNextStep = (ctx) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setContext(ctx);
  };

  const handlePreviousStep = (ctx) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setContext(ctx);
  };

  return (
    <Container maxWidth="lg">
      <Box display="flex">
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={<WizardConnector />}
          classes={classes}
        >
          {React.Children.map(children, (child) => (
            <Step key={child.props.label}>
              <StepLabel StepIconComponent={WizardIcon}>
                <Box m={2} fontWeight={500} fontSize={16}>
                  {mobile ? '' : child.props.label}
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box flex={1} display="flex">
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, {
              show: index === activeStep,
              step: index,
              total: React.Children.count(children),
              onPreviousStep: handlePreviousStep,
              onNextStep: handleNextStep,
              context: ctx,
            });
          })}
        </Box>
      </Box>
    </Container>
  );
}
