import React from 'react';
import { useParams } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

import Wizard from '../../components/Wizard/Wizard';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import TenantStep from './Steps/TenantStep';
import AmendmentStep from './Steps/AmendmentStep';
import ItemsStep from './Steps/ItemsStep';
import ReviewStep from './Steps/ReviewStep';

const ContractAmendmentProposalWizard = () => {
  const params = useParams();

  const [loading, setLoading] = React.useState(true);
  const [context, setContext] = React.useState({});

  const fetchContext = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(`/billing/tenants/${params.id}`);
      const { tenant } = response.data;
      const { subscription } = response.data;
      const { contract_items, payment_provider } = subscription;
      setContext({
        tenant: {
          ...tenant,
          value: tenant.id,
          label: tenant.name,
        },
        subscription: {
          ...subscription,
          value: subscription.id,
          label: `#${subscription.id} - ${tenant.name} (de ${moment(
            subscription.starts_at,
          ).format('DD/MM/YYYY')} até ${moment(subscription.ends_at).format(
            'DD/MM/YYYY',
          )})`,
        },
        proposal_items: contract_items.map((item) => ({
          ...item,
          contract_product: {
            ...item.contract_product,
            value: item.contract_product.id,
            label: item.contract_product.name,
          },
          observations: item.observations
            ? JSON.stringify(convertToRaw(stateFromHTML(item.observations)))
            : '',
        })),
        contract_items,
        payment_provider,
        name: tenant.name,
        contract_type: subscription.contract_type,
        observations: subscription.observations
          ? JSON.stringify(
              convertToRaw(stateFromHTML(subscription.observations)),
            )
          : '',
      });
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchContext();
  }, []);

  if (loading) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={84} />
      </Box>
    );
  }

  return (
    <Fade in>
      <Wizard context={context}>
        <TenantStep label="Selecionar Negócio" guided amendment />
        <ItemsStep label="Editar Itens" recurrent amendment />
        <AmendmentStep label="Proposta" />
        <ReviewStep label="Revisão" amendment />
      </Wizard>
    </Fade>
  );
};

export default ContractAmendmentProposalWizard;
