import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: '1px solid #EDEFF0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #EDEFF0',
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}))((props) => {
  const { classes, onClose, onSubmit, submitting, ...other } = props;

  return (
    <MuiDialogActions className={classes.root} {...other}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onClose}
        className={classes.cancel}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        className={classes.confirm}
        endIcon={
          submitting ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <CheckIcon />
          )
        }
      >
        Salvar
      </Button>
    </MuiDialogActions>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const ChargeSchema = Yup.object().shape({
  total: Yup.number().optional(),
  reason: Yup.string().optional(),
  paymentType: Yup.string().optional(),
});

export default function EditChargeDialog({
  open,
  onClose,
  onSubmit,
  selecteds,
  tenantId,
}) {
  const formRef = React.useRef();

  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Editar cobrança(s)
      </DialogTitle>
      <DialogContent>
        <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
          <Formik
            innerRef={formRef}
            enableReinitialize
            validationSchema={ChargeSchema}
            initialValues={{ total: '', reason: '' }}
            onSubmit={async (values) => {
              setSubmitting(true);
              try {
                await gateway.put(
                  `/billing/tenants/${tenantId}/charges/batch`,
                  {
                    total: values.total ? parseFloat(values.total) : undefined,
                    paymentType: values.paymentType
                      ? values.paymentType
                      : undefined,
                    charges: selecteds,
                    reason: values.reason || null,
                  },
                );
                onSubmit();
              } catch (err) {
                console.error(err);

                if (
                  err.response.status === 400 &&
                  err.response.data &&
                  err.response.data.message
                ) {
                  showError(err.response.data.message);
                } else {
                  showError('Falha ao editar cobranças');
                }
              } finally {
                setSubmitting(false);
              }
            }}
            render={({ errors, touched }) => {
              return (
                <form>
                  <Grid container spacing={4}>
                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Preço das novas cobranças
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={6}>
                        <Field
                          name="total"
                          placeholder="R$ 0,00"
                          component={TextField}
                          currency
                          error={errors.total && touched.total}
                        />
                      </Grid>
                      <Grid item sm={6} xs={6}>
                        <Field
                          name="paymentType"
                          label="Forma de pagamento"
                          placeholder="Forma de pagamento"
                          component={SelectField}
                          options={[
                            { label: 'BOLETO', value: 'VOUCHER' },
                            { label: 'PIX', value: 'PIX' },
                          ]}
                          error={errors.paymentType && touched.paymentType}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="reason"
                          label="Motivo"
                          placeholder="Motivo"
                          multiline
                          rows={3}
                          component={TextField}
                          error={errors.reason && touched.reason}
                          helperText={
                            errors.reason && touched.reason ? errors.reason : ''
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        onClose={onClose}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Dialog>
  );
}
