import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import filter from 'lodash/filter';

import { ReactComponent as DevicesStatus } from '../assets/ic_device_status.svg';

import { showError, showSuccess, showResponseError } from '../utils/toast';
import gateway from '../utils/gateway';
import Loading from './Loading';

const DeviceStatusPage = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [newDeviceStatus, setNewDeviceStatus] = useState('');
  const [deviceStatus, setDeviceStatus] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    const doFetch = async () => {
      const res = await gateway.get('/legacy/deviceStatus/');
      if (res.status !== 200) {
        setIsLoading(false);
        showError();
        return;
      }

      setDeviceStatus(res.data);
      setIsLoading(false);
    };
    doFetch();
  }, []);

  const addDeviceStatus = async () => {
    try {
      setIsLoading(true);

      const deviceStatusCheck = deviceStatus.find((deviceStatus) => {
        return (
          deviceStatus.name.toLowerCase() === newDeviceStatus.toLowerCase()
        );
      });

      if (deviceStatusCheck) {
        throw new Error('formErrors:deviceStatusAlreadyAdded');
      }

      const formData = new FormData();
      formData.append('deviceStatusName', newDeviceStatus);

      const res = await gateway.post('/legacy/deviceStatus/', formData);

      showSuccess();

      const savedDeviceStatus = res.data;
      setDeviceStatus(deviceStatus.concat(savedDeviceStatus));

      setNewDeviceStatus('');
      setIsLoading(false);
    } catch (e) {
      const message = e.message || e.response.data;
      showError(message);
      setIsLoading(false);
    }
  };

  const removeDeviceStatus = async (deviceStatusToRemove) => {
    const { id } = deviceStatusToRemove;

    setIsLoading(true);

    if (id) {
      try {
        await gateway.delete('/legacy/deviceStatus/', {
          params: { deviceStatusId: id },
        });
      } catch (e) {
        setIsLoading(false);
        showResponseError(e.response.data);
        return;
      }

      showSuccess();
    }

    const newDeviceStatus = filter(deviceStatus, (deviceStatus) => {
      return deviceStatus !== deviceStatusToRemove;
    });

    setDeviceStatus(newDeviceStatus);
    setIsLoading(false);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      addDeviceStatus();
    }
  };

  return (
    <div className="flex align-i-center justify-center grow fh">
      <div
        className="eye-card mt0"
        style={{ width: '485px', padding: '25px 75px' }}
      >
        <span className="avenir-medium-gray-title uppercase">
          {t('common:deviceStatus')}
        </span>
        <div className="flex align-i-center mt-xl mb-xl">
          <DevicesStatus />
          <input
            type="text"
            autoComplete="none"
            value={newDeviceStatus}
            placeholder={t('common:deviceStatusName')}
            onKeyDown={onKeyDown}
            className="input-default uppercase-placeholder"
            style={{ width: '280px', paddingLeft: '10px' }}
            onChange={(e) => setNewDeviceStatus(e.target.value)}
          />
          <i
            className={clsx('ic-plus pointer', isLoading && 'disabled')}
            onClick={addDeviceStatus}
          />
        </div>
        <div className="flex flex-column fw">
          <Loading isSmall show={isLoading} />
          {deviceStatus.length === 0 && !isLoading && (
            <div className="flex align-i-center justify-center">
              <i className="ic-alert-blue ic-sm mr-sm" />
              <span className="normal-case" style={{ lineHeight: '2' }}>
                {t('common:noDeviceStatusAdded')}
              </span>
            </div>
          )}
          {deviceStatus.length > 0 &&
            !isLoading &&
            deviceStatus.map((deviceStatus) => {
              return (
                <div className="flex align-i-center mb-lg">
                  <i
                    className={clsx(
                      'ic-minus pointer mr-sm',
                      isLoading && 'disabled',
                    )}
                    onClick={() => removeDeviceStatus(deviceStatus)}
                  />
                  <span className="avenir-medium-gray-md uppercase">
                    {deviceStatus.name}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DeviceStatusPage;
