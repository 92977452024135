import React from 'react';
import ReactLoading from 'react-loading';
import * as PropTypes from 'prop-types';

const Loading = ({ show, className, isSmall, center, loadingColor }) =>
  show && (
    <div className={`center-content ${center ? 'grow mt0' : ''} ${className}`}>
      <ReactLoading
        type="spin"
        color={loadingColor}
        height={isSmall ? 50 : 100}
        width={isSmall ? 50 : 100}
      />
    </div>
  );

Loading.defaultProps = {
  show: false,
  center: false,
  className: '',
  isSmall: false,
  loadingColor: '#7d1dd1',
};

Loading.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  center: PropTypes.bool,
  loadingColor: PropTypes.string,
};

export default Loading;
