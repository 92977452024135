import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import DateField from '../../components/DateField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: '1px solid #EDEFF0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #EDEFF0',
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}))((props) => {
  const { classes, onClose, onSubmit, submitting, ...other } = props;

  return (
    <MuiDialogActions className={classes.root} {...other}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onClose}
        className={classes.cancel}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        className={classes.confirm}
        endIcon={
          submitting ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <CheckIcon />
          )
        }
      >
        Salvar
      </Button>
    </MuiDialogActions>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const ChargeSchema = Yup.object().shape({
  total: Yup.number()
    .required('Campo obrigatório')
    .min(1, 'Preço inválido'),
  type: Yup.number().required('Campo obrigatório'),
  charge_at: Yup.date()
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  expires_in: Yup.number().required('Campo obrigatório'),
  description: Yup.string(),
  reason: Yup.string(),
});

export default function EditChargeDialog({ open, onClose, onSubmit, charge }) {
  const formRef = React.useRef();

  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Editar cobrança
      </DialogTitle>
      <DialogContent>
        <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
          <Formik
            innerRef={formRef}
            initialValues={{
              total: charge ? charge.total : 0,
              type: charge ? charge.type : 0,
              charge_at: charge
                ? charge.charge_at
                : moment()
                    .add(1, 'day')
                    .toDate(),
              expires_in: charge ? charge.expires_in : 7,
              description:
                charge && charge.description ? charge.description : '',
              reason: '',
            }}
            validationSchema={ChargeSchema}
            onSubmit={async (values) => {
              setSubmitting(true);

              try {
                if (charge) {
                  await gateway.post(
                    `/billing/tenants/${charge.tenant_id}/charges/${charge.id}`,
                    {
                      ...values,
                      total: Number(values.total),
                    },
                  );
                } else {
                  await gateway.post(
                    `/billing/tenants/${charge.tenant_id}/charges`,
                    {
                      ...values,
                      total: Number(values.total),
                    },
                  );
                }
                onSubmit();
              } catch (err) {
                console.error(err);

                if (
                  err.response.status === 400 &&
                  err.response.data &&
                  err.response.data.message
                ) {
                  showError(err.response.data.message);
                } else {
                  showError('Falha ao editar cobrança');
                }
              } finally {
                setSubmitting(false);
              }
            }}
            render={({ errors, touched }) => {
              return (
                <form>
                  <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                      <Field
                        name="total"
                        label="Total"
                        placeholder="Total"
                        currency
                        component={TextField}
                        error={errors.total && touched.total}
                        helperText={
                          errors.total && touched.total ? errors.total : ''
                        }
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field
                        name="type"
                        label="Forma de pagamento"
                        placeholder="Forma de pagamento"
                        component={SelectField}
                        options={[
                          { label: 'BOLETO', value: 'VOUCHER' },
                          { label: 'PIX', value: 'PIX' },
                        ]}
                        error={errors.type && touched.type}
                      />
                    </Grid>
                    <Grid item md={7} xs={12}>
                      <Field
                        name="charge_at"
                        label="Data de cobrança"
                        placeholder="DD/MM/YYYY"
                        format="DD/MM/YYYY"
                        disableToolbar
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        minDate={new Date()}
                        component={DateField}
                        error={errors.charge_at && touched.charge_at}
                        helperText={
                          errors.charge_at && touched.charge_at
                            ? errors.charge_at
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Field
                        name="expires_in"
                        label="Expira em"
                        component={SelectField}
                        options={[
                          { label: '1 DIA', value: 1 },
                          { label: '2 DIAS', value: 2 },
                          { label: '3 DIAS', value: 3 },
                          { label: '4 DIAS', value: 4 },
                          { label: '5 DIAS', value: 5 },
                          { label: '6 DIAS', value: 6 },
                          { label: '7 DIAS', value: 7 },
                        ]}
                        error={errors.expires_in && touched.expires_in}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="description"
                        label="Descrição"
                        placeholder="Descrição"
                        multiline
                        rows={3}
                        component={TextField}
                        error={errors.description && touched.description}
                        helperText={
                          errors.description && touched.description
                            ? errors.description
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="reason"
                        label="Motivo"
                        placeholder="Motivo"
                        multiline
                        rows={3}
                        component={TextField}
                        error={errors.reason && touched.reason}
                        helperText={
                          errors.reason && touched.reason ? errors.reason : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        onClose={onClose}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Dialog>
  );
}
