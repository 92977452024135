import { currentHost } from '../utils/http';

export const PLACEHOLDER =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/ic_placeholder.svg';
export const NO_DATA =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_cat.gif';
export const MENU_LIST_PLACEHOLDER =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/Placeholder_Lista_Produtos.svg';
export const URL_PRODUCT = `${currentHost}/images/view/products?f=`;
export const URL_USER = `${currentHost}/images/view/users?f=`;
export const URL_TENANT = `${currentHost}/images/view/tenants?f=`;
export const URL_POINT = `${currentHost}/images/view/tenants?f=`;
export const MENU_LIST_GROUP_PRODUCT_PLACEHOLDER =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/Placeholder_Adicionar_Categoria.svg';
export const MENU_LIST_PRODUCT_PLACEHOLDER =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/Placeholder_Lista_Produtos_vazio.svg';
export const PLACEHOLDER_IMG =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/placeholder_img.jpg';
export const PLACEHOLDER_POINT =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/placeholder_operacao.jpg';
export const PLACE_ADD_IMG =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/placeholder_photo.svg';
export const SRC_BANK =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Banks/';
export const PLACEHOLDER_USER =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/ic_cabeca_de_mudo.svg';
export const DEVICE_URL =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Devices/';
export const URL_LICENSE =
  '//s3-sa-east-1.amazonaws.com/eyemobile-cloud/licenses';
export const PLACEHOLDER_DEVICE =
  '//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/placeholder_device.svg';
