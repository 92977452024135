import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { default as MuiChip } from '@material-ui/core/Chip';

const CHARGE_STATUS = {
  0: 'PENDENTE',
  1: 'PAGA',
  2: 'PROCESSANDO',
  3: 'ATRASADA',
  4: 'CANCELADA',
  5: 'SUSPENSA',
  6: 'EXPIRADA',
};

const Chip = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 10,
    color: ({ status }) => {
      if (status === 0) return theme.palette.primary[500];
      if (status === 1) return theme.palette.success[500];
      if (status === 2) return theme.palette.primary[500];
      if (status === 3 || status === 6) return theme.palette.danger[500];
      return theme.palette.text[500];
    },
    backgroundColor: ({ status }) => {
      if (status === 0) return theme.palette.primary[50];
      if (status === 1) return theme.palette.success[50];
      if (status === 2) return theme.palette.primary[50];
      if (status === 3 || status === 6) return theme.palette.danger[50];
      return theme.palette.text[50];
    },
  },
}))(MuiChip);

export default function ChargeStatus({ status, ...props }) {
  return <Chip label={CHARGE_STATUS[status]} status={status} {...props} />;
}
