import React from 'react';
import * as PropTypes from 'prop-types';
import get from 'object-path';
import AsyncSelect from './AsyncSelect';
import gateway from '../utils/gateway';

const FormikAsyncSelect = (props) => {
  const { fetch, className, onChange, customFetch, ...rest } = props;

  const defaultFetchOptions = (q) =>
    gateway
      .get(get(fetch, 'fetch.path'), {
        params: { q, ...get(fetch, 'fetch.params') },
      })
      .then(({ data }) =>
        (data.items || []).map((item) => ({
          value: item.id,
          label: item.text,
        })),
      );

  return (
    <AsyncSelect
      {...rest}
      className={className}
      onChange={onChange}
      loadOptions={customFetch || defaultFetchOptions}
      defaultOptions
    />
  );
};
FormikAsyncSelect.defaultProps = {
  className: undefined,
  customFetch: undefined,
  fetch: undefined,
};
FormikAsyncSelect.propTypes = {
  customFetch: PropTypes.func,
  fetch: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
export default FormikAsyncSelect;
