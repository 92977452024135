export const ADMIN_MASTER = 1000;
export const ADMIN_STAFF = 1100;
export const ADMIN_FINANCIAL = 1200;
export const PARTNER_MASTER = 2000;
export const PARTNER_STAFF = 2100;
export const CUSTOMER_MASTER = 3000;
export const CUSTOMER_OBSERVER = 3100;
export const CUSTOMER_POINT = 3200;
export const CUSTOMER_BARTENDER = 3210;
export const CUSTOMER_WAITER = 3220;
export const CUSTOMER_STOCK = 3230;
export const CUSTOMER_HOSTESS = 3240;
