import React from 'react';

import Navigation from './Navigation';
import TopHeader from './TopHeader';

const isDev = process.env.REACT_APP_SERVER_ENV !== 'production';
const envNames = {
  staging: 'Homologação',
  development: 'Desenvolvimento',
};

export const withMainTemplate = (WrappedComponent) => {
  return (props) => {
    return (
      <>
        <div
          style={{ minHeight: '100%', display: 'flex', alignItems: 'stretch' }}
        >
          <div id="mobile-indicator" />
          <Navigation />
          <div className="page-wrapper">
            <TopHeader />
            <div className="main" style={{ display: 'flex' }}>
              <WrappedComponent {...props} />
            </div>
          </div>
        </div>
        {isDev && (
          <div
            style={{
              position: 'fixed',
              bottom: 8,
              right: 16,
              color: 'rgba(0, 0, 0, 0.2)',
            }}
          >
            {envNames[process.env.REACT_APP_SERVER_ENV] || envNames.development}
          </div>
        )}
      </>
    );
  };
};
