import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import SelectField from '../../components/SelectField';
import TextField from '../../components/TextField';
import DateTimeField from '../../components/DateTimeField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';
import roleGroups from '../../constants/roleGroups';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: '1px solid #EDEFF0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #EDEFF0',
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
});

const DialogTitle = withStyles(styles)(
  ({ children, classes, onClose, ...other }) => (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  ),
);

const DialogActions = withStyles(styles)(
  ({ classes, onClose, onSubmit, submitting, actionsDisabled, ...other }) => (
    <MuiDialogActions className={classes.actions} {...other}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onClose}
        className={classes.cancel}
        disabled={actionsDisabled}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        className={classes.confirm}
        endIcon={
          submitting ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <CheckIcon />
          )
        }
      >
        Confirmar
      </Button>
    </MuiDialogActions>
  ),
);

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const CancelTenantSchema = Yup.object().shape({
  type: Yup.number().required('Type is required'),
  description: Yup.string().required('Description is required'),
});

export default function CancelTenantDialog({
  open,
  onClose,
  onSubmit,
  tenant,
}) {
  const params = useParams();
  const [types, setTypes] = useState([]);
  const formRef = useRef();
  const [submitting, setSubmitting] = useState(false);
  const user = useSelector((state) => state.User.profile);

  const {
    subscription_status: subscriptionStatus,
    cancelled_at: cancelledAt,
    cancellation_type_id: cancellationTypeId,
    cancellation_description: cancellationDescription,
  } = tenant || {};

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await gateway.get('/billing/cancellation-reasons');
        let data = response.data.map((type) => ({
          label: type.name,
          value: type.id,
        }));
        data.push({ label: 'Outro (descreva o motivo)', value: -1 });

        if (user.group.id !== roleGroups.ADMIN_MASTER) {
          data = data.filter((reason) => reason.label !== 'Ghosting');
        }

        setTypes(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchTypes();
  }, [subscriptionStatus]);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Cancelamento
      </DialogTitle>
      <DialogContent>
        <Box p={4} pt={4} pb={4} display="flex" flexDirection="column">
          {subscriptionStatus !== 3 && (
            <Box mb={2}>
              <Alert severity="error">
                ATENÇÃO! O cliente será inativado e terá a assinatura suspensa.
              </Alert>
            </Box>
          )}
          <Formik
            innerRef={formRef}
            validationSchema={CancelTenantSchema}
            validateOnMount
            initialValues={{
              type: cancellationTypeId,
              description: cancellationDescription,
              date: cancelledAt || new Date(),
            }}
            onSubmit={async (values) => {
              setSubmitting(true);
              const action =
                subscriptionStatus === 3 ? gateway.put : gateway.post;
              const cancellation = {
                ...values,
                type: values.type === -1 ? null : values.type,
              };
              try {
                await action(
                  `/billing/tenants/${params.id}/cancel`,
                  cancellation,
                );
                onSubmit();
              } catch (err) {
                console.error(err);
                const message =
                  err.response?.status === 400 && err.response.data?.message
                    ? err.response.data.message
                    : 'Falha ao cancelar o tenant';
                showError(message);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {() => (
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="date"
                      label="Data"
                      placeholder="Informe a data"
                      component={DateTimeField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="type"
                      label="Selecione o motivo"
                      placeholder="Selecione o motivo"
                      component={SelectField}
                      options={types}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="description"
                      label="Descrição"
                      placeholder="Descrição"
                      component={TextField}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </DialogContent>
      <DialogActions
        onClose={onClose}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Dialog>
  );
}
