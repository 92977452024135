import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Popover } from 'reactstrap';
import { connect } from 'react-redux';

import { getUser } from '../store/modules/user';
import { toggleSideMenu } from '../store/modules/sideMenu';
import clearStore from '../store/modules/app';
import '../styles/top-header.css';
import avatar from '../assets/ic_avatar.svg';
import Profile from './Profile';
import { URL_USER } from '../constants/s3';
import gateway from '../utils/gateway';
import history from '../config/history';
import LocalStorageManager from '../utils/LocalStorageManager';

const actions = {
  getUser,
  toggleSideMenu,
  resetApp: clearStore,
};

const mapStateToProps = ({ User, Breadcrumb, SideMenu }) => ({
  user: User.profile,
  menuOpen: SideMenu.menuOpen,
  breadCrumb: Breadcrumb.description,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

const TopHeader = ({
  user,
  menuOpen,
  resetApp,
  breadCrumb,
  toggleSideMenu,
}) => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    firstShow: true,
    popoverOpen: false,
    showProfile: false,
    showCompany: false,
    popoverOpenMobile: false,
  });

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { popoverOpen, showProfile, popoverOpenMobile } = state;

  const changeState = (newState) => {
    setState({
      ...state,
      ...newState,
    });
  };

  useEffect(() => {
    if (isMobile) {
      if (menuOpen) {
        toggleSideMenu(false);
      }
    } else {
      toggleSideMenu(true);
    }
  }, [isMobile]);

  const togglePopover = () => {
    let state = { popoverOpen: !popoverOpen };
    if (isMobile) {
      state = { popoverOpenMobile: !popoverOpenMobile };
    }
    changeState(state);
  };

  const closeProfile = () => {
    changeState({ showProfile: false });
  };

  const contentPopover = () => {
    return (
      <div className="menu-list">
        <div className="menu-item hide">
          <i className="ic-extract" />
          {t('common:revenues')}
        </div>
        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: 'inherit',
            padding: 0,
          }}
          onClick={async () => {
            await gateway.post('/auth/logout', null).catch(console.error);
            LocalStorageManager.removeToken();
            LocalStorageManager.removeRefreshToken();
            resetApp();
            history.push('/login');
          }}
        >
          <div className="menu-item">
            <i className="ic-logout" />
            {t('common:exit')}
          </div>
        </button>
      </div>
    );
  };

  return (
    <div className="content-header">
      <nav className="navbar">
        <div
          className="ic ic-sidemenu-mobile mobile"
          onClick={() => toggleSideMenu(!menuOpen)}
        />
        <div className="eye-breadcrumb">
          <div className="flex-block align-i-center">
            <>
              <span>{breadCrumb}</span>
            </>
          </div>
        </div>
        <div className="flex align-i-end align-i-center">
          <div
            className="fh align-i-center justify-center"
            style={{ flexShrink: 0 }}
            onClick={() => togglePopover(true)}
          >
            <img
              alt="avatar"
              id="info-mobile"
              className="avatar mobile"
              src={user.image ? `${URL_USER}${user.image}&size=36` : avatar}
            />
          </div>
          <div style={{ flexShrink: 0 }} onClick={() => togglePopover(false)}>
            <img
              src={user.image ? `${URL_USER}${user.image}&size=36` : avatar}
              alt="avatar"
              className="avatar desktop pointer"
              id="info-desktop"
            />
          </div>
          <Popover
            placement="top"
            isOpen={popoverOpen}
            className="popover-user"
            target="info-desktop"
            toggle={() => togglePopover(false)}
          >
            {contentPopover(false)}
          </Popover>
          <Popover
            placement="top"
            isOpen={popoverOpenMobile}
            className="popover-user mobile"
            target="info-mobile"
            toggle={() => togglePopover(true)}
          >
            {contentPopover(true)}
          </Popover>
        </div>
      </nav>
      <Profile show={showProfile} onClose={closeProfile} />
    </div>
  );
};

TopHeader.defaultProps = {
  breadCrumb: '',
  menuOpen: true,
  user: { tenant: { image: '' } },
};

TopHeader.propTypes = {
  menuOpen: PropTypes.bool,
  user: PropTypes.object,
  breadCrumb: PropTypes.string,
  toggleSideMenu: PropTypes.func.isRequired,
  resetApp: PropTypes.func.isRequired,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(TopHeader);
