import React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useFormik, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';

import { CircularProgress } from '@material-ui/core';
import Dialog from '../../components/Dialog';
import DialogTitle from '../../components/DialogTitle';
import DialogContent from '../../components/DialogContent';
import DialogActions from '../../components/DialogActions';
import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Nome deve conter até 255 caracteres'),
  language: Yup.string().required('Campo obrigatório'),
  group_id: Yup.string().required('Campo obrigatório'),
  email: Yup.string()
    .email('Email inválido')
    .required('Campo obrigatório'),
  phone: Yup.string().required('Campo obrigatório'),
  login: Yup.string().required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas não conferem')
    .required('Campo obrigatório'),
});

export default function NewUserFormDialog({ open, onClose, onSubmit }) {
  const params = useParams();

  const formRef = React.useRef();

  const [verifying, setVerifying] = React.useState(false);
  const [verified, setVerified] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      partner_id: params.partner_id,
      name: '',
      image: null,
      language: 'pt-br',
      group_id: '4',
      email: '',
      phone: '',
      login: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: UserSchema,
    onSubmit: async (values, formikBag) => {
      formikBag.setSubmitting(true);

      try {
        await gateway.post(`/billing/users`, {
          ...values,
          partner_id: '1',
        });
        onSubmit();
      } catch (err) {
        console.error(err);

        if (
          err.response.status === 400 &&
          err.response.data &&
          err.response.data.message
        ) {
          showError(err.response.data.message);
        } else {
          showError('Falha ao adicionar usuário');
        }
      } finally {
        formikBag.setSubmitting(false);
      }

      formikBag.setSubmitting(true);
      onSubmit(values, formikBag);
    },
    enableReinitialize: true,
  });

  const {
    values,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    setFieldError,
  } = formik;

  const handleVerify = async () => {
    setVerifying(true);
    setVerified(false);

    try {
      await gateway
        .get('/billing/users/verify', {
          params: { login: values.login },
        })
        .then(({ data }) => data);

      setVerified(true);
    } catch (err) {
      setVerified(false);
      if (err?.response?.data?.code === 'VALIDATION_ERROR') {
        const { message } = err.response.data;
        setFieldError('login', message);
      } else {
        showError(err?.response?.data?.message || 'Falha ao verificar login');
      }
    } finally {
      setVerifying(false);
    }
  };

  return (
    <FormikProvider ref={formRef} value={formik}>
      <Dialog onClose={onClose} open={open} maxWidth="md">
        <DialogTitle onClose={onClose}>Adicionar usuário</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={6}>
            <Grid item container direction="column" spacing={2}>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">Dados pessoais</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" spacing={1}>
                <Grid item container direction="row" spacing={2}>
                  <Grid item sm={8} xs={12}>
                    <Field
                      name="name"
                      label="Nome"
                      placeholder="Nome"
                      component={TextField}
                      fullWidth
                      error={errors.name && touched.name}
                      helperText={
                        errors.name && touched.name ? errors.name : ''
                      }
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name="language"
                      label="Idioma"
                      placeholder="Idioma"
                      component={SelectField}
                      options={[
                        { label: 'Português (Brasil)', value: 'pt-br' },
                        { label: 'English (USA)', value: 'en-us' },
                      ]}
                      error={errors.language && touched.language}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Field
                    name="group_id"
                    label="Grupo de acesso"
                    placeholder="Grupo de acesso"
                    component={SelectField}
                    options={[
                      { label: 'Parceiro (administrador)', value: '4' },
                      { label: 'Parceiro (staff)', value: '5' },
                    ]}
                    error={errors.group_id && touched.group_id}
                  />
                </Grid>
                <Grid item container direction="row" spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <Field
                      name="email"
                      label="E-mail"
                      placeholder="E-mail"
                      component={TextField}
                      fullWidth
                      error={errors.email && touched.email}
                      helperText={
                        errors.email && touched.email ? errors.email : ''
                      }
                      type="email"
                      inputProps={{ inputMode: 'email' }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Field
                      name="phone"
                      label="Telefone"
                      placeholder="Telefone"
                      component={TextField}
                      fullWidth
                      error={errors.phone && touched.phone}
                      helperText={
                        errors.phone && touched.phone ? errors.phone : ''
                      }
                      mask={[
                        '+',
                        '5',
                        '5',
                        ' ',
                        '(',
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d?/,
                      ]}
                      inputProps={{ inputMode: 'tel' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">Acesso</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" spacing={1}>
                <Grid item>
                  <Field
                    name="login"
                    label="Login"
                    placeholder="Login"
                    component={TextField}
                    fullWidth
                    error={errors.login && touched.login}
                    helperText={
                      errors.login && touched.login ? errors.login : ''
                    }
                    onChange={(e) => {
                      setVerified(false);
                      setFieldValue('login', e.target.value);
                    }}
                    InputProps={{
                      endAdornment: verified ? (
                        <CheckCircleIcon color="primary" />
                      ) : (
                        <InputAdornment position="end">
                          <Button
                            variant="outlined"
                            size="large"
                            onClick={handleVerify}
                            disabled={
                              values.login === '' ||
                              verified === true ||
                              verifying
                            }
                          >
                            {verifying ? (
                              <CircularProgress size={24} />
                            ) : (
                              'Verificar'
                            )}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item container direction="row" spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <Field
                      name="password"
                      label="Senha"
                      placeholder="Senha"
                      component={TextField}
                      fullWidth
                      error={errors.password && touched.password}
                      helperText={
                        errors.password && touched.password
                          ? errors.password
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Field
                      name="password_confirmation"
                      label="Confirmação da senha"
                      placeholder="Confirmação da senha"
                      component={TextField}
                      fullWidth
                      error={
                        errors.password_confirmation &&
                        touched.password_confirmation
                      }
                      helperText={
                        errors.password_confirmation &&
                        touched.password_confirmation
                          ? errors.password_confirmation
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          onClose={onClose}
          onSubmit={handleSubmit}
          submitting={isSubmitting}
        />
      </Dialog>
    </FormikProvider>
  );
}
