import React from 'react';
import { useParams } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import Wizard from '../../components/Wizard/Wizard';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import TenantStep from './Steps/TenantStep';
import ProposalStep from './Steps/ProposalStep';
import ItemsStep from './Steps/ItemsStep';
import ReviewStep from './Steps/ReviewStep';

const NewContractProposalWizard = () => {
  const params = useParams();

  const [loading, setLoading] = React.useState(true);
  const [context, setContext] = React.useState({});

  const fetchContext = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(`/billing/tenants/${params.id}`);
      const { tenant } = response.data;

      setContext({
        tenant: {
          ...tenant,
          value: tenant.id,
          label: tenant.name,
        },
        name: tenant.name,
      });
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchContext();
  }, []);

  if (loading) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={84} />
      </Box>
    );
  }

  return (
    <Fade in>
      <Wizard context={context}>
        <TenantStep label="Selecionar Negócio" guided />
        <ItemsStep label="Editar Itens" recurrent />
        <ProposalStep label="Proposta" />
        <ReviewStep label="Revisão" contract />
      </Wizard>
    </Fade>
  );
};

export default NewContractProposalWizard;
