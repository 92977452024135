import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { PermissionContext } from '../../contexts/PermissionContext';
import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import CancellationReasonFormDialog from './CancellationReasonFormDialog';

const COLUMNS = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Nome' },
];

const ItemsTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: '8px 16px',
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const ButtonTableCell = withStyles(() => ({
  root: {
    width: 1,
    padding: 0,
    border: 0,
  },
}))(TableCell);

const CancellationReasonsTab = ({ show }) => {
  const { authorized } = useContext(PermissionContext);
  const [loading, setLoading] = useState(false);
  const [cancellationReasons, setCancellationReasons] = useState([]);
  const [cancellationReason, setCancellationReason] = useState(null);
  const [
    showCancellationReasonFormDialog,
    setShowCancellationReasonFormDialog,
  ] = useState(false);

  const fetchCancellationReasons = async () => {
    setLoading(true);
    try {
      const { data } = await gateway.get('/billing/cancellation-reasons');
      setCancellationReasons(data);
    } catch (err) {
      console.error(err);
      showError();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      fetchCancellationReasons();
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <>
      <Fade in>
        <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
          {authorized('admin.tools') && (
            <Box
              mb={2}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowCancellationReasonFormDialog(true)}
                startIcon={<AddIcon />}
              >
                Adicionar
              </Button>
            </Box>
          )}

          <Box pt={4}>
            <TableContainer>
              <Table aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((column) => (
                      <ItemsTableCell key={column.id}>
                        <Typography variant="subtitle2" color="primary">
                          {column.label}
                        </Typography>
                      </ItemsTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading &&
                    [1, 2, 3].map((value) => (
                      <TableRow tabIndex={-1} key={value}>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                      </TableRow>
                    ))}
                  {!loading &&
                    cancellationReasons.map((row) => (
                      <ItemsTableRow key={row.id}>
                        <ItemsTableCell>
                          <Typography variant="subtitle1">{row.id}</Typography>
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Typography variant="body1">{row.name}</Typography>
                        </ItemsTableCell>
                        <ButtonTableCell align="center">
                          <IconButton
                            onClick={() => {
                              setCancellationReason(row);
                              setShowCancellationReasonFormDialog(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </ButtonTableCell>
                      </ItemsTableRow>
                    ))}
                  {!loading && cancellationReasons.length === 0 && (
                    <TableRow>
                      <ItemsTableCell colSpan={COLUMNS.length} align="center">
                        <Box m={4}>
                          <Typography>
                            Nenhuma motivo de cancelamento encontrado
                          </Typography>
                        </Box>
                      </ItemsTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Fade>
      <CancellationReasonFormDialog
        open={showCancellationReasonFormDialog}
        cancellationReason={cancellationReason}
        onClose={() => {
          setCancellationReason(null);
          setShowCancellationReasonFormDialog(false);
        }}
        onSubmit={() => {
          setCancellationReason(null);
          fetchCancellationReasons();
          setShowCancellationReasonFormDialog(false);
        }}
      />
    </>
  );
};

export default CancellationReasonsTab;
