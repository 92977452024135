import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

const LoadMoreScroll = ({ action, isLoading }) => {
  const [hasCalledOnce, sethasCalledOnce] = useState(false);
  return (
    !isLoading && (
      <Waypoint
        topOffset={10}
        onEnter={() => {
          if (!hasCalledOnce) {
            action();
            sethasCalledOnce(true);
          }
        }}
        onLeave={() => {
          sethasCalledOnce(false);
        }}
        onPositionChange={() => {
          sethasCalledOnce(false);
        }}
      >
        <div style={{ height: 10 }} />
      </Waypoint>
    )
  );
};

LoadMoreScroll.defaultProps = {
  target: null,
  action: () => {},
  isLoading: false,
};

LoadMoreScroll.propTypes = {
  target: PropTypes.string,
  action: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default LoadMoreScroll;
