import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import TextField from '../../../components/TextField';
import DateField from '../../../components/DateField';
import RichTextField from '../../../components/RichTextField';

const SpotSchema = Yup.object().shape({
  expires_at: Yup.date()
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  email: Yup.string()
    .nullable()
    .email('Email inválido')
    .max(255, 'Email deve conter até 255 caracteres'),
  phone: Yup.string().nullable(),
});

const useStyles = makeStyles(() => ({
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}));

const SpotStep = ({
  show,
  step,
  total,
  onPreviousStep,
  onNextStep,
  context,
}) => {
  const classes = useStyles();

  const formRef = React.useRef();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <Fade in>
      <Paper square elevation={2} style={{ flex: 1 }}>
        <Box p={0} display="flex" flexDirection="column">
          <Box p={4} pt={6} display="flex" flexDirection="column">
            <Typography variant="subtitle2" color="primary">
              Passo {step + 1} de {total}
            </Typography>
            <Typography variant="h4">Proposta</Typography>
          </Box>
          <Box p={4} pt={4} pb={8} display="flex" flexDirection="column">
            <Formik
              innerRef={formRef}
              initialValues={{
                expires_at:
                  context.expires_at ||
                  moment()
                    .add(7, 'days')
                    .startOf('day'),
                observations: context.observations || '',
                email: context.email || '',
                phone: context.phone || '',
              }}
              validationSchema={SpotSchema}
              onSubmit={async (values) => {
                const { observations } = values;

                let formattedObservations;
                if (observations === null || observations === '') {
                  formattedObservations = '';
                } else {
                  const editor = convertFromRaw(JSON.parse(observations));
                  formattedObservations = editor.hasText()
                    ? stateToHTML(editor)
                    : '';
                }

                onNextStep({
                  ...context,
                  ...values,
                  formattedObservations,
                });
              }}
              render={({ errors, touched }) => {
                return (
                  <form>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Typography variant="h5">Dados gerais</Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            name="expires_at"
                            label="Data de expiração da proposta"
                            placeholder="DD/MM/YYYY"
                            format="DD/MM/YYYY"
                            disableToolbar
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            minDate={new Date()}
                            component={DateField}
                            error={errors.expires_at && touched.expires_at}
                            helperText={
                              errors.expires_at && touched.expires_at
                                ? errors.expires_at
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="observations"
                            label="Observações"
                            placeholder="Observações"
                            component={RichTextField}
                            defaultValue={context.observations || null}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">Dados de envio</Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <Field
                            name="email"
                            label="Email"
                            placeholder="Email"
                            type="email"
                            component={TextField}
                            error={errors.email && touched.email}
                            helperText={
                              errors.email && touched.email ? errors.email : ''
                            }
                            inputProps={{ inputMode: 'email' }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Field
                            name="phone"
                            label="Telefone"
                            placeholder="Telefone"
                            component={TextField}
                            error={errors.phone && touched.phone}
                            helperText={
                              errors.phone && touched.phone ? errors.phone : ''
                            }
                            mask={[
                              '(',
                              /\d/,
                              /\d/,
                              ')',
                              ' ',
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d?/,
                            ]}
                            inputProps={{ inputMode: 'tel' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            />
          </Box>
          <Box
            p={2}
            pl={4}
            pr={4}
            display="flex"
            justifyContent="space-between"
            borderTop="1px solid #ededed"
          >
            <Button
              color="default"
              onClick={() =>
                onPreviousStep({
                  ...context,
                })
              }
              className={classes.cancel}
              startIcon={<ChevronLeftIcon />}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.confirm}
              endIcon={<ChevronRightIcon />}
            >
              Próximo
            </Button>
          </Box>
        </Box>
      </Paper>
    </Fade>
  );
};

export default SpotStep;
