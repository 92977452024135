import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import TextField from '../../components/TextField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: '1px solid #EDEFF0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #EDEFF0',
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}))((props) => {
  const { classes, onClose, onSubmit, submitting, ...other } = props;

  return (
    <MuiDialogActions className={classes.root} {...other}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onClose}
        className={classes.cancel}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        className={classes.confirm}
        endIcon={
          submitting ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <CheckIcon />
          )
        }
      >
        Salvar
      </Button>
    </MuiDialogActions>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const PartnerSchema = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Nome deve conter até 255 caracteres'),
  email: Yup.string()
    .email('Email inválido')
    .max(255, 'Email deve conter até 255 caracteres')
    .required('Campo obrigatório'),
});

export default function EditPartnerDialog({
  open,
  onClose,
  onSubmit,
  partner,
}) {
  const formRef = React.useRef();

  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Editar revenda
      </DialogTitle>
      <DialogContent>
        <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
              name: partner && partner.name ? partner.name : '',
              email: partner && partner.email ? partner.email : '',
            }}
            validationSchema={PartnerSchema}
            onSubmit={async (values) => {
              setSubmitting(true);

              try {
                await gateway.post(`/billing/partners/${partner.id}`, {
                  ...values,
                });
                onSubmit();
              } catch (err) {
                console.error(err);

                if (
                  err.response.status === 400 &&
                  err.response.data &&
                  err.response.data.message
                ) {
                  showError(err.response.data.message);
                } else {
                  showError('Falha ao editar revenda');
                }
              } finally {
                setSubmitting(false);
              }
            }}
            render={({ errors, touched }) => {
              return (
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="name"
                        label="Nome"
                        placeholder="Nome"
                        component={TextField}
                        error={errors.name && touched.name}
                        helperText={
                          errors.name && touched.name ? errors.name : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="email"
                        label="Email"
                        placeholder="Email"
                        component={TextField}
                        error={errors.email && touched.email}
                        helperText={
                          errors.email && touched.email ? errors.email : ''
                        }
                        inputProps={{ inputMode: 'email' }}
                      />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        onClose={onClose}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Dialog>
  );
}
