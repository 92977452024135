import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { default as MuiChip } from '@material-ui/core/Chip';

const StyledChip = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 10,
    color: ({ type }) => {
      if (type === 'error') return theme.palette.danger[500];
      return theme.palette.text[500];
    },
    backgroundColor: ({ type }) => {
      if (type === 'error') return theme.palette.danger[50];
      return theme.palette.text[50];
    },
  },
  icon: {
    color: ({ type }) => {
      if (type === 'error') return theme.palette.danger[500];
      return theme.palette.text[500];
    },
  },
}))(MuiChip);

export default function Chip(props) {
  return <StyledChip {...props} />;
}
