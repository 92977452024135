import React from 'react';

import EyeModal from '../../components/EyeModal';
import TenantChildPointsModalContent from './TenantChildPointsModalContent';

const TenantChildPointsModal = ({
  visible,
  onClose,
  parentTenant,
  childTenant,
}) => {
  return (
    <EyeModal visible={visible} size="large" padding="25px 65px">
      <TenantChildPointsModalContent
        onClose={onClose}
        parentTenant={parentTenant}
        childTenant={childTenant}
      />
    </EyeModal>
  );
};

export default TenantChildPointsModal;
