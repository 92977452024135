import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import EyeButton from './EyeButton';

const EyeModalContent = ({
  text,
  style,
  title,
  width,
  subtitle,
  onCancel,
  disabled,
  children,
  isDialog,
  onConfirm,
  className,
  cancelLabel,
  confirmType,
  confirmLabel,
  contentStyle,
  confirmLoading,
  childrenClassName,
  contentClassName,
}) => {
  const { t } = useTranslation();

  return (
    <div style={style} className={className}>
      {title && (
        <p
          className="avenir-medium-gray-title text-uppercase break-center"
          style={{ margin: 0 }}
        >
          {title}
        </p>
      )}
      {text && (
        <p className="modal-text avenir-medium-gray-md break-center mt-30 mb0">
          {text}
        </p>
      )}
      <div className="flex-align-center">
        <div className="title-list">{subtitle}</div>
        {children && (
          <div
            className={clsx(contentClassName)}
            style={{
              width: width || '75%',
              ...contentStyle,
            }}
          >
            <div
              className={clsx('flex-block align-i-center', childrenClassName)}
            >
              {children}
            </div>
          </div>
        )}
      </div>
      <div className="flex-align-center-row justify-center mt-30">
        {!isDialog && (
          <EyeButton
            className="btn-md btn-solid-red uppercase mr-md"
            clickHandler={onCancel}
            label={t(cancelLabel)}
            disabled={disabled}
            type="button"
          />
        )}
        <EyeButton
          className={clsx('btn-md uppercase', {
            'btn-solid-green': !isDialog,
            'btn-solid-blue': isDialog,
          })}
          clickHandler={onConfirm}
          label={t(confirmLabel)}
          type={confirmType}
          disabled={disabled || confirmLoading}
          isLoading={confirmLoading}
        />
      </div>
    </div>
  );
};

EyeModalContent.defaultProps = {
  confirmLabel: 'button:save',
  cancelLabel: 'common:cancel',
  confirmType: 'button',
  onConfirm: () => {},
  onCancel: () => {},
  title: '',
  subtitle: '',
  disabled: false,
  text: '',
  confirmLoading: false,
  style: undefined,
  contentStyle: undefined,
  children: undefined,
  className: undefined,
  contentClassName: undefined,
  isDialog: false,
  width: undefined,
  childrenClassName: undefined,
};

EyeModalContent.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmType: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  confirmLoading: PropTypes.bool,
  style: PropTypes.object,
  contentStyle: PropTypes.object,
  contentClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.func,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  childrenClassName: PropTypes.string,
  width: PropTypes.string,
  isDialog: PropTypes.bool,
};

export default EyeModalContent;
