import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { compose } from 'redux';
import { protectedRoutes, commonRoutes } from './routePaths';
import { withBreadcrumb } from '../components/BreadcrumbEnhance';
import { withUserLoader } from '../components/UserLoaderEnhance';
import { PermissionProvider } from '../contexts/PermissionContext';

const renderRoute = (route) => {
  return (
    <Route
      key={route.path}
      path={route.path}
      component={route.component}
      exact
    />
  );
};

const Routes = () => {
  return (
    <PermissionProvider>
      <Switch>
        {protectedRoutes.map(renderRoute)}
        {commonRoutes.map(renderRoute)}
        <Redirect to="/tenants" />
      </Switch>
    </PermissionProvider>
  );
};

const enhance = compose(
  withBreadcrumb,
  withUserLoader,
);

export default enhance(Routes);
