import React from 'react';
import { default as MuiTextField } from '@material-ui/core/TextField';
import MUIRichTextEditor from 'mui-rte';

function RichTextCustom({
  inputRef,
  onChange,
  onBlur,
  value,
  defaultValue,
  ...other
}) {
  const editorRef = React.useRef(null);

  return (
    <MUIRichTextEditor
      {...other}
      defaultValue={defaultValue}
      controls={[
        'title',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'undo',
        'redo',
        'link',
        'numberList',
        'bulletList',
      ]}
      onChange={() => {
        editorRef.current.save();
      }}
      onSave={(data) => {
        onChange({
          target: {
            name: other.name,
            value: data,
          },
        });
      }}
      ref={editorRef}
    />
  );
}

const RichTextField = ({
  field,
  variant,
  InputProps,
  defaultValue,
  ...rest
}) => {
  return (
    <MuiTextField
      {...field}
      {...rest}
      variant={variant || 'filled'}
      fullWidth
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...InputProps,
        defaultValue,
        inputComponent: RichTextCustom,
      }}
    />
  );
};

export default RichTextField;
