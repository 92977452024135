import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import Skeleton from '@material-ui/lab/Skeleton';

import Tabs from '../../components/Tabs';
import PartnerStatus from '../../components/PartnerStatus';

import { PermissionContext } from '../../contexts/PermissionContext';
import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import UsersTab from './UsersTab';
import TenantsTab from './TenantsTab';

import EditPartnerDialog from './EditPartnerDialog';
import InactivatePartnerDialog from './InactivatePartnerDialog';
import ActivatePartnerDialog from './ActivatePartnerDialog';

const PartnerDetailPage = () => {
  const params = useParams();
  const history = useHistory();

  const { authorized } = React.useContext(PermissionContext);

  const [loading, setLoading] = React.useState(true);
  const [partner, setPartner] = React.useState(null);

  const [showEditPartnerDialog, setShowEditPartnerDialog] = React.useState(
    false,
  );
  const [
    showInactivatePartnerDialog,
    setShowInactivatePartnerDialog,
  ] = React.useState(false);
  const [
    showActivatePartnerDialog,
    setShowActivatePartnerDialog,
  ] = React.useState(false);

  const fetchPartner = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(
        `/billing/partners/${params.partner_id}`,
      );

      setPartner(response.data.partner);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchPartner();
  }, []);

  return (
    <>
      <Fade in>
        <Box flex={1}>
          <Paper square elevation={2}>
            <Box display="flex" flexDirection="column">
              <Box pt={6} p={4} display="flex" flexDirection="column">
                <Box alignSelf="flex-start">
                  <Button
                    onClick={() => history.push('/partners')}
                    startIcon={<ChevronLeftIcon />}
                  >
                    Voltar para revendas
                  </Button>
                </Box>
                <Box mt={2} display="flex" flexDirection="column">
                  {loading ? (
                    <>
                      <Skeleton variant="text" height={42} width={180} />
                      <Skeleton variant="text" height={24} width={120} />
                    </>
                  ) : (
                    <Box display="flex" alignItems="center">
                      <Box mr={2}>
                        <Typography variant="h4">{partner.name}</Typography>
                      </Box>
                      <Box mr={2}>
                        <PartnerStatus status={partner.status} />
                      </Box>
                      <Box
                        flex={1}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        {authorized('partners.edit') && (
                          <Box mr={2}>
                            <Button
                              variant="outlined"
                              color="primary"
                              startIcon={<EditIcon />}
                              onClick={() => setShowEditPartnerDialog(true)}
                            >
                              Editar
                            </Button>
                          </Box>
                        )}
                        {partner &&
                          partner.status === 0 &&
                          authorized('partners.activate') && (
                            <Box mr={2}>
                              <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<BlockIcon />}
                                onClick={() =>
                                  setShowActivatePartnerDialog(true)
                                }
                              >
                                Ativar
                              </Button>
                            </Box>
                          )}
                        {partner &&
                          partner.status === 1 &&
                          authorized('partners.inactivate') && (
                            <Box mr={2}>
                              <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<BlockIcon />}
                                onClick={() =>
                                  setShowInactivatePartnerDialog(true)
                                }
                              >
                                Inativar
                              </Button>
                            </Box>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Tabs>
                <UsersTab label="Usuários" partner={partner} />
                <TenantsTab label="Clientes" partner={partner} />
              </Tabs>
            </Box>
          </Paper>
        </Box>
      </Fade>
      <EditPartnerDialog
        open={showEditPartnerDialog}
        onClose={() => {
          setShowEditPartnerDialog(false);
        }}
        onSubmit={() => {
          fetchPartner();
          setShowEditPartnerDialog(false);
        }}
        partner={partner}
      />
      <InactivatePartnerDialog
        open={showInactivatePartnerDialog}
        onClose={() => {
          setShowInactivatePartnerDialog(false);
        }}
        onSubmit={() => {
          fetchPartner();
          setShowInactivatePartnerDialog(false);
        }}
      />
      <ActivatePartnerDialog
        open={showActivatePartnerDialog}
        onClose={() => {
          setShowActivatePartnerDialog(false);
        }}
        onSubmit={() => {
          fetchPartner();
          setShowActivatePartnerDialog(false);
        }}
      />
    </>
  );
};

export default PartnerDetailPage;
