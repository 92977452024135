import * as React from 'react';
import * as PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import clsx from 'clsx';

function EyeButton({
  id,
  label,
  className,
  selected,
  show,
  style,
  clickHandler,
  onMouseEnter,
  onMouseOut,
  onBlur,
  children,
  disabled,
  type,
  isLoading,
  loadColor,
  value,
  form,
  blue,
}) {
  return (
    show && (
      <button
        id={id}
        className={clsx(
          'btn-view align-center pointer justify-center',
          className,
          { selected },
          blue && 'btn-solid-blue',
        )}
        onKeyPress={clickHandler}
        style={style}
        tabIndex={0}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        onClick={clickHandler}
        onBlur={onBlur}
        type={type}
        value={value}
        form={form}
      >
        {children}
        {label}
        {isLoading && (
          <div className="btn-loading">
            <ReactLoading
              type="spin"
              color={loadColor}
              height={20}
              width={20}
            />
          </div>
        )}
      </button>
    )
  );
}

EyeButton.defaultProps = {
  id: null,
  label: '',
  className: 'mb-20',
  show: true,
  clickHandler: null,
  onMouseEnter: null,
  onMouseOut: null,
  onBlur: null,
  selected: true,
  disabled: false,
  isLoading: false,
  loadColor: '#fff',
  type: 'submit',
  style: {},
  value: '',
  form: null,
  children: null,
  blue: undefined,
};

EyeButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  show: PropTypes.bool,
  selected: PropTypes.bool,
  className: PropTypes.string,
  clickHandler: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseOut: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadColor: PropTypes.string,
  children: PropTypes.object,
  value: PropTypes.string,
  form: PropTypes.string,
  blue: PropTypes.bool,
};

export default EyeButton;
