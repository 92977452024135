import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { validate } from 'cnpj';

import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import CheckBoxField from '../../components/CheckBoxField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: '1px solid #EDEFF0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #EDEFF0',
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}))((props) => {
  const { classes, onClose, onSubmit, submitting, ...other } = props;

  return (
    <MuiDialogActions className={classes.root} {...other}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onClose}
        className={classes.cancel}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        className={classes.confirm}
        endIcon={
          submitting ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <CheckIcon />
          )
        }
      >
        Salvar
      </Button>
    </MuiDialogActions>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const TenantSchema = Yup.object().shape({
  document: Yup.string()
    .required('Campo obrigatório')
    .length(18, 'CNPJ inválido')
    .test('cnpj', 'CNPJ inválido', (value) => {
      return validate(value);
    }),
  name: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Razão social deve conter até 255 caracteres'),
  label: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Nome fantasia deve conter até 255 caracteres'),
  address: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Endereço deve conter até 255 caracteres'),
  number: Yup.string().required('Campo obrigatório'),
  address2: Yup.string().nullable(),
  district: Yup.string().required('Campo obrigatório'),
  city: Yup.string().required('Campo obrigatório'),
  state: Yup.string()
    .required('Campo obrigatório')
    .length(2, 'Estado inválido'),
  zipcode: Yup.string()
    .required('Campo obrigatório')
    .length(9, 'CEP inválido'),
  email: Yup.string()
    .email('Email inválido')
    .max(255, 'Email deve conter até 255 caracteres')
    .required('Campo obrigatório'),
  phone: Yup.string().required('Campo obrigatório'),
  has_stock: Yup.boolean().required('Campo obrigatório'),
  has_loyalty: Yup.boolean().required('Campo obrigatório'),
  has_goals: Yup.boolean().required('Campo obrigatório'),
  has_vouchers: Yup.boolean().required('Campo obrigatório'),
  has_accounts: Yup.boolean().required('Campo obrigatório'),
  has_orders: Yup.boolean().required('Campo obrigatório'),
  has_eyepass: Yup.boolean().required('Campo obrigatório'),
});

export default function EditTenantDialog({ open, onClose, onSubmit, tenant }) {
  const formRef = React.useRef();

  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Editar cliente
      </DialogTitle>
      <DialogContent>
        <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
              document: tenant && tenant.document ? tenant.document : '',
              name: tenant && tenant.name ? tenant.name : '',
              label: tenant && tenant.label ? tenant.label : '',
              address: tenant && tenant.address ? tenant.address : '',
              number:
                tenant && tenant.address_number ? tenant.address_number : '',
              address2: tenant && tenant.address2 ? tenant.address2 : null,
              district: tenant && tenant.district ? tenant.district : '',
              city: tenant && tenant.city ? tenant.city : '',
              state: tenant && tenant.state ? tenant.state : '',
              zipcode: tenant && tenant.zipcode ? tenant.zipcode : '',
              email: tenant && tenant.email ? tenant.email : '',
              phone: tenant && tenant.phone ? tenant.phone : '',
              has_stock: tenant && tenant.has_stock ? tenant.has_stock : false,
              has_loyalty:
                tenant && tenant.has_loyalty ? tenant.has_loyalty : false,
              has_goals: tenant && tenant.has_goals ? tenant.has_goals : false,
              has_vouchers:
                tenant && tenant.has_vouchers ? tenant.has_vouchers : false,
              has_accounts:
                tenant && tenant.has_accounts ? tenant.has_accounts : false,
              has_orders:
                tenant && tenant.has_orders ? tenant.has_orders : false,
              has_eyepass:
                tenant && tenant.has_eyepass ? tenant.has_eyepass : false,
            }}
            validationSchema={TenantSchema}
            onSubmit={async (values) => {
              setSubmitting(true);

              try {
                await gateway.post(`/billing/tenants/${tenant.id}`, {
                  document: values.document.replace(/[^\d]/g, ''),
                  ...values,
                });
                onSubmit();
              } catch (err) {
                console.error(err);

                if (
                  err.response.status === 400 &&
                  err.response.data &&
                  err.response.data.message
                ) {
                  showError(err.response.data.message);
                } else {
                  showError('Falha ao editar cliente');
                }
              } finally {
                setSubmitting(false);
              }
            }}
            render={({ errors, touched }) => {
              return (
                <form>
                  <Grid container spacing={4}>
                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Dados da empresa</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="document"
                          label="CNPJ"
                          placeholder="CNPJ"
                          mask={[
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                            '/',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                          ]}
                          component={TextField}
                          error={errors.document && touched.document}
                          helperText={
                            errors.document && touched.document
                              ? errors.document
                              : ''
                          }
                          inputProps={{ inputMode: 'numeric' }}
                        />
                      </Grid>
                      <Grid item sm={7} xs={12}>
                        <Field
                          name="name"
                          label="Razão Social"
                          placeholder="Razão Social"
                          component={TextField}
                          error={errors.name && touched.name}
                          helperText={
                            errors.name && touched.name ? errors.name : ''
                          }
                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        <Field
                          name="label"
                          label="Nome Fantasia"
                          placeholder="Nome Fantasia"
                          component={TextField}
                          error={errors.label && touched.label}
                          helperText={
                            errors.label && touched.label ? errors.label : ''
                          }
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Field
                          name="address"
                          label="Logradouro"
                          placeholder="Logradouro"
                          component={TextField}
                          error={errors.address && touched.address}
                          helperText={
                            errors.address && touched.address
                              ? errors.address
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item sm={2} xs={12}>
                        <Field
                          name="number"
                          label="Número"
                          placeholder="Número"
                          component={TextField}
                          error={errors.number && touched.number}
                          helperText={
                            errors.number && touched.number ? errors.number : ''
                          }
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Field
                          name="address2"
                          label="Complemento"
                          placeholder="Compl."
                          component={TextField}
                          error={errors.address2 && touched.address2}
                          helperText={
                            errors.address2 && touched.address2
                              ? errors.address2
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <Field
                          name="district"
                          label="Bairro"
                          placeholder="Bairro"
                          component={TextField}
                          error={errors.district && touched.district}
                          helperText={
                            errors.district && touched.district
                              ? errors.district
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Field
                          name="city"
                          label="Cidade"
                          placeholder="Cidade"
                          component={TextField}
                          error={errors.city && touched.city}
                          helperText={
                            errors.city && touched.city ? errors.city : ''
                          }
                        />
                      </Grid>
                      <Grid item sm={2} xs={12}>
                        <Field
                          name="state"
                          label="Estado"
                          placeholder="Estado"
                          component={SelectField}
                          options={[
                            { label: 'AC', value: 'AC' },
                            { label: 'AL', value: 'AL' },
                            { label: 'AP', value: 'AP' },
                            { label: 'AM', value: 'AM' },
                            { label: 'BA', value: 'BA' },
                            { label: 'CE', value: 'CE' },
                            { label: 'DF', value: 'DF' },
                            { label: 'ES', value: 'ES' },
                            { label: 'GO', value: 'GO' },
                            { label: 'MA', value: 'MA' },
                            { label: 'MT', value: 'MT' },
                            { label: 'MS', value: 'MS' },
                            { label: 'MG', value: 'MG' },
                            { label: 'PA', value: 'PA' },
                            { label: 'PB', value: 'PB' },
                            { label: 'PR', value: 'PR' },
                            { label: 'PE', value: 'PE' },
                            { label: 'PI', value: 'PI' },
                            { label: 'RJ', value: 'RJ' },
                            { label: 'RN', value: 'RN' },
                            { label: 'RS', value: 'RS' },
                            { label: 'RO', value: 'RO' },
                            { label: 'RR', value: 'RR' },
                            { label: 'SC', value: 'SC' },
                            { label: 'SP', value: 'SP' },
                            { label: 'SE', value: 'SE' },
                            { label: 'TO', value: 'TO' },
                          ]}
                          error={errors.state && touched.state}
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <Field
                          name="zipcode"
                          label="CEP"
                          placeholder="CEP"
                          component={TextField}
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          error={errors.zipcode && touched.zipcode}
                          helperText={
                            errors.zipcode && touched.zipcode
                              ? errors.zipcode
                              : ''
                          }
                          inputProps={{
                            maxLength: 8,
                            inputMode: 'numeric',
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Responsável financeiro
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Field
                          name="email"
                          label="Email"
                          placeholder="Email"
                          component={TextField}
                          error={errors.email && touched.email}
                          helperText={
                            errors.email && touched.email ? errors.email : ''
                          }
                          inputProps={{ inputMode: 'email' }}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Field
                          name="phone"
                          label="Telefone"
                          placeholder="Telefone"
                          component={TextField}
                          error={errors.phone && touched.phone}
                          helperText={
                            errors.phone && touched.phone ? errors.phone : ''
                          }
                          mask={[
                            '(',
                            /\d/,
                            /\d/,
                            ')',
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d?/,
                          ]}
                          inputProps={{ inputMode: 'tel' }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Módulos</Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Field
                          name="has_stock"
                          label="Estoque"
                          component={CheckBoxField}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Field
                          name="has_loyalty"
                          label="Fidelidade"
                          component={CheckBoxField}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Field
                          name="has_goals"
                          label="Metas"
                          component={CheckBoxField}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Field
                          name="has_vouchers"
                          label="Vouchers"
                          component={CheckBoxField}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Field
                          name="has_accounts"
                          label="Comandas"
                          component={CheckBoxField}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Field
                          name="has_orders"
                          label="Pedidos"
                          component={CheckBoxField}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Field
                          name="has_eyepass"
                          label="Eyepass"
                          component={CheckBoxField}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        onClose={onClose}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Dialog>
  );
}
