import { withMainTemplate } from '../components/MainTemplate';
import DistributionCentersPage from '../components/DistributionCentersPage';
import DevicesGroupPage from '../components/DevicesGroupPage';
import DevicesPage from '../components/DevicesPage';
import DeviceStatusPage from '../components/DeviceStatusPage';
import ProposalsPage from '../pages/ProposalsPage';
import ProposalDetailPage from '../pages/ProposalDetailPage';
import ContractProposalWizard from '../pages/ProposalWizard/ContractProposalWizard';
import EditProposalWizard from '../pages/ProposalWizard/EditProposalWizard';
import SpotProposalWizard from '../pages/ProposalWizard/SpotProposalWizard';
import AddContractWizard from '../pages/ProposalWizard/AddContractWizard';
import NewTenantWizard from '../pages/ProposalWizard/NewTenantWizard';
import ContractAmendmentProposalWizard from '../pages/ProposalWizard/ContractAmendmentProposalWizard';
import NewContractProposalWizard from '../pages/ProposalWizard/NewContractProposalWizard';
import SubscriptionWizard from '../pages/ProposalWizard/SubscriptionWizard';
import TenantsPage from '../pages/TenantsPage';
import TenantDetailPage from '../pages/TenantDetailPage';
import PartnersPage from '../pages/PartnersPage';
import PartnerDetailPage from '../pages/PartnerDetailPage';
import ChargeDetailPage from '../pages/ChargeDetailPage';
import ContractDetailPage from '../pages/ContractDetailPage';
import SettingsPage from '../pages/SettingsPage';
import LoginPageContainer from '../components/LoginPageContainer';

export const protectedRoutes = [
  {
    path: '/login',
    component: LoginPageContainer,
  },
];

export const commonRoutes = [
  {
    path: '/devices',
    component: DevicesPage,
  },
  {
    path: '/distributionCenters',
    component: DistributionCentersPage,
  },
  {
    path: '/devicesGroup',
    component: DevicesGroupPage,
  },
  {
    path: '/deviceStatus',
    component: DeviceStatusPage,
  },
  {
    path: '/proposals',
    component: ProposalsPage,
  },
  {
    path: '/proposals/contract',
    component: ContractProposalWizard,
  },
  {
    path: '/proposals/:id',
    component: ProposalDetailPage,
  },
  {
    path: '/proposals/:id/edit',
    component: EditProposalWizard,
  },
  {
    path: '/tenants',
    component: TenantsPage,
  },
  {
    path: '/tenants/new',
    component: NewTenantWizard,
  },
  {
    path: '/tenants/:id',
    component: TenantDetailPage,
  },
  {
    path: '/tenants/:id/spot',
    component: SpotProposalWizard,
  },
  {
    path: '/tenants/:id/amendment',
    component: ContractAmendmentProposalWizard,
  },
  {
    path: '/tenants/:id/subscription',
    component: SubscriptionWizard,
  },
  {
    path: '/tenants/:id/contract',
    component: NewContractProposalWizard,
  },
  {
    path: '/tenants/:id/contracts/add',
    component: AddContractWizard,
  },
  {
    path: '/tenants/:tenant_id/charges/:charge_id',
    component: ChargeDetailPage,
  },
  {
    path: '/tenants/:tenant_id/contracts/:contract_id',
    component: ContractDetailPage,
  },
  {
    path: '/partners',
    component: PartnersPage,
  },
  {
    path: '/partners/:partner_id',
    component: PartnerDetailPage,
  },
  {
    path: '/settings',
    component: SettingsPage,
  },
].map((route) => ({ ...route, component: withMainTemplate(route.component) }));
