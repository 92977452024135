import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import LoginPage from './LoginPage';

const LoginPageContainer = (props) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdXRq8kAAAAAOlYMWi30EIwxpMpLi9TroX0EPLy">
      <LoginPage {...props} />
    </GoogleReCaptchaProvider>
  );
};

export default LoginPageContainer;
