import React from 'react';
import { components } from 'react-select';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

export const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    display: 'flex',
    backgroundColor: '#80808014',
    borderBottom: 'none !important',
    borderRadius: '7px !important',
    cursor: 'pointer',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '14px !important',
  }),
  option: (provided) => ({
    ...provided,
    color: '#1e92c6',
  }),
  placeholder: (provided) => ({
    ...provided,
    border: 0,
  }),
};

export const CustomControl = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <SearchOutlinedIcon color="primary" style={{ margin: 8 }} />
      {children}
    </components.Control>
  );
};
