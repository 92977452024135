import React from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tabs from '../../components/Tabs';

import PayTypesTab from './PayTypesTab';
import CancellationReasonsTab from './CancellationReasonsTab';
import UsersTab from './UsersTab';

const SettingsPage = () => {
  return (
    <>
      <Fade in>
        <Box flex={1}>
          <Paper square elevation={2}>
            <Box display="flex" flexDirection="column">
              <Box pt={6} p={4} display="flex" flexDirection="column">
                <Typography variant="h4">Configurações</Typography>
              </Box>
              <Tabs>
                <UsersTab label="Administradores" />
                <PayTypesTab label="Formas de pagamento" />
                <CancellationReasonsTab label="Motivos de Cancelamento" />
              </Tabs>
            </Box>
          </Paper>
        </Box>
      </Fade>
    </>
  );
};

export default SettingsPage;
