import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { default as MuiChip } from '@material-ui/core/Chip';

const CONTRACT_TYPE = {
  0: 'PRIMEIRO CONTRATO',
  1: 'COBRANÇA AVULSA',
  2: 'ADITIVO',
  3: 'CONTRATO',
  4: 'ASSINATURA',
};

const Chip = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 10,
    color: ({ type }) => {
      if (type === 0) return theme.palette.primary[500];
      if (type === 1) return theme.palette.primary[500];
      if (type === 2) return theme.palette.primary[500];
      if (type === 3) return theme.palette.primary[500];
      return theme.palette.text[500];
    },
    backgroundColor: ({ type }) => {
      if (type === 0) return theme.palette.primary[50];
      if (type === 1) return theme.palette.primary[50];
      if (type === 2) return theme.palette.primary[50];
      if (type === 3) return theme.palette.primary[50];
      return theme.palette.text[50];
    },
  },
}))(MuiChip);

export default function ContractType({ type, ...props }) {
  return <Chip label={CONTRACT_TYPE[type]} type={type} {...props} />;
}
