import React from 'react';
import { default as MuiTextField } from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';

function TextMaskCustom({ inputRef, mask, ...other }) {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
    />
  );
}

function CurrencyField({ inputRef, onChange, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      allowNegative={false}
      prefix="R$ "
      fixedDecimalScale
      decimalScale={2}
    />
  );
}

function PercentualField({ inputRef, onChange, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      allowNegative={false}
      suffix="%"
      fixedDecimalScale
      decimalScale={2}
    />
  );
}

const TextField = ({
  field,
  mask,
  currency,
  percentual,
  variant,
  inputProps,
  InputProps,
  ...rest
}) => {
  if (mask) {
    return (
      <MuiTextField
        {...field}
        {...rest}
        variant={variant || 'filled'}
        fullWidth
        inputProps={{
          ...inputProps,
          mask,
        }}
        InputProps={{
          ...InputProps,
          inputComponent: TextMaskCustom,
        }}
      />
    );
  }

  if (currency || percentual) {
    return (
      <MuiTextField
        {...field}
        {...rest}
        variant={variant || 'filled'}
        fullWidth
        inputProps={inputProps}
        InputProps={{
          ...InputProps,
          inputComponent: currency ? CurrencyField : PercentualField,
        }}
      />
    );
  }

  return (
    <MuiTextField
      {...field}
      {...rest}
      variant={variant || 'filled'}
      fullWidth
      inputProps={inputProps}
      InputProps={InputProps}
    />
  );
};

export default TextField;
