import React from 'react';
import * as PropTypes from 'prop-types';

const FormikValidationIcon = (props) => {
  const { errors, iconOffset } = props;

  if (errors) {
    return <span style={{ right: iconOffset }} className="ic-error input-ic" />;
  }

  return <span style={{ right: iconOffset }} className="ic-success input-ic" />;
};

FormikValidationIcon.defaultProps = {
  errors: null,
  iconOffset: undefined,
};

FormikValidationIcon.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormikValidationIcon;
