import axios from 'axios';

import { PORT, CURRENT_ENV } from '../constants/constants';

const hosts = {
  development: `http://localhost:${PORT}`,
  production: 'https://portal.eyemobile.com.br',
  staging: 'https://homologacao.eyemobile.com.br',
};

export const currentHost = hosts[CURRENT_ENV];

const instance = axios.create({
  baseURL: currentHost,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 60000,
});

export default instance;
