import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { default as MuiDialogContent } from '@material-ui/core/DialogContent';

const DialogContentWrapper = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const DialogContent = ({ children }) => {
  return (
    <DialogContentWrapper className="vertical-scroll">
      <Box p={6} pt={4} pb={4} display="flex" flexDirection="column">
        {children}
      </Box>
    </DialogContentWrapper>
  );
};

export default DialogContent;
