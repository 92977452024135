import gateway from '../../utils/gateway';
import history from '../../config/history';
import {
  CUSTOMER_BARTENDER,
  CUSTOMER_HOSTESS,
  CUSTOMER_STOCK,
  CUSTOMER_WAITER,
} from '../../constants/role';
import { NoAccessError } from '../../utils/errors';
import { RESET_APP } from './app';
import { showError, showSuccess } from '../../utils/toast';
import LocalStorageManager from '../../utils/LocalStorageManager';

const USER = 'USER';
const INVALID_PASSWORD = 'INVALID_PASSWORD';

export const initialUser = {
  id: 0,
  name: '...',
  permission: {
    canSetDeviceLabel: false,
    canBlockUpdate: false,
  },
  authorities: [],
  phone: '',
  tenant: { label: '', image: '' },
};

const initialState = {
  profile: initialUser,
  userEdited: {
    id: 0,
    name: '...',
    permission: {
      canSetDeviceLabel: false,
      canBlockUpdate: false,
    },
    phone: '',
    tenant: { label: '', image: '' },
  },
  invalidPassword: false,
  list: [],
  closeAddModal: false,
  validContent: {
    isValid: true,
    deleteUsersSuccess: false,
  },
  addMultipleUsersSuccess: { success: false },
  selectUserList: [],
  selectedUsers: [],
  selectedUser: [],
};

export const userReducer = (state = initialState, action) => {
  if (action.type === USER) {
    const { payload } = action;
    return {
      ...state,
      profile: payload.data ? payload.data : state.profile,
      invalidPassword: false,
    };
  }

  return state;
};

export const resetInvalidPassword = () => {
  return async (dispatch) => {
    dispatch({
      type: INVALID_PASSWORD,
      payload: { invalidPassword: false },
    });
  };
};

export const getUser = () => {
  return async (dispatch) => {
    const res = await gateway.get('/legacy/users/logged');

    const notAuthorizedRoles = [
      CUSTOMER_BARTENDER,
      CUSTOMER_WAITER,
      CUSTOMER_STOCK,
      CUSTOMER_HOSTESS,
    ].includes(res.data.role);

    if (notAuthorizedRoles) {
      LocalStorageManager.removeToken();
      LocalStorageManager.removeRefreshToken();
      dispatch({
        type: RESET_APP,
      });
      history.push('/login', {
        errorMsg: 'Você não tem permissão para acessar este recurso',
      });
      throw new NoAccessError("User doesn't have access to the SPA.");
    }

    dispatch({
      type: USER,
      payload: { data: res.data },
    });

    // Sentry.configureScope((scope) => {
    //   scope.setUser({
    //     id: res.data.id,
    //     email: res.data.email,
    //     username: res.data.login,
    //   });
    //   scope.setExtra('tenant_id', res.data.tenant.id);
    //   scope.setExtra('tenant_name', res.data.tenant.label);
    // });

    return res.data;
  };
};

export const save = (user, message) => {
  return async (dispatch) => {
    const { file } = user;
    let newUser = { ...user };

    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      const resultImage = await gateway.post(
        '/legacy/images/upload/users',
        formData,
        {
          'content-type': 'multipart/form-data',
        },
      );
      const imageUpload = resultImage.data;
      if (imageUpload.success) {
        newUser = { ...newUser, image: imageUpload.path };
      }
      if (!imageUpload.success) {
        showError('Não foi possível enviar a imagem!');
      }
    }

    const response = await gateway
      .post('/legacy/users/profile', { ...newUser, file: undefined })
      .catch((e) => e.response);

    if (response.status !== 200) {
      if (response.status === 401) {
        showError('common:invalidPassword');
        dispatch({
          type: INVALID_PASSWORD,
          payload: { invalidPassword: true },
        });
        return;
      }

      showError();
      dispatch({
        type: USER,
        payload: { data: newUser },
      });
      return;
    }

    showSuccess(message);
    const result = await gateway.get('/legacy/users/logged');
    dispatch({
      type: USER,
      payload: { data: result.data },
    });
  };
};
