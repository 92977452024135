import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import Dictionary from '../../components/Dictionary';

import chargeTypes from '../../constants/chargeTypes';

const organizationMemberTypes = [
  { label: 'Matriz', value: 'business-parent' },
  { label: 'Filial', value: 'business-child' },
  { label: 'Franqueador', value: 'franchise-parent' },
  { label: 'Franqueado', value: 'franchise-child' },
];

const getOrganizationTypeLabel = (organizationType, organizationMemberType) => {
  const value = `${organizationType}-${organizationMemberType}`;
  const type = organizationMemberTypes.find((item) => item.value === value);
  return type ? type.label : 'Matriz';
};

const getBillingOwner = (organizationMemberType) => {
  if (organizationMemberType === 'parent') {
    return 'Associados';
  }

  return 'Este negócio';
};

const ProposalTab = ({ show, proposal }) => {
  const theme = useTheme();

  if (!show) {
    return null;
  }

  const contractType = {
    0: { label: 'ASSINATURA' },
    6: { label: 'EVENTOS/ PONTUAL' },
  };

  const dictionary1 = [
    {
      label: 'Tipo de contrato',
      value: proposal && contractType[proposal.contract_model].label,
    },
    {
      label: 'Tipo de negócio',
      value: getOrganizationTypeLabel(
        proposal?.organization_member?.organization?.type,
        proposal?.organization_member?.type,
      ),
    },
    {
      label: 'Responsável pelo pagamento',
      value: getBillingOwner(proposal?.organization_member?.type),
    },
    {
      label: 'Organização controladora',
      value: proposal?.organization_member?.organization?.name || '-',
    },
    {
      label: 'Total (recorrência)',
      value: proposal
        ? Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(proposal.recurrency_total)
        : '',
    },
    {
      label: 'Total (outras cobranças)',
      value: proposal
        ? Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(proposal.spot_total)
        : '',
    },
    {
      label: 'Duração',
      value: proposal && proposal.duration ? `${proposal.duration} meses` : '',
    },
    {
      label: 'Criada por',
      value: proposal && proposal.user ? proposal.user.name : '',
    },
    {
      label: 'Criada em',
      value: proposal
        ? moment(proposal.created_at).format('DD/MM/YYYY HH:mm')
        : '',
    },
    {
      label: 'Expira em',
      value:
        proposal && proposal.expires_at
          ? moment(proposal.expires_at).format('DD/MM/YYYY HH:mm')
          : '',
    },
    {
      label: 'Aceita em',
      value:
        proposal && proposal.accepted_at
          ? moment(proposal.accepted_at).format('DD/MM/YYYY HH:mm')
          : '',
    },
    {
      label: 'Recusada em',
      value:
        proposal && proposal.refused_at
          ? moment(proposal.refused_at).format('DD/MM/YYYY HH:mm')
          : '',
    },
    {
      label: 'Inicio de uso em',
      value:
        proposal && proposal.usage_start
          ? moment(proposal.usage_start).format('DD/MM/YYYY')
          : '',
    },
    {
      label: 'Fim de uso em',
      value:
        proposal && proposal.usage_end
          ? moment(proposal.usage_end).format('DD/MM/YYYY')
          : '',
    },
    {
      label: 'Paga em',
      value:
        proposal && proposal.paid_at
          ? moment(proposal.paid_at).format('DD/MM/YYYY HH:mm')
          : '',
    },
    {
      label: 'Cancelada em',
      value:
        proposal && proposal.cancelled_at
          ? moment(proposal.cancelled_at).format('DD/MM/YYYY HH:mm')
          : '',
    },
  ];

  const dictionary2 = [
    {
      label: 'Último pagamento em',
      value:
        proposal && proposal.last_payment_at
          ? moment(proposal.last_payment_at).format('DD/MM/YYYY HH:mm')
          : '',
    },
    {
      label: 'Percentual a ser aplicado sobre receita transacionada por mês',
      value: `${proposal ? proposal.percentage || 0 : 0}%`,
    },
    {
      label: 'Valor da cobrança de confirmação',
      value: proposal
        ? Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(proposal.confirmation_charge_total)
        : '',
    },
    {
      label: 'Forma de pagamento (cobranças recorrentes)',
      value:
        proposal && proposal.recurrency_charge_type !== null
          ? chargeTypes[proposal.recurrency_charge_type]
          : '',
    },
    {
      label: 'Forma de pagamento (outras cobranças)',
      value:
        proposal && proposal.spot_charge_type !== null
          ? chargeTypes[proposal.spot_charge_type]
          : '',
    },
    {
      label: 'Número de parcelas (outras cobranças)',
      value: proposal && proposal.installments ? proposal.installments : '',
    },
    {
      label: 'Código (PIX)',
      value:
        proposal &&
        proposal.charge &&
        proposal.charge.charge_payment &&
        proposal.charge.charge_payment.pix_qrcode
          ? proposal.charge.charge_payment.pix_qrcode
          : '',
    },
  ];

  const dictionary3 = [
    {
      label: 'E-mail',
      value: proposal ? proposal.email : '',
    },
    {
      label: 'Telefone',
      value: proposal ? proposal.phone : '',
    },
  ];

  return (
    <Fade in>
      <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
        <Grid container direction="row" spacing={6}>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Proposta</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={proposal === null}
                dictionary={dictionary1}
                align="right"
              />
            </Grid>
            <Grid item>
              {proposal && proposal.observations && (
                <Box
                  mb={4}
                  p={1}
                  pl={4}
                  pr={4}
                  bgcolor={fade(theme.palette.text.primary, 0.1)}
                  dangerouslySetInnerHTML={{
                    __html: proposal ? proposal.observations : '',
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Pagamento</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={proposal === null}
                dictionary={dictionary2}
                align="right"
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Dados de envio</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={proposal === null}
                dictionary={dictionary3}
                align="right"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default ProposalTab;
