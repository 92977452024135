import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import AsyncSelect from './AsyncSelect';
import gateway from '../utils/gateway';

const lookupModels = async (q) =>
  gateway
    .get('/legacy/deviceModels/lookup', { params: { q } })
    .then((response) => response.data);

const SelectModel = (props) => {
  const { t } = useTranslation();

  const handleOnChange = (selected) => {
    const { onChange } = props;

    if (onChange) {
      onChange(selected);
    }
  };

  const {
    disabled,
    hideLabel,
    className,
    placeholder,
    labelClassName,
    wrapperClassName,
    selectWrapperClassName,
    ...rest
  } = props;

  return (
    <div
      className={clsx('flex', 'no-scroll', 'align-i-center', wrapperClassName, {
        disabled,
      })}
    >
      {!hideLabel && (
        <span className={`filter-label mr ${labelClassName}`}>
          {t('common:models')}
        </span>
      )}
      <AsyncSelect
        {...rest}
        defaultOptions
        className={className}
        placeholder="common:all"
        onChange={handleOnChange}
        loadOptions={lookupModels}
        wrapperClassName={selectWrapperClassName}
      />
    </div>
  );
};

SelectModel.defaultProps = {
  className: '',
  disabled: false,
  hideLabel: false,
  onChange: () => {},
  labelClassName: undefined,
  placeholder: 'common:all',
  wrapperClassName: undefined,
  selectWrapperClassName: undefined,
};

SelectModel.propTypes = {
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  selectWrapperClassName: PropTypes.string,
  placeholder: PropTypes.string,
};

export default SelectModel;
