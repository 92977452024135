import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

const DateField = ({ field, onChange, variant, ...rest }) => {
  return (
    <KeyboardDatePicker
      inputVariant={variant || 'filled'}
      fullWidth
      autoOk
      {...field}
      {...rest}
      onChange={(value) => {
        if (field) {
          return field.onChange({
            target: {
              name: field.name,
              value: value === null ? null : value.toDate(),
            },
          });
        }
        return onChange({
          target: {
            value: value === null ? null : value.toDate(),
          },
        });
      }}
    />
  );
};
export default DateField;
