const BREADCRUMB = 'BREADCRUMB';

const initialState = {
  data: [],
  description: '',
};

export function setBreadcrumb(description) {
  return (dispatch) => {
    dispatch({
      type: BREADCRUMB,
      description,
    });
  };
}

export const breadcrumbReducer = (state = initialState, action) => {
  if (action.type === BREADCRUMB) {
    const { description } = action;
    return {
      ...state,
      description,
    };
  }

  return state;
};
