import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import moment from 'moment';

import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import CheckBoxField from '../../components/CheckBoxField';
import TenantStatus from '../../components/TenantStatus';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const COLUMNS = [
  {
    id: 'name',
    label: 'RAZÃO SOCIAL',
  },
  { id: 'label', label: 'NOME FANTASIA' },
  { id: 'document', label: 'CNPJ' },
  { id: 'onboarding', align: 'center', label: 'ONB.' },
  { id: 'delinquent', align: 'center', label: 'ATRASADO' },
  { id: 'status', align: 'center', label: 'STATUS' },
  {
    id: 'created',
    align: 'right',
    label: 'CRIADO EM',
  },
];

const ItemsTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: '8px 16px',
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const TenantsTab = ({ show }) => {
  const params = useParams();
  const history = useHistory();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [loading, setLoading] = React.useState(false);
  const [tenants, setTenants] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const [search, setSearch] = React.useState('');
  const [status, setStatus] = React.useState(-1);
  const [onboardingOnly, setOnboardingOnly] = React.useState(true);
  const [iuguOnly, setIuguOnly] = React.useState(false);
  const [delinquentOnly, setDelinquentOnlyOnly] = React.useState(false);
  const [filters, setFilters] = React.useState({
    search: '',
    status: -1,
    onboardingOnly: true,
    delinquentOnly: false,
    iuguOnly: false,
  });

  const handleClick = (id) => {
    history.push(`/tenants/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setFilters({
      search,
      status,
      onboardingOnly,
      delinquentOnly,
      iuguOnly,
    });
  };

  const fetchTenants = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(
        `/billing/partners/${params.partner_id}/tenants`,
        {
          params: {
            ...(filters.search.length > 0 ? { search: filters.search } : {}),
            status: filters.status === -1 ? [0, 1] : [filters.status],
            onboarding_only: filters.onboardingOnly,
            delinquent_only: filters.delinquentOnly,
            iugu_only: filters.iuguOnly,
            limit: rowsPerPage,
            offset: page * rowsPerPage,
          },
        },
      );

      setTenants(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchTenants();
  }, [rowsPerPage, page, filters]);

  if (!show) {
    return null;
  }

  return (
    <Fade in>
      <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
        <Box pt={4} display="flex" alignItems="center">
          <Box pr={4}>
            <TextField
              name="search"
              label="Pesquisar"
              placeholder=""
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="inherit" />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
          </Box>
          <Box pr={4} width={160}>
            <SelectField
              name="status"
              label="Status"
              placeholder="Status"
              options={[
                { label: 'Todos', value: -1 },
                { label: 'Ativos', value: 1 },
                { label: 'Inativos', value: 0 },
              ]}
              variant="standard"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            />
          </Box>
          <Box pr={4}>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Buscar
            </Button>
          </Box>
        </Box>
        <Box pt={2} display="flex" alignItems="center">
          <Box pr={4}>
            <CheckBoxField
              name="onboardingOnly"
              label="Exibir clientes do novo onboarding"
              checked={onboardingOnly}
              onChange={(e) => {
                setOnboardingOnly(e.target.checked);
              }}
            />
          </Box>
          <Box pr={4}>
            <CheckBoxField
              name="delinquentOnly"
              label="Exibir inadimplentes"
              checked={delinquentOnly}
              onChange={(e) => {
                setDelinquentOnlyOnly(e.target.checked);
              }}
            />
          </Box>
          <Box pr={4}>
            <CheckBoxField
              name="iuguOnly"
              label="Apenas Iugu"
              checked={iuguOnly}
              onChange={(e) => {
                setIuguOnly(e.target.checked);
              }}
            />
          </Box>
        </Box>
        <Box pt={4}>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <ItemsTableCell
                      key={column.id}
                      align={column.align ? column.align : 'left'}
                    >
                      <Typography variant="subtitle2" color="primary">
                        {column.label}
                      </Typography>
                    </ItemsTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  [1, 2, 3].map((value) => (
                    <TableRow tabIndex={-1} key={value}>
                      <ItemsTableCell>
                        <Skeleton variant="text" width={80} />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell align="right">
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                    </TableRow>
                  ))}

                {!loading &&
                  tenants.map((row) => {
                    return (
                      <ItemsTableRow
                        hover
                        onClick={() => handleClick(row.id)}
                        key={row.id}
                      >
                        <ItemsTableCell>{row.name}</ItemsTableCell>
                        <ItemsTableCell>{row.label}</ItemsTableCell>
                        <ItemsTableCell>{row.document}</ItemsTableCell>
                        <ItemsTableCell align="center">
                          {row.contract_id !== null && (
                            <CheckCircleIcon color="primary" />
                          )}
                        </ItemsTableCell>
                        <ItemsTableCell align="center">
                          {row.delinquent && <EventBusyIcon color="error" />}
                        </ItemsTableCell>
                        <ItemsTableCell align="center">
                          <TenantStatus size="small" status={row.status} />
                        </ItemsTableCell>
                        <ItemsTableCell align="right">
                          {moment(row.created).format('DD/MM/YYYY HH:mm')}
                        </ItemsTableCell>
                      </ItemsTableRow>
                    );
                  })}
                {!loading && tenants.length === 0 && (
                  <TableRow>
                    <ItemsTableCell colSpan={COLUMNS.length} align="center">
                      <Box m={4}>
                        <Typography>Nenhum cliente encontrado</Typography>
                      </Box>
                    </ItemsTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Resultados por página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} / ${count}`
            }
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Fade>
  );
};

export default TenantsTab;
