import React from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import Dictionary from '../../components/Dictionary';

const TenantTab = ({ show, tenant }) => {
  if (!show) {
    return null;
  }

  const dictionary = [
    {
      label: 'CNPJ',
      value: tenant ? tenant.document : '',
    },
    {
      label: 'Razão social',
      value: tenant ? tenant.name : '',
    },
    {
      label: 'Nome fantasia',
      value: tenant ? tenant.label : '',
    },
    {
      label: 'Logradouro',
      value: tenant ? tenant.address : '',
    },
    {
      label: 'Número',
      value: tenant ? tenant.address_number : '',
    },
    {
      label: 'Complemento',
      value: tenant ? tenant.address2 : '',
    },
    {
      label: 'Bairro',
      value: tenant ? tenant.district : '',
    },
    {
      label: 'Cidade',
      value: tenant ? tenant.city : '',
    },
    {
      label: 'Estado',
      value: tenant ? tenant.state : '',
    },
    {
      label: 'CEP',
      value: tenant ? tenant.zipcode : '',
    },
    {
      label: 'Criado em',
      value: tenant ? moment(tenant.created).format('DD/MM/YYYY') : '',
    },
  ];

  const owner = [
    {
      label: 'Nome',
      value: tenant ? tenant.company_owner_name : '',
    },
    {
      label: 'Documento',
      value: tenant ? tenant.company_owner_document : '',
    },
    {
      label: 'Email',
      value: tenant ? tenant.company_owner_email : '',
    },
    {
      label: 'Telefone',
      value: tenant ? tenant.company_owner_phone : '',
    },
    {
      label: 'Data de nascimento',
      value:
        tenant && tenant.company_owner_birthday
          ? moment(tenant.company_owner_birthday).format('DD/MM/YYYY')
          : '',
    },
  ];

  const cancellation = [
    {
      label: 'Data',
      value: tenant && moment(tenant.cancelled_at).format('DD/MM/YYYY HH:mm'),
    },
    {
      label: 'Motivo',
      value: tenant?.cancellation_type?.name || 'Outro',
    },
    {
      label: 'Descrição',
      value: tenant ? tenant.cancellation_description : '',
    },
    {
      label: 'Cancelado por',
      value: tenant ? tenant.cancelled_by_user?.email : '',
    },
  ];

  const financial = [
    {
      label: 'Nome',
      value: tenant ? tenant.financial_name : '',
    },
    {
      label: 'Email',
      value: tenant ? tenant.email : '',
    },
    {
      label: 'Telefone',
      value: tenant ? tenant.phone : '',
    },
  ];

  const modules = [
    {
      label: 'Estoque',
      value: tenant && tenant.has_stock ? 'SIM' : 'NÃO',
    },
    {
      label: 'Fidelidade',
      value: tenant && tenant.has_loyalty ? 'SIM' : 'NÃO',
    },
    {
      label: 'Vouchers',
      value: tenant && tenant.has_vouchers ? 'SIM' : 'NÃO',
    },
    {
      label: 'Eyepass',
      value: tenant && tenant.has_eyepass ? 'SIM' : 'NÃO',
    },
    {
      label: 'Metas',
      value: tenant && tenant.has_goals ? 'SIM' : 'NÃO',
    },
    {
      label: 'Comandas',
      value: tenant && tenant.has_accounts ? 'SIM' : 'NÃO',
    },
    {
      label: 'Pedidos',
      value: tenant && tenant.has_orders ? 'SIM' : 'NÃO',
    },
  ];

  return (
    <Fade in>
      <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
        <Grid container direction="row" spacing={6}>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Dados da empresa</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={tenant === null}
                dictionary={dictionary}
                align="right"
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Responsável LEGAL</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={tenant === null}
                dictionary={owner}
                align="right"
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Responsável financeiro</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={tenant === null}
                dictionary={financial}
                align="right"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={6}>
          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Módulos</Typography>
            </Grid>
            <Grid item>
              <Dictionary
                loading={tenant === null}
                dictionary={modules}
                align="right"
              />
            </Grid>
          </Grid>

          {tenant?.subscription_status === 3 && (
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
              container
              direction="column"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h6">Cancelamento</Typography>
              </Grid>
              <Grid item>
                <Dictionary
                  loading={tenant === null}
                  dictionary={cancellation}
                  align="right"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Fade>
  );
};

export default TenantTab;
