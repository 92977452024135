import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { css } from 'emotion';

const wrapper = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 100,
};

const overlay = {
  width: '100%',
  height: '100%',
  background:
    'linear-gradient(-45deg, rgba(54, 77, 179, 0.85), rgba(64, 7, 108, 0.85))',
};

const inner = {
  width: '100%',
  height: '100%',
  position: 'relative',
  overflowY: 'auto',
  marginBottom: 100,
};

const content = {
  backgroundColor: 'white',
  borderRadius: 10,
  position: 'absolute',
  left: '50%',
  fontSize: 14,
  color: '#737689',
  boxShadow: '1px 2px 5px 0 rgba(0, 0, 0, 0.5)',
};

const sizes = {
  small: 375,
  medium: 535,
  large: 700,
};

const modalBps = css`
  @media screen and (max-width: 580px) {
    width: 90% !important;
  }
`;

const EyeModal = ({
  size,
  padding,
  visible,
  children,
  topOffset,
  customWidth,
  customHeight,
  innerStyle,
  keepMounted,
  wrapperStyle,
}) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (visible) {
      element.setAttribute('style', 'overflow: hidden;');
    }

    return () => {
      element.removeAttribute('style');
    };
  }, [visible]);

  if (!keepMounted && !visible) return null;

  return (
    <Portal>
      <div
        style={{
          ...wrapper,
          ...wrapperStyle,
        }}
      >
        <div style={overlay}>
          <div
            style={{
              ...inner,
              ...innerStyle,
            }}
          >
            <div
              className={modalBps}
              style={{
                height: customHeight || 'auto',
                width: customWidth || sizes[size],
                top: topOffset || '50%',
                transform: `translate(-50%, ${topOffset ? '0' : '-50%'})`,
                marginBottom: topOffset,
                padding: padding || '25px',
                ...content,
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

EyeModal.defaultProps = {
  visible: false,
  innerStyle: {},
  wrapperStyle: {},
  keepMounted: false,
  size: 'small',
  topOffset: undefined,
  padding: undefined,
  customWidth: undefined,
  customHeight: undefined,
};

EyeModal.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.element.isRequired,
  wrapperStyle: PropTypes.object,
  innerStyle: PropTypes.object,
  keepMounted: PropTypes.bool,
  size: PropTypes.string,
  topOffset: PropTypes.string,
  padding: PropTypes.string,
  customWidth: PropTypes.number,
  customHeight: PropTypes.number,
};

export default EyeModal;
