const contractModels = [
  { value: 0, label: 'ASSINATURA' },
  { value: 6, label: 'EVENTOS/ PONTUAL' },
];

export const contractModelsMap = {
  0: 'ASSINATURA',
  1: 'FRANQUEADOR',
  2: 'FRANQUEADO',
  3: 'REDE DE LOJAS (PAGAMENTO CENTRALIZADO)',
  4: 'REDE DE LOJAS (PAGAMENTO INDIVIDUALIZADO)',
  5: 'PERCENTUAL/ SUBLOCAÇÃO',
  6: 'EVENTOS/ PONTUAL',
  7: 'TESTE',
};

export default contractModels;
