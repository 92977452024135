import { css } from 'emotion';

export const tableWrapper = css`
  width: 100%;
  text-align: left;
  color: #737689;
  font-size: 14px;
  overflow: auto;
  border-spacing: 0;
  box-shadow: 0px 2px 4px 0px #00000029;
  border-radius: 8px;
  margin: 1px;
  font-weight: 500;
  line-height: 16.8px;

  & > .header {
    font-size: 14px !important;
  }

  th {
    color: #8b2ad5;
    font-size: 14px !important;
    border-top: 1px solid #e7e7e7;
  }

  th:first-child {
    padding: 10px;
  }

  th:not(:first-child) {
    padding: 10px;
  }

  th:last-child {
    padding: 10px 15px 10px 10px;
  }

  th {
    font-size: 13px;
    max-height: 10px;
  }

  td {
    height: 10px;
    max-height: 10px;
    border-bottom: 1px solid #e0dddd;
  }

  td:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  td:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  & > tbody td {
    font-size: 15px;
    color: #737689;
  }

  & > tbody tr:nth-child(odd) {
    background-color: #fafafa;
  }

  tr {
    height: 10px;
    max-height: 10px;
  }

  @media (min-width: 768px) {
    th {
      position: sticky;
      top: 0;
      background-color: #f5f7fa;
    }
  }
`;
