import React from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';

const ItemsTableCell = withStyles(() => ({
  root: {
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const AmendmentTitleTableCell = withStyles(() => ({
  root: {
    border: 0,
    verticalAlign: 'top',
  },
}))(TableCell);

const AmendmentTableCell = withStyles(() => ({
  root: {
    border: 0,
    padding: '8px 16px 8px 16px',
    backgroundColor: '#F0F0F0',
  },
}))(TableCell);

const DetailTableCell = withStyles(() => ({
  root: {
    padding: 0,
    border: 0,
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  image: {
    borderRadius: 16,
    width: 52,
    height: 52,
  },
}));

const AMENDMENT_COLUMNS = [
  {
    id: 'id',
    label: 'ID',
  },
  { id: 'created_at', align: 'right', label: 'CRIADO EM' },
];

const ITEM_COLUMNS = [
  {
    id: 'product',
    label: 'PRODUTO',
  },
  { id: 'quantity', label: 'QTD.' },
  { id: 'price', label: 'PREÇO' },
  { id: 'discount', label: 'DESC.' },
  { id: 'total', align: 'right', label: 'TOTAL' },
];

const AmendmentsTab = ({ show, contract }) => {
  const classes = useStyles();
  const theme = useTheme();

  if (!show) {
    return null;
  }

  return (
    <Fade in>
      <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {AMENDMENT_COLUMNS.map((column) => (
                  <ItemsTableCell
                    key={column.id}
                    align={column.align ? column.align : 'left'}
                  >
                    <Typography variant="subtitle2" color="primary">
                      {column.label}
                    </Typography>
                  </ItemsTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {contract === null &&
                [1, 2, 3].map((value) => (
                  <TableRow tabIndex={-1} key={value}>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                    <ItemsTableCell align="right">
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                  </TableRow>
                ))}
              {contract !== null &&
                contract.contract_amendments.map((row) => {
                  const totalRecurrent = row.contract_items.reduce(
                    (sum, item) => {
                      if (item.contract_product.recurrency_type === 0) {
                        return sum;
                      }
                      return sum + item.total;
                    },
                    0,
                  );

                  const formattedTotalRecurrent = Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(totalRecurrent);

                  return (
                    <React.Fragment key={row.id}>
                      <TableRow tabIndex={-1}>
                        <ItemsTableCell>
                          <Typography>{row.id}</Typography>
                        </ItemsTableCell>
                        <ItemsTableCell align="right">
                          <Typography>
                            {moment(row.created_at).format('DD/MM/YYYY HH:mm')}
                          </Typography>
                        </ItemsTableCell>
                      </TableRow>
                      <TableRow tabIndex={-1}>
                        <AmendmentTitleTableCell align="right">
                          <Typography variant="h6">Itens</Typography>
                        </AmendmentTitleTableCell>
                        <AmendmentTableCell
                          colSpan={AMENDMENT_COLUMNS.length - 1}
                        >
                          <TableContainer>
                            <Table
                              aria-labelledby="tableTitle"
                              size="medium"
                              aria-label="enhanced table"
                            >
                              <TableHead>
                                <TableRow>
                                  {ITEM_COLUMNS.map((column) => (
                                    <ItemsTableCell
                                      key={column.id}
                                      align={
                                        column.align ? column.align : 'left'
                                      }
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color="primary"
                                      >
                                        {column.label}
                                      </Typography>
                                    </ItemsTableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.contract_items
                                  .filter((item) => item.recurrency_type !== 0)
                                  .map((item) => {
                                    const formattedDiscount = Intl.NumberFormat(
                                      'pt-BR',
                                      {
                                        style: 'currency',
                                        currency: 'BRL',
                                      },
                                    ).format(item.discount);

                                    const formattedPrice = Intl.NumberFormat(
                                      'pt-BR',
                                      {
                                        style: 'currency',
                                        currency: 'BRL',
                                      },
                                    ).format(item.price);

                                    const formattedTotal = Intl.NumberFormat(
                                      'pt-BR',
                                      {
                                        style: 'currency',
                                        currency: 'BRL',
                                      },
                                    ).format(item.total);

                                    let measure = '';
                                    if (item.measure === 1) {
                                      measure = ' / mês';
                                    } else if (item.measure === 2) {
                                      measure = ' / hora';
                                    } else if (item.measure === 3) {
                                      measure = ' / usuário';
                                    } else if (item.measure === 4) {
                                      measure = ' / usuário / mês';
                                    } else if (item.measure === 5) {
                                      measure = ' / ano';
                                    } else if (item.measure === 6) {
                                      measure = ' / usuário / ano';
                                    }

                                    let recurrency = '';
                                    if (item.recurrency_type === 1) {
                                      recurrency = ' / mês';
                                    } else if (item.recurrency_type === 2) {
                                      recurrency = ' / ano';
                                    }

                                    return (
                                      <React.Fragment key={item.id}>
                                        <TableRow tabIndex={-1}>
                                          <ItemsTableCell>
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                            >
                                              <Box mr={2}>
                                                <img
                                                  src={
                                                    item.contract_product.image
                                                  }
                                                  alt={item.name}
                                                  className={classes.image}
                                                />
                                              </Box>
                                              <Box
                                                display="flex"
                                                flexDirection="column"
                                              >
                                                <Typography>
                                                  {item.name}
                                                </Typography>
                                                {item.receivable_deadline !==
                                                null ? (
                                                  <Typography variant="body2">{`Prazo dos recebíveis ${item.receivable_deadline}`}</Typography>
                                                ) : null}
                                                {item.credit_percentage !==
                                                null ? (
                                                  <Typography variant="body2">{`Taxa de crédito ${item.credit_percentage}%`}</Typography>
                                                ) : null}
                                                {item.debit_percentage !==
                                                null ? (
                                                  <Typography variant="body2">{`Taxa de débito ${item.debit_percentage}%`}</Typography>
                                                ) : null}
                                                {item.ecommerce_transaction_fee !==
                                                null ? (
                                                  <Typography variant="body2">{`Valor da transação ${Intl.NumberFormat(
                                                    'pt-BR',
                                                    {
                                                      style: 'currency',
                                                      currency: 'BRL',
                                                    },
                                                  ).format(
                                                    item.ecommerce_transaction_fee,
                                                  )}`}</Typography>
                                                ) : null}
                                                {item.ecommerce_transaction_percentage !==
                                                null ? (
                                                  <Typography variant="body2">{`Taxa ${item.ecommerce_transaction_percentage}%`}</Typography>
                                                ) : null}
                                              </Box>
                                            </Box>
                                          </ItemsTableCell>
                                          <ItemsTableCell>
                                            <Typography>
                                              {item.quantity}
                                            </Typography>
                                          </ItemsTableCell>
                                          <ItemsTableCell>
                                            <Typography>
                                              {formattedPrice + measure}
                                            </Typography>
                                          </ItemsTableCell>
                                          <ItemsTableCell>
                                            {item.discount > 0 &&
                                              item.discount_type === 0 && (
                                                <Typography>
                                                  {item.discount}%
                                                </Typography>
                                              )}
                                            {item.discount > 0 &&
                                              item.discount_type === 1 && (
                                                <Typography>
                                                  {formattedDiscount}
                                                </Typography>
                                              )}
                                          </ItemsTableCell>
                                          <ItemsTableCell align="right">
                                            <Typography>
                                              {formattedTotal + recurrency}
                                            </Typography>
                                          </ItemsTableCell>
                                        </TableRow>
                                        {item.observations &&
                                          item.observations.length > 0 && (
                                            <TableRow tabIndex={-1}>
                                              <DetailTableCell colSpan={5}>
                                                <Box
                                                  p={1}
                                                  pl={4}
                                                  pr={4}
                                                  bgcolor={fade(
                                                    theme.palette.text.primary,
                                                    0.1,
                                                  )}
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.observations,
                                                  }}
                                                />
                                              </DetailTableCell>
                                            </TableRow>
                                          )}
                                      </React.Fragment>
                                    );
                                  })}
                                {row.contract_items.filter(
                                  (item) => item.recurrency_type !== 0,
                                ).length === 0 && (
                                  <TableRow>
                                    <ItemsTableCell
                                      colSpan={ITEM_COLUMNS.length}
                                      align="center"
                                    >
                                      <Box p={2}>
                                        <Typography>
                                          Nenhum item encontrado
                                        </Typography>
                                      </Box>
                                    </ItemsTableCell>
                                  </TableRow>
                                )}
                                {contract !== null &&
                                  contract.contract_items.length > 0 && (
                                    <TableRow>
                                      <ItemsTableCell
                                        colSpan={ITEM_COLUMNS.length - 1}
                                      />
                                      <ItemsTableCell align="right">
                                        <Typography variant="h6">
                                          Total {`${formattedTotalRecurrent}`}
                                        </Typography>
                                      </ItemsTableCell>
                                    </TableRow>
                                  )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AmendmentTableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              {contract !== null && contract.contract_amendments.length === 0 && (
                <TableRow>
                  <ItemsTableCell
                    colSpan={AMENDMENT_COLUMNS.length}
                    align="center"
                  >
                    <Box p={2}>
                      <Typography>Nenhum aditivo encontrado</Typography>
                    </Box>
                  </ItemsTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Fade>
  );
};

export default AmendmentsTab;
