class LocalStorageManager {
  static prefix = 'PORTAL_SESSION';

  static setItem(key, data) {
    localStorage.setItem(`${this.prefix}_${key}`, JSON.stringify(data));
  }

  static removeItem(key) {
    if (typeof localStorage[`${this.prefix}_${key}`] !== 'undefined') {
      localStorage.removeItem(`${this.prefix}_${key}`);
    }
  }

  static getItem(key) {
    if (
      typeof localStorage[`${this.prefix}_${key}`] !== 'undefined' &&
      localStorage[`${this.prefix}_${key}`] !== 'undefined'
    ) {
      const item = localStorage.getItem(`${this.prefix}_${key}`);

      try {
        const parsedItem = JSON.parse(item);

        return parsedItem;
      } catch (error) {
        return item;
      }
    }

    return undefined;
  }

  static setToken(token) {
    localStorage.setItem(`${this.prefix}_TOKEN`, token);
  }

  static getToken() {
    if (
      typeof localStorage[`${this.prefix}_TOKEN`] !== 'undefined' &&
      localStorage[`${this.prefix}_TOKEN`] !== 'undefined'
    ) {
      return localStorage.getItem(`${this.prefix}_TOKEN`);
    }

    return undefined;
  }

  static removeToken() {
    if (typeof localStorage[`${this.prefix}_TOKEN`] !== 'undefined') {
      localStorage.removeItem(`${this.prefix}_TOKEN`);
    }
  }

  static setRefreshToken(token) {
    localStorage.setItem(`${this.prefix}_REFRESH_TOKEN`, token);
  }

  static getRefreshToken() {
    if (
      typeof localStorage[`${this.prefix}_REFRESH_TOKEN`] !== 'undefined' &&
      localStorage[`${this.prefix}_REFRESH_TOKEN`] !== 'undefined'
    ) {
      return localStorage.getItem(`${this.prefix}_REFRESH_TOKEN`);
    }

    return undefined;
  }

  static removeRefreshToken() {
    if (typeof localStorage[`${this.prefix}_REFRESH_TOKEN`] !== 'undefined') {
      localStorage.removeItem(`${this.prefix}_REFRESH_TOKEN`);
    }
  }

  static setSecret(secret) {
    localStorage.setItem(`${this.prefix}_SECRET`, secret);
  }

  static getSecret() {
    if (
      typeof localStorage[`${this.prefix}_SECRET`] !== 'undefined' &&
      localStorage[`${this.prefix}_SECRET`] !== 'undefined'
    ) {
      return localStorage.getItem(`${this.prefix}_SECRET`);
    }

    return undefined;
  }

  static removeSecret() {
    if (typeof localStorage[`${this.prefix}_SECRET`] !== 'undefined') {
      localStorage.removeItem(`${this.prefix}_SECRET`);
    }
  }

  static clearSession() {
    this.removeToken();
    this.removeRefreshToken();
    this.removeSecret();
  }
}

export default LocalStorageManager;
