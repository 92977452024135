import React from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from 'moment';

import ContractType from '../../components/ContractType';
import ProposalStatus from '../../components/ProposalStatus';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const COLUMNS = [
  { id: 'id', label: '#', align: 'left' },
  { id: 'type', align: 'center', label: 'TIPO' },
  { id: 'revision', align: 'right', label: 'REVISÃO' },
  { id: 'user', label: 'CRIADA POR' },
  { id: 'status', align: 'center', label: 'STATUS' },
  {
    id: 'created_at',
    align: 'right',
    label: 'CRIADA EM',
  },
];

const ItemsTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: '8px 16px',
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const ProposalsTab = ({ show, tenant }) => {
  const history = useHistory();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [proposals, setProposals] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const handleClick = (id) => {
    history.push(`/proposals/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchProposals = async () => {
    setLoading(true);

    try {
      const response = await gateway.get('/billing/proposals/', {
        params: {
          tenant_id: tenant.id,
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        },
      });

      setProposals(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (show) {
      fetchProposals();
    }
  }, [show, rowsPerPage, page]);

  if (!show) {
    return null;
  }

  return (
    <Fade in>
      <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {COLUMNS.map((column) => (
                  <ItemsTableCell
                    key={column.id}
                    align={column.align ? column.align : 'left'}
                  >
                    <Typography variant="subtitle2" color="primary">
                      {column.label}
                    </Typography>
                  </ItemsTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading &&
                [1, 2, 3].map((value) => (
                  <TableRow tabIndex={-1} key={value}>
                    <ItemsTableCell>
                      <Skeleton variant="text" width={80} />
                    </ItemsTableCell>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                  </TableRow>
                ))}
              {!loading &&
                proposals.map((row) => {
                  return (
                    <ItemsTableRow
                      hover
                      onClick={() => handleClick(row.id)}
                      key={row.id}
                    >
                      <ItemsTableCell align="left">{row.id}</ItemsTableCell>
                      <ItemsTableCell align="center">
                        <ContractType size="small" type={row.type} />
                      </ItemsTableCell>
                      <ItemsTableCell align="right">
                        {row.revision}
                      </ItemsTableCell>
                      <ItemsTableCell>
                        {row.user ? row.user.name : ''}
                      </ItemsTableCell>
                      <ItemsTableCell align="center">
                        <ProposalStatus size="small" status={row.status} />
                      </ItemsTableCell>
                      <ItemsTableCell align="right">
                        {moment(row.created_at).format('DD/MM/YYYY HH:mm')}
                      </ItemsTableCell>
                    </ItemsTableRow>
                  );
                })}
              {!loading && proposals.length === 0 && (
                <TableRow>
                  <ItemsTableCell colSpan={COLUMNS.length} align="center">
                    <Box m={4}>
                      <Typography>Nenhuma proposta encontrada</Typography>
                    </Box>
                  </ItemsTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Resultados por página"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} / ${count}`
          }
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </Fade>
  );
};

export default ProposalsTab;
