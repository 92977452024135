import React from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import moment from 'moment';

import ContractStatus from '../../components/ContractStatus';

import { PermissionContext } from '../../contexts/PermissionContext';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const COLUMNS = [
  { id: 'id', label: '#', align: 'right' },
  { id: 'version', align: 'right', label: 'VERSÃO' },
  { id: 'starts_at', label: 'INICIA EM' },
  { id: 'ends_at', label: 'TERMINA EM' },
  { id: 'cancelled_at', label: 'CANCELADO EM' },
  { id: 'status', align: 'center', label: 'STATUS' },
  {
    id: 'createdAt',
    align: 'right',
    label: 'CRIADO EM',
  },
];

const ItemsTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: '8px 16px',
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const CyclesTab = ({ show, tenant }) => {
  const history = useHistory();

  const { authorized } = React.useContext(PermissionContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [cycles, setCyles] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const handleClick = (id) => {
    history.push(`/tenants/${tenant.id}/contracts/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchCycles = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(
        `/billing/tenants/${tenant.id}/cycles`,
        {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
          },
        },
      );

      setCyles(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (show) {
      fetchCycles();
    }
  }, [show, rowsPerPage, page]);

  if (!show) {
    return null;
  }

  return (
    <Fade in>
      <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
        {tenant !== null && authorized('proposals.add') && (
          <Box
            mb={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ gap: '20px' }}
          >
            {authorized('proposals.add') && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (tenant.phone === null || tenant.email === null) {
                    showError('Cliente sem e-mail ou telefone cadastrado');
                  } else {
                    history.push(`/tenants/${tenant.id}/spot`);
                  }
                }}
                startIcon={<AttachMoneyIcon />}
              >
                Novo Ciclo (Com Proposta)
              </Button>
            )}
          </Box>
        )}

        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {COLUMNS.map((column) => (
                  <ItemsTableCell
                    key={column.id}
                    align={column.align ? column.align : 'left'}
                  >
                    <Typography variant="subtitle2" color="primary">
                      {column.label}
                    </Typography>
                  </ItemsTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading &&
                [1, 2, 3].map((value) => (
                  <TableRow tabIndex={-1} key={value}>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                    <ItemsTableCell>
                      <Skeleton variant="text" width={80} />
                    </ItemsTableCell>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                    <ItemsTableCell>
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                    <ItemsTableCell align="right">
                      <Skeleton variant="text" />
                    </ItemsTableCell>
                  </TableRow>
                ))}
              {!loading &&
                cycles.map((row) => {
                  return (
                    <ItemsTableRow
                      hover
                      onClick={() => handleClick(row.id)}
                      key={row.id}
                    >
                      <ItemsTableCell align="right">{`#${row.id}`}</ItemsTableCell>
                      <ItemsTableCell align="right">
                        {row.version}
                      </ItemsTableCell>
                      <ItemsTableCell>
                        {row.starts_at
                          ? moment(row.starts_at).format('DD/MM/YYYY HH:mm')
                          : ''}
                      </ItemsTableCell>
                      <ItemsTableCell>
                        {row.ends_at
                          ? moment(row.ends_at).format('DD/MM/YYYY HH:mm')
                          : ''}
                      </ItemsTableCell>
                      <ItemsTableCell>
                        {row.cancelled_at
                          ? moment(row.cancelled_at).format('DD/MM/YYYY HH:mm')
                          : ''}
                      </ItemsTableCell>
                      <ItemsTableCell align="center">
                        <ContractStatus size="small" status={row.status} />
                      </ItemsTableCell>
                      <ItemsTableCell align="right">
                        {moment(row.created_at).format('DD/MM/YYYY HH:mm')}
                      </ItemsTableCell>
                    </ItemsTableRow>
                  );
                })}
              {!loading && cycles.length === 0 && (
                <TableRow>
                  <ItemsTableCell colSpan={COLUMNS.length} align="center">
                    <Box m={4}>
                      <Typography>Nenhum ciclo encontrado</Typography>
                    </Box>
                  </ItemsTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Resultados por página"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} / ${count}`
          }
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </Fade>
  );
};

export default CyclesTab;
