import React, { useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import Language from '@material-ui/icons/Language';
import DescriptionIcon from '@material-ui/icons/Description';
import Skeleton from '@material-ui/lab/Skeleton';

import moment from 'moment';

import Tabs from '../../components/Tabs';
import TenantStatus from '../../components/TenantStatus';
import Chip from '../../components/Chip';

import { PermissionContext } from '../../contexts/PermissionContext';
import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import TenantTab from './TenantTab';
import SubscriptionTab from './SubscriptionTab';
import CyclesTab from './CyclesTab';

import ChargesTab from './ChargesTab';
import ProposalsTab from './ProposalsTab';
import TenantAssociationTab from './TenantAssociationTab';

import EditTenantDialog from './EditTenantDialog';
import InactivateTenantDialog from './InactivateTenantDialog';
import ActivateTenantDialog from './ActivateTenantDialog';
import CancelTenantDialog from './CancelTenantDialog';
import IuguDialog from './IuguDialog';
import PaymentProvider from '../../components/PaymentProvider';

const TenantDetailPage = () => {
  const params = useParams();
  const history = useHistory();

  const { authorized, isEyemobileUser } = useContext(PermissionContext);

  const [isLoading, setIsLoading] = useState(true);
  const [tenant, setTenant] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const [showEditTenantDialog, setShowEditTenantDialog] = useState(false);
  const [showIuguDialog, setShowIuguDialog] = useState(false);
  const [showCancelTenantDialog, setShowCancelTenantDialog] = useState(false);
  const [showInactivateTenantDialog, setShowInactivateTenantDialog] = useState(
    false,
  );

  const [showActivateTenantDialog, setShowActivateTenantDialog] = useState();

  const fetchTenant = async () => {
    setIsLoading(true);

    try {
      const response = await gateway.get(`/billing/tenants/${params.id}`);

      setTenant(response.data.tenant);
      setSubscription(response.data.subscription);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const expiresAt = tenant?.contract?.expires_at
    ? moment(tenant.contract.expires_at).format('DD/MM/YYYY')
    : '-';

  const isExpired = tenant?.contract?.expires_at
    ? moment(tenant.contract.expires_at).isBefore(moment())
    : false;

  const getStatus = () => {
    if (
      tenant?.contract?.payment_provider !== 1 &&
      tenant.subscription_status !== 3
    ) {
      return tenant.status;
    }

    return tenant.subscription_status;
  };

  React.useEffect(() => {
    fetchTenant();
  }, []);

  return (
    <>
      <Fade in>
        <Box flex={1}>
          <Paper square elevation={2}>
            <Box display="flex" flexDirection="column">
              <Box pt={6} p={4} display="flex" flexDirection="column">
                <Box alignSelf="flex-start">
                  <Button
                    onClick={() => history.push('/tenants')}
                    startIcon={<ChevronLeftIcon />}
                  >
                    Voltar para clientes
                  </Button>
                </Box>
                <Box mt={2} display="flex" flexDirection="column">
                  {isLoading ? (
                    <>
                      <Skeleton variant="text" height={42} width={180} />
                      <Skeleton variant="text" height={24} width={120} />
                    </>
                  ) : (
                    <>
                      <Box display="flex" alignItems="center">
                        <Box mr={2}>
                          <Typography variant="h4">{tenant.name}</Typography>
                        </Box>
                        <Box mr={2}>
                          <TenantStatus status={getStatus()} />
                        </Box>
                        <Box
                          flex={1}
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          {authorized('tenants.edit') && (
                            <Box mr={2}>
                              <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={() => setShowEditTenantDialog(true)}
                              >
                                Editar
                              </Button>
                            </Box>
                          )}
                          {isEyemobileUser ? (
                            <Box mr={2}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setShowIuguDialog(true)}
                                startIcon={<DescriptionIcon />}
                              >
                                Iugu
                              </Button>
                            </Box>
                          ) : null}

                          {authorized('admin.tools') ? (
                            <Box mr={2}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  window.open(
                                    `https://portal.eyemobile.com.br/enter/${tenant.id}`,
                                    '_blank',
                                  );
                                }}
                                startIcon={<Language />}
                              >
                                Acessar Portal
                              </Button>
                            </Box>
                          ) : (
                            undefined
                          )}
                          {tenant &&
                            tenant.subscription_status !== 3 &&
                            authorized('tenants.inactivate') && (
                              <Box mr={2}>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  startIcon={<BlockIcon />}
                                  onClick={() =>
                                    setShowCancelTenantDialog(true)
                                  }
                                >
                                  Cancelar
                                </Button>
                              </Box>
                            )}
                          {tenant &&
                            tenant.subscription_status === 3 &&
                            authorized('tenants.inactivate') && (
                              <Box mr={2}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  startIcon={<BlockIcon />}
                                  onClick={() =>
                                    setShowCancelTenantDialog(true)
                                  }
                                >
                                  Alterar Cancelamento
                                </Button>
                              </Box>
                            )}
                        </Box>
                      </Box>
                      <Box mt={1} display="flex" flexDirection="row">
                        <Typography variant="subtitle2">
                          {tenant.partner ? tenant.partner.name : ''}
                        </Typography>
                      </Box>

                      <Box
                        mt={1}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <Typography variant="subtitle">
                          Pagamento via:
                        </Typography>
                        <PaymentProvider
                          paymentProvider={tenant.iugu_customer_id ? 1 : 0}
                        />
                      </Box>
                      {tenant.subscription_status === 1 &&
                        tenant?.contract?.payment_provider === 1 &&
                        tenant?.iugu_subscription_id && (
                          <Box
                            mt={1}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Typography variant="subtitle">
                              Próximo vencimento:
                            </Typography>
                            {expiresAt}
                            {isExpired && (
                              <Box mr={2}>
                                <Chip
                                  icon={<EventBusyIcon color="error" />}
                                  label="ATRASADO"
                                  type="error"
                                  size="small"
                                />
                              </Box>
                            )}
                          </Box>
                        )}

                      {tenant.subscription_status === 1 &&
                        tenant?.contract?.payment_provider === 1 &&
                        !tenant?.iugu_subscription_id && (
                          <Box
                            mt={1}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Typography variant="subtitle">
                              Ativo até:
                            </Typography>
                            14/06/2023
                          </Box>
                        )}
                    </>
                  )}
                </Box>
              </Box>
              <Tabs isLoading={isLoading}>
                <TenantTab label="Dados Cadastrais" tenant={tenant} />
                <SubscriptionTab
                  label="Assinatura"
                  tenant={tenant}
                  subscription={subscription}
                />
                <CyclesTab label="Ciclos" tenant={tenant} />
                <ChargesTab label="Cobranças" tenant={tenant} />
                <ProposalsTab label="Propostas" tenant={tenant} />
                <TenantAssociationTab
                  label="Estrutura organizacional"
                  tenant={tenant}
                />
              </Tabs>
            </Box>
          </Paper>
        </Box>
      </Fade>
      <EditTenantDialog
        open={showEditTenantDialog}
        onClose={() => {
          setShowEditTenantDialog(false);
        }}
        onSubmit={() => {
          fetchTenant();
          setShowEditTenantDialog(false);
        }}
        tenant={tenant}
      />

      <InactivateTenantDialog
        open={showInactivateTenantDialog}
        onClose={() => {
          setShowInactivateTenantDialog(false);
        }}
        onSubmit={() => {
          fetchTenant();
          setShowInactivateTenantDialog(false);
        }}
        tenant={tenant}
      />
      <ActivateTenantDialog
        open={showActivateTenantDialog}
        onClose={() => {
          setShowActivateTenantDialog(false);
        }}
        onSubmit={() => {
          fetchTenant();
          setShowActivateTenantDialog(false);
        }}
        tenant={tenant}
      />
      <CancelTenantDialog
        open={showCancelTenantDialog}
        onClose={() => {
          setShowCancelTenantDialog(false);
        }}
        onSubmit={() => {
          fetchTenant();
          setShowCancelTenantDialog(false);
        }}
        tenant={tenant}
      />
      <IuguDialog
        open={showIuguDialog}
        onClose={() => {
          setShowIuguDialog(false);
        }}
        onSubmit={() => {
          fetchTenant();
          setShowIuguDialog(false);
        }}
        tenant={tenant}
      />
    </>
  );
};

export default TenantDetailPage;
