import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { default as MuiChip } from '@material-ui/core/Chip';

const map = {
  0: 'INATIVO',
  1: 'ATIVO',
  2: 'SUSPENSO',
  3: 'CANCELADO',
};

const getColor = (theme, status) => {
  switch (status) {
    case 0:
      return theme.palette.danger[500];
    case 1:
      return theme.palette.success[500];
    case 2:
      return theme.palette.warning[500];
    case 3:
      return theme.palette.danger[500];
    default:
      return theme.palette.primary[500];
  }
};

const getBackgroundColor = (theme, status) => {
  switch (status) {
    case 0:
      return theme.palette.danger[50];
    case 1:
      return theme.palette.success[50];
    case 2:
      return theme.palette.warning[50];
    case 3:
      return theme.palette.danger[50];
    default:
      return theme.palette.primary[50];
  }
};

const Chip = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 10,
    color: ({ status }) => getColor(theme, status),
    backgroundColor: ({ status }) => getBackgroundColor(theme, status),
  },
}))(MuiChip);

export default function TenantStatus({ status, ...props }) {
  return <Chip label={map[status]} status={status} {...props} />;
}
