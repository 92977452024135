import React from 'react';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import CheckBoxField from '../../components/CheckBoxField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: '1px solid #EDEFF0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #EDEFF0',
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}))((props) => {
  const { classes, onClose, onSubmit, submitting, ...other } = props;

  return (
    <MuiDialogActions className={classes.root} {...other}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onClose}
        className={classes.cancel}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        className={classes.confirm}
        endIcon={
          submitting ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <CheckIcon />
          )
        }
      >
        Confirmar
      </Button>
    </MuiDialogActions>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const SuspendContractSchema = Yup.object().shape({
  suspend_pending_charges: Yup.bool().required('Campo obrigatório'),
});

export default function SuspendContractDialog({
  open,
  onClose,
  onSubmit,
  contract,
}) {
  const params = useParams();

  const formRef = React.useRef();

  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);

  const fetchCharges = async () => {
    if (contract === null) {
      return;
    }

    setLoading(true);

    try {
      const response = await gateway.get(
        `/billing/tenants/${contract.tenant.id}/charges`,
        {
          params: {
            contract_id: contract.id,
            offset: 0,
            limit: 10,
            status: [0, 2, 3],
            ends_at: moment().toISOString(),
          },
        },
      );

      setTotal(response.data.total);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchCharges();
  }, [contract]);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Suspender contrato
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box
            p={4}
            pt={6}
            pb={8}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box p={4} pt={4} pb={4} display="flex" flexDirection="column">
            <Box mb={2}>
              <Alert severity="error">
                ATENÇÃO! O cliente será inativado enquanto o contrato estiver
                suspenso.
              </Alert>
            </Box>
            <Box mb={2} display="flex" flexDirection="column">
              <Typography variant="body1">
                Foram identificadas {total} cobranças pendentes para este
                cliente. Selecione a opção abaixo caso deseja suspender as
                cobranças pendentes.
              </Typography>
            </Box>
            <Formik
              innerRef={formRef}
              initialValues={{
                suspend_pending_charges: false,
              }}
              validationSchema={SuspendContractSchema}
              onSubmit={async (values) => {
                setSubmitting(true);

                try {
                  await gateway.post(
                    `/billing/contracts/${params.contract_id}/suspend`,
                    {
                      ...values,
                    },
                  );
                  onSubmit();
                } catch (err) {
                  console.error(err);

                  if (
                    err.response.status === 400 &&
                    err.response.data &&
                    err.response.data.message
                  ) {
                    showError(err.response.data.message);
                  } else {
                    showError('Falha ao suspender contrato');
                  }
                } finally {
                  setSubmitting(false);
                }
              }}
              render={() => {
                return (
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          name="suspend_pending_charges"
                          label="Suspender cobranças pendentes (cobranças com data de pagamento até o dia de hoje)"
                          component={CheckBoxField}
                        />
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions
        onClose={onClose}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Dialog>
  );
}
