import React from 'react';
import { useParams } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import Wizard from '../../components/Wizard/Wizard';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import CompanyStep from './Steps/CompanyStep';
import ItemsStep from './Steps/ItemsStep';
import ContractStep from './Steps/ContractStep';

const AddContractWizard = () => {
  const params = useParams();

  const [loading, setLoading] = React.useState(true);
  const [context, setContext] = React.useState({});

  const fetchTenant = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(`/billing/tenants/${params.id}`);

      const { tenant } = response.data;

      setContext({
        tenant: { ...tenant },
        document: tenant.document || '',
        corporate_name: tenant.name || '',
        trading_name: tenant.label || '',
        address: tenant.address || '',
        number: tenant.address_number || '',
        address2: tenant.address2 || '',
        district: tenant.district || '',
        city: tenant.city || '',
        state: tenant.state || '',
        zipcode: tenant.zipcode.replace(/[^\d]/g, '') || '',
        partner_name: '',
        partner_document: '',
        partner_email: '',
        partner_phone: '',
        partner_birthday: '',
        financial_name: '',
        financial_email: tenant.email || '',
        financial_phone: tenant.phone || '',
      });
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchTenant();
  }, []);

  if (loading) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={84} />
      </Box>
    );
  }

  return (
    <Fade in>
      <Wizard context={context}>
        <CompanyStep label="Dados Cadastrais" />
        <ItemsStep label="Adicionar Itens" recurrent />
        <ContractStep label="Contrato" />
      </Wizard>
    </Fade>
  );
};

export default AddContractWizard;
