import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Field, Formik } from 'formik';
import Container from '@eyemobile/core/Container';
import clsx from 'clsx';
import EyeModal from './EyeModal';
import EyeModalContent from './EyeModalContent';
import { showResponseError, showSuccess } from '../utils/toast';
import FormikSelect from './FormikSelect';
import gateway from '../utils/gateway';
import useFocus from '../utils/useFocus';
import EyeButton from './EyeButton';
import providers from '../constants/providers';
import TransferDeviceModalItem from './TransferDeviceModalItem';

const TransferSchema = Yup.object().shape({
  dcOrigin: Yup.object()
    .required('common:fieldRequired')
    .nullable(),
  dcDestination: Yup.object()
    .required('common:fieldRequired')
    .nullable(),
});

const TransferDeviceModal = ({ visible, onClose }) => {
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState(false);
  const [setFocus, focusProps] = useFocus(true);
  const [address, setAddress] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [transferError, setTransferError] = useState(null);
  const [devices, setDevices] = useState([]);

  const addDevice = async (values) => {
    const { dcOrigin: origin, dcDestination: destination } = values;
    setIsChecking(true);

    let device = null;

    try {
      device = await gateway
        .get('/legacy/devices/validateTransfer', {
          params: { address, origin: origin.value },
        })
        .then(({ data }) => data);

      const provider = providers.find(
        (p) => p.value === String(device.provider),
      );

      device = {
        ...device,
        provider,
        origin,
        destination,
      };
    } catch (e) {
      setIsChecking(false);
      showResponseError(e.response.data);
      console.error(e.response.data);
      return;
    }

    setIsChecking(false);

    setDevices(devices.concat(device));
  };

  const handleClose = () => {
    setTransferError(null);
    setDevices([]);
    onClose(false);
  };

  const onTransfer = async () => {
    setIsSaving(true);

    const devicesNormalized = devices.map((d) => ({
      ...d,
      provider: d.provider.value,
      origin: d.origin.value,
      destination: d.destination.value,
    }));

    try {
      await gateway.put('/legacy/devices/transferMultiple', {
        devices: devicesNormalized,
      });

      showSuccess();
      setIsSaving(false);
      handleClose();
    } catch (e) {
      setIsSaving(false);
      setTransferError(e.response.data);
    }
  };

  const removeDevice = (address) => {
    const newDevices = devices.filter((d) => {
      return d.address !== address;
    });

    setDevices(newDevices);
  };

  return (
    <Formik
      initialValues={{
        dcOrigin: undefined,
        dcDestination: undefined,
        address: '',
      }}
      validationSchema={TransferSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
          addDevice(values);
          setAddress('');
          setFocus(true);
        } catch (e) {
          setSubmitting(false);
        }
      }}
      render={({ handleSubmit, submitForm, resetForm }) => {
        return (
          <EyeModal visible={visible} customWidth="auto" padding="25px 65px">
            <EyeModalContent
              width="100%"
              onConfirm={onTransfer}
              onCancel={() => {
                handleClose();
                resetForm();
              }}
              confirmLabel="common:transfer"
              cancelLabel="common:close"
              disabled={isSaving}
              confirmLoading={isSaving}
              title={t('common:transferDevice')}
              childrenClassName="flex-column justify-center"
            >
              <form onSubmit={handleSubmit} className="fw">
                <div className="flex align-i-center mt-lg">
                  <i className="ic-blue-round-dcs mr" />
                  <Field
                    name="dcOrigin"
                    render={({
                      field,
                      form: { setFieldValue, touched, errors },
                    }) => {
                      return (
                        <FormikSelect
                          hideLabel
                          onBlur={field.onBlur}
                          isMulti={false}
                          selected={field.value}
                          containerClassName="fw"
                          placeholder="common:origin"
                          touched={touched}
                          field={field}
                          errors={errors}
                          onChange={(value) => {
                            setFieldValue(field.name, value);
                          }}
                          fetch={() => {
                            return gateway
                              .get('/legacy/distributionCenter/lookup')
                              .then(({ data }) => data);
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <div className="flex align-i-center mt-lg">
                  <i className="ic-blue-round-dcs mr" />
                  <Field
                    name="dcDestination"
                    render={({
                      field,
                      form: { setFieldValue, touched, errors },
                    }) => {
                      return (
                        <FormikSelect
                          hideLabel
                          onBlur={field.onBlur}
                          isMulti={false}
                          selected={field.value}
                          containerClassName="fw"
                          placeholder="common:destination"
                          touched={touched}
                          field={field}
                          errors={errors}
                          onChange={(value) => {
                            setFieldValue(field.name, value);
                          }}
                          fetch={() => {
                            return gateway
                              .get('/legacy/distributionCenter/lookup')
                              .then(({ data }) => data);
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <div className="flex flex-column align-i-center mt-lg">
                  <div className="flex align-i-center fw">
                    <i className="ic-blue-round-serialNumber mr" />
                    <input
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          submitForm();
                        }
                      }}
                      value={address}
                      style={{ paddingLeft: '10px' }}
                      placeholder={t('common:serialNumber')}
                      className="input-default uppercase-placeholder"
                      onChange={(e) => {
                        setTransferError(null);
                        setAddress(e.target.value);
                      }}
                      {...focusProps}
                    />
                  </div>
                  <div className="flex justify-center fw mt-lg">
                    <EyeButton
                      type="button"
                      className="btn-solid-green"
                      label={t('common:add')}
                      clickHandler={submitForm}
                      isLoading={isChecking}
                      disabled={isChecking || isSaving}
                    />
                  </div>
                  {devices && devices.length > 0 && (
                    <Container width="100%" marginLeft="15px">
                      <span className="filter-label">
                        {t('common:devicesCount', { count: devices.length })}
                      </span>
                    </Container>
                  )}
                  <span className="red mt ml-lg" style={{ maxWidth: '300px' }}>
                    {transferError}
                  </span>
                  <div
                    className={clsx(
                      'flex flex-column vertical-scroll fw',
                      devices && devices.length > 0 && 'mt-lg',
                    )}
                    style={{ maxHeight: '385px', padding: '0px 10px' }}
                  >
                    {devices.map((device) => (
                      <TransferDeviceModalItem
                        device={device}
                        removeDevice={removeDevice}
                      />
                    ))}
                  </div>
                </div>
              </form>
            </EyeModalContent>
          </EyeModal>
        );
      }}
    />
  );
};

TransferDeviceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

TransferDeviceModal.defaultProps = {
  visible: false,
};

export default TransferDeviceModal;
