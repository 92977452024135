import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { default as MuiDialog } from '@material-ui/core/Dialog';

const DialogWrapper = withStyles(() => ({
  scrollPaper: {
    background:
      'linear-gradient(-45deg, rgba(40, 151, 200, 0.85), rgba(0, 110, 160, 0.85))',
  },
}))(MuiDialog);

const Dialog = ({ children, ...props }) => {
  return (
    <DialogWrapper fullWidth disableScrollLock {...props}>
      {children}
    </DialogWrapper>
  );
};

export default Dialog;
