import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Async, components } from 'react-select';
import debounce from 'debounce-promise';
import clsx from 'clsx';

import { arrowRenderer, getStyles } from '../utils/util';
import Spin from './Spin';

const AsyncSelect = (props) => {
  const {
    dark,
    white,
    style,
    center,
    styles,
    selected,
    className,
    loadOptions,
    placeholder,
    transparent,
    optionRenderer,
    defaultOptions,
    containerStyle,
    centerPlaceholder,
    closeMenuOnSelect,
    wrapperClassName,
    customComponents,
    customLoading,
    ...rest
  } = props;

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const inputStyles = getStyles(dark, white, center, transparent);

  const fetchOptions = async (inputValue) => {
    const results =
      inputValue.length === 1 && !/\d/.test(inputValue)
        ? []
        : await loadOptions(inputValue);

    setIsLoading(false);

    return Promise.resolve(results);
  };

  const debouncedLoadOptions = debounce(fetchOptions, 1000, { leading: false });

  return (
    <div className={clsx('flex grow', wrapperClassName)} style={containerStyle}>
      <Async
        {...rest}
        isLoading={customLoading || isLoading}
        isClearable
        style={style}
        styles={{
          ...styles,
          multiValueLabel: (provided) => ({
            ...provided,
            color: '#ffffff',
            lineHeight: 1,
            padding: 6,
            paddingLeft: 10,
          }),
        }}
        value={selected}
        maxMenuHeight="300"
        menuPlacement="auto"
        minMenuHeight="1000"
        classNamePrefix="react-select"
        defaultOptions={defaultOptions}
        loadOptions={debouncedLoadOptions}
        closeMenuOnSelect={closeMenuOnSelect}
        searchPromptText={t('common:typeToSearch')}
        loadingMessage={() => <span>{t('common:loading')}</span>}
        className={clsx(
          'react-select-container',
          inputStyles,
          className,
          centerPlaceholder && 'center-placeholder',
        )}
        components={{
          LoadingIndicator: () => (
            <Spin fontSize={20} color={dark || white ? '#FFFFFF' : '#7D1DD1'} />
          ),
          DropdownIndicator: () =>
            arrowRenderer((white || dark) && !transparent),
          IndicatorSeparator: null,
          Option: ({ children, ...optionProps }) =>
            optionRenderer ? (
              optionRenderer({ ...optionProps })
            ) : (
              <components.Option {...optionProps}>{children}</components.Option>
            ),
          Placeholder: ({ ...rest }) => (
            <components.Placeholder {...rest}>
              <span>{t(placeholder)}</span>
            </components.Placeholder>
          ),
          NoOptionsMessage: () => (
            <div className="flex grow">
              <span className="text-center fw mt mb">
                {t('common:noResultFound')}
              </span>
            </div>
          ),
          SingleValue: ({
            children,
            options,
            selectProps,
            hasValue,
            ...props
          }) => {
            let selectedValue =
              selectProps.value &&
              selectProps.value.value &&
              selectProps.value.label
                ? selectProps.value
                : { label: '' };

            if (
              selectProps.value &&
              selectProps.value.value &&
              !selectProps.value.label &&
              options.length > 0 &&
              defaultOptions
            ) {
              selectedValue = options.find(
                (opt) =>
                  opt.value === (selectProps.value.value || selectProps.value),
              );
            }

            if (!hasValue || !defaultOptions || selectProps.inputValue) {
              return (
                <components.SingleValue {...props}>
                  {children}
                </components.SingleValue>
              );
            }

            return (
              <components.SingleValue {...props}>
                {selectedValue ? selectedValue.label : children}
              </components.SingleValue>
            );
          },
          ...customComponents,
        }}
      />
    </div>
  );
};

AsyncSelect.defaultProps = {
  style: {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid #a9a9a9',
    borderRadius: '0',
  },
  dark: false,
  white: false,
  center: false,
  className: undefined,
  selected: null,
  transparent: false,
  defaultOptions: false,
  loadOptions: undefined,
  optionRenderer: null,
  styles: undefined,
  closeMenuOnSelect: true,
  centerPlaceholder: false,
  containerStyle: undefined,
  placeholder: 'common:select',
  wrapperClassName: undefined,
};

AsyncSelect.propTypes = {
  style: PropTypes.object,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  dark: PropTypes.bool,
  white: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
  loadOptions: PropTypes.func,
  transparent: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultOptions: PropTypes.bool,
  optionRenderer: PropTypes.func,
  styles: PropTypes.object,
  containerStyle: PropTypes.object,
  centerPlaceholder: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

export default AsyncSelect;
