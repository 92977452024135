import { CURRENT_ENV } from '../constants/constants';

export const BUCKET =
  CURRENT_ENV === 'production' ? 'eyemobile-public' : 'eyemobile-public-staging';

export const IMAGES_CLOUDFRONT = `https://images.eyemobile.com.br`;

export const buildImageUrl = (options) => {
  if (!options.name) {
    return null;
  }

  let url = IMAGES_CLOUDFRONT;
  if (CURRENT_ENV !== 'production') {
    url = `https://${BUCKET}.s3.amazonaws.com`;
  }

  if (options.folder) {
    url = url.concat(`/${options.folder}`);
  }

  if (options.size) {
    url = url.concat(`/${options.size}x${options.size}`);
  }

  return url.concat(`/${options.name}`);
};
