import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useUIDSeed } from 'react-uid';

import EyeTable from './EyeTable';
import Loading from './Loading';

const EyeTableInfinite = ({ fetch, fetchMore, parameters, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const seed = useUIDSeed();

  const handleFetch = (page = 1) => {
    if (!isLoading) {
      if (page === 1) {
        setTableData([]);
      }

      setIsLoading(true);
      const doFetch = async () => {
        const fetchData = await fetch(page);
        const dataWithKeys = fetchData.map((row) => ({
          ...row,
          key: seed(row),
        }));
        const newTableData =
          page === 1 ? dataWithKeys : tableData.concat(dataWithKeys);
        setTableData(newTableData);
        setIsLoading(false);
      };
      doFetch();
    }
  };

  const handleScroll = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    handleFetch(page);
  }, [page]);

  useEffect(() => {
    if (parameters) {
      handleFetch(1);
      setPage(1);
    }
  }, [parameters]);

  return (
    <>
      <EyeTable
        {...rest}
        infinite
        data={tableData}
        isLoading={isLoading}
        handleScroll={handleScroll}
      />
      <Loading show={isLoading} isSmall={page > 1} className="adjust-center" />
    </>
  );
};

EyeTableInfinite.propTypes = {
  fetch: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  ),
  parameters: PropTypes.any,
};

EyeTableInfinite.defaultProps = {
  parameters: undefined,
  data: [],
};

export default EyeTableInfinite;
