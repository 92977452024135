import React from 'react';
import { useParams } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import Wizard from '../../components/Wizard/Wizard';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import TenantStep from './Steps/TenantStep';
import ItemsStep from './Steps/ItemsStep';
import SpotStep from './Steps/SpotStep';
import ReviewStep from './Steps/ReviewStep';

const SpotProposalWizard = () => {
  const params = useParams();

  const [loading, setLoading] = React.useState(true);
  const [context, setContext] = React.useState({});

  const fetchTenant = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(`/billing/tenants/${params.id}`);
      setContext({
        tenant: {
          ...response.data.tenant,
          value: response.data.tenant.id,
          label: response.data.tenant.name,
        },
        name: response.data.tenant.name,
      });
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchTenant();
  }, []);

  if (loading) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={84} />
      </Box>
    );
  }

  return (
    <Fade in>
      <Wizard context={context}>
        <TenantStep label="Selecionar Cliente" guided spot />
        <ItemsStep label="Adicionar Itens" spot />
        <SpotStep label="Proposta" />
        <ReviewStep label="Revisão" spot />
      </Wizard>
    </Fade>
  );
};

export default SpotProposalWizard;
