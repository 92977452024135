import React from 'react';
import * as PropTypes from 'prop-types';

const Spin = ({ color, fontSize }) => {
  return (
    <div style={{ color, fontSize, lineHeight: 1 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width={fontSize}
        height={fontSize}
      >
        <path
          opacity=".25"
          d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
        />
        <path
          d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z"
          transform="rotate(141.25 16 16)"
          fill="currentColor"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 16 16"
            to="360 16 16"
            dur="0.8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  );
};

Spin.defaultProps = {
  color: '#7D1DD1',
  fontSize: 32,
};

Spin.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.number,
};

export default Spin;
