import React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useFormik, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';

import Dialog from '../../components/Dialog';
import DialogTitle from '../../components/DialogTitle';
import DialogContent from '../../components/DialogContent';
import DialogActions from '../../components/DialogActions';
import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Nome deve conter até 255 caracteres'),
  language: Yup.string().required('Campo obrigatório'),
  group_id: Yup.string().required('Campo obrigatório'),
  email: Yup.string()
    .email('Email inválido')
    .required('Campo obrigatório'),
  phone: Yup.string().required('Campo obrigatório'),
});

export default function EditUserFormDialog({ open, user, onClose, onSubmit }) {
  const params = useParams();

  const formRef = React.useRef();

  const formik = useFormik({
    initialValues: {
      partner_id: params.partner_id,
      name: user ? user.name : '',
      language: user ? user.language : 'pt-br',
      group_id: user ? user.group_id : '4',
      email: user ? user.email : '',
      phone: user ? user.phone : '',
    },
    validationSchema: UserSchema,
    onSubmit: async (values, formikBag) => {
      formikBag.setSubmitting(true);

      try {
        await gateway.post(`/billing/users/${user.id}`, {
          ...values,
        });
        onSubmit();
      } catch (err) {
        console.error(err);

        if (
          err.response.status === 400 &&
          err.response.data &&
          err.response.data.message
        ) {
          showError(err.response.data.message);
        } else {
          showError('Falha ao editar usuário');
        }
      } finally {
        formikBag.setSubmitting(false);
      }

      formikBag.setSubmitting(true);
      onSubmit(values, formikBag);
    },
    enableReinitialize: true,
  });

  const { handleSubmit, isSubmitting, errors, touched } = formik;

  return (
    <FormikProvider ref={formRef} value={formik}>
      <Dialog onClose={onClose} open={open} maxWidth="md">
        <DialogTitle onClose={onClose}>Editar usuário</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={6}>
            <Grid item container direction="column" spacing={2}>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">Dados pessoais</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" spacing={1}>
                <Grid item container direction="row" spacing={2}>
                  <Grid item sm={8} xs={12}>
                    <Field
                      name="name"
                      label="Nome"
                      placeholder="Nome"
                      component={TextField}
                      fullWidth
                      error={errors.name && touched.name}
                      helperText={
                        errors.name && touched.name ? errors.name : ''
                      }
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Field
                      name="language"
                      label="Idioma"
                      placeholder="Idioma"
                      component={SelectField}
                      options={[
                        { label: 'Português (Brasil)', value: 'pt-br' },
                        { label: 'English (USA)', value: 'en-us' },
                      ]}
                      error={errors.language && touched.language}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Field
                    name="group_id"
                    label="Grupo de acesso"
                    placeholder="Grupo de acesso"
                    component={SelectField}
                    options={[
                      { label: 'Parceiro (administrador)', value: '4' },
                      { label: 'Parceiro (staff)', value: '5' },
                    ]}
                    error={errors.group_id && touched.group_id}
                  />
                </Grid>
                <Grid item container direction="row" spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <Field
                      name="email"
                      label="E-mail"
                      placeholder="E-mail"
                      component={TextField}
                      fullWidth
                      error={errors.email && touched.email}
                      helperText={
                        errors.email && touched.email ? errors.email : ''
                      }
                      type="email"
                      inputProps={{ inputMode: 'email' }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Field
                      name="phone"
                      label="Telefone"
                      placeholder="Telefone"
                      component={TextField}
                      fullWidth
                      error={errors.phone && touched.phone}
                      helperText={
                        errors.phone && touched.phone ? errors.phone : ''
                      }
                      mask={[
                        '+',
                        '5',
                        '5',
                        ' ',
                        '(',
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d?/,
                      ]}
                      inputProps={{ inputMode: 'tel' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          onClose={onClose}
          onSubmit={handleSubmit}
          submitting={isSubmitting}
        />
      </Dialog>
    </FormikProvider>
  );
}
