import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import gateway from '../utils/gateway';
import AsyncSelect from './AsyncSelect';

const lookupEvents = async (q) =>
  gateway.get('/legacy/events/lookupAll', { params: { q } }).then((response) =>
    (response.data || []).map((event) => ({
      ...event,
      value: event.id,
      label: event.name,
    })),
  );

const SelectEvent = (props) => {
  const {
    disabled,
    onChange,
    hideLabel,
    className,
    labelClassName,
    wrapperClassName,
    selectWrapperClassName,
    ...rest
  } = props;

  const { t } = useTranslation();

  const handleOnChange = (selected) => {
    if (onChange) {
      onChange(selected);
    }
  };

  return (
    <div
      className={clsx(
        !hideLabel && 'flex align-i-center no-scroll mt-lg',
        wrapperClassName,
        { disabled },
      )}
    >
      {!hideLabel && (
        <span className={clsx('filter-label', 'mr', labelClassName)}>
          {t('common:event')}
        </span>
      )}
      <AsyncSelect
        {...rest}
        className={className}
        placeholder="common:all"
        onChange={handleOnChange}
        loadOptions={lookupEvents}
        wrapperClassName={selectWrapperClassName}
      />
    </div>
  );
};

SelectEvent.defaultProps = {
  className: '',
  disabled: false,
  hideLabel: false,
  onChange: undefined,
  labelClassName: undefined,
  wrapperClassName: undefined,
  selectWrapperClassName: undefined,
};

SelectEvent.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  selectWrapperClassName: PropTypes.string,
};

export default SelectEvent;
