import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Global, css } from '@emotion/core';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import './styles/button.css';
import './styles/card.css';
import './styles/colors.css';
import './styles/customized-toast.css';
import './styles/flexbox.scss';
import './styles/float-register.css';
import './styles/floating-bar.css';
import './styles/fonts.scss';
import './styles/icons.css';
import './styles/input.css';
import './styles/input-radio.css';
import './styles/main.scss';
import './styles/margins.css';
import './styles/multiple-select.css';
import './styles/navigation.css';
import './styles/paddings.css';
import './styles/scroll.css';
import './styles/select.css';
import './styles/table.css';
import './styles/top-header.css';

import App from './App';

import store from './store/configureStore';

import gateway from './utils/gateway';
import applyInterceptors from './utils/gatewayInterceptors';

applyInterceptors(gateway);

render(
  <Provider store={store}>
    <Global
      styles={css`
        *,
        :after,
        :before {
          box-sizing: border-box;
        }
      `}
    />
    <App />
    <ToastContainer />
  </Provider>,
  document.getElementById('root'),
);
