import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

import { resetInvalidPassword, getUser, save } from '../store/modules/user';
import '../styles/float-register.css';
import { URL_USER } from '../constants/s3';
import { userPlaceholder } from '../constants/constants';
import FloatingBar from './FloatingBar';

const actions = {
  resetInvalidPassword,
  getUser,
  save,
};

const setErrorPassword = (formApi) => {
  formApi.setError('newPassword', 'error');
  formApi.setError('retypePassword', 'error');
  formApi.setSuccess('newPassword', '');
  formApi.setSuccess('retypePassword', '');
};

const setSuccessPassword = (formApi) => {
  formApi.setSuccess('newPassword', 'success');
  formApi.setSuccess('retypePassword', 'success');
  formApi.setError('newPassword', '');
  formApi.setError('retypePassword', '');
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSyncSocialMedia: false,
      hasSetImage: false,
      isLoading: true,
      hasSubmitted: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { formApi, hasSubmitted } = this.state;
    const { show: pShow, user: pUser } = this.props;
    const { i18n, show, getUser, user } = nextProps;

    if (!pShow && show) {
      this.setState({ hasSubmitted: false });
      getUser();
      return;
    }

    if (user.language !== pUser.language) {
      i18n.changeLanguage(user.language);
    }

    if (formApi) {
      this.setState({ isLoading: false });
      formApi.setAllValues(user);
    }

    if (hasSubmitted) {
      this.close();
    }
  }

  onSubmit = (newValues) => {
    const { user, save } = this.props;
    const { formApi, isLoading } = this.state;

    const userToSave = {
      ...user,
      ...newValues,
      created: null,
    };

    if (!isLoading) {
      this.setState({ hasSubmitted: true });
      formApi.setAllValues(userToSave);
      save(userToSave);
    }
  };

  onSetImage = ({ preview }) => {
    this.setState({ preview, hasSetImage: true });
  };

  onCropImage = ({ file, preview }) => {
    const { user, save } = this.props;

    this.setState({ preview, hasSetImage: false, isLoading: true });

    const userToSave = {
      ...user,
      created: null,
      tenant: null,
      file,
    };

    save(userToSave, 'common:successImage');
  };

  getApi = (formApi) => {
    this.setState({ formApi });
  };

  handleChange = () => {
    const { isSyncSocialMedia } = this.state;
    this.setState({ isSyncSocialMedia: !isSyncSocialMedia });
  };

  close = () => {
    const { user, onClose } = this.props;
    const { formApi } = this.state;
    onClose();
    formApi.resetAll();
    formApi.setAllValues(user);
  };

  optionRenderer = (option) => {
    const { t } = this.props;
    return (
      <div className="flex-block align-i-center">
        <span className="ml">{t(`roles:${option.value}`)}</span>
      </div>
    );
  };

  render() {
    const { preview, isLoading, hasSetImage } = this.state;

    const { t, user, show, invalidPassword, resetInvalidPassword } = this.props;

    return (
      <div>
        <FloatingBar
          className="floating-sm bar-scroll profile-page"
          open={show}
        >
          <div className="flex-block justify-end fw">
            <i
              className={`ic-close ${isLoading ? 'disabled' : ''}`}
              role="button"
              onClick={this.close}
            />
          </div>
          <div className="content-image">
            <img
              src={
                preview ||
                (user.image
                  ? `${URL_USER}${user.image}&size=96`
                  : userPlaceholder)
              }
              alt="avatar"
              className="avatar-profile"
            />
            {/* <UploadFile */}
            {/*  t={t} */}
            {/*  id="uploadProfileImage" */}
            {/*  isLoading={isLoading} */}
            {/*  disabled={isLoading} */}
            {/*  onUpload={this.onSetImage} */}
            {/*  icon="ic-cam" */}
            {/*  classBtn="btn-white mt-lg" */}
            {/* /> */}
            <div className="uppercase mt-md">{user.name}</div>
          </div>
          {/* <Form */}
          {/*  onSubmit={this.onSubmit} */}
          {/*  getApi={this.getApi} */}
          {/*  render={(formApi) => { */}
          {/*    const { id } = this.props; */}
          {/*    const { newPassword, retypePassword } = formApi.values; */}
          {/*    const errors = formApi.errors || {}; */}

          {/*    if (invalidPassword && !errors.password) { */}
          {/*      formApi.setError('password', 'error'); */}
          {/*    } */}
          {/*    if (!invalidPassword && errors.password) { */}
          {/*      formApi.setError('password', ''); */}
          {/*    } */}

          {/*    let errorNewPass = false; */}

          {/*    if ( */}
          {/*      (newPassword || retypePassword) && */}
          {/*      newPassword !== retypePassword */}
          {/*    ) { */}
          {/*      errorNewPass = true; */}
          {/*    } */}

          {/*    if ( */}
          {/*      newPassword && */}
          {/*      newPassword === retypePassword && */}
          {/*      (!formApi.successes || !formApi.successes.newPassword) */}
          {/*    ) { */}
          {/*      setSuccessPassword(formApi); */}
          {/*      errorNewPass = false; */}
          {/*    } */}

          {/*    if (errorNewPass && formApi.submits > 0 && !errors.newPassword) { */}
          {/*      setErrorPassword(formApi); */}
          {/*      errorNewPass = true; */}
          {/*    } */}

          {/*    return ( */}
          {/*      <form */}
          {/*        className="form-sidebar" */}
          {/*        onSubmit={(form) => { */}
          {/*          if (errorNewPass) { */}
          {/*            setErrorPassword(formApi); */}
          {/*            showError('common:passwordNotMatch'); */}
          {/*            formApi.submitForm(form); */}
          {/*            return; */}
          {/*          } */}

          {/*          if (formApi.errors) { */}
          {/*            showError('common:inconsistency'); */}
          {/*          } */}
          {/*          formApi.submitForm(form); */}
          {/*        }} */}
          {/*      > */}
          {/*        <div className="block ov-hidden"> */}
          {/*          <div className="inline"> */}
          {/*            <label htmlFor="name">{`${t('common:name')}: `}</label> */}
          {/*            <Text */}
          {/*              field="name" */}
          {/*              className="ml" */}
          {/*              id={`${id}_name`} */}
          {/*              autoComplete="name" */}
          {/*              disabled={isLoading} */}
          {/*            /> */}
          {/*          </div> */}
          {/*          <div className="inline"> */}
          {/*            <label htmlFor="login">{`${t('common:login')}: `}</label> */}
          {/*            <Text */}
          {/*              disabled */}
          {/*              field="login" */}
          {/*              className="ml" */}
          {/*              id={`${id}_login`} */}
          {/*              autoComplete="username" */}
          {/*            /> */}
          {/*          </div> */}
          {/*          <div className="inline"> */}
          {/*            <label htmlFor="email">{`${t('common:email')}: `}</label> */}
          {/*            <Text */}
          {/*              field="email" */}
          {/*              id={`${id}_email`} */}
          {/*              className="ml" */}
          {/*              disabled={isLoading} */}
          {/*            /> */}
          {/*          </div> */}
          {/*        </div> */}
          {/*        <div className="block ov-hidden"> */}
          {/*          <div className="inline align-i-end"> */}
          {/*            <label htmlFor="password"> */}
          {/*              {`${t('common:passwordActually')}: `} */}
          {/*            </label> */}
          {/*            <FormField */}
          {/*              className="ml" */}
          {/*              type="password" */}
          {/*              field="password" */}
          {/*              disabled={isLoading} */}
          {/*              id={`${id}_password`} */}
          {/*              autoComplete="password" */}
          {/*              onChange={() => resetInvalidPassword()} */}
          {/*            /> */}
          {/*          </div> */}
          {/*          <div className="inline align-i-end"> */}
          {/*            <label htmlFor="newPassword"> */}
          {/*              {`${t('common:newPassword')}: `} */}
          {/*            </label> */}
          {/*            <FormField */}
          {/*              className="ml" */}
          {/*              type="password" */}
          {/*              field="newPassword" */}
          {/*              disabled={isLoading} */}
          {/*              id={`${id}_newPassword`} */}
          {/*              autoComplete="newPassword" */}
          {/*            /> */}
          {/*          </div> */}
          {/*          <div className="inline align-i-end"> */}
          {/*            <label htmlFor="retypePassword"> */}
          {/*              {`${t('common:confirmPassword')}: `} */}
          {/*            </label> */}
          {/*            <FormField */}
          {/*              type="password" */}
          {/*              className="ml" */}
          {/*              disabled={isLoading} */}
          {/*              field="retypePassword" */}
          {/*              id={`${id}_retypePassword`} */}
          {/*              autoComplete="retypePassword" */}
          {/*            /> */}
          {/*          </div> */}
          {/*        </div> */}
          {/*        <div className="block"> */}
          {/*          <div className="inline align-i-end"> */}
          {/*            <label htmlFor="phone">{`${t('common:phone')}:`}</label> */}
          {/*            <PhoneInput */}
          {/*              field="phone" */}
          {/*              id={`${id}_phone`} */}
          {/*              submits={formApi.submits} */}
          {/*              disabled={isLoading} */}
          {/*              className="ml" */}
          {/*            /> */}
          {/*          </div> */}
          {/*          <div className="mt-mx sync-social hide"> */}
          {/*            <div>{t('common:syncWithSocialMedia')}</div> */}
          {/*            <Switch */}
          {/*              onChange={this.handleChange} */}
          {/*              checked={isSyncSocialMedia} */}
          {/*              checkedIcon={<div />} */}
          {/*              uncheckedIcon={<div />} */}
          {/*              offColor="#d5d5d5" */}
          {/*              onColor="#07DCAC" */}
          {/*              className="react-switch" */}
          {/*              id="normal-switch" */}
          {/*            /> */}
          {/*          </div> */}
          {/*        </div> */}
          {/*        <div className="flex-block justify-center"> */}
          {/*          <EyeButton */}
          {/*            id={`${id}_button`} */}
          {/*            className="btn-white normal-width float-right mt-md" */}
          {/*            label={t('common:save')} */}
          {/*            disabled={isLoading} */}
          {/*            isLoading={isLoading} */}
          {/*            loadColor="#7d1dd1" */}
          {/*            show */}
          {/*          > */}
          {/*            <i className="ic-label ic-save" /> */}
          {/*          </EyeButton> */}
          {/*        </div> */}
          {/*      </form> */}
          {/*    ); */}
          {/*  }} */}
          {/* /> */}
        </FloatingBar>
        {/* <CropperImage */}
        {/*  id="profileCropper" */}
        {/*  t={t} */}
        {/*  onCropped={this.onCropImage} */}
        {/*  show={hasSetImage} */}
        {/*  src={preview} */}
        {/*  onClose={() => { */}
        {/*    this.setState({ hasSetImage: false }); */}
        {/*  }} */}
        {/* /> */}
      </div>
    );
  }
}

Profile.defaultProps = {
  user: {},
  i18n: {},
  show: false,
  id: 'userForm',
  invalidPassword: false,
};

Profile.propTypes = {
  id: PropTypes.string,
  t: PropTypes.func.isRequired,
  show: PropTypes.bool,
  invalidPassword: PropTypes.bool,
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func,
  }),
  user: PropTypes.shape({
    language: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  resetInvalidPassword: PropTypes.func.isRequired,
};

export default connect(
  ({ User }) => ({
    user: User.profile,
  }),
  (dispatch) => bindActionCreators(actions, dispatch),
)(withTranslation()(Profile));
