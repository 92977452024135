export function AuthError(message) {
  this.message = message;
}

export function InvalidAccessError(message) {
  this.message = message;
}

export function NoAccessError(message) {
  this.message = message;
}
