import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dictionary from '../../components/Dictionary';

import gateway from '../../utils/gateway';
import { showError, showInfo } from '../../utils/toast';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: '1px solid #EDEFF0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #EDEFF0',
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
});

const DialogTitle = withStyles(styles)(
  ({ children, classes, onClose, ...other }) => (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  ),
);

const DialogActions = withStyles(styles)(
  ({
    classes,
    onClose,
    onSubmit,
    submitting,
    actionDisabled,
    actionLabel,
    ...other
  }) => (
    <MuiDialogActions className={classes.actions} {...other}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onClose}
        className={classes.cancel}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        disabled={actionDisabled}
        className={classes.confirm}
        endIcon={
          submitting ? <CircularProgress size={20} color="inherit" /> : null
        }
      >
        {actionLabel}
      </Button>
    </MuiDialogActions>
  ),
);

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

export default function CancelTenantDialog({
  open,
  onClose,
  onSubmit,
  tenant,
}) {
  const [submitting, setSubmitting] = useState(false);

  const { iugu_customer_id: customerId, iugu_subscription_id: subscriptionId } =
    tenant || {};

  const dictionary = [
    {
      label: 'Customer ID',
      value: customerId,
    },
    {
      label: 'Subscription ID',
      value: subscriptionId,
    },
  ];

  // useEffect(() => {
  //   const fetchTypes = async () => {
  //     try {
  //       const response = await gateway.get('/billing/cancellation-reasons');
  //       const data = response.data.map((type) => ({
  //         label: type.name,
  //         value: type.id,
  //       }));
  //       data.push({ label: 'Outro (descreva o motivo)', value: -1 });
  //       setTypes(data);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  //   fetchTypes();
  // }, [subscriptionStatus]);

  const migrate = async () => {
    setSubmitting(true);

    try {
      await gateway
        .post(`/billing/tenants/${tenant.id}/migrate`)
        .then(() => showInfo('Cliente cadastrado com sucesso'));
      onSubmit();
    } catch (err) {
      console.error(err);
      const message =
        err.response?.status === 400 && err.response.data?.message
          ? err.response.data.message
          : 'Falha ao migrar o tenant';
      showError(message);
    } finally {
      setSubmitting(false);
    }
  };

  const openIugu = () => {
    window.open(
      `https://alia.iugu.com/contact/customers/${customerId}`,
      '_blank',
    );
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Iugu
      </DialogTitle>
      <DialogContent>
        <Box p={4} pt={4} pb={4} display="flex">
          {customerId ? (
            <Dictionary loading={tenant === null} dictionary={dictionary} />
          ) : (
            <Box mb={2}>
              <Alert severity="info">Cliente não possui cadastro na Iugu</Alert>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        onClose={onClose}
        onSubmit={customerId ? openIugu : migrate}
        submitting={submitting}
        actionLabel={customerId ? 'Acessar' : 'Cadastrar'}
      />
    </Dialog>
  );
}
