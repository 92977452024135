import React from 'react';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';

import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import UserStatus from '../../components/UserStatus';

import { PermissionContext } from '../../contexts/PermissionContext';
import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import NewUserFormDialog from './NewUserFormDialog';
import EditUserFormDialog from './EditUserFormDialog';

const COLUMNS = [
  {
    id: 'name',
    label: 'NOME',
  },
  { id: 'login', label: 'LOGIN' },
  { id: 'email', label: 'E-MAIL' },
  { id: 'phone', label: 'TELEFONE' },
  { id: 'status', align: 'center', label: 'STATUS' },
  {
    id: 'created',
    align: 'right',
    label: 'CRIADO EM',
  },
  {
    id: 'edit',
    align: 'center',
    label: '',
  },
];

const ItemsTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: '8px 16px',
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const ButtonTableCell = withStyles(() => ({
  root: {
    width: 1,
    padding: 0,
    border: 0,
  },
}))(TableCell);

const UsersTab = ({ show }) => {
  const params = useParams();

  const { authorized } = React.useContext(PermissionContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const [search, setSearch] = React.useState('');
  const [status, setStatus] = React.useState(-1);
  const [filters, setFilters] = React.useState({
    search: '',
    status: -1,
  });

  const [user, setUser] = React.useState(null);
  const [showNewUserDialog, setShowNewUserDialog] = React.useState(false);
  const [showEditUserDialog, setShowEditUserDialog] = React.useState(false);
  const [
    showInactivateUserDialog,
    setShowInactivateUserDialog,
  ] = React.useState(false);
  const [
    showActivateUserDialog,
    setShowActivateUserDialog,
  ] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setFilters({
      search,
      status,
    });
  };

  const fetchUsers = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(
        `/billing/partners/${params.partner_id}/users`,
        {
          params: {
            ...(filters.search.length > 0 ? { search: filters.search } : {}),
            status: filters.status === -1 ? [0, 1] : [filters.status],
            limit: rowsPerPage,
            offset: page * rowsPerPage,
          },
        },
      );

      setUsers(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchUsers();
  }, [rowsPerPage, page, filters]);

  if (!show) {
    return null;
  }

  return (
    <>
      <Fade in>
        <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            {authorized('admin.tools') && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setShowNewUserDialog(true)}
              >
                Adicionar
              </Button>
            )}
          </Box>
          <Box pt={4} display="flex" alignItems="center">
            <Box pr={4}>
              <TextField
                name="search"
                label="Pesquisar"
                placeholder=""
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="inherit" />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
            </Box>
            <Box pr={4} width={160}>
              <SelectField
                name="status"
                label="Status"
                placeholder="Status"
                options={[
                  { label: 'Todos', value: -1 },
                  { label: 'Ativos', value: 1 },
                  { label: 'Inativos', value: 0 },
                ]}
                variant="standard"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              />
            </Box>
            <Box pr={4}>
              <Button variant="contained" color="primary" onClick={handleSearch}>
                Buscar
              </Button>
            </Box>
          </Box>
          <Box pt={4}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((column) => (
                      <ItemsTableCell
                        key={column.id}
                        align={column.align ? column.align : 'left'}
                      >
                        <Typography variant="subtitle2" color="primary">
                          {column.label}
                        </Typography>
                      </ItemsTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading &&
                    [1, 2, 3].map((value) => (
                      <TableRow tabIndex={-1} key={value}>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell align="right">
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell align="center">
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                      </TableRow>
                    ))}
                  {!loading &&
                    users.map((row) => {
                      return (
                        <ItemsTableRow hover key={row.id}>
                          <ItemsTableCell>{row.name}</ItemsTableCell>
                          <ItemsTableCell>{row.login}</ItemsTableCell>
                          <ItemsTableCell>{row.email}</ItemsTableCell>
                          <ItemsTableCell>{row.phone}</ItemsTableCell>
                          <ItemsTableCell align="center">
                            <UserStatus size="small" status={row.status} />
                          </ItemsTableCell>
                          <ItemsTableCell align="right">
                            {moment(row.created).format('DD/MM/YYYY HH:mm')}
                          </ItemsTableCell>
                          <ButtonTableCell align="center">
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                setUser(row);
                                setShowEditUserDialog(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </ButtonTableCell>
                        </ItemsTableRow>
                      );
                    })}
                  {!loading && users.length === 0 && (
                    <TableRow>
                      <ItemsTableCell colSpan={COLUMNS.length} align="center">
                        <Box m={4}>
                          <Typography>Nenhum usuário encontrado</Typography>
                        </Box>
                      </ItemsTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Resultados por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} / ${count}`
              }
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      </Fade>
      <NewUserFormDialog
        open={showNewUserDialog}
        onClose={() => {
          setUser(null);
          setShowNewUserDialog(false);
        }}
        onSubmit={() => {
          setUser(null);
          fetchUsers();
          setShowNewUserDialog(false);
        }}
      />
      <EditUserFormDialog
        open={showEditUserDialog}
        user={user}
        onClose={() => {
          setUser(null);
          setShowEditUserDialog(false);
        }}
        onSubmit={() => {
          setUser(null);
          fetchUsers();
          setShowEditUserDialog(false);
        }}
      />
    </>
  );
};

export default UsersTab;
