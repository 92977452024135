export const RESET_APP = 'RESET_APP';

const clearStore = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_APP,
    });
  };
};

export default clearStore;
