import { combineReducers } from 'redux';
import { userReducer } from './modules/user';
import { breadcrumbReducer } from './modules/breadcrumb';
import { sideMenuReducer } from './modules/sideMenu';

export default combineReducers({
  User: userReducer,
  Breadcrumb: breadcrumbReducer,
  SideMenu: sideMenuReducer,
});
