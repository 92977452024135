import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';

const DateTimeField = ({ field, onChange, variant, ...rest }) => {
  const formatDate = (value) => {
    return value.toDate();
  };

  return (
    <DateTimePicker
      inputVariant={variant || 'filled'}
      fullWidth
      autoOk
      ampm={false}
      {...field}
      {...rest}
      onChange={(value) => {
        if (field) {
          return field.onChange({
            target: {
              name: field.name,
              value: value === null ? null : formatDate(value),
            },
          });
        }
        return onChange({
          target: {
            value: value === null ? null : formatDate(value),
          },
        });
      }}
    />
  );
};
export default DateTimeField;
