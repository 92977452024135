import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import filter from 'lodash/filter';

import { showError, showSuccess } from '../utils/toast';
import gateway from '../utils/gateway';
import Loading from './Loading';

const DistributionCentersPage = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [newDistributionCenter, setNewDistributionCenter] = useState(null);
  const [distributionCenters, setDistributionCenters] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    const doFetch = async () => {
      const res = await gateway.get('/legacy/distributionCenter/');
      if (res.status !== 200) {
        setIsLoading(false);
        showError();
        return;
      }

      setDistributionCenters(res.data);
      setIsLoading(false);
    };
    doFetch();
  }, []);

  const addDistributionCenter = async () => {
    setIsLoading(true);

    const dcCheck = distributionCenters.find((dc) => {
      return dc === newDistributionCenter;
    });

    if (dcCheck) {
      showError('formErrors:distributionCenterAlreadyAdded');
      return;
    }

    const formData = new FormData();
    formData.append('distributionCenter', newDistributionCenter);

    const res = await gateway.post('/legacy/distributionCenter/', formData);

    if (res.status !== 200) {
      showError();
      return;
    }

    showSuccess();

    const savedDistributionCenter = res.data;
    setDistributionCenters(distributionCenters.concat(savedDistributionCenter));

    setNewDistributionCenter('');
    setIsLoading(false);
  };

  const removeDistributionCenter = async (dcToRemove) => {
    setIsLoading(true);

    if (dcToRemove.id) {
      try {
        await gateway.delete('/legacy/distributionCenter/', {
          params: { distributionCenterId: dcToRemove.id },
        });
      } catch (e) {
        setIsLoading(false);
        showError(e.response.data);
        return;
      }

      showSuccess();
    }

    const newDistributionCenters = filter(distributionCenters, (dc) => {
      return dc !== dcToRemove;
    });

    setDistributionCenters(newDistributionCenters);
    setIsLoading(false);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      addDistributionCenter();
    }
  };

  return (
    <div className="flex align-i-center justify-center grow fh">
      <div
        className="eye-card mt0"
        style={{ width: '485px', padding: '25px 75px' }}
      >
        <span className="avenir-medium-gray-title uppercase">
          {t('common:distributionCenters')}
        </span>
        <div className="flex align-i-center mt-xl mb-xl">
          <i className="ic-blue-round-dcs mr" />
          <input
            type="text"
            autoComplete="none"
            value={newDistributionCenter}
            placeholder={t('common:dcName')}
            onKeyDown={onKeyDown}
            className="input-default uppercase-placeholder"
            style={{ width: '280px', paddingLeft: '10px' }}
            onChange={(e) => setNewDistributionCenter(e.target.value)}
          />
          <i
            className={clsx('ic-plus pointer', isLoading && 'disabled')}
            onClick={addDistributionCenter}
          />
        </div>
        <div className="flex flex-column fw">
          <Loading isSmall show={isLoading} />
          {distributionCenters.length === 0 && !isLoading && (
            <div className="flex align-i-center justify-center">
              <i className="ic-alert-blue ic-sm mr-sm" />
              <span className="normal-case" style={{ lineHeight: '2' }}>
                {t('common:noDistributionCenterAdded')}
              </span>
            </div>
          )}
          {distributionCenters.length > 0 &&
            !isLoading &&
            distributionCenters.map((dc) => {
              return (
                <div className="flex align-i-center mb-lg">
                  <i
                    className={clsx(
                      'ic-minus pointer mr-sm',
                      isLoading && 'disabled',
                    )}
                    onClick={() => removeDistributionCenter(dc)}
                  />
                  <span className="avenir-medium-gray-md uppercase">
                    {dc.name}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DistributionCentersPage;
