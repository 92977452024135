const SIDE_MENU = 'SIDE_MENU';

export const toggleSideMenu = (menuOpen) => {
  return (dispatch) => {
    dispatch({
      type: SIDE_MENU,
      payload: menuOpen,
    });
  };
};

const initialState = {
  menuOpen: false,
};

export const sideMenuReducer = (state = initialState, action) => {
  if (action.type === SIDE_MENU) {
    return { ...state, menuOpen: action.payload };
  }

  return state;
};
