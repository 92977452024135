import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RemoveIcon from '@material-ui/icons/Remove';
import BlockIcon from '@material-ui/icons/Block';
import moment from 'moment';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const ActivitiesTab = ({ show }) => {
  const params = useParams();
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const [activities, setActivities] = React.useState([]);

  const fetchPayments = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(
        `/billing/tenants/${params.tenant_id}/charges/${params.charge_id}/activities`,
        {
          params: {
            limit: 100,
            offset: 0,
          },
        },
      );

      setActivities(response.data.data);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (show) {
      fetchPayments();
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const ACTIVITY_ACTION = {
    0: {
      Icon: (
        <TimelineDot style={{ backgroundColor: theme.palette.primary[500] }}>
          <AddIcon />
        </TimelineDot>
      ),
      label: 'Cobrança criada',
    },
    1: {
      Icon: (
        <TimelineDot style={{ backgroundColor: theme.palette.primary[500] }}>
          <EditIcon />
        </TimelineDot>
      ),
      label: 'Atualizada por usuário',
    },
    2: {
      Icon: (
        <TimelineDot style={{ backgroundColor: theme.palette.success[500] }}>
          <CheckIcon />
        </TimelineDot>
      ),
      label: 'Paga',
    },
    3: {
      Icon: (
        <TimelineDot style={{ backgroundColor: theme.palette.danger[500] }}>
          <CloseIcon />
        </TimelineDot>
      ),
      label: 'Cancelada',
    },
    4: {
      Icon: (
        <TimelineDot style={{ backgroundColor: theme.palette.primary[500] }}>
          <AttachMoneyIcon />
        </TimelineDot>
      ),
      label: 'Pagamento gerado',
    },
    5: {
      Icon: (
        <TimelineDot style={{ backgroundColor: theme.palette.danger[500] }}>
          <RemoveIcon />
        </TimelineDot>
      ),
      label: 'Recusa de pagamento',
    },
    6: {
      Icon: (
        <TimelineDot style={{ backgroundColor: theme.palette.danger[500] }}>
          <BlockIcon />
        </TimelineDot>
      ),
      label: 'Pagamento cancelado',
    },
    7: {
      Icon: (
        <TimelineDot style={{ backgroundColor: theme.palette.primary[500] }}>
          <EditIcon />
        </TimelineDot>
      ),
      label: 'Atualizada por alteração de contrato',
    },
  };

  return (
    <>
      <Fade in>
        <Box p={4} pt={2} pb={8} display="flex" flexDirection="column">
          {loading && (
            <Box
              m={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={84} />
            </Box>
          )}
          <Timeline align="alternate">
            {!loading &&
              activities.map((activity, index) => {
                return (
                  <TimelineItem>
                    <TimelineOppositeContent>
                      <Typography variant="body2">
                        {moment(activity.created_at).format('DD/MM/YYYY HH:mm')}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      {ACTIVITY_ACTION[activity.action].Icon}
                      {index < activities.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="h6">
                        {ACTIVITY_ACTION[activity.action].label}
                      </Typography>
                      {activity.user && (
                        <Box mb={2}>
                          <Typography variant="body2">
                            {activity.user.name}
                          </Typography>
                        </Box>
                      )}
                      {activity.description && (
                        <Box display="flex" justifyContent="flex-end">
                          <Box
                            bgcolor={fade(theme.palette.text.primary, 0.1)}
                            p={2}
                            width="fit-content"
                          >
                            <Typography variant="body1">
                              {activity.description}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
          </Timeline>
        </Box>
      </Fade>
    </>
  );
};

export default ActivitiesTab;
