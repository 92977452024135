export default {
  menu: (provided) => ({
    ...provided,
    bottom: provided.bottom && 'calc(100% + 10px)',
    top: provided.top && 'calc(100% + 10px)',
    zIndex: 1000,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#ffffff',
    lineHeight: 1,
    padding: 6,
    paddingLeft: 10,
    alignSelf: 'center',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    fontSize: 14,
    lineHeight: 1,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 14,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'rgba(255, 0, 247, .08)' : 'transparent',
  }),
};
