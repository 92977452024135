import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';

class RoundedInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }

  render() {
    const {
      id,
      value,
      onChange,
      className,
      placeholder,
      wrapperClassName,
      uppercasePlaceholder,
    } = this.props;

    return (
      <div className={wrapperClassName}>
        <input
          id={id}
          className={clsx(
            'rounded-input',
            className,
            uppercasePlaceholder && 'uppercase-placeholder',
          )}
          value={value || this.state.value}
          placeholder={placeholder}
          autoFocus
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
      </div>
    );
  }
}

RoundedInput.defaultProps = {
  id: '',
  value: '',
  className: '',
  onChange: null,
  placeholder: '',
  wrapperClassName: '',
  uppercasePlaceholder: false,
};

RoundedInput.propTypes = {
  id: PropTypes.string,
  uppercasePlaceholder: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default RoundedInput;
