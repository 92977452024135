const providers = [
  { value: '0', label: 'PAGARME' },
  { value: '1', label: 'ZOOP' },
  { value: '2', label: 'ADYEN' },
  { value: '3', label: 'MUNDIPAGG' },
  { value: '4', label: 'STONE' },
  { value: '5', label: 'PAGNET' },
  { value: '6', label: 'PASSEPAG' },
  { value: '7', label: 'CIELO' },
  { value: '8', label: 'GETNET' },
  { value: '9', label: 'EYECASHLESS' },
  { value: '10', label: 'PAGSEGURO' },
  { value: '11', label: 'REDE' },
  { value: '12', label: 'MERCADOLIBRE' },
  { value: '13', label: 'INFINITYPAY' },
  { value: '14', label: 'BIN' },
  { value: '15', label: 'FIRSTDATA' },
  { value: '16', label: 'ADIQ' },
  { value: '17', label: 'SAFRA' },
  { value: '18', label: 'MULTICAJA' },
  { value: '19', label: 'PPASS' },
  { value: '20', label: 'ZETTLE' },
  { value: '21', label: 'PAGONXT' },
  { value: '22', label: 'PINBANK' },
  { value: '23', label: 'CASHFAST' },
  { value: '24', label: 'PHOEBUS' },
  { value: '25', label: 'PAYNET' },
  { value: '26', label: 'FISERV' },
];

export default providers;
