import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

class FloatingBar extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { open } = nextProps;

    // toggleScrollBody(open);
  }

  render() {
    const { id, open, children, className, innerClassName } = this.props;

    return (
      <div
        id={id}
        className={`floating-bar ${className} ${open ? 'open' : ''} `}
      >
        <div className={`content vertical-scroll ${innerClassName}`}>
          {children}
        </div>
      </div>
    );
  }
}

FloatingBar.defaultProps = {
  id: 'floating-bar',
  open: false,
  children: null,
  className: '',
  innerClassName: '',
};

FloatingBar.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  className: PropTypes.string,
  innerClassName: PropTypes.string,
};

export default FloatingBar;
