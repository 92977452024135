import { toast } from 'react-toastify';
import i18n from '../locales/i18n';

const options = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  preventDuplicated: true,
};

let toastSuccessId = null;
let toastInfoId = null;
let toastErrorId = null;
let toastDownloadInfoId = null;
let toastNotificationId = null;

const dismissWithDelay = (toastId, timeout = 5000) => {
  setTimeout(() => {
    toast.dismiss(toastId);
  }, timeout);
};

const dismiss = (toastId) => {
  toast.dismiss(toastId);
};

const showSuccess = (
  message = 'Seus dados foram salvos com sucesso!',
  prevent = false,
  timeout = 5000,
) => {
  if (prevent && toast.isActive(toastSuccessId)) {
    return;
  }

  toastSuccessId = toast.success(message, options);
  dismissWithDelay(toastSuccessId, timeout);
};

const showError = (
  message = 'Não foi possível processar a sua requisição',
  parameter = {},
  prevent = false,
  timeout = 5000,
) => {
  if (prevent && toast.isActive(toastErrorId)) {
    return;
  }

  toastErrorId = toast.error(message, options);
  dismissWithDelay(toastErrorId, timeout);
};

const showResponseError = (message, timeout = 5000) => {
  if (toast.isActive(toastErrorId)) {
    return;
  }

  toastErrorId = toast.error(
    message || i18n.t('common:requestFailure'),
    options,
  );
  dismissWithDelay(toastErrorId, timeout);
};

const showInfo = (message, parameter = {}, prevent = false, timeout = 5000) => {
  if (prevent && toast.isActive(toastInfoId)) {
    return;
  }

  toastInfoId = toast.warn(
    i18n.t(message || 'common:errorMessage', parameter),
    options,
  );
  dismissWithDelay(toastInfoId, timeout);
};

const showDownloadInfo = (message, parameter = {}, prevent = false) => {
  if (prevent && toast.isActive(toastInfoId)) {
    return null;
  }

  toastDownloadInfoId = toast.info(
    i18n.t(message || 'common:yourFileIsBeingProcessed', parameter),
    {
      position: 'top-right',
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      preventDuplicated: true,
      className: 'purple-bg',
    },
  );

  return toastDownloadInfoId;
};

const showNotification = (message, prevent = false) => {
  if (prevent && toast.isActive(toastNotificationId)) {
    return null;
  }

  toastNotificationId = toast.info(message, {
    ...options,
    className: 'transparent-gray-bg',
  });

  return toastNotificationId;
};

export {
  showSuccess,
  showError,
  showInfo,
  showDownloadInfo,
  showNotification,
  dismiss,
  showResponseError,
};
