import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Dialog from '../../components/Dialog';
import DialogTitle from '../../components/DialogTitle';
import DialogContent from '../../components/DialogContent';
import DialogActions from '../../components/DialogActions';
import TextField from '../../components/TextField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const CancellationReasonSchema = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Nome deve conter até 255 caracteres'),
});

export default function CancellationReasonFormDialog({
  open,
  cancellationReason,
  onClose,
  onSubmit,
}) {
  const formik = useFormik({
    initialValues: {
      name: cancellationReason?.name || '',
    },
    validationSchema: CancellationReasonSchema,
    onSubmit: async (values, formikBag) => {
      formikBag.setSubmitting(true);

      try {
        const action = cancellationReason ? gateway.put : gateway.post;

        const url = `/billing/cancellation-reasons${
          cancellationReason ? `/${cancellationReason.id}` : ''
        }`;

        await action(url, values);
        onSubmit();
      } catch (err) {
        console.error(err);

        const message =
          err.response?.data?.message ||
          `Falha ao ${
            cancellationReason ? 'editar' : 'adicionar'
          } motivo de cancelamento`;

        showError(message);
      } finally {
        formikBag.setSubmitting(false);
      }
    },
    enableReinitialize: true,
  });

  const { handleSubmit, isSubmitting, errors, touched } = formik;

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      <DialogTitle onClose={onClose}>
        {cancellationReason
          ? 'Editar motivo de cancelamento'
          : 'Adicionar motivo de cancelamento'}
      </DialogTitle>
      <DialogContent>
        <Grid item>
          <Typography variant="h6">Motivo de cancelamento</Typography>
        </Grid>
        <TextField
          name="name"
          label="Nome"
          placeholder="Nome"
          fullWidth
          error={!!errors.name && touched.name}
          helperText={errors.name && touched.name && errors.name}
          {...formik.getFieldProps('name')}
        />
      </DialogContent>
      <DialogActions
        onClose={onClose}
        onSubmit={handleSubmit}
        submitting={isSubmitting}
      />
    </Dialog>
  );
}
