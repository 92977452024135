import React from 'react';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CropFreeIcon from '@material-ui/icons/CropFree';
import SendIcon from '@material-ui/icons/Send';
import BlockIcon from '@material-ui/icons/Block';
import moment from 'moment';

import PaymentStatus from '../../components/PaymentStatus';

import chargeTypes from '../../constants/chargeTypes';
import gateway from '../../utils/gateway';
import { showError, showSuccess } from '../../utils/toast';

import SendPaymentDialog from './SendPaymentDialog';
import CancelPaymentDialog from './CancelPaymentDialog';

const COLUMNS = [
  { id: 'id', label: '#' },
  { id: 'status', align: 'center', label: 'STATUS' },
  { id: 'type', label: 'FORMA DE PGTO.' },
  { id: 'created_at', label: 'CRIADO EM' },
  { id: 'expires_at', label: 'VENCIMENTO' },
  {
    id: 'total',
    align: 'right',
    label: 'TOTAL',
  },
  { id: 'voucher', align: 'center', label: '' },
  { id: 'pix', align: 'center', label: '' },
  { id: 'send', align: 'center', label: '' },
  { id: 'cancel', align: 'center', label: '' },
];

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: '8px 16px',
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const ButtonTableCell = withStyles(() => ({
  root: {
    width: 1,
    padding: 0,
    border: 0,
  },
}))(TableCell);

const PaymentsTab = ({ show }) => {
  const params = useParams();

  const [showSendPaymentDialog, setShowSendPaymentDialog] = React.useState(
    false,
  );
  const [showCancelPaymentDialog, setShowCancelPaymentDialog] = React.useState(
    false,
  );
  const [payment, setPayment] = React.useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [payments, setPayments] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const handleCancelPayment = (row) => {
    return () => {
      setPayment(row);
      setShowCancelPaymentDialog(true);
    };
  };

  const handleSendPayment = (row) => {
    return () => {
      setPayment(row);
      setShowSendPaymentDialog(true);
    };
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchPayments = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(
        `/billing/tenants/${params.tenant_id}/charges/${params.charge_id}/payments`,
        {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
          },
        },
      );

      setPayments(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (show) {
      fetchPayments();
    }
  }, [show, rowsPerPage, page]);

  if (!show) {
    return null;
  }

  return (
    <>
      <Fade in>
        <Box p={4} pt={2} pb={8} display="flex" flexDirection="column">
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <ItemsTableCell
                      key={column.id}
                      align={column.align ? column.align : 'left'}
                    >
                      <Typography variant="subtitle2" color="primary">
                        {column.label}
                      </Typography>
                    </ItemsTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  [1, 2, 3].map((value) => (
                    <TableRow tabIndex={-1} key={value}>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell align="center">
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell>
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell align="right">
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell align="center">
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell align="center">
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell align="center">
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                      <ItemsTableCell align="center">
                        <Skeleton variant="text" />
                      </ItemsTableCell>
                    </TableRow>
                  ))}
                {!loading &&
                  payments.map((row) => {
                    const formattedTotal = Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(row.total);

                    return (
                      <TableRow key={row.id}>
                        <ItemsTableCell>{row.id}</ItemsTableCell>
                        <ItemsTableCell align="center">
                          <PaymentStatus size="small" status={row.status} />
                        </ItemsTableCell>
                        <ItemsTableCell>{chargeTypes[row.type]}</ItemsTableCell>
                        <ItemsTableCell>
                          {moment(row.created_at).format('DD/MM/YYYY HH:mm')}
                        </ItemsTableCell>
                        <ItemsTableCell>
                          {row.expires_at
                            ? moment(row.expires_at).format('DD/MM/YYYY HH:mm')
                            : ''}
                        </ItemsTableCell>
                        <ItemsTableCell align="right">
                          {formattedTotal}
                        </ItemsTableCell>
                        <ButtonTableCell align="center">
                          {row.voucher_url !== null && (
                            <IconButton
                              aria-label="voucher"
                              onClick={() => {
                                window.open(row.voucher_url, '_blank');
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          )}
                        </ButtonTableCell>
                        <ButtonTableCell align="center">
                          {row.pix_qrcode_image !== null && (
                            <IconButton
                              aria-label="pix"
                              onClick={() => {
                                navigator.clipboard.writeText(row.pix_qrcode);
                                showSuccess(
                                  'O código do PIX foi copiado para a área de transferência.',
                                );
                              }}
                            >
                              <CropFreeIcon />
                            </IconButton>
                          )}
                        </ButtonTableCell>
                        <ButtonTableCell align="center">
                          {row.status === 0 &&
                            (row.type === 1 || row.type === 3) && (
                              <IconButton
                                aria-label="cancel"
                                onClick={handleSendPayment(row)}
                              >
                                <SendIcon />
                              </IconButton>
                            )}
                        </ButtonTableCell>
                        <ButtonTableCell align="center">
                          {row.status === 0 &&
                            (row.type === 1 || row.type === 3) && (
                              <IconButton
                                aria-label="cancel"
                                onClick={handleCancelPayment(row)}
                              >
                                <BlockIcon />
                              </IconButton>
                            )}
                        </ButtonTableCell>
                      </TableRow>
                    );
                  })}
                {!loading && payments.length === 0 && (
                  <TableRow>
                    <ItemsTableCell colSpan={COLUMNS.length} align="center">
                      <Box m={4}>
                        <Typography>Nenhum pagamento encontrado</Typography>
                      </Box>
                    </ItemsTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Resultados por página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} / ${count}`
            }
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Fade>
      <SendPaymentDialog
        open={showSendPaymentDialog}
        onClose={() => {
          setShowSendPaymentDialog(false);
        }}
        onSubmit={() => {
          setShowSendPaymentDialog(false);
        }}
        payment={payment}
      />
      <CancelPaymentDialog
        open={showCancelPaymentDialog}
        onClose={() => {
          setShowCancelPaymentDialog(false);
        }}
        onSubmit={() => {
          fetchPayments();
          setShowCancelPaymentDialog(false);
        }}
        payment={payment}
      />
    </>
  );
};

export default PaymentsTab;
