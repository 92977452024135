import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import en from './i18n/en';
import pt from './i18n/pt';
import es from './i18n/es';

i18n.use(initReactI18next).init({
  lng: 'pt-BR',
  fallbackLng: 'pt-BR',
  debug: true,
  wait: true,
  resources: {
    'en-US': en,
    es,
    'pt-BR': pt,
  },
  react: {
    wait: true,
    defaultTransParent: 'span',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['strong'],
  },
  interpolation: {
    escapeValue: false,
  },
});

moment.locale('pt-BR');

if (process.env.NODE_ENV === 'development') {
  i18n.on('missingKey', (lngs, namespace, key) => {
    console.warn(`Missing translation: ${lngs} - [${namespace}]`, key);
  });
}

export default i18n;
