import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { validate } from 'cnpj';

import TextField from '../../../components/TextField';
import SelectField from '../../../components/SelectField';

const CompanySchema = Yup.object().shape({
  document: Yup.string()
    .required('Campo obrigatório')
    .length(14, 'CNPJ inválido')
    .test('cnpj', 'CNPJ inválido', (value) => {
      return validate(value || '');
    }),
  corporate_name: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Razão social deve conter até 255 caracteres'),
  trading_name: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Nome fantasia deve conter até 255 caracteres'),
  address: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Endereço deve conter até 255 caracteres'),
  number: Yup.string().required('Campo obrigatório'),
  address2: Yup.string().nullable(),
  district: Yup.string().required('Campo obrigatório'),
  city: Yup.string().required('Campo obrigatório'),
  state: Yup.string()
    .required('Campo obrigatório')
    .length(2, 'Estado inválido'),
  zipcode: Yup.string()
    .required('Campo obrigatório')
    .length(8, 'CEP inválido'),
  financial_email: Yup.string()
    .email('Email inválido')
    .max(255, 'Email deve conter até 255 caracteres')
    .required('Campo obrigatório'),
  financial_phone: Yup.string().required('Campo obrigatório'),
  partner_name: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Nome deve conter até 255 caracteres'),
  partner_document: Yup.string()
    .required('Campo obrigatório')
    .length(14, 'CPF inválido'),
  partner_email: Yup.string()
    .email('Email inválido')
    .max(255, 'Email deve conter até 255 caracteres')
    .required('Campo obrigatório'),
  partner_phone: Yup.string().required('Campo obrigatório'),
  partner_birthday: Yup.string()
    .required('Campo obrigatório')
    .matches(/\d\d\/\d\d\/\d\d\d\d/, 'Data de aniversário inválida'),
});

const useStyles = makeStyles(() => ({
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}));

const CompanyStep = ({ show, step, total, onNextStep, context }) => {
  const classes = useStyles();
  const history = useHistory();

  const formRef = React.useRef();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <Fade in>
      <Paper square elevation={2} style={{ flex: 1 }}>
        <Box p={0} display="flex" flexDirection="column">
          <Box p={4} pt={6} display="flex" flexDirection="column">
            <Typography variant="subtitle2" color="primary">
              Passo {step + 1} de {total}
            </Typography>
            <Typography variant="h4">Dados Cadastrais</Typography>
          </Box>
          <Box p={4} pt={4} pb={8} display="flex" flexDirection="column">
            <Formik
              innerRef={formRef}
              initialValues={{
                document: context.document || '',
                corporate_name: context.corporate_name || '',
                trading_name: context.trading_name || '',
                address: context.address || '',
                number: context.number || '',
                address2: context.address2 || '',
                district: context.district || '',
                city: context.city || '',
                state: context.state || '',
                zipcode: context.zipcode || '',
                partner_name: context.partner_name || '',
                partner_document: context.partner_document || '',
                partner_email: context.partner_email || '',
                partner_phone: context.partner_phone || '',
                partner_birthday: context.partner_birthday || '',
                financial_name: context.financial_name || '',
                financial_email: context.financial_email || '',
                financial_phone: context.financial_phone || '',
              }}
              validationSchema={CompanySchema}
              onSubmit={async (values) => {
                onNextStep({
                  ...context,
                  ...values,
                });
              }}
              render={({ errors, touched, setFieldValue }) => {
                return (
                  <form>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Typography variant="h5">Empresa</Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            name="document"
                            label="CNPJ"
                            placeholder="CNPJ"
                            mask={[
                              /\d/,
                              /\d/,
                              '.',
                              /\d/,
                              /\d/,
                              /\d/,
                              '.',
                              /\d/,
                              /\d/,
                              /\d/,
                              '/',
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                              /\d/,
                            ]}
                            component={TextField}
                            onChange={(e) => {
                              setFieldValue(
                                'document',
                                e.target.value.replace(/[^\d]/g, ''),
                              );
                            }}
                            error={errors.document && touched.document}
                            helperText={
                              errors.document && touched.document
                                ? errors.document
                                : ''
                            }
                            inputProps={{ inputMode: 'numeric' }}
                          />
                        </Grid>
                        <Grid item sm={7} xs={12}>
                          <Field
                            name="corporate_name"
                            label="Razão Social"
                            placeholder="Razão Social"
                            component={TextField}
                            error={
                              errors.corporate_name && touched.corporate_name
                            }
                            helperText={
                              errors.corporate_name && touched.corporate_name
                                ? errors.corporate_name
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item sm={5} xs={12}>
                          <Field
                            name="trading_name"
                            label="Nome Fantasia"
                            placeholder="Nome Fantasia"
                            component={TextField}
                            error={errors.trading_name && touched.trading_name}
                            helperText={
                              errors.trading_name && touched.trading_name
                                ? errors.trading_name
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Field
                            name="address"
                            label="Logradouro"
                            placeholder="Logradouro"
                            component={TextField}
                            error={errors.address && touched.address}
                            helperText={
                              errors.address && touched.address
                                ? errors.address
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item sm={2} xs={12}>
                          <Field
                            name="number"
                            label="Número"
                            placeholder="Número"
                            component={TextField}
                            error={errors.number && touched.number}
                            helperText={
                              errors.number && touched.number
                                ? errors.number
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Field
                            name="address2"
                            label="Complemento"
                            placeholder="Compl."
                            component={TextField}
                            error={errors.address2 && touched.address2}
                            helperText={
                              errors.address2 && touched.address2
                                ? errors.address2
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Field
                            name="district"
                            label="Bairro"
                            placeholder="Bairro"
                            component={TextField}
                            error={errors.district && touched.district}
                            helperText={
                              errors.district && touched.district
                                ? errors.district
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Field
                            name="city"
                            label="Cidade"
                            placeholder="Cidade"
                            component={TextField}
                            error={errors.city && touched.city}
                            helperText={
                              errors.city && touched.city ? errors.city : ''
                            }
                          />
                        </Grid>
                        <Grid item sm={2} xs={12}>
                          <Field
                            name="state"
                            label="Estado"
                            placeholder="Estado"
                            component={SelectField}
                            options={[
                              { label: 'AC', value: 'AC' },
                              { label: 'AL', value: 'AL' },
                              { label: 'AP', value: 'AP' },
                              { label: 'AM', value: 'AM' },
                              { label: 'BA', value: 'BA' },
                              { label: 'CE', value: 'CE' },
                              { label: 'DF', value: 'DF' },
                              { label: 'ES', value: 'ES' },
                              { label: 'GO', value: 'GO' },
                              { label: 'MA', value: 'MA' },
                              { label: 'MT', value: 'MT' },
                              { label: 'MS', value: 'MS' },
                              { label: 'MG', value: 'MG' },
                              { label: 'PA', value: 'PA' },
                              { label: 'PB', value: 'PB' },
                              { label: 'PR', value: 'PR' },
                              { label: 'PE', value: 'PE' },
                              { label: 'PI', value: 'PI' },
                              { label: 'RJ', value: 'RJ' },
                              { label: 'RN', value: 'RN' },
                              { label: 'RS', value: 'RS' },
                              { label: 'RO', value: 'RO' },
                              { label: 'RR', value: 'RR' },
                              { label: 'SC', value: 'SC' },
                              { label: 'SP', value: 'SP' },
                              { label: 'SE', value: 'SE' },
                              { label: 'TO', value: 'TO' },
                            ]}
                            error={errors.state && touched.state}
                          />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Field
                            name="zipcode"
                            label="CEP"
                            placeholder="CEP"
                            mask={[
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            component={TextField}
                            onChange={(e) => {
                              setFieldValue(
                                'zipcode',
                                e.target.value.replace(/[^\d]/g, ''),
                              );
                            }}
                            error={errors.zipcode && touched.zipcode}
                            helperText={
                              errors.zipcode && touched.zipcode
                                ? errors.zipcode
                                : ''
                            }
                            inputProps={{ inputMode: 'numeric' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">Responsável Legal</Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item sm={8} xs={12}>
                          <Field
                            name="partner_name"
                            label="Nome"
                            placeholder="Nome"
                            component={TextField}
                            error={errors.partner_name && touched.partner_name}
                            helperText={
                              errors.partner_name && touched.partner_name
                                ? errors.partner_name
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Field
                            name="partner_document"
                            label="CPF"
                            placeholder="CPF"
                            mask={[
                              /\d/,
                              /\d/,
                              /\d/,
                              '.',
                              /\d/,
                              /\d/,
                              /\d/,
                              '.',
                              /\d/,
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                              /\d/,
                            ]}
                            component={TextField}
                            error={
                              errors.partner_document &&
                              touched.partner_document
                            }
                            helperText={
                              errors.partner_document &&
                              touched.partner_document
                                ? errors.partner_document
                                : ''
                            }
                            inputProps={{ inputMode: 'numeric' }}
                          />
                        </Grid>
                        <Grid item sm={5} xs={12}>
                          <Field
                            name="partner_email"
                            label="Email"
                            placeholder="Email"
                            component={TextField}
                            error={
                              errors.partner_email && touched.partner_email
                            }
                            helperText={
                              errors.partner_email && touched.partner_email
                                ? errors.partner_email
                                : ''
                            }
                            inputProps={{ inputMode: 'email' }}
                          />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Field
                            name="partner_phone"
                            label="Telefone"
                            placeholder="Telefone"
                            component={TextField}
                            error={
                              errors.partner_phone && touched.partner_phone
                            }
                            helperText={
                              errors.partner_phone && touched.partner_phone
                                ? errors.partner_phone
                                : ''
                            }
                            mask={[
                              '(',
                              /\d/,
                              /\d/,
                              ')',
                              ' ',
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d?/,
                            ]}
                            onChange={(e) => {
                              setFieldValue(
                                'partner_phone',
                                e.target.value.replace(/[^\d]/g, ''),
                              );
                            }}
                            inputProps={{ inputMode: 'tel' }}
                          />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Field
                            name="partner_birthday"
                            label="Data de aniversário"
                            placeholder="DD/MM/YYYY"
                            component={TextField}
                            error={
                              errors.partner_birthday &&
                              touched.partner_birthday
                            }
                            helperText={
                              errors.partner_birthday &&
                              touched.partner_birthday
                                ? errors.partner_birthday
                                : ''
                            }
                            mask={[
                              /[0-3]/,
                              /\d/,
                              '/',
                              /[0-1]/,
                              /\d/,
                              '/',
                              /[1-2]/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            inputProps={{ inputMode: 'numeric' }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          Responsável Financeiro
                        </Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            name="financial_name"
                            label="Nome"
                            placeholder="Nome"
                            component={TextField}
                            error={
                              errors.financial_name && touched.financial_name
                            }
                            helperText={
                              errors.financial_name && touched.financial_name
                                ? errors.financial_name
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Field
                            name="financial_email"
                            label="Email"
                            placeholder="Email"
                            component={TextField}
                            error={
                              errors.financial_email && touched.financial_email
                            }
                            helperText={
                              errors.financial_email && touched.financial_email
                                ? errors.financial_email
                                : ''
                            }
                            inputProps={{ inputMode: 'email' }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Field
                            name="financial_phone"
                            label="Telefone"
                            placeholder="Telefone"
                            component={TextField}
                            error={
                              errors.financial_phone && touched.financial_phone
                            }
                            helperText={
                              errors.financial_phone && touched.financial_phone
                                ? errors.financial_phone
                                : ''
                            }
                            mask={[
                              '(',
                              /\d/,
                              /\d/,
                              ')',
                              ' ',
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d?/,
                            ]}
                            onChange={(e) => {
                              setFieldValue(
                                'financial_phone',
                                e.target.value.replace(/[^\d]/g, ''),
                              );
                            }}
                            inputProps={{ inputMode: 'tel' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            />
          </Box>
          <Box
            p={2}
            pl={4}
            pr={4}
            display="flex"
            justifyContent="space-between"
            borderTop="1px solid #ededed"
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => history.goBack()}
              className={classes.cancel}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.confirm}
              endIcon={<ChevronRightIcon />}
            >
              Próximo
            </Button>
          </Box>
        </Box>
      </Paper>
    </Fade>
  );
};

export default CompanyStep;
