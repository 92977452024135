import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from './Loading';

import { getUser } from '../store/modules/user';
import { protectedRoutes } from '../config/routePaths';
import { showError } from '../utils/toast';

export const withUserLoader = (WrappedComponent) => {
  const mapDispathToProps = (dispatch) =>
    bindActionCreators({ fetchUser: getUser }, dispatch);

  class UserLoaderHOC extends Component {
    state = {
      isLoading: true,
    };

    componentDidMount() {
      // Bypass user check in protected routes
      const { location } = this.props;
      const routes = protectedRoutes.map((route) => route.path);
      if (!routes.includes(location.pathname)) {
        this.loadUser();
      } else {
        this.setState({ isLoading: false });
      }
    }

    loadUser = async () => {
      const { fetchUser, history } = this.props;

      try {
        const res = await fetchUser();

        if (!res) {
          showError();
          throw new Error("Couldn't fetch user.");
        }

        this.setState({ isLoading: false });
      } catch (e) {
        this.setState({ isLoading: false });
        history.push('/login');
        console.error(e);
      }
    };

    render() {
      const { isLoading } = this.state;

      return isLoading ? (
        <div className="flex fw fh align-i-center">
          <Loading show={isLoading} />
        </div>
      ) : (
        <WrappedComponent {...this.props} />
      );
    }
  }

  UserLoaderHOC.propTypes = {
    fetchUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  const enhance = compose(
    withRouter,
    connect(
      null,
      mapDispathToProps,
    ),
  );

  return enhance(UserLoaderHOC);
};
