import React from 'react';
import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import EyeSelect from './EyeSelect';
import gateway from '../utils/gateway';

const SelectDistributionCenter = (props) => {
  const { t } = useTranslation();

  const valueRenderer = (model) => {
    if (isEmpty(model)) {
      return t('common:select');
    }

    return model.label;
  };

  const {
    disabled,
    hideLabel,
    placeholder,
    labelClassName,
    wrapperClassName,
    selectWrapperClassName,
    ...rest
  } = props;

  return (
    <div
      className={cx('flex', 'no-scroll', 'align-i-center', wrapperClassName, {
        disabled,
      })}
    >
      {!hideLabel && (
        <span className={`filter-label mr ${labelClassName}`}>
          {t('common:distributionCenters')}
        </span>
      )}
      <EyeSelect
        {...rest}
        fetch={() =>
          gateway
            .get('/legacy/distributionCenter/lookup')
            .then(({ data }) => data)
        }
        menuPlacement="auto"
        placeholder={t(placeholder)}
        valueRenderer={valueRenderer}
        wrapperClassName={selectWrapperClassName}
      />
    </div>
  );
};

SelectDistributionCenter.defaultProps = {
  hideLabel: false,
  disabled: false,
  placeholder: 'common:all',
  labelClassName: undefined,
  wrapperClassName: undefined,
  selectWrapperClassName: undefined,
};

SelectDistributionCenter.propTypes = {
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  selectWrapperClassName: PropTypes.string,
};

export default SelectDistributionCenter;
