import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';

import Tabs from '../../components/Tabs';
import ContractStatus from '../../components/ContractStatus';

import { PermissionContext } from '../../contexts/PermissionContext';
import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import ContractTab from './ContractTab';
import ItemsTab from './ItemsTab';
import AmendmentsTab from './AmendmentsTab';

import ReactivateContractDialog from './ReactivateContractDialog';
import SuspendContractDialog from './SuspendContractDialog';

const Version = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 10,
  },
}))(Chip);

const ContractDetailPage = () => {
  const params = useParams();
  const history = useHistory();

  const { authorized } = React.useContext(PermissionContext);

  const [loading, setLoading] = React.useState(true);
  const [contract, setContract] = React.useState(null);

  const [
    showSuspendContractDialog,
    setShowSuspendContractDialog,
  ] = React.useState(false);
  const [
    showReactivateContractDialog,
    setShowReactivateContractDialog,
  ] = React.useState(false);

  const fetchContract = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(
        `/billing/contracts/${params.contract_id}`,
      );

      setContract(response.data.contract);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchContract();
  }, [params]);

  return (
    <>
      <Fade in>
        <Box flex={1}>
          <Paper square elevation={2}>
            <Box display="flex" flexDirection="column">
              <Box pt={6} p={4} display="flex" flexDirection="column">
                <Box alignSelf="flex-start">
                  <Button
                    onClick={() => history.push(`/tenants/${params.tenant_id}`)}
                    startIcon={<ChevronLeftIcon />}
                  >
                    Voltar para cliente
                  </Button>
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                  {loading ? (
                    <Skeleton variant="text" width={120} />
                  ) : (
                    <>
                      <Box mr={2}>
                        <Typography variant="h4">
                          {contract.tenant?.name}
                        </Typography>
                      </Box>
                      <Box mr={2}>
                        <Version
                          color="default"
                          label={`VERSÃO ${contract.version}`}
                        />
                      </Box>
                      <Box mr={2}>
                        <ContractStatus status={contract.status} />
                      </Box>
                      <Box
                        flex={1}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        {contract &&
                          contract.status === 7 &&
                          authorized('contracts.cancel') && (
                            <Box mr={2}>
                              <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<CancelIcon />}
                                disabled
                              >
                                Cancelar
                              </Button>
                            </Box>
                          )}

                        {contract &&
                          contract.status === 8 &&
                          authorized('contracts.reactivate') && (
                            <Box mr={2}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  setShowReactivateContractDialog(true)
                                }
                              >
                                Reativar
                              </Button>
                            </Box>
                          )}
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <Tabs>
                <ContractTab label="Contrato" contract={contract} />
                <ItemsTab label="Itens" contract={contract} />
                <AmendmentsTab label="Aditivos" contract={contract} />
              </Tabs>
            </Box>
          </Paper>
        </Box>
      </Fade>
      <SuspendContractDialog
        open={showSuspendContractDialog}
        onClose={() => {
          setShowSuspendContractDialog(false);
        }}
        onSubmit={() => {
          fetchContract();
          setShowSuspendContractDialog(false);
        }}
        contract={contract}
      />
      <ReactivateContractDialog
        open={showReactivateContractDialog}
        onClose={() => {
          setShowReactivateContractDialog(false);
        }}
        onSubmit={() => {
          fetchContract();
          setShowReactivateContractDialog(false);
        }}
        contract={contract}
      />
    </>
  );
};

export default ContractDetailPage;
