import React from 'react';

export const arrowRenderer = (white) =>
  white ? (
    <i className="ic-dropdown-arrow-white" />
  ) : (
    <i className="ic-dropdown-arrow-gray" />
  );

export function getStyles(dark, white, center, transparent) {
  let styles = '';

  if (dark) {
    styles += ' dark';
  }

  if (white) {
    styles += ' white';
  }

  if (center) {
    styles += ' center';
  }

  if (transparent) {
    styles += ' transparent';
  }

  return styles;
}

export function formatCnpj(document) {
  return document.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5',
  );
}

export function formatCpf(document) {
  return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}
