import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { default as MuiChip } from '@material-ui/core/Chip';

const PROPOSAL_STATUS = {
  0: 'PENDENTE DE ACEITAÇÃO',
  1: 'PENDENTE DE PAGAMENTO',
  2: 'RECUSADA',
  3: 'PROCESSANDO PAGAMENTO',
  4: 'CONFIRMADA',
  5: 'EXPIRADA',
  6: 'CANCELADA',
  7: 'PENDENTE DE ASSINATURA',
  8: 'PENDENTE DE FORMULÁRIO',
};

const Chip = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 10,
    color: ({ status }) => {
      if (status === 0) return theme.palette.primary[500];
      if (status === 1) return theme.palette.brown[500];
      if (status === 2) return theme.palette.danger[500];
      if (status === 3) return theme.palette.warning[500];
      if (status === 4) return theme.palette.success[500];
      if (status === 5) return theme.palette.text[500];
      if (status === 6) return theme.palette.text[500];
      if (status === 7) return theme.palette.primary[500];
      if (status === 8) return theme.palette.primary[500];
      return theme.palette.text[500];
    },
    backgroundColor: ({ status }) => {
      if (status === 0) return theme.palette.primary[50];
      if (status === 1) return theme.palette.brown[50];
      if (status === 2) return theme.palette.danger[50];
      if (status === 3) return theme.palette.warning[50];
      if (status === 4) return theme.palette.success[50];
      if (status === 5) return theme.palette.text[50];
      if (status === 6) return theme.palette.text[50];
      if (status === 7) return theme.palette.primary[50];
      if (status === 8) return theme.palette.primary[50];
      return theme.palette.text[50];
    },
  },
}))(MuiChip);

export default function ProposalStatus({ status, ...props }) {
  return <Chip label={PROPOSAL_STATUS[status]} status={status} {...props} />;
}
