import LocalStorageManager from './LocalStorageManager';
import { encoder, decoder, base64Url } from './base64Url';
import { concat } from './bufferUtils';
import { generateNonce } from './generateNonce';

const sign = async (data, secretKey) => {
  const decodedKey = new Uint8Array(Buffer.from(secretKey, 'base64'));

  const cryptoKey = await window.crypto.subtle.importKey(
    'raw',
    decodedKey,
    {
      name: 'HMAC',
      hash: 'SHA-256',
    },
    false,
    ['sign'],
  );

  const signature = await window.crypto.subtle.sign('HMAC', cryptoKey, data);

  return new Uint8Array(signature);
};

const createJWT = async (payload, secretKey) => {
  const header = { alg: 'HS256', typ: 'JWT' };

  const protectedHeader = encoder.encode(base64Url(JSON.stringify(header)));
  const encodedPayload = encoder.encode(base64Url(JSON.stringify(payload)));

  const data = concat(protectedHeader, encoder.encode('.'), encodedPayload);

  const signature = await sign(data, secretKey);

  const jws = {
    header: decoder.decode(protectedHeader),
    payload: decoder.decode(encodedPayload),
    signature: base64Url(signature),
  };

  return `${jws.header}.${jws.payload}.${jws.signature}`;
};

export const generateSessionSignature = async ({ config, token }) => {
  const secretKey = LocalStorageManager.getSecret();

  if (secretKey) {
    const nonce = generateNonce();

    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    const requestPath = `${config.method.toUpperCase()} ${config.url.replace(
      config.baseURL,
      '',
    )}`;

    const payload = {
      token,
      nonce,
      requestPath,
      iat: now, // Issued at
      exp: now + 300, // Expiration time in seconds (5 minutes)
    };

    return createJWT(payload, secretKey);
  }

  return undefined;
};
