import { createBrowserHistory as createHistory } from 'history';
import qhistory from 'qhistory';

import { stringify, parse } from 'qs';

const supportsHistory = 'pushState' in window.history;

export default qhistory(
  createHistory({
    forceRefresh: !supportsHistory,
    keyLength: 12,
  }),
  stringify,
  parse,
);
