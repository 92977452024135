import React from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import EyeSelect from './EyeSelect';

const FormikSelect = (props) => {
  const {
    label,
    field,
    onChange,
    disabled,
    hideLabel,
    errorStyle,
    hideErrors,
    placeholder,
    labelClassName,
    wrapperClassName,
    touched,
    errors,
    errorColor,
    ...rest
  } = props;

  const { t } = useTranslation();

  const valueRenderer = (model) => {
    const { t } = props;
    if (isEmpty(model)) {
      return t('common:select');
    }

    return model.label;
  };

  return (
    <div
      className={cx('flex', 'no-scroll', 'align-i-center', wrapperClassName, {
        disabled,
      })}
    >
      {!hideLabel && (
        <span className={`filter-label mr ${labelClassName}`}>{t(label)}</span>
      )}
      <div className="flex flex-column fw">
        <EyeSelect
          {...rest}
          onChange={onChange}
          menuPlacement="auto"
          placeholder={t(placeholder)}
          valueRenderer={valueRenderer}
        />
        {touched[field.name] && errors[field.name] && (
          <div
            style={{
              color: errorColor,
              fontSize: 14,
              marginTop: '5px',
              ...errorStyle,
            }}
          >
            {t(errors[field.name])}
          </div>
        )}
      </div>
    </div>
  );
};

FormikSelect.defaultProps = {
  disabled: false,
  hideLabel: false,
  labelClassName: '',
  wrapperClassName: '',
  errorColor: '#FF4F65',
  label: 'common:select',
  placeholder: 'common:all',
};

FormikSelect.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  errorColor: PropTypes.string,
  placeholder: PropTypes.string,
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default FormikSelect;
