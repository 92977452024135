import React, { useState, useEffect } from 'react';
import { components } from 'react-select';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import { useTranslation } from 'react-i18next';

import TenantAssociationTable from './TenantAssociationTable';
import AsyncSelect from '../../components/AsyncSelect';
import Loading from '../../components/Loading';
import LoadingWrapper from '../../components/LoadingWrapper';
import TenantStatus from '../../components/TenantStatus';
import gateway from '../../utils/gateway';
import { showError, showSuccess } from '../../utils/toast';
import { formatCnpj, formatCpf } from '../../utils/util';
import { customStyles, CustomControl } from './TenantAssociationTab.styles';
import TenantChildPointsModal from './TenantChildPointsModal';

const formatDocument = (document) => {
  return document.length === 11 ? formatCpf(document) : formatCnpj(document);
};

const getTableColumns = ({ onRemove, onModalOpen }) => [
  {
    key: 'tenant',
    label: 'Tenant',
    rowStyle: {
      width: '10%',
      color: '#777777',
      backgroundColor: '#EEF0F4',
      borderTopLeftRadius: 12,
      margin: 8,
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
      borderRadius: 0,
      color: '#737689',
    },
    dataKey: 'id',
  },
  {
    key: 'name',
    label: 'Nome',
    rowStyle: {
      width: '50%',
      color: '#777777',
      backgroundColor: '#EEF0F4',
      textAlign: 'left',
      padding: 0,
    },
    cellStyle: {
      color: '#737689',
      textAlign: 'left',
    },
    dataKey: 'name',
  },
  {
    key: 'status',
    label: 'Status',
    rowStyle: {
      width: '10%',
      color: '#777777',
      backgroundColor: '#EEF0F4',
      textAlign: 'center',
    },
    cellStyle: {
      color: '#737689',
      textAlign: 'left',
    },
    dataKey: 'status',
    render: (childTenant) => <TenantStatus status={childTenant.status} />,
  },
  {
    key: 'document',
    label: 'CNPJ',
    rowStyle: {
      color: '#777777',
      backgroundColor: '#EEF0F4',
      width: '15%',
      textAlign: 'right',
      padding: 0,
    },
    cellStyle: { textAlign: 'right' },
    dataKey: 'document',
    render: (childTenant) => {
      return (
        <p>
          {childTenant?.document !== null
            ? formatDocument(childTenant.document)
            : ' - '}
        </p>
      );
    },
  },
  {
    key: 'remove',
    rowStyle: {
      width: '15%',
      color: '#777777',
      backgroundColor: '#EEF0F4',
      borderTopRightRadius: 12,
      textAlign: 'center',
      margin: 8,
      minWidth: 50,
    },
    cellStyle: { borderRadius: 0, textAlign: 'left', width: '20%' },
    render: (childTenant) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton
          aria-label="associated-points"
          onClick={onModalOpen(childTenant, true)}
        >
          <StorefrontOutlinedIcon color="primary" />
        </IconButton>
        <IconButton aria-label="remove" onClick={onRemove(childTenant.id)}>
          <DeleteOutlineOutlinedIcon color="primary" />
        </IconButton>
      </div>
    ),
  },
];

const TenantAssociationTab = ({ show, tenant }) => {
  const [associatedTenants, setAssociatedTenants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [associatedPointsVisible, setAssociatedPointsVisible] = useState(false);
  const [targetTenant, setTargetTenant] = useState(null);
  const { t } = useTranslation();

  const fetchAssociatedTenants = async () => {
    try {
      setIsLoading(true);
      const response = await gateway
        .get(`/tenants/admin/${tenant.id}/sub-tenants`)
        .then(({ data }) => data);
      setAssociatedTenants(response.data);
    } catch (error) {
      showError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      fetchAssociatedTenants();
    }
  }, [show]);

  const handleAssociatedPointsModalToggle = (tenantValue, visible) => () => {
    setAssociatedPointsVisible(visible);
    setTargetTenant(tenantValue);
  };

  const fetchAvailableTenants = async (query) => {
    if (query === '') {
      return null;
    }

    const params = {
      page: 1,
      q: query,
      limit: 30,
    };

    return gateway
      .get(`/tenants/admin/${tenant.id}/available-sub-tenants`, { params })
      .then(({ data }) => data.data);
  };

  const handleAssociateTenant = async (value) => {
    try {
      setIsProcessing(true);

      const { id, parentTenantId } = value[0];

      if (parentTenantId !== null) {
        showError('common:tenantAlreadyAssociated');
        return;
      }

      const data = {
        parentTenantId: tenant.id,
        childTenantId: id,
      };

      await gateway.post(`/tenants/admin/associate-sub-tenant`, data);
      fetchAssociatedTenants();
      showSuccess();
    } catch (e) {
      showError(e.response.data.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDisassociateTenant = (childTenantId) => async () => {
    try {
      setIsProcessing(true);

      const data = {
        parentTenantId: tenant.id,
        childTenantId,
      };

      await gateway.post(`/tenants/admin/disassociate-sub-tenant`, data);
      fetchAssociatedTenants();
      showSuccess();
    } catch (e) {
      showError(e.response.data.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const optionRenderer = (option) => {
    const { id, name, parentTenantId } = option.data;
    const isAssociated = parentTenantId !== null;
    const associatedOption = `react-select__option ${
      option.isFocused && !option.isSelected
        ? 'react-select__option--is-focused'
        : ''
    } ${!option.isSelected && isAssociated ? 'is-associated' : ''}`;

    return (
      <components.Option {...option} className={associatedOption}>
        <div
          className="flex-block align-i-center justify-between"
          style={{ height: 20 }}
        >
          <div style={{ maxWidth: 500 }}>
            <span className="ml">{`${id} - ${name}`}</span>
          </div>
          {isAssociated ? (
            <div className="flex flex-row">
              <i className="ic-union ml mr" />
              <b>{parentTenantId}</b>
            </div>
          ) : (
            <div>{option.isFocused && <b>Associar</b>}</div>
          )}
        </div>
      </components.Option>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div
          className="align-i-center"
          style={{
            width: '75%',
            marginTop: 36,
          }}
        >
          <Loading isSmall show={isLoading} />
        </div>
      );
    }

    if (associatedTenants.length === 0) {
      return (
        <div
          className="align-i-center"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '75%',
            marginTop: 36,
          }}
        >
          <i className="ic-no-results" />
          <Typography
            variant="subtitle1"
            align="center"
            style={{ color: '#777777', marginTop: 8 }}
          >
            Sem clientes associados
          </Typography>
          <Typography variant="body2" align="center">
            O cliente ainda não possui
          </Typography>
          <Typography variant="body2" align="center">
            outros clientes associados.
          </Typography>
        </div>
      );
    }

    return (
      <div
        className="flex flex-column vertical-scroll mt-lg"
        style={{ width: '75%', paddingRight: 2 }}
      >
        <TenantAssociationTable
          columns={getTableColumns({
            onRemove: handleDisassociateTenant,
            onModalOpen: handleAssociatedPointsModalToggle,
          })}
          infinite
          data={associatedTenants}
          isisLoading={isLoading}
        />
      </div>
    );
  };

  if (!show) {
    return null;
  }

  return (
    <div>
      <Fade in>
        <div className="p20 ml-30">
          <div style={{ maxWidth: 700 }}>
            <Typography variant="h6" className="mb">
              {t('common:childTenants')}
            </Typography>
            <Typography variant="body2" align="left" className="mb">
              {t('common:childTenantsDescription')}
            </Typography>
          </div>
          <div style={{ width: '55%' }}>
            <AsyncSelect
              isMulti
              placeholder="common:tenantAssociationSelectLabel"
              loadOptions={fetchAvailableTenants}
              defaultOptions
              onChange={handleAssociateTenant}
              optionRenderer={optionRenderer}
              styles={customStyles}
              customComponents={{
                Control: CustomControl,
                DropdownIndicator: () => null,
                NoOptionsMessage: (props) => {
                  const { inputValue } = props.selectProps;
                  if (inputValue === '') {
                    return (
                      <div className="flex grow">
                        <span className="text-center fw mt mb">
                          Digite um nome ou Tenant ID para buscar um cliente
                        </span>
                      </div>
                    );
                  }
                  return (
                    <div className="flex grow">
                      <span className="text-center fw mt mb">
                        Sem resultados
                      </span>
                    </div>
                  );
                },
              }}
            />
          </div>
          <div>{renderContent()}</div>
        </div>
      </Fade>
      <LoadingWrapper isProcessing={isProcessing}>
        <Loading show={isProcessing} />
      </LoadingWrapper>
      <TenantChildPointsModal
        parentTenant={tenant}
        childTenant={targetTenant}
        onClose={handleAssociatedPointsModalToggle(null, false)}
        visible={associatedPointsVisible}
      />
    </div>
  );
};

export default TenantAssociationTab;
