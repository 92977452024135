import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#EAEAEA',
    zIndex: 1,
    color: theme.palette.primary.main,
    width: 50,
    height: 50,
    fontSize: 22,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 24,
    },
    [theme.breakpoints.down('sm')]: {
      width: 22,
      height: 22,
      fontSize: 14,
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      },
    },
  },
  active: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 0 10px 2px ${theme.palette.primary[100]}`,
  },
  completed: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 0 10px 2px ${theme.palette.primary[100]}`,
  },
}));

const WizardIcon = ({ icon, active, completed }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <CheckIcon /> : String(icon)}
    </div>
  );
};

export default WizardIcon;
