import React, { useState, useEffect } from 'react';
import { components } from 'react-select';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

import Loading from '../../components/Loading';
import TenantAssociationTable from './TenantAssociationTable';
import EyeModalContent from '../../components/EyeModalContent';
import AsyncSelect from '../../components/AsyncSelect';
import { showError, showSuccess } from '../../utils/toast';
import gateway from '../../utils/gateway';
import { customStyles, CustomControl } from './TenantAssociationTab.styles';

const getTableColumns = ({ onRemove }) => [
  {
    key: 'point',
    label: 'Operação',
    rowStyle: {
      width: '10%',
      color: '#777777',
      backgroundColor: '#EEF0F4',
      borderTopLeftRadius: 12,
      margin: 8,
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
      borderRadius: 0,
      color: '#737689',
    },
    dataKey: 'id',
  },
  {
    key: 'name',
    label: 'Nome',
    rowStyle: {
      width: '40%',
      color: '#777777',
      backgroundColor: '#EEF0F4',
      textAlign: 'left',
    },
    cellStyle: {
      color: '#737689',
      textAlign: 'left',
    },
    dataKey: 'name',
  },
  {
    key: 'remove',
    rowStyle: {
      width: '7%',
      color: '#777777',
      backgroundColor: '#EEF0F4',
      borderTopRightRadius: 12,
      textAlign: 'center',
      margin: 8,
      minWidth: 50,
    },
    cellStyle: { borderRadius: 0, textAlign: 'left', width: '20%' },
    render: (point) => (
      <div style={{ display: 'flex', marginRight: 8 }}>
        <IconButton aria-label="remove" onClick={onRemove(point.id)}>
          <DeleteOutlineOutlinedIcon color="primary" />
        </IconButton>
      </div>
    ),
  },
];

const TenantChildPointsModalContent = ({
  onClose,
  parentTenant,
  childTenant,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [associatedPoints, setAssociatedPoints] = useState([]);
  const [pointOptions, setPointOptions] = useState([]);
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const fetchAssociatedPoints = async () => {
    try {
      setIsLoading(true);

      const response = await gateway
        .get(
          `/tenants/admin/${parentTenant.id}/sub-tenants/${childTenant.id}/points`,
        )
        .then(({ data }) => data.data);

      setAssociatedPoints(response);
    } catch (err) {
      showError();
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPoints = async () => {
    try {
      setIsProcessing(true);

      const response = await gateway
        .get(`/tenants/admin/${parentTenant.id}/points`)
        .then(({ data }) => data.data);

      setPointOptions(response);
    } catch (err) {
      showError();
    } finally {
      setIsProcessing(false);
    }
  };

  const searchPoints = async (query) => {
    if (!query) {
      return pointOptions;
    }

    return pointOptions.filter(
      (point) =>
        new RegExp(query, 'i').test(point.name) ||
        new RegExp(query).test(point.id),
    );
  };

  useEffect(() => {
    fetchPoints();
    fetchAssociatedPoints();
  }, []);

  const handleAssociatePoint = async (value) => {
    try {
      setIsProcessing(true);

      const { id } = value;

      const data = {
        childTenantId: childTenant.id,
      };

      await gateway.post(
        `/tenants/admin/${parentTenant.id}/points/${id}/association`,
        data,
      );

      await fetchAssociatedPoints();
      await fetchPoints();
      showSuccess();
    } catch (err) {
      if (err.response) {
        showError(err.response.data.message);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDisassociatePoint = (pointId) => async () => {
    try {
      setIsProcessing(true);

      await gateway.delete(
        `/tenants/admin/${parentTenant.id}/points/${pointId}/association/${childTenant.id}`,
      );

      await fetchAssociatedPoints();
      await fetchPoints();
      showSuccess();
    } catch (err) {
      if (err.response) {
        showError(err.response.data.message);
      }
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  const optionRenderer = (option) => {
    const { id, name, licensesFromTenantId } = option.data;
    const isAssociated = licensesFromTenantId !== null;
    const associatedOption = `react-select__option ${
      option.isFocused && !option.isSelected
        ? 'react-select__option--is-focused'
        : ''
    } ${!option.isSelected && isAssociated ? 'is-associated' : ''}`;

    return (
      <components.Option {...option} className={associatedOption}>
        <div
          className="flex-block align-i-center justify-between"
          style={{ height: 20 }}
        >
          <div style={{ maxWidth: 500 }}>
            <span className="ml">{`${id} - ${name}`}</span>
          </div>
          {isAssociated ? (
            <div className="flex flex-row">
              <i className="ic-union ml mr" />
              <b>{licensesFromTenantId}</b>
            </div>
          ) : (
            <div>{option.isFocused && <b>Associar</b>}</div>
          )}
        </div>
      </components.Option>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div
          className="align-i-center"
          style={{
            width: '100%',
            marginTop: 36,
          }}
        >
          <Loading isSmall show={isLoading} />
        </div>
      );
    }

    if (associatedPoints.length === 0) {
      return (
        <div
          className="align-i-center"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: 36,
          }}
        >
          <i className="ic-no-results" />
          <Typography
            variant="subtitle1"
            align="center"
            style={{ color: '#777777', marginTop: 8 }}
          >
            Sem operações associadas
          </Typography>
          <Typography variant="body2" align="center">
            O cliente filho ainda não possui
          </Typography>
          <Typography variant="body2" align="center">
            operações associadas.
          </Typography>
        </div>
      );
    }

    return (
      <div
        className="flex flex-column vertical-scroll mt-lg"
        style={{ width: '100%', paddingRight: 2 }}
      >
        <TenantAssociationTable
          columns={getTableColumns({ onRemove: handleDisassociatePoint })}
          infinite
          data={associatedPoints}
          isLoading={isLoading}
        />
      </div>
    );
  };

  return (
    <EyeModalContent
      width="100%"
      onConfirm={handleClose}
      confirmType="button"
      confirmLabel="common:close"
      title={t('common:associatedPointsWithChildTenant')}
      disabled={isProcessing}
      isDialog
      childrenClassName="flex-column justify-center"
    >
      <p>{t('common:associatedPointsWithChildTenantDescription')}</p>
      <p style={{ marginTop: 0 }}>{childTenant.name}</p>
      <div style={{ width: '100%' }}>
        <AsyncSelect
          placeholder="common:pointAssociationSelectLabel"
          defaultOptions={pointOptions}
          loadOptions={searchPoints}
          customLoading={isProcessing}
          onChange={handleAssociatePoint}
          optionRenderer={optionRenderer}
          styles={customStyles}
          customComponents={{
            Control: CustomControl,
            DropdownIndicator: () => null,
            NoOptionsMessage: (props) => {
              const { inputValue } = props.selectProps;
              if (inputValue === '') {
                return (
                  <div className="flex grow">
                    <span className="text-center fw mt mb">
                      Digite para buscar uma operação
                    </span>
                  </div>
                );
              }
              return (
                <div className="flex grow">
                  <span className="text-center fw mt mb">Sem resultados</span>
                </div>
              );
            },
          }}
        />
        {renderContent()}
      </div>
    </EyeModalContent>
  );
};

export default TenantChildPointsModalContent;
