import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import { ReactComponent as DeviceGroupWhite } from '../assets/ic_device_group_white.svg';
import FloatingBar from './FloatingBar';
import EyeButton from './EyeButton';
import gateway from '../utils/gateway';
import { showError, showSuccess } from '../utils/toast';
import FormikSelect from './FormikSelect';
import providers from '../constants/providers';
import Input from './FormikInput';
import FormikAsyncSelect from './FormikAsyncSelect';
import Loading from './Loading';
import allocationTypes, {
  allocationTypesMap,
} from '../constants/allocationType';

const lookupModels = async (q) =>
  gateway
    .get('/legacy/deviceModels/lookup', { params: { q } })
    .then((response) => response.data);

const DeviceSchema = Yup.object().shape({
  provider: Yup.object()
    .required('common:fieldRequired')
    .nullable(),
  model: Yup.string().required('common:fieldRequired'),
  address: Yup.string()
    .trim()
    .required('common:fieldRequired'),
});

const EditDeviceBar = ({ deviceIdToEdit, onClose, show }) => {
  const { t } = useTranslation();

  const [device, setDevice] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getDevice = async () => {
      try {
        const res = await gateway.get('/legacy/devices/edit', {
          params: { deviceId: deviceIdToEdit },
        });

        setDevice(res.data);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e.response.data);
        showError();
      }
    };

    if (show) {
      setIsLoading(true);
      getDevice();
    }
  }, [show]);

  return (
    <FloatingBar className="floating-sm blue" innerClassName="fh" open={show}>
      <div
        className="flex flex-column justify-center align-i-center flex-wrap"
        style={{ minHeight: '50%' }}
      >
        <Formik
          enableReinitialize
          validationSchema={DeviceSchema}
          initialValues={{
            id: device.id,
            provider: device.provider
              ? {
                  value: String(device.provider),
                }
              : undefined,
            model: {
              value: device.deviceModelId,
              label: device.deviceModelName,
            },
            devicesGroup: device.devicesGroupId
              ? {
                  value: String(device.devicesGroupId),
                  label: device.devicesGroupName,
                }
              : undefined,
            address: device.address,
            deviceStatus: device.deviceStatusId
              ? {
                  value: String(device.deviceStatusId),
                  label: device.deviceStatusName,
                }
              : undefined,
            allocationType: device.allocationType
              ? {
                  value: String(device.allocationType),
                  label:
                    allocationTypesMap[String(device.allocationType)] ||
                    allocationTypesMap['0'],
                }
              : undefined,
            note: device.note,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true);
              const {
                id,
                model,
                provider,
                address,
                devicesGroup,
                deviceStatus,
                comment,
                allocationType,
              } = values;

              const device = {
                id,
                address,
                comment,
                name: model.value,
                provider: provider.value,
                devicesGroupId: devicesGroup ? devicesGroup.value : undefined,
                deviceStatusId: deviceStatus ? deviceStatus.value : undefined,
                allocationType: allocationType
                  ? allocationType.value
                  : undefined,
              };

              await gateway.put('/legacy/devices/', { ...device });

              showSuccess();
              setSubmitting(false);
              onClose(true);
            } catch (e) {
              console.error(e);
              setSubmitting(false);
              showError(e.response.data);
            }
          }}
          render={({ handleSubmit, submitForm, isSubmitting, resetForm }) => {
            if (isLoading) {
              return (
                <div className="flex fw fh align-i-center">
                  <Loading show={isLoading} isSmall loadingColor="#fff" />
                </div>
              );
            }

            return (
              <div
                className="flex flex-column justify-center align-i-center flex-wrap fh fw"
                style={{ flex: '1 0 0%' }}
              >
                <div className="flex grow justify-end fw">
                  <div
                    className={clsx(
                      'ic-close mt-lg',
                      isSubmitting && 'disabled',
                    )}
                    onClick={() => {
                      resetForm();
                      onClose(false);
                    }}
                  />
                </div>
                <div
                  className="flex justify-center"
                  style={{ maxWidth: '336px' }}
                >
                  <form onSubmit={handleSubmit} className="flex flex-column">
                    <div className="flex justify-center">
                      <span className="avenir-medium-white-title uppercase">
                        {t('common:edit')}
                      </span>
                    </div>
                    <div className="flex align-i-center mt-xl">
                      <div className="ic-white-round-device mr" />
                      <Field
                        name="model"
                        render={({
                          field,
                          form: { setFieldValue, touched, errors },
                        }) => {
                          return (
                            <FormikAsyncSelect
                              white
                              hideLabel
                              customFetch={lookupModels}
                              onBlur={field.onBlur}
                              selected={field.value}
                              containerClassName="fw"
                              placeholder="common:models"
                              touched={touched}
                              field={field}
                              errors={errors}
                              onChange={(value) => {
                                setFieldValue(field.name, value);
                              }}
                              disabled={isSubmitting}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="flex align-i-center mt-lg">
                      <i className="ic-white-round-currency mr" />
                      <Field
                        name="provider"
                        render={({
                          field,
                          form: { setFieldValue, touched, errors },
                        }) => {
                          return (
                            <FormikSelect
                              white
                              errorColor="#fff"
                              hideLabel
                              isMulti={false}
                              onBlur={field.onBlur}
                              selected={field.value}
                              containerClassName="fw"
                              options={providers}
                              placeholder="common:providers"
                              touched={touched}
                              field={field}
                              errors={errors}
                              onChange={(value) => {
                                setFieldValue(field.name, value);
                              }}
                              disabled={isSubmitting}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="flex align-i-center mt-lg">
                      <DeviceGroupWhite className="mr" />
                      <Field
                        name="devicesGroup"
                        render={({
                          field,
                          form: { setFieldValue, touched, errors },
                        }) => {
                          return (
                            <FormikSelect
                              white
                              errorColor="#fff"
                              hideLabel
                              onBlur={field.onBlur}
                              isMulti={false}
                              selected={field.value}
                              containerClassName="fw"
                              placeholder="common:devicesGroup"
                              touched={touched}
                              field={field}
                              errors={errors}
                              onChange={(value) => {
                                setFieldValue(field.name, value);
                              }}
                              fetch={() => {
                                return gateway
                                  .get('/legacy/devicesGroup/lookup')
                                  .then(({ data }) => data);
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="flex align-i-center mt-lg">
                      <DeviceGroupWhite className="mr" />
                      <Field
                        name="deviceStatus"
                        render={({
                          field,
                          form: { setFieldValue, touched, errors },
                        }) => {
                          return (
                            <FormikSelect
                              white
                              errorColor="#fff"
                              hideLabel
                              onBlur={field.onBlur}
                              isMulti={false}
                              selected={field.value}
                              containerClassName="fw"
                              placeholder="common:status"
                              touched={touched}
                              field={field}
                              errors={errors}
                              onChange={(value) => {
                                setFieldValue(field.name, value);
                              }}
                              fetch={() => {
                                return gateway
                                  .get('/legacy/deviceStatus/lookup')
                                  .then(({ data }) => data);
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="flex align-i-center mt-lg">
                      <i className="ic-white-round-dcs mr" />
                      <input
                        value={device.local || ''}
                        disabled
                        className="input-default uppercase-placeholder white pl"
                      />
                    </div>
                    <div className="flex align-i-center mt-lg">
                      <i className="ic-white-round-serialNumber mr" />
                      <Field
                        name="address"
                        component={Input}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            submitForm();
                          }
                        }}
                        containerClassName="fw"
                        style={{ paddingLeft: '10px' }}
                        placeholder={t('common:serialNumber')}
                        className="input-default uppercase-placeholder white"
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className="flex align-i-center mt-lg">
                      <div className="ic-white-round-device mr" />
                      <Field
                        name="note"
                        component={Input}
                        containerClassName="fw"
                        style={{ paddingLeft: '10px' }}
                        placeholder={t('common:note')}
                        className="input-default uppercase-placeholder white"
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className="flex align-i-center mt-lg">
                      <i className="ic-white-round-currency mr" />
                      <Field
                        name="allocationType"
                        render={({
                          field,
                          form: { setFieldValue, touched, errors },
                        }) => {
                          return (
                            <FormikSelect
                              white
                              errorColor="#fff"
                              hideLabel
                              isMulti={false}
                              onBlur={field.onBlur}
                              selected={field.value}
                              containerClassName="fw"
                              options={allocationTypes}
                              placeholder="common:allocationType"
                              touched={touched}
                              field={field}
                              errors={errors}
                              onChange={(value) => {
                                setFieldValue(field.name, value);
                              }}
                              disabled={isSubmitting}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="flex justify-center">
                      <EyeButton
                        className="btn-solid-green btn-md mt-lg"
                        label={t('common:save')}
                        clickHandler={submitForm}
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                      />
                    </div>
                  </form>
                </div>
              </div>
            );
          }}
        />
      </div>
    </FloatingBar>
  );
};

EditDeviceBar.propTypes = {
  deviceIdToEdit: PropTypes.number.isRequired,
  onClose: PropTypes.func,
  show: PropTypes.bool,
};

EditDeviceBar.defaultProps = {
  show: false,
  onClose: undefined,
};

export default EditDeviceBar;
