import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import isNil from '../../utils/isNil';
import { tableWrapper } from './TenantAssociationTable.styles';

const TenantAssociationTable = ({ columns, data, style }) => {
  const renderRow = (row, dataKey, render) => {
    if (render) {
      return render(row);
    }

    const rowData = row[dataKey];
    return !isNil(rowData) ? rowData : '-';
  };

  const renderColumns = () =>
    columns.map((column) => (
      <th key={column.key} style={column.rowStyle}>
        {column.label}
      </th>
    ));

  const renderTable = () => {
    if (!data || data.length === 0) {
      return null;
    }
    return data.map((row) => (
      <tr key={row.key}>
        {columns.map((column) => {
          const { render, dataKey, rowStyle, cellStyle } = column;
          return (
            <td style={cellStyle || rowStyle}>
              {renderRow(row, dataKey, render)}
            </td>
          );
        })}
      </tr>
    ));
  };

  return (
    <table
      className={clsx(tableWrapper)}
      style={{
        tableLayout: 'fixed',
        ...style,
      }}
    >
      <thead>
        <tr>{renderColumns()}</tr>
      </thead>
      <tbody>{renderTable()}</tbody>
    </table>
  );
};

TenantAssociationTable.defaultProps = {
  style: undefined,
};

TenantAssociationTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.object,
};

export default TenantAssociationTable;
