import React from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { default as MuiTable } from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';

import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import TenantStatus from '../../components/TenantStatus';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const columns = [
  {
    id: 'name',
    label: 'NOME',
  },
  { id: 'status', align: 'center', width: 1, label: 'STATUS' },
  {
    id: 'created',
    align: 'right',
    width: 1,
    label: 'CRIADO EM',
  },
];

const ItemsTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: '8px 16px',
    border: 0,
    whiteSpace: 'nowrap',
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const PartnersPage = () => {
  const history = useHistory();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [loading, setLoading] = React.useState(false);
  const [partners, setPartners] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const [search, setSearch] = React.useState('');
  const [status, setStatus] = React.useState(-1);
  const [filters, setFilters] = React.useState({
    search: '',
    status: -1,
  });

  const handleClick = (id) => {
    history.push(`/partners/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchPartners = async () => {
    setLoading(true);

    try {
      const response = await gateway.get('/billing/partners', {
        params: {
          ...(filters.search.length > 0 ? { search: filters.search } : {}),
          status: filters.status === -1 ? [0, 1] : [filters.status],
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        },
      });

      setPartners(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchPartners();
  }, [rowsPerPage, page, filters]);

  return (
    <Fade in>
      <Box flex={1}>
        <Paper square elevation={2}>
          <Box pt={6} p={4}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h4">Revendas</Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                disabled
              >
                Nova revenda
              </Button>
            </Box>
            <Box pt={4} display="flex" alignItems="center">
              <Box pr={4}>
                <TextField
                  name="search"
                  label="Pesquisar"
                  placeholder=""
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="inherit" />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </Box>
              <Box pr={4} width={160}>
                <SelectField
                  name="status"
                  label="Status"
                  placeholder="Status"
                  options={[
                    { label: 'Todas', value: -1 },
                    { label: 'Ativas', value: 1 },
                    { label: 'Inativas', value: 0 },
                  ]}
                  variant="standard"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                />
              </Box>
              <Box pr={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    setFilters({
                      search,
                      status,
                    })
                  }
                >
                  Buscar
                </Button>
              </Box>
            </Box>
            <Box pt={4}>
              <TableContainer>
                <MuiTable
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <ItemsTableCell
                          key={column.id}
                          align={column.align ? column.align : 'left'}
                          style={column.width ? { width: column.width } : {}}
                        >
                          <Typography variant="subtitle2" color="primary">
                            {column.label}
                          </Typography>
                        </ItemsTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading &&
                      [1, 2, 3].map((value) => (
                        <TableRow tabIndex={-1} key={value}>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell align="right">
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                        </TableRow>
                      ))}
                    {!loading &&
                      partners.map((row) => {
                        return (
                          <ItemsTableRow
                            hover
                            onClick={() => handleClick(row.id)}
                            key={row.id}
                          >
                            <ItemsTableCell>{row.name}</ItemsTableCell>
                            <ItemsTableCell align="center">
                              <TenantStatus size="small" status={row.status} />
                            </ItemsTableCell>
                            <ItemsTableCell align="right">
                              {moment(row.created).format('DD/MM/YYYY HH:mm')}
                            </ItemsTableCell>
                          </ItemsTableRow>
                        );
                      })}
                    {!loading && partners.length === 0 && (
                      <TableRow>
                        <ItemsTableCell colSpan={columns.length} align="center">
                          <Box m={4}>
                            <Typography>Nenhuma revenda encontrada</Typography>
                          </Box>
                        </ItemsTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </MuiTable>
              </TableContainer>
              <TablePagination
                labelRowsPerPage="Resultados por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} / ${count}`
                }
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
};

export default PartnersPage;
