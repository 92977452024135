import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { default as MuiSelectField } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const SelectField = ({
  field,
  label,
  options,
  placeholder,
  variant,
  ...rest
}) => {
  return (
    <FormControl variant={variant || 'filled'} fullWidth>
      <InputLabel>{label}</InputLabel>
      <MuiSelectField
        {...field}
        variant={variant || 'filled'}
        fullWidth
        {...rest}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelectField>
    </FormControl>
  );
};

export default SelectField;
