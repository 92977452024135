import React from 'react';
import Fade from '@material-ui/core/Fade';

import Wizard from '../../components/Wizard/Wizard';

import CompanyStep from './Steps/CompanyStep';
import ItemsStep from './Steps/ItemsStep';
import ContractStep from './Steps/ContractStep';

const NewTenantWizard = () => {
  return (
    <Fade in>
      <Wizard>
        <CompanyStep label="Dados Cadastrais" />
        <ItemsStep label="Adicionar Itens" recurrent />
        <ContractStep label="Contrato" add />
      </Wizard>
    </Fade>
  );
};

export default NewTenantWizard;
