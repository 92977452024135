import React from 'react';
import Fade from '@material-ui/core/Fade';

import Wizard from '../../components/Wizard/Wizard';

import TenantStep from './Steps/TenantStep';
import ItemsStep from './Steps/ItemsStep';
import ProposalStep from './Steps/ProposalStep';
import ReviewStep from './Steps/ReviewStep';

const ContractProposalWizard = () => {
  return (
    <Fade in>
      <Wizard>
        <TenantStep label="Selecionar Cliente" guided />
        <ItemsStep label="Adicionar Itens" />
        <ProposalStep label="Proposta" />
        <ReviewStep label="Revisão" first />
      </Wizard>
    </Fade>
  );
};

export default ContractProposalWizard;
