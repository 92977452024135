import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { default as MuiChip } from '@material-ui/core/Chip';

const PAYMENT_PROVIDER = {
  0: 'OUTRO',
  1: 'IUGU',
};

const Chip = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 10,
    color: ({ paymentProvider }) =>
      paymentProvider === 0
        ? theme.palette.warning[500]
        : theme.palette.success[500],
    backgroundColor: ({ paymentProvider }) =>
      paymentProvider === 0
        ? theme.palette.warning[50]
        : theme.palette.success[50],
  },
}))(MuiChip);

export default function PaymentProvider({ paymentProvider }) {
  return (
    <Chip
      style={{ minWidth: 65 }}
      label={PAYMENT_PROVIDER[paymentProvider]}
      paymentProvider={paymentProvider}
      size="small"
    />
  );
}
