import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CheckIcon from '@material-ui/icons/Check';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';

import DateField from '../../../components/DateField';
import TextField from '../../../components/TextField';
import RichTextField from '../../../components/RichTextField';
import SelectField from '../../../components/SelectField';

import gateway from '../../../utils/gateway';
import { showError } from '../../../utils/toast';

const ContractSchema = Yup.object().shape({
  starts_at: Yup.date()
    .required('Campo obrigatório')
    .typeError('Data inválida'),
  duration: Yup.number()
    .required('Campo obrigatório')
    .min(1, 'Duração inválida')
    .max(96, 'Duração inválida'),
  receivable_deadline: Yup.string().nullable(),
  charge_type: Yup.string().required('Campo obrigatório'),
  next_charge_at: Yup.string().required('Campo obrigatório'),
});

const useStyles = makeStyles(() => ({
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}));

const ContractStep = ({ show, step, total, onPreviousStep, context, add }) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  const [submitting, setSubmitting] = React.useState(false);

  const formRef = React.useRef();

  const receivable = context.proposal_items
    ? context.proposal_items.some(
        (item) =>
          item.debit_percentage !== null ||
          item.credit_percentage !== null ||
          item.debit_antecipation_percentage !== null ||
          item.credit_antecipation_percentage !== null,
      )
    : false;

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <Fade in>
      <Paper square elevation={2} style={{ flex: 1 }}>
        <Box p={0} display="flex" flexDirection="column">
          <Box p={4} pt={6} display="flex" flexDirection="column">
            <Typography variant="subtitle2" color="primary">
              Passo {step + 1} de {total}
            </Typography>
            <Typography variant="h4">Contrato</Typography>
          </Box>
          <Box p={4} pt={4} pb={8} display="flex" flexDirection="column">
            <Formik
              innerRef={formRef}
              initialValues={{
                starts_at: context.starts_at || null,
                duration: context.duration || 12,
                observations: context.observations || '',
                receivable_deadline: context.receivable_deadline || null,
                charge_type: context.charge_type || null,
                next_charge_at: context.next_charge_at || null,
              }}
              validationSchema={ContractSchema}
              onSubmit={async (values) => {
                const { observations } = values;

                setSubmitting(true);

                const proposal_items = context.proposal_items.map((item) => {
                  let formattedObservations;
                  if (item.observations === null || item.observations === '') {
                    formattedObservations = '';
                  } else {
                    const editor = convertFromRaw(
                      JSON.parse(item.observations),
                    );
                    formattedObservations = editor.hasText()
                      ? stateToHTML(editor)
                      : '';
                  }
                  return {
                    ...item,
                    observations: formattedObservations,
                  };
                });

                let formattedObservations;
                if (observations === null || observations === '') {
                  formattedObservations = '';
                } else {
                  const editor = convertFromRaw(JSON.parse(observations));
                  formattedObservations = editor.hasText()
                    ? stateToHTML(editor)
                    : '';
                }

                const payload = {
                  ...context,
                  contract_items: proposal_items,
                  ...values,
                  observations: formattedObservations,
                };

                payload.contract_items = payload.contract_items.map((item) => {
                  return {
                    ...item,
                    contract_product_id: item.contract_product.id,
                  };
                });

                try {
                  let response;
                  if (add) {
                    response = await gateway.post(`/billing/tenants`, payload);
                  } else {
                    response = await gateway.post(
                      `/billing/tenants/${params.id}/associate`,
                      payload,
                    );
                  }

                  history.push(`/tenants/${response.data.contract.tenant.id}`);
                } catch (err) {
                  console.error(err);
                  showError(err?.response?.data?.message);
                  setSubmitting(false);
                }
              }}
              render={({ values, errors, touched }) => {
                let options = [];

                if (
                  values.starts_at !== null &&
                  context.recurrency_type === 1
                ) {
                  options = new Array(values.duration)
                    .fill(0)
                    .map((value, index) => {
                      return {
                        label: moment(values.starts_at)
                          .add(index, 'month')
                          .format('DD/MM/YYYY'),
                        value: moment(values.starts_at)
                          .add(index, 'month')
                          .format('DD/MM/YYYY'),
                      };
                    });
                } else if (
                  values.starts_at !== null &&
                  context.recurrency_type === 2
                ) {
                  options = new Array(Math.round(values.duration / 12))
                    .fill(0)
                    .map((value, index) => {
                      return {
                        label: moment(values.starts_at)
                          .add(index, 'year')
                          .format('DD/MM/YYYY'),
                        value: moment(values.starts_at)
                          .add(index, 'year')
                          .format('DD/MM/YYYY'),
                      };
                    });
                }

                return (
                  <form>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Typography variant="h5">Contrato</Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="starts_at"
                            label="Início do contrato"
                            placeholder="DD/MM/YYYY"
                            format="DD/MM/YYYY"
                            disableToolbar
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            component={DateField}
                            error={errors.starts_at && touched.starts_at}
                            helperText={
                              errors.starts_at && touched.starts_at
                                ? errors.starts_at
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="duration"
                            label="Duração do contrato (meses)"
                            type="number"
                            InputProps={{ inputProps: { min: 1 } }}
                            component={TextField}
                            error={errors.duration && touched.duration}
                            helperText={
                              errors.duration && touched.duration
                                ? errors.duration
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="observations"
                            label="Observações"
                            placeholder="Observações"
                            component={RichTextField}
                            defaultValue={context.observations || null}
                          />
                        </Grid>
                        {receivable && (
                          <Grid item xs={12}>
                            <Field
                              name="receivable_deadline"
                              label="Prazo de recebimento"
                              placeholder="Prazo de recebimento"
                              component={SelectField}
                              options={[
                                { label: 'D+1', value: 'D+1' },
                                { label: 'D+30', value: 'D+30' },
                              ]}
                              error={
                                errors.receivable_deadline &&
                                touched.receivable_deadline
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">Pagamento</Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Alert severity="info">
                            Você pode ignorar cobranças ao definir a data da
                            próxima cobrança diferente da data de início de
                            contrato.
                          </Alert>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="charge_type"
                            label="Forma de pagamento"
                            placeholder="Forma de pagamento"
                            component={SelectField}
                            options={[
                              { label: 'BOLETO', value: 'VOUCHER' },
                              { label: 'PIX', value: 'PIX' },
                            ]}
                            error={errors.charge_type && touched.charge_type}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="next_charge_at"
                            label="Data da pŕoxima cobrança"
                            placeholder="Data da pŕoxima cobrança"
                            component={SelectField}
                            disabled={values.starts_at === null}
                            options={options}
                            error={
                              errors.next_charge_at && touched.next_charge_at
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            />
          </Box>
          <Box
            p={2}
            pl={4}
            pr={4}
            display="flex"
            justifyContent="space-between"
            borderTop="1px solid #ededed"
          >
            <Button
              color="default"
              onClick={() =>
                onPreviousStep({
                  ...context,
                })
              }
              className={classes.cancel}
              startIcon={<ChevronLeftIcon />}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={submitting}
              className={classes.confirm}
              endIcon={
                submitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckIcon />
                )
              }
            >
              Salvar
            </Button>
          </Box>
        </Box>
      </Paper>
    </Fade>
  );
};

export default ContractStep;
