import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  label: {
    color: '#3B4256',
  },
});

const CheckBoxField = ({ field, label, checked, ...rest }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          {...rest}
          checked={field ? field.value : checked}
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
        />
      }
      label={<Typography variant="subtitle2">{label}</Typography>}
      classes={classes}
    />
  );
};

export default CheckBoxField;
