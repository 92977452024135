export default {
  common: {
    toView: 'VIEW',
    currency: 'R$',
    currencyPlaceholder: 'R$ 0,00',
    currencyPlaceholderNoSymbol: '0,00',
    groupingBy: 'GROUPING BY',
    grouping: 'GROUPING',
    orderBy: 'ORDER BY',
    value: 'value',
    amount: 'QUANTITY',
    attention: 'Attention',
    all: 'All',
    allFemale: 'All',
    allValues: 'All values',
    allEvents: 'All events',
    allMenuList: 'All product lists',
    allUsers: 'All users',
    allPoint: 'All points',
    allTransactionTypes: 'All transaction types',
    allPayTypes: 'All payment types',
    allSectors: 'All Sectors',
    allProducts: 'All products',
    allProductGroups: 'All product groups',
    cardReceivables: 'Card receivables',
    free: 'free',
    open: 'open',
    close: 'closed',
    singleOpen: 'Open',
    singleClose: 'Closed',
    calls: 'Calls',
    call: 'Call',
    cashiers: 'Cashiers',
    cashier: 'Cashier',
    bar: 'Bar',
    busyFemale: 'Busy',
    search: 'Search',
    searchMenu: 'Search list',
    searchAccount: 'Search comanda',
    searchEvent: 'Search event',
    searchUserOrLogin: 'Search user or login',
    searchPoint: 'Search point',
    account: 'Comanda',
    bankAccount: 'Bank account',
    item: 'Item',
    qtd: 'Qtd',
    serviceCharge: 'Service charge',
    tenPercent: '10%',
    placeHolderPicker: 'DD/MM/AAAA',
    from: 'From',
    to: 'To',
    locale: 'pt-BR',
    lastUpdate: 'Last update',
    noData: 'No data to show at this time!',
    noDataToExport: 'No data to be exported at the moment!',
    wait: 'Wait...',
    filterBy: 'FILTER BY',
    situation: 'Situation',
    display: 'show',
    opening: 'Opening',
    closure: 'Closure',
    lastSync: 'LATEST SYNCHRONIZATION',
    lastSyncAtTime: 'SYNCHRONIZED AT LESS TIME',
    id: 'ID',
    point: 'Point',
    dateCashier: 'Date of the cashier',
    status: 'Status',
    sale: 'Sale',
    transactionType: 'Transaction type',
    cancellation: 'Cancellation',
    totalShift: 'Total of cashier',
    productSale: 'Sale of products',
    print: 'Print',
    toPrint: 'print',
    reprint: 'Reprint',
    reprints: 'Reprints',
    dateLastSync: 'Sync date',
    dateLastUpdate: 'Update date',
    android: 'Android',
    app: 'App',
    updateIn: 'Update in',
    updatedBy: 'Updated by',
    syncIn: 'Synced to',
    totalDevices: 'Total devices',
    batteryLevel: 'Battery level',
    lockUpdate: 'Lock update',
    unlockUpdate: 'Unlock update',
    courtesy: 'Courtesy',
    production: 'Production',
    purchase: 'Purchase',
    input: 'Input',
    output: 'Output',
    totalSale: 'TOTAL SALE',
    welcomeTitle: 'É "DASHBOARD NOVO" QUE SE FALA NÉ?',
    evolutionDashboard:
      'O Portal Eyemobile está evoluindo e você agora pode experimentar essa evolução.',
    amazingDashboard: 'O que já era incrível agora está espetacular!',
    goBackDashboard:
      'Para voltar ao Dashboard antigo, basta clicar no botão que aparece no canto inferior da tela',
    notShowAgain: 'NÃO EXIBIR NOVAMENTE',
    enter: 'Entrar',
    ratingTitle: 'A GENTE GOSTA DE BATER UM PAPO E SABER MAIS SOBRE VOCÊ!',
    perfectDashboard:
      'O Portal Eyemobile está evoluindo e queremos torná-lo perfeito para você.',
    ratingDashboard:
      'Dê sua pontuação e conte-nos o que achou. Aceitamos elogios, reclamações, críticas e o que você achar importante.',
    cuteDashboard: 'Seja gentil! :)',
    notNow: 'Agora não!',
    evaluate: 'Avaliar',
    talkAbout: 'Diga o que achou do novo dashboard',
    type: 'Type',
    valueTransaction: 'Os valores acima correspondem',
    valueEyepay: 'a transações por Eyepay',
    requestEvent: 'Request event',
    enableFilter: 'ENABLE FILTERS',
    select: 'Select',
    access: 'Access',
    edit: 'Edit',
    extract: 'Extract',
    anticipate: 'Anticipate',
    anticipateValues: 'Anticipate values',
    anticipation: 'Anticipation',
    anticipations: 'anticipations',
    anticipationsDesc:
      'Anticipations made until 11am will be available\n in your account on the same business day. Values \n anticipated after 11am, available next business day',
    redeem: 'Redeem',
    redeemAll: 'Redeem all',
    redeemValues: 'Redeem values',
    showMore: 'Show more',
    supportMail: 'suporte@eyemobile.com.br',
    supportMsg1: 'Needing a little help? Access our',
    centralSupport: 'Support center',
    supportMsg2: 'Or send an email to',
    inConfiguration: 'in configuration',
    release: 'Releases',
    billing: 'Billing',
    waitApproval: 'Wait approval',
    support: 'Support',
    eyebank: 'EYEBANK',
    ag: 'AG',
    cc: 'CC',
    available: 'Available',
    available_: 'Available:',
    toReceive: 'To receive',
    company: 'Company',
    myCompany: 'My company',
    revenues: 'Revenues',
    exit: 'Exit',
    myProfile: 'My profile',
    name: 'Name',
    shortName: 'Short name',
    login: 'Login',
    email: 'Email',
    invalidEmail: 'Oops! You have entered an invalid email',
    passwordActually: 'Password actually',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    phone: 'Phone',
    zipCode: 'Zip code',
    address: 'Address',
    address2: 'Complement',
    city: 'City',
    state: 'State',
    district: 'District',
    syncWithSocialMedia: 'SINCRONIZAR COM REDES SOCIAIS?',
    save: 'SAVE',
    findPostal: 'Find Postal',
    updatePhoto: 'Update photo',
    successMessage: 'Your information has been successfully saved!',
    errorMessage: 'Error saving your information. Try again later!',
    accessDeniedMessage: 'Ops! Você não possui permissão para acessar es',
    socialName: 'Razão social',
    fantasyName: 'Nome fantasia',
    fiscalIe: 'Inscrição estadual',
    fiscalIm: 'Inscrição municipal',
    taxRegime: 'Regime tributário',
    cnpj: 'Document',
    infoSupport:
      'If you wish to change any of your company tax information, please contact Eyemobil Support:',
    number: 'Number',
    confirm: 'Confirm',
    cancel: 'Cancel',
    greatPicture: 'EXCELENTE IMAGEM!',
    letAdjust: 'VAMOS AJUSTAR?',
    successImage: 'Imagem atualizada com sucesso!',
    imageSentSuccess: 'Imagem enviada com sucesso!',
    certificateUploadSuccess: 'Certificado enviado com sucesso!',
    certificateUploadError: 'Ops! Não foi possível enviar o certificado',
    errorImage: 'Não foi possível enviar a imagem!',
    invalidPhone: 'Número de telefone inválido!',
    invalidDocument: 'Documento inválido!',
    invalidZipCode: 'CEP inválido!',
    emptyZipCode: 'O CEP não pode ser vazio!',
    emptyProductGroupName: 'O grupo de produto não pode ter o nome vazio',
    addProductGroup: 'Adicionar grupo',
    inconsistency: 'Ops... há inconsistência nas informações! Verifique',
    passwordNotMatch: 'As senhas não conferem!',
    invalidPassword: 'Senha inválida!',
    neighborhood: 'Neighborhood',
    searchProductMenu: 'Pesquisar lista de produto',
    includeNewList: 'Include new list',
    toClone: 'Clone',
    products: 'Products',
    groupProducts: 'Group products',
    eyeplace: 'Eyeplace',
    actives: 'Actives',
    inactives: 'Inactives',
    accessGroup: 'Acess Group',
    actions: 'Actions',
    delete: 'Delete',
    activate: 'Ativar',
    deactivate: 'Inativar',
    selectAll: 'Select All',
    unSelectAll: 'Unselect All',
    deleteSelected: 'Delete Selected',
    activateSelected: 'Activate Selected',
    inactivateSelected: 'Inactivate Selected',
    create: 'Create',
    createdAt: 'Criado em',
    includeList: 'Include list',
    includeItem: 'Incluir item',
    productGroupInstruction:
      'The following are pre-defined product groups. You can create new ones if you wish.',
    tip: 'Dica',
    productGroupTip: 'always use product groups\n to separate your products',
    includeProductGroupName: 'Type a new product group',
    typeProductGroup: 'Type product group',
    include: 'Include',
    addCategory: 'Adicionar categoria',
    start: 'Home',
    listName: 'Nome da lista',
    editList: 'Editar lista',
    editAccountList: 'Editar lista de comandas',
    addAccountList: 'Adicionar lista de comandas',
    includeAccountList: 'Incluir lista de comandas',
    addAccountItem: 'Adicionar comanda',
    editItem: 'Edit item',
    inactive: 'Inactive',
    active: 'Active',
    errorProductGroupRemove: 'Não é possível remover a categoria {{name}}',
    toClose: 'Close',
    sendPhotoDesc: 'Envie uma foto do produto',
    sendPhoto: 'Send photo',
    tipPhoto: 'use fotos com fundo branco',
    imageSuggestions: 'Image suggestions',
    userSearchForAddProduct:
      'Use o campo de pesquisa para adicionar um produto.',
    YouCanAddNewProductifYouCannotFind:
      'Caso não encontre, você pode adicionar um novo.',
    productInsertedSuccess: 'Produto incluído \ncom sucesso',
    successOnDeleteProduct: 'Produto excluído com sucesso!',
    yes: 'Yes',
    no: 'No',
    wantAddProduct: 'Deseja adicionar outro produto no \ngrupo {{name}} ?',
    clickIn: 'Clique em',
    plus: '+',
    toAddCategory: 'parar adicionar um produto na categoria que desejar',
    tipCategory: 'sempre use os grupos para separar os seus produtos',
    productName: 'Nome do produto',
    typeProductName: 'Digite o nome do produto',
    typeToSearch: 'Digite para pesquisar',
    loadingSuggestions: 'Carregando sugestões...',
    loading: 'Carregando...',
    users: 'Users',
    user: 'User',
    add: 'Add',
    addPoint: 'Add Point',
    addRecipient: 'Add recipient',
    addPagarMeRecipient: 'Credenciamento Pagar.me',
    addPassePagRecipient: 'Credenciamento PassePag',
    confirmActivate: 'Deseja ativar este registro?',
    confirmActivate_plural: 'Deseja ativar estes registros?',
    confirmActivateText:
      'Tem certeza de que deseja ativar? \nVocê poderá inativa-lo\n posteriormente.',
    confirmActivateText_plural:
      'Tem certeza de que deseja ativar estes registros? \nVocê poderá inativa-los\n posteriormente.',
    confirmDeactivate: 'Deseja inativar este registro?',
    confirmDeactivate_plural: 'Deseja inativar estes registros?',
    confirmDeactivateText:
      'Tem certeza de que deseja inativar este registro? \nVocê poderá ativa-lo\n posteriormente.',
    confirmDeactivateText_plural:
      'Tem certeza de que deseja inativar estes registros? \nVocê poderá ativa-los\n posteriormente.',
    confirmDelete: 'Deseja excluir este registro?',
    confirmDelete_plural: 'Deseja excluir estes registros?',
    confirmDeleteText:
      'Tem certeza de que deseja excluir este registro? \nEsta ação não poderá\n ser desfeita.',
    confirmDeleteText_plural:
      'Tem certeza de que deseja excluir estes registros? \nEsta ação não poderá\n ser desfeita.',
    successOnAddUser: 'Usuário incluído com sucesso!',
    errorOnAddUser: 'Ops! Ocorreu um erro ao tentarmos incluir o usuário.',
    confirmDeleteUser: 'Deseja excluir este usuário?',
    confirmDeleteUser_plural: 'Deseja excluir estes usuários?',
    confirmDeleteUserText:
      'Tem certeza de que deseja excluir este usuário? \nEsta ação não poderá\n ser desfeita.',
    confirmDeleteUserText_plural:
      'Tem certeza de que deseja excluir estes usuários? \nEsta ação não poderá\n ser desfeita.',
    confirmActivateUser: 'Deseja ativar este usuário?',
    confirmActivateUser_plural: 'Deseja ativar estes usuários?',
    confirmActivateUserText:
      'Tem certeza de que deseja ativar este usuário? \nVocê poderá inativa-lo\n posteriormente.',
    confirmActivateUserText_plural:
      'Tem certeza de que deseja ativar estes usuários? \nVocê poderá inativa-los\n posteriormente.',
    menuClonedSuccessfully: 'Lista de produtos clonado com sucesso!',
    confirmActivateMenu: 'Deseja ativar esta lista de produtos?',
    confirmActivateMenu_plural: 'Deseja ativar estas listas de produtos?',
    confirmActivateMenuText:
      'Tem certeza de que deseja ativar esta lista de produtos? \nVocê poderá inativa-lo\n posteriormente.',
    confirmActivateMenuText_plural:
      'Tem certeza de que deseja ativar estas listas de produtos? \nVocê poderá inativa-los\n posteriormente.',
    confirmDeactivateUser: 'Deseja inativar este usuário?',
    confirmDeactivateUser_plural: 'Deseja inativar estes usuários?',
    confirmDeactivateUserText:
      'Tem certeza de que deseja inativar este usuário? \nVocê poderá ativa-lo\n posteriormente.',
    confirmDeactivateUserText_plural:
      'Tem certeza de que deseja inativar estes usuários? \nVocê poderá ativa-los\n posteriormente.',
    confirmDeactivateMenu: 'Deseja inativar esta lista de produtos?',
    confirmDeactivateMenu_plural: 'Deseja inativar estas listas de produtos?',
    confirmDeactivateMenuText:
      'Tem certeza de que deseja inativar esta lista de produtos? \nVocê poderá ativa-lo\n posteriormente.',
    confirmDeactivateMenuText_plural:
      'Tem certeza de que deseja inativar estas listas de produtos? \nVocê poderá ativa-los\n posteriormente.',
    successOnDeleteUser: 'Usuário excluído com sucesso!',
    successOnDeleteUser_plural: 'Usuários excluídos com sucesso!',
    successOnUpdateUser: 'Usuário atualizado com sucesso!',
    successOnUpdateUser_plural: 'Usuários atualizados com sucesso!',
    successOnUpdateMenu: 'Lista de produtos atualizado com sucesso!',
    successOnUpdateMenu_plural: 'Listas de produtos atualizados com sucesso!',
    errorOnDeleteUser:
      'Não foi possível concluir a exclusão pois o usuário está em uso.',
    errorOnDeleteUser_plural:
      'Não foi possível concluir a exclusão pois existem um ou mais usuários em uso.',
    errorOnUpdateUser: 'Não foi possível atualizar o status do usuário.',
    errorOnUpdateUser_plural:
      'Não foi possível atualizar o status dos usuários.',
    errorOnUpdateMenu:
      'Não foi possível atualizar o status da lista de produtos.',
    errorOnUpdateMenu_plural:
      'Não foi possível atualizar o status das listas de produtos.',
    confirmDeleteAccountList: 'Deseja excluir esta lista de comanda?',
    confirmDeleteAccountList_plural: 'Deseja excluir estas listas?',
    confirmDeleteAccountListText:
      'Tem certeza de que deseja excluir lista de comanda selecionada? \nEsta ação não poderá\n ser desfeita.',
    confirmDeleteAccountListText_plural:
      'Tem certeza de que deseja excluir estas listas? \nEsta ação não poderá\n ser desfeita.',
    confirmActivateAccountList: 'Deseja ativar esta lista de comandas?',
    confirmActivateAccountList_plural: 'Deseja ativar estas listas?',
    confirmActivateAccountListText:
      'Tem certeza de que deseja ativar lista de comanda ? \nVocê poderá inativa-lo\n posteriormente.',
    confirmActivateAccountListText_plural:
      'Tem certeza de que deseja ativar estas listas selecionadas? \nVocê poderá inativa-los\n posteriormente.',
    confirmDeactivateAccountList: 'Deseja inativar esta lista de comandas?',
    confirmDeactivateAccountList_plural: 'Deseja inativar estas listas?',
    confirmDeactivateAccountListText:
      'Tem certeza de que deseja inativar lista de comanda ? \nVocê poderá ativa-lo\n posteriormente.',
    confirmDeactivateAccountListText_plural:
      'Tem certeza de que deseja inativar estas listas selecionadas? \nVocê poderá ativa-los\n posteriormente',
    successOnDeleteAccountList: 'Lista de comanda excluída com sucesso!',
    successOnDeleteAccountList_plural:
      'Listas de comanda excluídas com sucesso!',
    errorOnDeleteAccountList:
      'Não foi possível excluir pois a lista de comanda está em uso.',
    errorOnDeleteAccountList_plural:
      'Não foi possível excluir pois existem uma ou mais listas em uso.',
    errorOnUpdateAccountList:
      'Não foi possível atualizar o status da lista de comanda.',
    errorOnUpdateAccountList_plural:
      'Não foi possível atualizar o status das listas.',
    actionCanNotBeUndone: 'Esta ação não pode ser desfeita',
    confirmDeleteProductGroup: 'Deseja remover o grupo de produtos: {{name}}',
    confirmDeleteAccountItem: 'Deseja excluir esta comanda?',
    confirmDeleteAccountItem_plural: 'Deseja excluir estas comandas?',
    confirmDeleteAccountItemText:
      'Tem certeza de que deseja excluir comanda selecionada? \nEsta ação não poderá\n ser desfeita.',
    confirmDeleteAccountItemText_plural:
      'Tem certeza de que deseja excluir estas comandas? \nEsta ação não poderá\n ser desfeita.',
    confirmDeleteEventPoint: 'Deseja excluir a operação deste evento?',
    confirmActivatePoint: 'Deseja ativar esta operação?',
    confirmActivatePoint_plural: 'Deseja ativar estas operações?',
    confirmActivatePointText:
      'Tem certeza de que deseja ativar esta operação? \nVocê poderá inativa-la\n posteriormente.',
    confirmActivatePointText_plural:
      'Tem certeza de que deseja ativar estas operações? \nVocê poderá inativa-las\n posteriormente.',
    confirmDeactivatePoint: 'Deseja inativar esta operação?',
    confirmDeactivatePoint_plural: 'Deseja inativar estas operação?',
    confirmDeactivatePointText:
      'Tem certeza de que deseja inativar esta operação? \nVocê poderá ativa-la\n posteriormente.',
    confirmDeactivatePointText_plural:
      'Tem certeza de que deseja inativar estas operação? \nVocê poderá ativa-las\n posteriormente.',
    confirmDeletePoint: 'Deseja excluir esta operação?',
    confirmDeletePoint_plural: 'Deseja excluir estas operações?',
    confirmDeletePointText:
      'Tem certeza de que deseja excluir esta operação? \nEsta ação não poderá\n ser desfeita.',
    confirmDeletePointText_plural:
      'Tem certeza de que deseja excluir estas operações? \nEsta ação não poderá\n ser desfeita.',
    confirmCancelTransaction: 'Deseja cancelar esta transação?',
    confirmCancelTransactionText:
      'Tem certeza de que deseja cancelar esta transação? \n Você poderá ativar a transação posteriormente',
    confirmReactivateTransaction: 'Deseja reativar esta transação?',
    confirmReactivateTransactionText:
      'Tem certeza de que deseja reativar esta transação? \n Você poderá cancelar a transação posteriormente',
    deleteMenu: 'Deseja excluir essa lista?',
    errorOnAddRecipient: 'Ops! Ocorreu um erro ao tentarmos cadastrar a conta.',
    youNeedToFillMenuName: 'Você precisa preencher o nome da lista',
    verify: 'Verify',
    availableLogin: 'Este login está disponível para uso',
    unavailableLogin: 'Ops, este login não está disponível para uso',
    accountNumber: 'Número de comandas',
    accounts: 'Comandas',
    fieldRequired: 'Campo obrigatório.',
    titleList: 'Título da lista',
    numberCard: 'Número de comandas',
    insertInitialNumber: 'Insira um número inicial (ex: 1)',
    insertFinalNumber: 'Insira um número final (ex: 100)',
    exportQrCode: 'Exportar qr code',
    printQrCode: 'Imprimir qr code',
    waitersNumber: 'Number of cashiers',
    bartendersNumber: 'Number of bars',
    zero: '0',
    multipleUsers: 'Multiple users',
    startIn: 'Start in',
    loginPrefix: 'Login prefix',
    availablePrefix: 'Este prefixo está disponível para uso',
    unavailablePrefix: 'Ops, este prefixo não está disponível para uso',
    noResultType: 'No type found',
    noResultFound: 'No result found',
    noDataToShow: 'Nenhuma informação para exibir',
    confirmMovements: 'Confirmar movimentações',
    movements: 'Movimentações',
    fee: 'Taxa',
    fee_plural: 'Taxas',
    transferCost: 'Custo da transação',
    transferCost_: 'Custo da transação:',
    transfersAreMadeBy: 'Transferências são realizadas por',
    ted_doc: 'TED/DOC',
    atTheCostOf: 'ao custo de',
    transferFee: 'R$ 3,67',
    byTransfer: 'por operação.',
    bradescoAccountsAreFree: 'Contas Bradesco estão isentas deste custo.',
    transferFeeDesc:
      'Transferências são realizadas por TED/DOC, ao\n custo de R$ 3,67 por operação. Contas Bradesco\n estão isentas deste custo',
    movementsDesc:
      'Você pode incluir um lançamento para cobrir\nqualquer despesa devida do recebedor durante o evento.\nEsse valor será descontado no total da operação no Eyeplace.',
    newEntry: 'Novo lançamento',
    participations: 'Participations',
    code: 'Code',
    totalPoints: 'Total de operações',
    pointsTotal: 'Total das operações',
    files: 'Files',
    authorFile: 'por {{author}} em {{date}}',
    eventSetup: 'Configuração do evento',
    approvalAdm: 'Aprovação do administrador',
    nameDateTime: '{{name}} em {{date}}',
    debit: 'Debit',
    credit: 'Credit',
    creditInCash: 'Crédito à vista',
    credit2to6Installments: 'Crédito 2-6x',
    credit7to12Installments: 'Crédito 7-12x',
    permission: 'Permission',
    addPermission: 'Add permission',
    toApprove: 'Aprovar',
    disapprove: 'Reprovar',
    modify: 'Modificar',
    editProduct: 'Edit product',
    payType: 'Payment type',
    printLayout: 'Layout de impressão',
    menu: 'Product list',
    taxResponsible: 'Tax responsible',
    important: 'Importante',
    addPermissionDescription:
      'Técnicos e administradores já possuem acesso a este evento. \n Você poderá configurar usuários adicionais que poderão apenas acompanhar os\n resultados deste evento',
    addFile: 'Add file',
    fileUploadError: 'Ops! Não foi possível enviar o arquivo',
    fileUploadSuccess: 'Arquivo enviado com sucesso!',
    notFiles: 'Não há arquivos adicionados.',
    notFollowers: 'Não há permissões.',
    requestApproval: 'Solicitar liberação',
    note: 'Observações',
    limitFile: 'Ops... parece que você excedeu o limite de 10MB por arquivo!',
    disapproveEvent: 'Evento recusado',
    editEvent: 'Editar evento',
    enablePaymentMethodIntegration: 'Habilitar pagamento em cartão integrado',
    debitFee: 'Taxa de débito',
    creditFee: 'Taxa de crédito ',
    showInInvoice: 'Exibir na fatura do cliente',
    tax: 'Taxas',
    recipients: 'Recebedores',
    noResultRecipient: 'Nenhum recebedor encontrado',
    payProvider: 'Pagar adquirente',
    advancedSettings: 'Configurações avançadas',
    eventName: 'Event name',
    initialDate: 'Data ínicio',
    finalDate: 'Data fim',
    addEquipment: 'Adicionar equipamento',
    equipment: 'Equipamento',
    equipments: 'Equipamentos',
    noResultEquipment: 'Nenhum equipamento encontrado',
    inventory: 'Inventário (pode ser definido depois)',
    addLicense: 'Adicionar licença',
    quantity: 'Quantidade',
    optionalComments: 'Comentário (opcional)',
    paymentFrequency: 'Frequencia de pagamento',
    noResultPaymentFrequency: 'Nenhuma frequencia encontrada',
    macAddress: 'Mac Address',
    inventoryNumber: 'Número de inventário',
    changeEquipment: 'Trocar equipamento',
    licenseDescription: '{{quantity}}X {{name}}',
    oneRecipientPayTax: 'Atenção somente um recebedor pode pagar as taxas!',
    youNeedAddEquipment:
      'Você precisa adicionar equipamentos para essa operação e licenças \n vinculados a cada equipamento',
    noConfigured: 'Não configurado',
    observerDisapproved: 'Você precisa preencher a observação',
    period: 'Period',
    month: 'Mês',
    monthYear: 'Mês/Ano',
    year: 'Ano',
    day: 'Dia',
    genericConfirm: 'Tem certeza de que deseja continuar?',
    confirmRequestText:
      'Tem certeza de que deseja solicitar a liberação do evento?',
    fiscal: 'Fiscal',
    origin: 'Origem',
    ncm: 'Ncm',
    cest: 'Cest',
    eangtim: 'Ean/Gtim',
    cfop: 'Cfop',
    icsmCstCsosn: 'CST/CSOSN ICMS',
    icmsAliquot: 'Alíquota ICMS',
    pisCst: 'CST PIS',
    pisAliquot: 'Alíquota PIS',
    cofinsCst: 'CST COFINS',
    cofinsAliquot: 'Alíquota Cofins',
    product: 'Produto',
    associateRegister:
      'Não é possível excluir pois existem registros associados!',
    location: 'Localização',
    searchRecipient: 'Pesquisar conta',
    balance: 'Saldo',
    currentBalance: 'Current balance',
    event: 'Event',
    availableAllRecipients: 'Disponível em\n todas as contas',
    toReceiveAllRecipients: 'A receber em\n todas as contas',
    fullExtract: 'Extrato geral',
    warnings: 'Warnings',
    contract: 'Contract',
    socialContract: 'Contrato social',
    licenses: 'Licenças',
    pagarMeContractDesc:
      'Baixe aqui o contrato de solução de\n pagamentos da Pagar.me',
    downloadContract: 'Download contract',
    timeLimit: 'Horários limites',
    schedule: 'Horário',
    maximumAvailable: 'Máximo disponível',
    maximumAvailable_: 'Máximo disponível:',
    redeems: 'Resgates',
    redeemsDesc:
      'Resgates feitos até as 15h estarão disponíveis na\n sua conta no mesmo dia útil. Valores resgatados\n após as 15h, estarão disponíveis no próximo dia\n útil',
    anticipationDesc:
      'Antecipações feitas até às 11h estarão disponíveis na sua conta no mesmo dia útil. \n Valores antecipados após às 11h,\n estarão disponíveis no próximo dia útil.',
    eyebankDesc:
      'Com o Eyebank você recebe os valores de suas vendas em cartão\n de modo prático e simples.',
    configureYourBankAccount:
      'Configure sua conta bancária para seus recebimentos.',
    eyebankSecurityDesc:
      'Adicione usuários e configure permissões específicas para as suas contas',
    bank: 'Banco',
    bankName: 'Nome do banco',
    agency: 'Agência',
    digit: 'Dígito',
    addRecipientPagarmeDesc1: 'As informações serão transmitidas à ',
    pagarme: 'Pagar.me',
    addRecipientPagarmeDesc3:
      ' para análise e\n credenciamento, em casa de aprovação cadastral. O contrato de\n credenciamento pode ser consultado ',
    checkingAccount: 'Conta corrente',
    savingsAccount: 'Conta poupança',
    digitalAccount: 'Conta digital',
    here: 'aqui',
    cpf: 'Cpf',
    cpf_cnpj: 'CPF / CNPJ',
    customerCpfCnpj: 'CPF / CNPJ do consumidor',
    orChooseAnotherValue: 'ou escolha outro valor',
    agencyAbrev: 'AG',
    accountAbrev: 'CC',
    next: 'Avançar',
    back: 'Voltar',
    security: 'Security',
    addUser: 'Add user',
    selectUser: 'Select user',
    userAlreadyAdded: 'Oops! This user has already been added.',
    productGroupAlreadyAdded:
      'Oops! This product group has already been added.',
    changeRecipientStatusSuccess: 'Account successfully changed!',
    registrationInfo: 'Registration information',
    bankInfo: 'Bank informations',
    settlementInfo: 'Establishment Information',
    pointType: 'Operation type',
    independentSettlement: 'This operation is an establishment independent?',
    stateCannotBeEmptyToCheck:
      'Você precisa preencher o campo Estado para verificarmos a Inscrição Estadual',
    validStateRegistration: 'A Inscrição Estadual informada é válida!',
    nationalSimple: 'Simples Nacional',
    nationalSimpleRevenueExcess:
      'Simples Nacional - excesso de sublimite de receita bruta',
    normalRegime: 'Regime Normal',
    undefined: 'Indefinido',
    eyecashless: 'Eyecashless',
    eyeticket: 'Eyeticket',
    eyecheckout: 'Eyecheckout',
    backstageExclusive: 'Vendas exclusivas para a produção do evento',
    backstage: 'Produção',
    selectPayType: 'Selecione uma forma de pagamento',
    destination: 'Destino',
    companyLogo: 'Logotipo da empresa',
    pointImage: 'Imagem da operação',
    customerName: 'Customer name',
    customerPhone: 'Telefone do consumidor',
    customerIndentification: 'Customer indentification',
    cellphone: 'Celular',
    birthday: 'Data de nascimento',
    cabin: 'Camarote',
    optional: 'Opcional',
    validFiscalIe: 'A inscrição estadual informada é válida',
    invalidFiscalIe: 'A inscrição estadual informada é inválida',
    addParticipation: 'Add participation',
    areYouSureDeleteParticipation:
      'Tem certeza de que deseja excluir esta participação?',
    selectRecipient: 'Select a recipient',
    participationPercentage: '% da participação',
    pointOwner: 'belongs to the operation',
    doNotTransmit: "Don't transmit",
    alwaysTransmit: 'Always transmit',
    fiscalDocument: 'Fiscal document',
    transmitFiscal: 'Transmit fiscal document',
    fiscalSender: 'Fiscal document emissor',
    thisPoint: 'This point',
    fiscalType: 'Type of fiscal document',
    nfcE: 'NFC-E',
    environment: 'Environment',
    token: 'Token',
    csc: 'CSC',
    digitalCertificate: 'Digital certificate',
    upload: 'Send',
    setNumbering: 'Define numbering',
    enterPassword: 'Insira a senha',
    passwordCannotBeEmpty: 'Ops! A senha não pode ser vazia',
    lastTransmittedNfcE: 'Última NFC-e emitida',
    fiscalSequenceNumber: 'Série',
    defineNumbering: 'Define numbering',
    changeCertificate: 'Change certificate',
    validUntil: 'Valid until',
    addParticipationHint:
      'Click Add participation to include a participation in this operation',
    emptyParticipationDescription:
      'Your settings indicate the use of integrated card payment (card machine).\n However, this operation does not have any participations configured',
    emptyParticipationDescription2:
      'In this way you will not be able to receive payments through debit / credit cards \n using integration as a means of payment',
    gotItIgnoreParticipationSetting:
      'Got it. This operation will not have integration with integrated card payment',
    pagarMe: 'Pagar.me',
    passePag: 'PassePag',
    cnae: 'CNAE',
    getProvidersError: 'Could not search the list of available providers',
    getZipCodeError: 'Oops! Could not search address information',
    loadListError: 'Oops! Could not search information',
    loadNumberingListError: 'Oops! Numbering information could not be fetched',
    a920: 'A920',
    attach: 'Anexar',
    documents: 'Documents',
    document: 'Document',
    adminPartnerDocument: 'RG/CNH do sócio administrador',
    options: 'Options',
    info: 'Informations',
    ok: 'Ok',
    splitTransaction: 'Split in transaction',
    retentionOfValues: 'Retention of values',
    howDoYouWantToPayYourParticipants: 'How you want to pay the participants?',
    total: 'Total',
    generateBills: 'Gerar cobranças',
    generateCharge: 'Gerar cobrança',
    generateBill: 'Gerar boleto',
    printBill: 'Imprimir boleto',
    billToPay: 'A pagar em boleto:',
    paidBill: 'Pagamento realizado:',
    chargeHistory: 'Histórico de cobrança',
    generateChargeDesc:
      'Esse procedimento gerará cobranças em boleto\n para cada operação. Através dessa cobrança, será\n possível receber os valores de participação referente\n às formas de pagamento que não utilizam Eyepay\n (como dinheiro, por exemplo)',
    selectDate: 'Selecione uma data',
    selectDueDate: 'Selecione um vencimento',
    moneyTransferWillHappenAfterBillPayment:
      'Os repasses dos valores em cartão aos operadores\n ocorrerão após o pagamento dos boletos',
    lock: 'Bloquear',
    unlock: 'Desbloquear',
    lockAllPoints: 'Bloquear todas as operações',
    lockAllPointsConfirm:
      'Tem certeza de que deseja bloquear todas as operações?',
    unlockAllPoints: 'Desbloquear todas as operações',
    unlockAllPointsConfirm:
      'Tem certeza de que deseja desbloquear todas as operações',
    lockPoint: 'Bloquear operação',
    lockPointConfirm:
      'Tem certeza de que deseja bloquear esta operação? Ela estará impossibilitada de resgatar valores',
    unlockPoint: 'Desbloquear operação',
    unlockPointConfirm:
      'Tem certeza de que deseja desbloquear esta operação? Ela estará disponível para resgatar valores',
    insertAmount: 'Insira um valor',
    settleCharge: 'Quitar cobrança',
    settleChargeConfirm:
      'Tem certeza de que deseja quitar a cobrança para a operação?',
    export: 'Exportar',
    exportToSpreadsheet: 'Exportar para planilha',
    exportToSpreadsheetSuccess: 'Os dados foram exportados com sucesso',
    paid: 'Pago',
    pending: 'Pendente',
    description: 'Descrição',
    emission: 'Emissão',
    deadline: 'Vencimento',
    paidOn: 'Pago em',
    sector: 'Sector',
    sectors: 'Sectors',
    sectorName: 'Nome do setor',
    noSectorAdded: 'Nenhum setor adicionado',
    noProductGroupAdded: 'No product groups added',
    noPayTypeAdded: 'Nenhuma forma de pagamento adicionada',
    sectorsDescription:
      'Adicione setores a sua operação. Ao decorrer do evento você poderá filtrar os setores no Dashboard',
    sectorNameCantBeEmpty: 'Ops! O nome do setor não pode ser vazio',
    closeEvent: 'Encerrar evento',
    closeEventConfirmText: 'Tem certeza de que deseja encerrar o evento?',
    paymentMethods: 'Payment methods',
    showAdvancedFilters: 'Show advanced filters',
    selectedDisplayType: 'Forma de exibição',
    productGroup: 'Product group',
    uidTag: 'UID Tag',
    device: 'Dispositivo',
    deviceSecureID: 'Dispositivo (Secure ID)',
    table: 'Mesa',
    tableAccount: 'Mesa / Comanda',
    identification: 'Identificação',
    transmitted: 'Emitido',
    notTransmitted: 'Não emitido',
    indifferent: 'Indiferente',
    saleStatus: 'Situação da venda',
    canceled: 'Cancelado',
    notCanceled: 'Não cancelado',
    tefSale: 'Vendas com cartão',
    withEyepay: 'Com Eyepay',
    withoutEyepay: 'Sem Eyepay',
    withTax: 'Com taxa',
    withoutTax: 'Sem taxa',
    customerGender: 'Gênero do consumidor',
    male: 'Masculino',
    female: 'Feminino',
    others: 'Outros',
    other: 'Outros',
    uninformed: 'Não informado',
    pdf: 'Pdf',
    sheet: 'Planilha',
    operator: 'Operador',
    customer: 'Consumidor',
    doNotApply: 'Não se aplica',
    clear: 'Clear',
    selectGrouping: 'Selecione um agrupamento',
    transactionDateHour: 'Data / Hora da venda',
    username: 'Nome do usuário',
    cashierIndentification: 'Identificação do caixa',
    cashierId: 'Caixa (ID)',
    receipt: 'Comprovante',
    transactionCancelDate: 'Data de cancelamento',
    customerRegistration: 'Identificador do consumidor (Matrícula)',
    transactionDetails: 'Detalhes da transação',
    price: 'Preço',
    cardBrand: 'Bandeira',
    fileType: 'Tipo de arquivo',
    skuOptional: 'SKU (Opcional)',
    Un: 'Un',
    Kg: 'Kg',
    L: 'L',
    saleXml: 'XML de venda',
    cancelXml: 'XML de cancelamento',
    cancelTransaction: 'Cancelar transação',
    reactivateTransaction: 'Reativar transação',
    yourFileIsBeingProcessed: 'O seu arquivo está sendo preparado...',
    discount: 'Discount',
    pointFunction: 'Point function',
    backstageSalesOnly: 'Exclusive sales for the production of the event',
    payTypeWillBeSetUpAutomatically:
      'As formas de pagamento serão configuradas automaticamente',
    searchImage: 'Search image',
    uploadPhoto: 'Enviar foto',
  },
  topHeader: {
    eye: 'EYEMOBILE',
  },
  options: {
    today: 'Today',
    hour: 'Hour',
    thisWeek: 'This week',
    thisMonth: 'This month',
    thisYear: 'This year',
    otherPeriod: 'Other period',
    dayOfWeek: 'Day of week',
    dayOfMonth: 'Day of month',
    dayOfPeriod: 'Day of period',
    weekOfMonth: 'Week of month',
    all: 'All',
    products: 'products',
  },
  main: {
    total: 'TOTAIS',
    totalSingle: 'TOTAL',
    amount: 'VALOR TOTAL',
    outstandingAmount: 'VALOR TOTAL EM ABERTO',
    services: 'SERVIÇOS',
    expensesXincome: 'DESPESAS X RECEITAS',
    recipes: 'RECEITAS',
    expenses: 'DESPESAS',
    commands: 'COMANDAS',
    products: 'PRODUTOS',
    shifts: 'CAIXAS / TURNOS',
    points: 'POINTS',
    telemetries: 'DISPOSITIVOS',
    groupProducts: 'PRODUCT GROUP',
    amountPaidCommands: 'VALOR PAGO PELAS COMMANDAS',
    pendingValue: 'VALOR PENDENTE',
    pendingValues: 'Valores pendentes',
    busyCards: 'COMANDAS OCUPADAS',
    serviceChargePercent: 'Service charge',
    serviceIncluded: 'Taxa de serviço inclusa no total',
    serviceNotIncluded: 'Taxa de serviço não inclusa no total',
    totalOpen: 'TOTAL ABERTOS',
    lastSyncAtTime: 'SINCRONIZADOS A MENOS TEMPO',
    aZ: 'A - Z',
    high: 'Bateria carregada',
    average: 'Em bom estado',
    low: 'Bateria baixa',
    critical: 'Bateria crítica',
    residual: 'RESIDUAL',
    amountSale: 'BAIXA DE PRODUTOS',
    infoTag:
      'Os valores agrupados por forma de pagamento não contabilizam venda de tag',
    details: 'DETALHES',
    saleCredit: 'VENDA DE CRÉDITOS',
    saleTicket: 'VENDA DE TICKET',
    returnOfResidualBalance: 'DEVOLUÇÃO DE SALDO RESIDUAL',
    tags: 'TAGs',
    tagsReleased: 'TAGs LIBERADAS',
    tagsReturned: 'TAGs DEVOLVIDAS',
    productionTags: 'TAGs PRODUÇÃO',
    productionTagsReleased: 'TAGs LIBERADAS PARA PRODUÇÃO',
    productionTagsReturned: 'TAGs DEVOLVIDAS PARA PRODUÇÃO',
    balanceTag: 'SALDO DE TAGs',
    tagsNotReturned: 'TAGs NÃO DEVOLVIDAS',
    valueForReturn: 'VALOR PARA DEVOLUÇÃO',
    creditProduction: 'CRÉDITO PARA PRODUÇÃO',
    debitProduction: 'CONSUMO DE PRODUÇÃO',
    residualProduction: 'RESIDUAL DE PRODUÇÃO',
    otherInfo: 'OUTRAS INFORMAÇÕES',
    ticketAverage: 'TICKET MÉDIO',
    eyeplace: 'EYEPLACE',
    selectDate:
      'Selecione uma data de início e uma data final para exibir resultados',
    key: 'item',
    key_plural: 'items',
    events: 'Events',
    draft: 'Testes',
    statistics: 'Statistics',
    loadProductListError:
      'Ops! Ocorreu um erro ao buscarmos a lista de produtos',
    tagBlacklistingWarning:
      'A tag<1></1><2>{{tag}}</2><3></3>será bloqueada.<5></5><6></6>Essa ação não poderá ser desfeita.<8></8>Deseja continuar?',
  },
  roles: {
    1000: 'Administrador de sistema',
    1100: 'Suporte',
    1200: 'Financeiro',
    2000: 'Distribuidor',
    2100: 'Técnico',
    3000: 'Cliente (Administrador)',
    3100: 'Cliente (resultados)',
    3200: 'Gestor de operações',
    3210: 'Operador de atendimento',
    3220: 'Operador de venda',
    3230: 'Operador de estoque',
    no_role: 'Sem função definida',
  },
  status: {
    1: 'Em aprovação',
    2: 'Reprovado',
    3: 'Configuração',
    4: 'Em andamento',
    5: 'Encerrado',
    6: 'Em faturamento',
  },
  paymentFrequency: {
    1: 'Diária',
    2: 'Mensal',
    3: 'Anual',
    4: 'Esporádica',
  },
  button: {
    notNow: 'Agora não',
  },
  single: {
    participation: 'Participation',
  },
  breadcrumb: {
    events: 'Events',
    timeline: 'Home',
    users: 'Users',
    pointForm: 'Points',
    products: 'Products',
    menu: 'Products list',
    accounts: 'Lista de comandas',
    accountItem: 'Comandas',
    eyeplace: 'Eyeplace',
    points: 'Points',
    setup: 'Setup',
    eyebank: 'Eyebank',
    fiscal: 'Fiscal',
    statistics: 'Statistics',
  },
  transactionType: {
    purchase: 'Venda de crédito/ticket',
    cashOut: 'Retirada',
    cashIn: 'Suprimento',
    cashBack: 'Devolução',
    sale: 'Baixa de produto',
    newTag: 'Liberação de tag',
    returnTag: 'Devolução de tag',
    transferFrom: 'Transferência (origem)',
    transferTo: 'Transferência (destino)',
    employeeIn: 'Pagamento de funcionário',
    employeeOut: 'Saque de funcionário',
    backstagePurchase: 'Produção',
    backstageSale: 'Consumo de produção',
    backstageNewTag: 'Liberação p/ produção',
    backstageReturnTag: 'Devolução tag produção',
    order: 'Pedido',
  },
  key: 'item',
  key_plural: 'items',
  formErrors: {
    youNeedToFillAllFields: 'Ops! Você precisa preencher todos os campos',
    youNeedToFillPointName: 'Ops! Você precisa preencher o nome da operação',
    youNeedToFillPointLabel:
      'Ops! Você precisa preencher o nome abreviado da operação ',
    youNeedToFillAccount:
      'Ops! Você precisa selecionar uma lista de comandas para continuar',
    youNeedToFillLayout: 'Ops! Você precisa preencher o layout de impressão',
    youNeedToFillServiceCharge: 'Ops! Você precisa preencher a taxa de serviço',
    youNeedToFillDiscount: 'Ops! Você precisa preencher o desconto',
    youNeedToFillZipCode: 'Ops! Você precisa preencher o CEP',
    youNeedToFillAddress: 'Ops! Você precisa preencher o endereço',
    youNeedToFillDistrict: 'Ops! Você precisa preencher o bairro',
    youNeedToFillCity: 'Ops! Você precisa preencher a cidade',
    youNeedToFillState: 'Ops! Você precisa preencher o estado',
    youNeedToFillPhone: 'Ops! Você precisa preencher o telefone',
    youNeedToFillFiscalDocument: 'Ops! Você precisa preencher o documento',
    youNeedToFillFiscalName: 'Ops! Você precisa preencher a razão social',
    youNeedToFillFiscalLabel: 'Ops! Você precisa preencher o nome fantasia',
    youNeedToFillFiscalIm: 'Ops! Você precisa preencher a inscrição municipal',
    youNeedToFillCrt: 'Ops! Você precisa preencher o regime tributário',
    youNeedToFillSettlementInfo:
      'Ops! Você precisa preencher as informações do estabelecimento pois esta operação irá emitir documento fiscal',
    youNeedToFillStateRegistration:
      'Ops! Você precisa preencher a inscrição estadual',
    youNeedToFillCsc: 'Ops! Você precisa preencher o CSC',
    youNeedToFillFiscalToken: 'Ops! Você precisa preencher o token fiscal',
    youNeedToFillParticipationPercentage:
      'Ops! Você precisa preencher a porcentagem da participação',
    youNeedToFillRecipient: 'Ops! Você precisa selecionar um recebedor',
    invalidStateRegistration:
      'A Inscrição Estadual informada não é válida, verifique o seu número ou o estado informado',
    totalParticipationError:
      'Ops! O total das participações não podem ultrapassar 100%',
    participationPercentageError:
      'Ops! A porcentagem da participação não pode ultrapassar 100%',
    participationPercentageFormatError: 'Ops! A porcentagem não é válida',
    participationPayTaxesError:
      'Ops! Já existe uma participação responsável pelas taxas',
    participationPointOwnerError:
      'Ops! Já existe uma participação pertencente à operação',
    participationNoPayTaxesError:
      'Ops! Pelo menos um recebedor deve ser responsável pelas taxas',
    participationNoPointOwnerError:
      'Ops! Pelo menos um recebedor deve percenter à operação',
    participationAlreadyAdded: 'Ops! Este recebedor já foi adicionado',
    payTypeAlreadyAdded: 'Ops! Esta forma de pagamento já foi adicionada',
    userAlreadyAdded: 'Ops! Este usuário já foi adicionado',
    invalidFiscalSequenceNumber:
      'Ops! A numeração da última nota fiscal emitida deve ser maior que zero',
    invalidFiscalSequenceSerie:
      'Ops! A série da nota fiscal deve ser maior que zero',
    invalidFiscalCertificate:
      'Ops! É necessário enviar um certificado para emissao de notas fiscais',
  },
};
