import { withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';

const WizardConnector = withStyles((theme) => ({
  root: {
    flex: 0,
    margin: '-2px 23px',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      margin: '-3px -10px',
    },
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    width: 4,
    border: 0,
    backgroundColor: '#eaeaf0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 4,
    },
  },
}))(StepConnector);

export default WizardConnector;
