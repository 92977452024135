import React from 'react';
import InputMask from 'react-text-mask';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormikValidationIcon from './FormikValidationIcon';

const FormikInput = ({
  mask,
  field,
  suffix,
  inputIcon,
  hideIcons,
  iconOffset,
  hideErrors,
  errorStyle,
  wrapperStyle,
  normalizeValue,
  containerClassName,
  inputWrapperStyle,
  onBlur,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className={containerClassName} style={wrapperStyle}>
      <div style={inputWrapperStyle}>
        {inputIcon}
        {mask ? (
          <InputMask
            mask={mask}
            {...field}
            {...props}
            onChange={({ target }) => {
              const newValue = normalizeValue
                ? normalizeValue(target.value)
                : target.value;
              setFieldValue(field.name, newValue);
            }}
          />
        ) : (
          <div className="flex align-i-center" style={{ width: '100%' }}>
            <input
              {...field}
              {...props}
              onBlur={(e) => {
                field.onBlur(e);
                onBlur({
                  isTouched: touched[field.name],
                  hasError: typeof errors[field.name] === 'string',
                  value: field.value,
                });
              }}
              onChange={({ target }) => {
                const newValue = normalizeValue
                  ? normalizeValue(target.value)
                  : target.value;
                setFieldValue(field.name, newValue);
              }}
            />
          </div>
        )}
        {!hideIcons && touched[field.name] && (
          <FormikValidationIcon
            errors={errors[field.name]}
            iconOffset={iconOffset}
          />
        )}
      </div>
      {!hideErrors && touched[field.name] && errors[field.name] && (
        <div
          style={{
            color: '#FF4F65',
            fontSize: 14,
            ...errorStyle,
          }}
        >
          {t(errors[field.name])}
        </div>
      )}
    </div>
  );
};

FormikInput.defaultProps = {
  mask: false,
  hideIcons: false,
  suffix: undefined,
  hideErrors: false,
  inputIcon: undefined,
  iconOffset: undefined,
  wrapperStyle: undefined,
  normalizeValue: undefined,
  inputWrapperStyle: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
  },
  onBlur: () => {},
  containerClassName: undefined,
  errorStyle: undefined,
};

FormikInput.propTypes = {
  hideIcons: PropTypes.bool,
  inputIcon: PropTypes.node,
  hideErrors: PropTypes.bool,
  normalizeValue: PropTypes.func,
  mask: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.func]),
  suffix: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
  iconOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperStyle: PropTypes.object,
  inputWrapperStyle: PropTypes.object,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
  }).isRequired,
  onBlur: PropTypes.func,
  containerClassName: PropTypes.string,
  errorStyle: PropTypes.object,
};

export default FormikInput;
