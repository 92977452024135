import styled from '@emotion/styled';

const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  z-index: ${({ isProcessing }) => (isProcessing ? '9999' : '-1')};
  opacity: ${({ isProcessing }) => (isProcessing ? '1' : '0')};
  transition: ${({ isProcessing }) =>
    isProcessing
      ? 'opacity 0.5s 0.5s, z-index: 0.5s'
      : 'opacity 0.5s, z-index: 0.5s 0.5s'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
`;

export default LoadingWrapper;
