import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import CheckBoxField from '../../components/CheckBoxField';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: '1px solid #EDEFF0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #EDEFF0',
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}))((props) => {
  const { classes, onClose, onSubmit, submitting, ...other } = props;

  return (
    <MuiDialogActions className={classes.root} {...other}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onClose}
        className={classes.cancel}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        className={classes.confirm}
        endIcon={
          submitting ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <CheckIcon />
          )
        }
      >
        Confirmar
      </Button>
    </MuiDialogActions>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const CloneProposalSchema = Yup.object().shape({
  copy_fields: Yup.bool().required('Campo obrigatório'),
});

export default function CloneProposalDialog({
  open,
  onClose,
  onSubmit,
}) {
  const params = useParams();
  const history = useHistory();
  const formRef = React.useRef();

  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Clonar proposta
      </DialogTitle>
      <DialogContent>
        <Box p={4} pt={4} pb={4} display="flex" flexDirection="column">
          <Box mb={2} display="flex" flexDirection="column">
            <Typography variant="body1">
              Confirmar clone da proposta?
            </Typography>
          </Box>
          <Formik
            innerRef={formRef}
            initialValues={{
              copy_fields: false,
            }}
            validationSchema={CloneProposalSchema}
            onSubmit={async (values) => {
              setSubmitting(true);

              try {
                const response = await gateway.post(
                  `/billing/proposals/${params.id}/clone`,
                  values,
                );
            
                history.push(`/proposals/${response.data.proposal.id}`);
                onSubmit();
              } catch (err) {
                console.error(err);
                if (
                  err.response.status === 400 &&
                  err.response.data &&
                  err.response.data.message
                ) {
                  showError(err.response.data.message);
                } else {
                  showError('Falha ao clonar proposta');
                }
              } finally {
                setSubmitting(false);
              }
            }}
            render={() => {
              return (
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="copy_fields"
                        label="Copiar dados já preenchidos para o clone"
                        component={CheckBoxField}
                      />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        onClose={onClose}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Dialog>
  );
}
