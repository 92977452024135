import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CropFreeIcon from '@material-ui/icons/CropFree';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import moment from 'moment';

import ChargeStatus from '../../components/ChargeStatus';
import SelectField from '../../components/SelectField';
import DateField from '../../components/DateField';
import CheckBoxField from '../../components/CheckBoxField';
import EditChargeDialog from './EditChargeDialog';

import chargeTypes from '../../constants/chargeTypes';
import gateway from '../../utils/gateway';
import { showError, showSuccess } from '../../utils/toast';

const COLUMNS = [
  { id: 'id', label: '#' },
  { id: 'description', label: 'DESCRIÇÃO' },
  { id: 'status', align: 'center', label: 'STATUS' },
  { id: 'spot', align: 'center', label: 'AVULSA' },
  { id: 'contract', label: 'CONTRATO' },
  { id: 'type', label: 'FORMA DE PGTO.' },
  { id: 'charge_at', label: 'VENCIMENTO' },
  { id: 'paid_at', label: 'PAGO EM' },

  {
    id: 'total',
    align: 'right',
    label: 'TOTAL',
  },
  { id: 'voucher', align: 'center', label: '' },
  { id: 'pix', align: 'center', label: '' },
];

const CONTRACT_COLUMNS = [
  {
    id: 'product',
    label: 'PRODUTO',
  },
  { id: 'quantity', label: 'QTD.' },
  { id: 'price', label: 'PREÇO' },
  { id: 'discount', label: 'DESC.' },
  { id: 'total', align: 'right', label: 'TOTAL' },
];

const paymentProvidersMap = {
  0: 'OUTRO',
  1: 'IUGU',
};

const ItemsTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: '8px 16px',
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const ButtonTableCell = withStyles(() => ({
  root: {
    width: 1,
    padding: 0,
    border: 0,
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  image: {
    borderRadius: 16,
    width: 52,
    height: 52,
  },
}));

const ChargesTab = ({ show }) => {
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [startsAt, setStartsAt] = React.useState(null);
  const [endsAt, setEndsAt] = React.useState(null);
  const [status, setStatus] = React.useState(-1);
  const [recurrentOnly, setRecurrentOnly] = React.useState(false);
  const [spotOnly, setSpotOnly] = React.useState(false);
  const [filters, setFilters] = React.useState({
    startsAt: null,
    endsAt: null,
    status: -1,
    recurrentOnly: false,
    spotOnly: false,
  });

  const [charge] = React.useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [charges, setCharges] = React.useState([]);
  const [showEditChargeDialog, setShowEditChargeDialog] = React.useState(false);
  const [total, setTotal] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchCharges = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(
        `/billing/tenants/${params.id}/charges`,
        {
          params: {
            ...(filters.status === -1
              ? { status: [0, 1, 2, 3, 4, 6] }
              : { status: [filters.status] }),
            ...(filters.startsAt !== null
              ? { starts_at: filters.startsAt }
              : {}),
            ...(filters.endsAt !== null ? { ends_at: filters.endsAt } : {}),
            ...(filters.recurrentOnly ? { recurrent_only: true } : {}),
            ...(filters.spotOnly ? { spot_only: true } : {}),
            limit: rowsPerPage,
            offset: page * rowsPerPage,
          },
        },
      );

      setCharges(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      console.error(err);
      showError();
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (show) {
      fetchCharges();
    }
  }, [show, rowsPerPage, page, filters]);

  if (!show) {
    return null;
  }

  const getPaymentMethod = (provider, type) => {
    if (provider === 1 && type === 2) {
      return `IUGU`;
    }

    return `${paymentProvidersMap[provider]}/${chargeTypes[type]}`;
  };

  return (
    <>
      <Fade in>
        <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <Box pr={4} width={200}>
              <DateField
                name="startsAt"
                label="Início"
                placeholder="DD/MM/YYYY"
                format="DD/MM/YYYY"
                variant="standard"
                disableToolbar
                value={startsAt}
                onChange={(e) => {
                  setStartsAt(e.target.value);
                }}
              />
            </Box>
            <Box pr={4} width={200}>
              <DateField
                name="endsAt"
                label="Fim"
                placeholder="DD/MM/YYYY"
                format="DD/MM/YYYY"
                variant="standard"
                disableToolbar
                value={endsAt}
                onChange={(e) => {
                  setEndsAt(e.target.value);
                }}
              />
            </Box>
            <Box pr={4} width={160}>
              <SelectField
                name="status"
                label="Status"
                placeholder="Status"
                options={[
                  { label: 'Todas', value: -1 },
                  { label: 'Pendentes', value: 0 },
                  { label: 'Pagas', value: 1 },
                  { label: 'Processando', value: 2 },
                  { label: 'Atrasadas', value: 3 },
                  { label: 'Canceladas', value: 4 },
                  { label: 'Expiradas', value: 6 },
                ]}
                variant="standard"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              />
            </Box>
            <Box pr={4}>
              <CheckBoxField
                name="recurrentOnly"
                label="Apenas recorrentes"
                checked={recurrentOnly}
                onChange={(e) => {
                  setRecurrentOnly(e.target.checked);
                }}
              />
            </Box>
            <Box pr={4}>
              <CheckBoxField
                name="spotOnly"
                label="Apenas avulsas"
                checked={spotOnly}
                onChange={(e) => {
                  setSpotOnly(e.target.checked);
                }}
              />
            </Box>
            <Box pr={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  setFilters({
                    startsAt,
                    endsAt,
                    status,
                    recurrentOnly,
                    spotOnly,
                  })
                }
              >
                Buscar
              </Button>
            </Box>
          </Box>
          <Box pt={4}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((column) => (
                      <ItemsTableCell
                        key={column.id}
                        align={column.align ? column.align : 'left'}
                      >
                        <Typography variant="subtitle2" color="primary">
                          {column.label}
                        </Typography>
                      </ItemsTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading &&
                    [1, 2, 3].map((value) => (
                      <TableRow tabIndex={-1} key={value}>
                        <ItemsTableCell />
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell align="center">
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell align="center">
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell>
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell align="right">
                          <Skeleton variant="text" />
                        </ItemsTableCell>
                        <ItemsTableCell />
                        <ItemsTableCell />
                      </TableRow>
                    ))}
                  {!loading &&
                    charges.map((row) => {
                      const formattedTotal = Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(row.total);

                      return (
                        <React.Fragment key={row.id}>
                          <ItemsTableRow>
                            {/* <ButtonTableCell align="center">
                              {row.contract === null &&
                                row.paymentProvider === 1 && (
                                  <IconButton
                                    aria-label="expand"
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (charge && charge.id === row.id) {
                                        setCharge(null);
                                      } else {
                                        setCharge(row);
                                      }
                                    }}
                                  >
                                    {charge && charge.id === row.id ? (
                                      <ExpandLessIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </IconButton>
                                )}
                            </ButtonTableCell> */}

                            <ItemsTableCell
                              sx={{ textDecoration: 'underline' }}
                              onClick={() => {
                                history.push(
                                  `/tenants/${params.id}/charges/${row.id}`,
                                );
                              }}
                            >
                              <Typography variant="subtitle2">
                                {row.id}
                              </Typography>
                            </ItemsTableCell>
                            <ItemsTableCell align="center">
                              {row.description}
                            </ItemsTableCell>
                            <ItemsTableCell align="center">
                              <ChargeStatus size="small" status={row.status} />
                            </ItemsTableCell>
                            <ItemsTableCell align="center">
                              {!row.recurrent && (
                                <CheckCircleIcon color="primary" />
                              )}
                            </ItemsTableCell>
                            <ItemsTableCell>
                              {row.contract !== null && (
                                <Typography
                                  variant="subtitle2"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(
                                      `/tenants/${params.id}/contracts/${row.contract.id}`,
                                    );
                                  }}
                                >
                                  {`#${row.contract.id}`}
                                </Typography>
                              )}
                            </ItemsTableCell>
                            <ItemsTableCell>
                              {getPaymentMethod(row.payment_provider, row.type)}
                            </ItemsTableCell>

                            <ItemsTableCell>
                              {moment(row.charge_at).format('DD/MM/YYYY')}
                            </ItemsTableCell>
                            <ItemsTableCell>
                              {row.paid_at
                                ? moment(row.paid_at).format('DD/MM/YYYY HH:mm')
                                : ''}
                            </ItemsTableCell>

                            <ItemsTableCell align="right">
                              {formattedTotal}
                            </ItemsTableCell>
                            <ButtonTableCell align="center">
                              {row.charge_payment &&
                                row.payment_provider === 1 &&
                                row.external_id !== null && (
                                  <IconButton
                                    aria-label="invoice"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window.open(
                                        row.metadata?.invoiceUrl,
                                        '_blank',
                                      );
                                    }}
                                  >
                                    <VisibilityOutlinedIcon />
                                  </IconButton>
                                )}
                            </ButtonTableCell>

                            <ButtonTableCell align="center">
                              {row.charge_payment &&
                                row.payment_provider !== 1 &&
                                row.charge_payment.voucher_url !== null && (
                                  <IconButton
                                    aria-label="voucher"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window.open(
                                        row.charge_payment.voucher_url,
                                        '_blank',
                                      );
                                    }}
                                  >
                                    <OpenInNewIcon />
                                  </IconButton>
                                )}
                            </ButtonTableCell>
                            <ButtonTableCell align="center">
                              {row.charge_payment &&
                                row.charge_payment.pix_qrcode_image !== null &&
                                row.payment_provider !== 1 && (
                                  <IconButton
                                    aria-label="pix"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigator.clipboard.writeText(
                                        row.charge_payment.pix_qrcode,
                                      );
                                      showSuccess(
                                        'O código do PIX foi copiado para a área de transferência.',
                                      );
                                    }}
                                  >
                                    <CropFreeIcon />
                                  </IconButton>
                                )}
                            </ButtonTableCell>
                          </ItemsTableRow>
                          {charge && charge.id === row.id && (
                            <ItemsTableRow>
                              <ItemsTableCell />
                              <ItemsTableCell colSpan={COLUMNS.length - 3}>
                                <Fade in>
                                  <Box
                                    mb={4}
                                    p={2}
                                    bgcolor="#F0F0F0"
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    <TableContainer>
                                      <Table
                                        aria-labelledby="tableTitle"
                                        size="medium"
                                        aria-label="enhanced table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            {CONTRACT_COLUMNS.map((column) => (
                                              <ItemsTableCell
                                                key={column.id}
                                                align={
                                                  column.align
                                                    ? column.align
                                                    : 'left'
                                                }
                                              >
                                                <Typography
                                                  variant="subtitle2"
                                                  color="primary"
                                                >
                                                  {column.label}
                                                </Typography>
                                              </ItemsTableCell>
                                            ))}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.proposal.proposal_items.map(
                                            (row) => {
                                              const formattedDiscount = Intl.NumberFormat(
                                                'pt-BR',
                                                {
                                                  style: 'currency',
                                                  currency: 'BRL',
                                                },
                                              ).format(row.discount);

                                              const formattedPrice = Intl.NumberFormat(
                                                'pt-BR',
                                                {
                                                  style: 'currency',
                                                  currency: 'BRL',
                                                },
                                              ).format(row.price);

                                              const formattedTotal = Intl.NumberFormat(
                                                'pt-BR',
                                                {
                                                  style: 'currency',
                                                  currency: 'BRL',
                                                },
                                              ).format(row.total);

                                              let measure = '';
                                              if (row.measure === 1) {
                                                measure = ' / mês';
                                              } else if (row.measure === 2) {
                                                measure = ' / hora';
                                              } else if (row.measure === 3) {
                                                measure = ' / usuário';
                                              } else if (row.measure === 4) {
                                                measure = ' / usuário / mês';
                                              } else if (row.measure === 5) {
                                                measure = ' / ano';
                                              } else if (row.measure === 6) {
                                                measure = ' / usuário / ano';
                                              }

                                              let recurrency = '';
                                              if (row.recurrency_type === 1) {
                                                recurrency = ' / mês';
                                              } else if (
                                                row.recurrency_type === 2
                                              ) {
                                                recurrency = ' / ano';
                                              }

                                              return (
                                                <TableRow
                                                  key={row.id}
                                                  tabIndex={-1}
                                                >
                                                  <ItemsTableCell>
                                                    <Box
                                                      display="flex"
                                                      alignItems="center"
                                                    >
                                                      <Box mr={2}>
                                                        <img
                                                          src={
                                                            row.contract_product
                                                              .image
                                                          }
                                                          alt={row.name}
                                                          className={
                                                            classes.image
                                                          }
                                                        />
                                                      </Box>
                                                      <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                      >
                                                        <Typography>
                                                          {row.name}
                                                        </Typography>
                                                      </Box>
                                                    </Box>
                                                  </ItemsTableCell>
                                                  <ItemsTableCell>
                                                    <Typography>
                                                      {row.quantity}
                                                    </Typography>
                                                  </ItemsTableCell>
                                                  <ItemsTableCell>
                                                    <Typography>
                                                      {formattedPrice + measure}
                                                    </Typography>
                                                  </ItemsTableCell>
                                                  <ItemsTableCell>
                                                    {row.discount > 0 &&
                                                      row.discount_type ===
                                                        0 && (
                                                        <Typography>
                                                          {row.discount}%
                                                        </Typography>
                                                      )}
                                                    {row.discount > 0 &&
                                                      row.discount_type ===
                                                        1 && (
                                                        <Typography>
                                                          {formattedDiscount}
                                                        </Typography>
                                                      )}
                                                  </ItemsTableCell>
                                                  <ItemsTableCell align="right">
                                                    <Typography>
                                                      {formattedTotal +
                                                        recurrency}
                                                    </Typography>
                                                  </ItemsTableCell>
                                                </TableRow>
                                              );
                                            },
                                          )}
                                          {row.proposal.proposal_items
                                            .length === 0 && (
                                            <TableRow>
                                              <ItemsTableCell
                                                colSpan={5}
                                                align="center"
                                              >
                                                <Box p={2}>
                                                  <Typography>
                                                    Nenhum item encontrado
                                                  </Typography>
                                                </Box>
                                              </ItemsTableCell>
                                            </TableRow>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Box>
                                </Fade>
                              </ItemsTableCell>
                              <ItemsTableCell />
                              <ItemsTableCell />
                            </ItemsTableRow>
                          )}
                        </React.Fragment>
                      );
                    })}
                  {!loading && charges.length === 0 && (
                    <TableRow>
                      <ItemsTableCell colSpan={COLUMNS.length} align="center">
                        <Box m={4}>
                          <Typography>Nenhuma cobrança encontrada</Typography>
                        </Box>
                      </ItemsTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Resultados por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} / ${count}`
              }
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      </Fade>
      <EditChargeDialog
        open={showEditChargeDialog}
        onClose={() => {
          setShowEditChargeDialog(false);
        }}
        onSubmit={() => {
          setShowEditChargeDialog(false);
          fetchCharges();
        }}
        tenantId={params.id}
      />
    </>
  );
};

export default ChargesTab;
