import React from 'react';
import { useParams } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

import Wizard from '../../components/Wizard/Wizard';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import TenantStep from './Steps/TenantStep';
import ItemsStep from './Steps/ItemsStep';
import ProposalStep from './Steps/ProposalStep';
import ReviewStep from './Steps/ReviewStep';
import organizationRelationship from '../../constants/organizationRelationship';
import billingOwner from '../../constants/billingOwner';

const EditProposalWizard = () => {
  const params = useParams();

  const [loading, setLoading] = React.useState(true);
  const [proposal, setProposal] = React.useState({});
  const [isOrganizationParent, setIsOrganizationParent] = React.useState(false);

  const fetchProposal = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(`/billing/proposals/${params.id}`);

      const { proposal } = response.data;
      const items = proposal.proposal_items.map((item) => ({
        ...item,
        contract_product: {
          ...item.contract_product,
          value: item.contract_product.id,
          label: item.contract_product.name,
        },
        observations: item.observations
          ? JSON.stringify(convertToRaw(stateFromHTML(item.observations)))
          : '',
      }));
      const proposalData = {
        ...proposal,
        proposal_items: items,
        deal_key:
          proposal.deal_key !== null
            ? {
                value: proposal.deal_key,
                label: proposal.name,
              }
            : null,
        organization_relationship:
          proposal?.organization_member?.type === 'child'
            ? organizationRelationship.child
            : proposal?.organization_member?.organization?.type ||
              organizationRelationship.business,
        organization_name:
          proposal?.organization_member?.organization?.name || '',
        billing_owner:
          proposal?.organization_member?.type === 'parent'
            ? billingOwner.associates
            : billingOwner.self,
        organization_id: proposal?.organization_member?.organization_id
          ? {
              value: String(proposal?.organization_member?.organization_id),
              label: proposal?.organization_member?.organization?.name,
            }
          : null,
        observations: proposal.observations
          ? JSON.stringify(convertToRaw(stateFromHTML(proposal.observations)))
          : '',
      };

      setIsOrganizationParent(proposal?.organization_member?.type === 'parent');
      setProposal(proposalData);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchProposal();
  }, []);

  if (loading) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={84} />
      </Box>
    );
  }

  return (
    <Fade in>
      <Wizard context={proposal}>
        <TenantStep
          label="Selecionar Cliente"
          guided
          edit
          isOrganizationParent={isOrganizationParent}
        />
        <ItemsStep
          label="Adicionar Itens"
          spot={proposal && proposal.type === 1}
          recurrent={proposal && (proposal.type === 2 || proposal.type === 3)}
        />
        <ProposalStep label="Proposta" />
        <ReviewStep
          label="Revisão"
          update
          first={proposal && proposal.type === 0}
          spot={proposal && proposal.type === 1}
          amendment={proposal && proposal.type === 2}
          contract={proposal && proposal.type === 3}
        />
      </Wizard>
    </Fade>
  );
};

export default EditProposalWizard;
