import React from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { default as MuiTable } from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';

import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import CheckBoxField from '../../components/CheckBoxField';
import Autocomplete from '../../components/Autocomplete';
import ContractType from '../../components/ContractType';
import DateTimeField from '../../components/DateTimeField';
import ProposalStatus from '../../components/ProposalStatus';

import { PermissionContext } from '../../contexts/PermissionContext';
import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

const columns = [
  {
    id: 'tenant',
    label: 'NOME',
  },
  { id: 'type', align: 'center', label: 'TIPO' },
  { id: 'revision', align: 'right', label: 'REVISÃO' },
  { id: 'partner', label: 'REVENDA' },
  { id: 'user', label: 'CRIADA POR' },
  { id: 'status', align: 'center', label: 'STATUS' },
  {
    id: 'createdAt',
    align: 'right',
    label: 'CRIADA EM',
  },
];

const ItemsTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: '8px 16px',
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const ProposalsPage = () => {
  const history = useHistory();

  const { authorized } = React.useContext(PermissionContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [proposals, setProposals] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const [startsAt, setStartsAt] = React.useState(null);
  const [endsAt, setEndsAt] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [status, setStatus] = React.useState(-1);
  const [cancelled, setCancelled] = React.useState(false);
  const [partner, setPartner] = React.useState(
    authorized('partners.view')
      ? {
          value: '1',
          label: 'EYEMOBILE Matriz',
        }
      : null,
  );

  const [filters, setFilters] = React.useState({
    search: '',
    startsAt: null,
    endsAt: null,
    status: -1,
    cancelled: false,
    partner,
  });

  const handleClick = (id) => {
    history.push(`/proposals/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setFilters({
      startsAt,
      endsAt,
      search,
      status,
      cancelled,
      partner,
    });
  };

  const fetchProposals = async () => {
    setLoading(true);

    try {
      let status =
        filters.status === -1 ? [0, 1, 2, 3, 4, 5, 7, 8] : [filters.status];
      if (filters.cancelled) {
        status = [...status, 6];
      }

      const response = await gateway.get('/billing/proposals', {
        params: {
          ...(filters.search.length > 0 ? { search: filters.search } : {}),
          status,
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          ...(filters.startsAt !== null ? { starts_at: filters.startsAt } : {}),
          ...(filters.endsAt !== null ? { ends_at: filters.endsAt } : {}),
          ...(filters.partner
            ? { partner_id: filters.partner.value }
            : undefined),
        },
      });

      setProposals(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPartners = async (search) => {
    let options = [];
    try {
      const response = await gateway.get('/billing/partners', {
        params: {
          search,
          offset: 0,
          limit: 100,
        },
      });

      options = response.data.data.map((partner) => ({
        value: partner.id,
        label: partner.name,
      }));
    } catch (err) {
      console.error(err);
      showError();
    }

    return options;
  };

  React.useEffect(() => {
    fetchProposals();
  }, [rowsPerPage, page, filters]);

  return (
    <Fade in>
      <Box flex={1}>
        <Paper square elevation={2}>
          <Box pt={6} p={4}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h4">Propostas</Typography>
              {authorized('proposals.add') && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => history.push('/proposals/contract')}
                >
                  Nova proposta
                </Button>
              )}
            </Box>
            <Box pt={4} display="flex" alignItems="center">
              <Box pr={4}>
                <TextField
                  name="search"
                  label="Pesquisar"
                  placeholder=""
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="inherit" />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />
              </Box>
              <Box pr={4} width={160}>
                <SelectField
                  name="status"
                  label="Status"
                  placeholder="Status"
                  options={[
                    { label: 'Todos', value: -1 },
                    { label: 'Pendentes de aceitação', value: 0 },
                    { label: 'Pendentes de pagamento', value: 1 },
                    { label: 'Recusadas', value: 2 },
                    { label: 'Processando pagamento', value: 3 },
                    { label: 'Confirmadas', value: 4 },
                    { label: 'Expiradas', value: 5 },
                    { label: 'Pendentes de assinatura', value: 7 },
                    { label: 'Pendentes de formulário', value: 8 },
                  ]}
                  variant="standard"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                />
              </Box>
              <Box pr={4}>
                <CheckBoxField
                  name="cancelled"
                  label="Exibir canceladas"
                  checked={cancelled}
                  onChange={(e) => {
                    setCancelled(e.target.checked);
                  }}
                />
              </Box>
              {authorized('partners.view') && (
                <Box pr={4}>
                  <Autocomplete
                    name="partner"
                    label="Revenda"
                    placeholder="Revenda"
                    fetch={fetchPartners}
                    loadingText="Carregando..."
                    noOptionsText="Nenhuma revenda encontrada"
                    variant="standard"
                    value={partner}
                    style={{ width: 260 }}
                    onChange={(e) => {
                      setPartner(e.target.value);
                    }}
                  />
                </Box>
              )}
              <Box display="flex" alignItems="center">
                <Box pr={4} width={200}>
                  <DateTimeField
                    name="startsAt"
                    label="Início"
                    placeholder="DD/MM/YYYY HH:mm"
                    format="DD/MM/YYYY HH:mm"
                    variant="standard"
                    disableToolbar
                    value={startsAt}
                    onChange={(e) => {
                      setStartsAt(e.target.value);
                    }}
                  />
                </Box>
                <Box pr={4} width={200}>
                  <DateTimeField
                    name="endsAt"
                    label="Fim"
                    placeholder="DD/MM/YYYY HH:mm"
                    format="DD/MM/YYYY HH:mm"
                    variant="standard"
                    disableToolbar
                    value={endsAt}
                    onChange={(e) => {
                      setEndsAt(e.target.value);
                    }}
                  />
                </Box>
              </Box>
              <Box pr={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                >
                  Buscar
                </Button>
              </Box>
            </Box>
            <Box pt={4}>
              <TableContainer>
                <MuiTable
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <ItemsTableCell
                          key={column.id}
                          align={column.align ? column.align : 'left'}
                        >
                          <Typography variant="subtitle2" color="primary">
                            {column.label}
                          </Typography>
                        </ItemsTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading &&
                      [1, 2, 3].map((value) => (
                        <TableRow tabIndex={-1} key={value}>
                          <ItemsTableCell>
                            <Skeleton variant="text" width={80} />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell align="right">
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                        </TableRow>
                      ))}
                    {!loading &&
                      proposals.map((row) => {
                        return (
                          <ItemsTableRow
                            hover
                            onClick={() => handleClick(row.id)}
                            key={row.id}
                          >
                            <ItemsTableCell>{row.name}</ItemsTableCell>
                            <ItemsTableCell align="center">
                              <ContractType size="small" type={row.type} />
                            </ItemsTableCell>
                            <ItemsTableCell align="right">
                              {row.revision}
                            </ItemsTableCell>
                            <ItemsTableCell>
                              {row.partner ? row.partner.name : ''}
                            </ItemsTableCell>
                            <ItemsTableCell>
                              {row.user ? row.user.name : ''}
                            </ItemsTableCell>
                            <ItemsTableCell align="center">
                              <ProposalStatus
                                size="small"
                                status={row.status}
                              />
                            </ItemsTableCell>
                            <ItemsTableCell align="right">
                              {moment(row.created_at).format(
                                'DD/MM/YYYY HH:mm',
                              )}
                            </ItemsTableCell>
                          </ItemsTableRow>
                        );
                      })}
                    {!loading && proposals.length === 0 && (
                      <TableRow>
                        <ItemsTableCell colSpan={columns.length} align="center">
                          <Box m={4}>
                            <Typography>Nenhuma proposta encontrada</Typography>
                          </Box>
                        </ItemsTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </MuiTable>
              </TableContainer>
              <TablePagination
                labelRowsPerPage="Resultados por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} / ${count}`
                }
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
};

export default ProposalsPage;
