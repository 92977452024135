import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LinkIcon from '@material-ui/icons/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import LayersIcon from '@material-ui/icons/Layers';
import DescriptionIcon from '@material-ui/icons/Description';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

import Tabs from '../../components/Tabs';
import ContractType from '../../components/ContractType';
import ProposalStatus from '../../components/ProposalStatus';

import { PermissionContext } from '../../contexts/PermissionContext';
import { CURRENT_ENV } from '../../constants/constants';
import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import ProposalTab from './ProposalTab';
import ItemsTab from './ItemsTab';
import CompanyTab from './CompanyTab';

import CloneProposalDialog from './CloneProposalDialog';

const Revision = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 10,
  },
}))(Chip);

const ProposalDetailPage = () => {
  const params = useParams();
  const history = useHistory();

  const { authorized } = React.useContext(PermissionContext);

  const [loading, setLoading] = React.useState(true);
  const [proposal, setProposal] = React.useState(null);
  const [cancelling, setCancelling] = React.useState(false);

  const [showCloneProposalDialog, setShowCloneProposalDialog] = React.useState(
    false,
  );

  const fetchProposal = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(`/billing/proposals/${params.id}`);

      setProposal(response.data.proposal);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const cancelProposal = async () => {
    setCancelling(true);

    try {
      const response = await gateway.post(
        `/billing/proposals/${params.id}/cancel`,
      );

      setProposal(response.data.proposal);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setCancelling(false);
    }
  };

  React.useEffect(() => {
    fetchProposal();
  }, [params]);

  return (
    <>
      <Fade in>
        <Box flex={1}>
          <Paper square elevation={2}>
            <Box display="flex" flexDirection="column">
              <Box pt={6} p={4} display="flex" flexDirection="column">
                <Box alignSelf="flex-start">
                  <Button
                    onClick={() => history.push('/proposals')}
                    startIcon={<ChevronLeftIcon />}
                  >
                    Voltar para propostas
                  </Button>
                </Box>
                <Box mt={2} display="flex" flexDirection="column">
                  {loading ? (
                    <>
                      <Skeleton variant="text" height={42} width={180} />
                      <Skeleton variant="text" height={24} width={120} />
                    </>
                  ) : (
                    <>
                      <Box display="flex" alignItems="center">
                        <Box mr={2}>
                          <Typography variant="h4">{proposal.name}</Typography>
                        </Box>
                        <Box mr={2}>
                          <ContractType type={proposal.type} />
                        </Box>
                        <Box mr={2}>
                          <Revision
                            color="default"
                            label={`REVISÃO ${proposal.revision}`}
                          />
                        </Box>
                        <Box mr={2}>
                          <ProposalStatus status={proposal.status} />
                        </Box>
                        <Box
                          flex={1}
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          {proposal &&
                            proposal.charge &&
                            proposal.charge.charge_payment &&
                            proposal.charge.charge_payment.type === 1 &&
                            proposal.charge.charge_payment.voucher_url && (
                              <Box mr={2}>
                                <Button
                                  onClick={() => {
                                    window.open(
                                      proposal.charge.charge_payment
                                        .voucher_url,
                                      '_blank',
                                    );
                                  }}
                                  startIcon={<OpenInNewIcon />}
                                >
                                  Visualizar boleto
                                </Button>
                              </Box>
                            )}
                          {proposal &&
                            (proposal.status === 0 ||
                              proposal.status === 2 ||
                              proposal.status === 5) &&
                            authorized('proposals.edit') && (
                              <Box mr={2}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    history.push(
                                      `/proposals/${proposal.id}/edit`,
                                    )
                                  }
                                  startIcon={<EditIcon />}
                                >
                                  Editar
                                </Button>
                              </Box>
                            )}
                          {proposal && authorized('proposals.add') && (
                            <>
                              {proposal.payment_provider_id ? (
                                <Box mr={2}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      window.open(
                                        `https://alia.iugu.com/receive/billing/${proposal.payment_provider_id}`,
                                        '_blank',
                                      );
                                    }}
                                    startIcon={<DescriptionIcon />}
                                  >
                                    Assinatura
                                  </Button>
                                </Box>
                              ) : (
                                undefined
                              )}

                              <Box mr={2}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    setShowCloneProposalDialog(true)
                                  }
                                  startIcon={<LayersIcon />}
                                >
                                  Clonar
                                </Button>
                              </Box>
                            </>
                          )}
                          {proposal &&
                            (proposal.status === 0 || proposal.status === 1) &&
                            authorized('proposals.cancel') && (
                              <Box mr={2}>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={cancelProposal}
                                  disabled={cancelling}
                                  startIcon={
                                    cancelling ? (
                                      <CircularProgress
                                        color="secondary"
                                        size={20}
                                      />
                                    ) : (
                                      <BlockIcon />
                                    )
                                  }
                                >
                                  Cancelar
                                </Button>
                              </Box>
                            )}
                          <Button
                            onClick={() => {
                              navigator.clipboard.writeText(
                                CURRENT_ENV === 'production'
                                  ? `https://onboarding.eyemobile.com.br?uuid=${proposal.uuid}`
                                  : `https://staging-onboarding.eyemobile.com.br?uuid=${proposal.uuid}`,
                              );
                            }}
                            startIcon={<LinkIcon />}
                          >
                            Copiar link
                          </Button>
                        </Box>
                      </Box>
                      <Box mt={1}>
                        <Typography variant="subtitle2">
                          {proposal.partner ? proposal.partner.name : ''}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
                {!loading && proposal.refusal_comment && (
                  <Box mt={2}>
                    <Alert severity="error">{proposal.refusal_comment}</Alert>
                  </Box>
                )}
              </Box>
              <Tabs>
                <ProposalTab label="Proposta" proposal={proposal} />
                <ItemsTab label="Itens" proposal={proposal} />
                <CompanyTab label="Dados Cadastrais" proposal={proposal} />
              </Tabs>
            </Box>
          </Paper>
        </Box>
      </Fade>
      <CloneProposalDialog
        open={showCloneProposalDialog}
        onClose={() => {
          setShowCloneProposalDialog(false);
        }}
        onSubmit={() => {
          setShowCloneProposalDialog(false);
        }}
      />
    </>
  );
};

export default ProposalDetailPage;
