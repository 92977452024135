import React from 'react';
import * as PropTypes from 'prop-types';
import { Container } from '@eyemobile/core';

const DeviceModalItem = ({ device, removeDevice }) => {
  return (
    <div className="eye-card ml0 mr0 mt0 mb-lg pr-lg pl-lg">
      <div className="flex align-i-center justify-between fw">
        <Container column>
          <span>{device.model.label}</span>
          <span style={{ marginTop: '5px' }}>{device.provider.label}</span>
          <span style={{ marginTop: '5px' }}>{device.address}</span>
        </Container>
        <div className="flex">
          <i
            className="ic-close-red ml"
            onClick={() => removeDevice(device.address)}
          />
        </div>
      </div>
    </div>
  );
};

DeviceModalItem.propTypes = {
  device: PropTypes.object,
  removeDevice: PropTypes.func,
};

DeviceModalItem.defaultProps = {
  device: undefined,
  removeDevice: undefined,
};

export default DeviceModalItem;
