import React, { createContext } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = ({ User }) => ({
  user: User.profile,
});

export const PermissionContext = createContext();

export const PermissionProvider = connect(
  mapStateToProps,
  null,
)(({ user, children }) => {
  const hasPermission = ({ name, level }) => {
    if (!name || !level || !user) {
      return false;
    }
    return user.permissions?.[name]?.[level];
  };

  const authorized = (permission) => {
    const [name, level] = permission.split('.');
    if (!name || !level || !user) {
      return false;
    }
    return user.permissions?.[name]?.[level];
  };

  const isEyemobileUser = user.email?.includes('eyemobile.com.br');
  return (
    <PermissionContext.Provider
      value={{
        user,
        hasPermission,
        authorized,
        isEyemobileUser,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
});
