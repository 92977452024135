import { useState } from 'react';
import { isBoolean } from 'lodash';

const useFocus = (initialFocus = false, id = '') => {
  const [focus, setFocus] = useState(initialFocus);
  const setFocusWithTrueDefault = (param) =>
    setFocus(isBoolean(param) ? param : true);
  return [
    setFocusWithTrueDefault,
    {
      autoFocus: focus,
      key: `${id}${focus}`,
      onFocus: () => setFocus(true),
      onBlur: () => setFocus(false),
    },
  ];
};

export default useFocus;
