import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import facepaint from 'facepaint';
import { css } from 'emotion';
import ReactLoading from 'react-loading';

import moment from 'moment';
import gateway from '../utils/gateway';
import SelectModel from './SelectModel';
import SelectProvider from './SelectProvider';
import RoundedInput from './RoundedInput';
import SelectDistributionCenter from './SelectDistributionCenter';
import EyeButton from './EyeButton';
import AddDevicesModal from './AddDevicesModal';
import EyeTableInfinite from './EyeTableInfinite';
import { showError } from '../utils/toast';
import EditDeviceBar from './EditDeviceBar';
import TransferDeviceModal from './TransferDeviceModal';
import EyeRadioButtonGroupRow from './EyeRadioButtonGroupRow';
import { ALL, DISTRIBUTION_CENTER, EVENT } from '../constants/allocationType';
import SelectEvent from './SelectEvent';
import SelectDevicesGroup from './SelectDevicesGroup';
import SelectDeviceStatus from './SelectDeviceStatus';

const mq = facepaint([
  '@media(max-width: 1885px)',
  '@media(max-width: 1600px)',
  '@media(max-width: 1366px)',
  '@media(max-width: 1280px)',
  '@media(max-width: 768px)',
]);

const filterWrapper = css(
  mq({
    marginRight: '20px',
    marginTop: [0, '20px', '20px', '20px', '20px', '20px', 0],
  }),
);

const serialNumberWrapper = css(
  mq({
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
    marginTop: [0, 0, 0, 0, '20px'],
  }),
);

const DevicesPage = () => {
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedDistributionCenter, setSelectedDistributionCenter] = useState(
    null,
  );
  const [address, setAddress] = useState(null);
  const [addDevicesVisibility, setAddDevicesVisibility] = useState(false);
  const [searchParameters, setSearchParameters] = useState({
    allocationType: 2,
  });
  const [isSearching, setIsSearching] = useState(false);
  const [deviceIdToEdit, setDeviceIdToEdit] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showEditDeviceBar, setShowEditDeviceBar] = useState(false);
  const [devicesTotal, setTotalDevices] = useState(null);
  const [allocationType, setAllocationType] = useState(2);
  const [selectedEvents, setSelectedEvents] = useState(null);
  const [selectedDevicesGroup, setSelectedDevicesGroup] = useState(null);
  const [selectedDeviceStatus, setSelectedDeviceStatus] = useState(null);

  const { t } = useTranslation();

  const handleDeviceEdit = useCallback(
    (deviceIdToEdit) => {
      setDeviceIdToEdit(deviceIdToEdit);
      setShowEditDeviceBar(true);
    },
    [deviceIdToEdit],
  );

  const getColumns = () => {
    return [
      {
        label: <Trans i18nKey="common:model" />,
        rowStyle: { color: '#2897C8' },
        cellStyle: { color: '#737689' },
        dataKey: 'name',
      },
      {
        label: <Trans i18nKey="common:provider" />,
        rowStyle: { color: '#2897C8', textAlign: 'center' },
        cellStyle: { color: '#737689', textAlign: 'center' },
        dataKey: 'providerName',
      },
      {
        label: <Trans i18nKey="common:serialNumber" />,
        rowStyle: { color: '#2897C8', textAlign: 'center' },
        cellStyle: { color: '#737689', textAlign: 'center' },
        dataKey: 'address',
      },
      {
        label: <Trans i18nKey="common:group" />,
        rowStyle: { color: '#2897C8', textAlign: 'center' },
        cellStyle: { color: '#737689', textAlign: 'center' },
        dataKey: 'devicesGroupName',
      },
      {
        label: <Trans i18nKey="common:status" />,
        rowStyle: { color: '#2897C8', textAlign: 'center' },
        cellStyle: { color: '#737689', textAlign: 'center' },
        dataKey: 'deviceStatusName',
      },
      {
        label: <Trans i18nKey="common:site" />,
        rowStyle: { color: '#2897C8', textAlign: 'center' },
        cellStyle: {
          color: '#737689',
          textTransform: 'none',
          textAlign: 'center',
        },
        dataKey: 'local',
        render: (device) => {
          if (device.allocationType === 0) {
            return device.local ? (
              <div className="flex justify-center">
                <div
                  className="light-blue-pill"
                  style={{ fontSize: '14px', minWidth: '170px' }}
                >
                  {device.local || '-'}
                </div>
              </div>
            ) : (
              <div>-</div>
            );
          }

          if (device.allocationType === 1) {
            return (
              <div
                className="text-ellipsis"
                style={{ maxWidth: '300px', margin: '0 auto' }}
              >
                <span title={device.local || '-'}>{device.local || '-'}</span>
              </div>
            );
          }

          return <div>-</div>;
        },
      },
      {
        label: '',
        rowStyle: {
          width: '60px',
          textAlign: 'right',
        },
        render: (deviceToEdit) => (
          <i
            className="ic-grey-edit pointer"
            onClick={() => handleDeviceEdit(deviceToEdit.id)}
          />
        ),
      },
    ];
  };

  const fetch = async (page = 1) => {
    const params = {
      ...searchParameters,
      page,
    };

    try {
      const res = await gateway.get('/legacy/devices/', { params });
      setIsSearching(false);
      setTotalDevices(res.data.count);
      return res.data.devices;
    } catch (e) {
      setIsSearching(false);
      console.error(e.response.data);
      showError();
      return null;
    }
  };

  const onSearch = () => {
    const params = {
      models: selectedModel
        ? String(selectedModel.map((model) => model.value))
        : '',
      providers: selectedProvider
        ? String(selectedProvider.map((provider) => provider.value))
        : '',
      address,
      distributionCenter: selectedDistributionCenter
        ? String(selectedDistributionCenter.map((dc) => dc.value))
        : '',
      allocationType,
      events: selectedEvents
        ? String(selectedEvents.map((event) => event.value))
        : '',
      devicesGroup: selectedDevicesGroup
        ? String(selectedDevicesGroup.map((deviceGroup) => deviceGroup.value))
        : '',
      deviceStatus: selectedDeviceStatus
        ? String(selectedDeviceStatus.map((deviceStatus) => deviceStatus.value))
        : '',
    };

    setIsSearching(true);
    setSearchParameters(params);
  };

  return (
    <div className="flex flex-column align-i-start fh">
      <div className="flex fw">
        <div className="flex flex-wrap">
          <SelectProvider
            dark
            isMulti
            id="modelSelect"
            wrapperClassName="mr-lg"
            closeMenuOnSelect={false}
            selectWrapperClassName="fw"
            selected={selectedProvider}
            onChange={(selected) => setSelectedProvider(selected)}
          />
          <SelectModel
            dark
            isMulti
            id="modelSelect"
            wrapperClassName="mr-lg"
            selected={selectedModel}
            closeMenuOnSelect={false}
            selectWrapperClassName="fw"
            onChange={(selected) => setSelectedModel(selected)}
          />
          <RoundedInput
            id="serialNumber"
            uppercasePlaceholder
            value={address}
            className="input-xl"
            wrapperClassName={serialNumberWrapper}
            placeholder={t('common:serialNumber')}
            onChange={(value) => {
              setAddress(value);
            }}
          />
          <SelectDistributionCenter
            dark
            isMulti
            id="modelSelect"
            closeMenuOnSelect={false}
            selectWrapperClassName="fw"
            disabled={allocationType === 1}
            wrapperClassName={filterWrapper}
            selected={selectedDistributionCenter}
            onChange={(selected) => setSelectedDistributionCenter(selected)}
          />
          <SelectEvent
            dark
            isMulti
            defaultOptions
            wrapperClassName="mr-lg"
            selected={selectedEvents}
            disabled={allocationType === 0}
            onChange={setSelectedEvents}
            formatOptionLabel={({ label, tenantLabel, start, end }) => {
              return (
                <div className="flex flex-column">
                  <span>{label}</span>
                  <span>{`${t('common:tenant_')} ${tenantLabel}`}</span>
                  <span>{`${moment(start).format('DD/MM/YYYY')} - ${moment(
                    end,
                  ).format('DD/MM/YYYY')}`}</span>
                </div>
              );
            }}
          />
          <SelectDevicesGroup
            dark
            isMulti
            defaultOptions
            wrapperClassName="mr-lg"
            selected={selectedDevicesGroup}
            onChange={setSelectedDevicesGroup}
          />
          <SelectDeviceStatus
            dark
            isMulti
            defaultOptions
            wrapperClassName="mr-lg"
            selected={selectedDeviceStatus}
            onChange={setSelectedDeviceStatus}
          />
          <EyeRadioButtonGroupRow
            t={t}
            containerClassName="flex-column-xs mt-lg mr-lg"
            radioButtonGroup={{
              selected: allocationType,
              radioButtons: [
                {
                  key: 'all',
                  label: 'common:all',
                  value: ALL,
                  labelClassName: 'uppercase',
                  containerClassName: 'mr-xl mb-md-xs',
                  onChangeAction: setAllocationType,
                },
                {
                  key: 'distributionCenter',
                  label: 'common:distributionCenter',
                  value: DISTRIBUTION_CENTER,
                  labelClassName: 'uppercase',
                  containerClassName: 'mr-xl mb-md-xs',
                  onChangeAction: setAllocationType,
                },
                {
                  key: 'event',
                  label: 'common:event',
                  value: EVENT,
                  labelClassName: 'uppercase',
                  containerClassName: 'mr-xl',
                  onChangeAction: (value) => {
                    setSelectedDistributionCenter(null);
                    setAllocationType(value);
                  },
                },
              ],
            }}
          />
        </div>
        <div className="flex justify-end grow ml-lg">
          <EyeButton
            blue
            selected
            isLoading={isSearching}
            disabled={isSearching}
            label={t('common:search')}
            clickHandler={onSearch}
          />
        </div>
      </div>
      <div className="flex align-i-center justify-between mt-lg fw">
        <div className="flex">
          {devicesTotal > 0 && (
            <div className="flex align-i-center">
              <span className="filter-label">{t('common:results')}</span>
              {isSearching ? (
                <ReactLoading
                  type="spin"
                  color="#7d1dd1"
                  height={20}
                  width={20}
                />
              ) : (
                <span className="purple-pill">
                  {t('common:deviceCount', { count: devicesTotal })}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="flex">
          <EyeButton
            selected
            label={t('common:transfer')}
            className="mr-lg"
            clickHandler={() => setShowTransferModal(true)}
          >
            <i className="ic-label ic-exchange" />
          </EyeButton>
          <EyeButton
            selected
            label={t('common:add')}
            clickHandler={() => setAddDevicesVisibility(true)}
          >
            <i className="ic-label ic-add" />
          </EyeButton>
        </div>
      </div>
      <div
        className="flex flex-column vertical-scroll mt-lg"
        style={{ flex: '1 0 0%' }}
      >
        <EyeTableInfinite
          fetch={fetch}
          fetchMore={fetch}
          columns={getColumns()}
          parameters={searchParameters}
        />
      </div>
      <AddDevicesModal
        visible={addDevicesVisibility}
        onClose={() => setAddDevicesVisibility(false)}
      />
      <EditDeviceBar
        deviceIdToEdit={deviceIdToEdit}
        show={showEditDeviceBar}
        onClose={(reload) => {
          setShowEditDeviceBar(false);
          if (reload) {
            onSearch();
          }
        }}
      />
      <TransferDeviceModal
        visible={showTransferModal}
        onClose={(reload) => {
          setShowTransferModal(false);
          if (reload) {
            onSearch();
          }
        }}
      />
    </div>
  );
};

export default DevicesPage;
