import React from 'react';
import * as PropTypes from 'prop-types';
import EyeRadioButtonRow from './EyeRadioButtonRow';

function EyeRadioButtonGroupRow({ t, radioButtonGroup, containerClassName }) {
  return (
    <div className={`flex ${containerClassName}`}>
      {radioButtonGroup.radioButtons.map((radioButton) => (
        <EyeRadioButtonRow
          t={t}
          key={radioButton.key}
          labelClassName={radioButton.labelClassName}
          containerClassName={radioButton.containerClassName}
          checked={radioButtonGroup.selected === radioButton.value}
          onChangeAction={() => {
            radioButton.onChangeAction(radioButton.value);
          }}
          label={radioButton.label}
        />
      ))}
    </div>
  );
}

EyeRadioButtonGroupRow.defaultProps = {
  radioButtonGroup: [],
  containerClassName: '',
};

EyeRadioButtonGroupRow.propTypes = {
  t: PropTypes.func.isRequired,
  radioButtonGroup: PropTypes.object,
  containerClassName: PropTypes.string,
};

export default EyeRadioButtonGroupRow;
