/**
 * Generate a secure nonce.
 * @param {number} length - The desired length of the nonce in bytes.
 * @returns {string} - A hexadecimal representation of the nonce.
 */
export const generateNonce = (length = 16) => {
  // Create a Uint8Array to hold the random bytes
  const array = new Uint8Array(length);

  // Fill the array with cryptographically secure random bytes
  window.crypto.getRandomValues(array);

  // Convert the byte array to a hexadecimal string
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join(
    '',
  );
};
