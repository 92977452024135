import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import DescriptionIcon from '@material-ui/icons/Description';
import EventBusyIcon from '@material-ui/icons/EventBusy';

import PostAddIcon from '@material-ui/icons/PostAdd';
import moment from 'moment';

import { PermissionContext } from '../../contexts/PermissionContext';
import Chip from '../../components/Chip';

import { showError } from '../../utils/toast';

const ItemsTableCell = withStyles(() => ({
  root: {
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const DetailTableCell = withStyles(() => ({
  root: {
    padding: 0,
    border: 0,
  },
}))(TableCell);

const COLUMNS = [
  {
    id: 'product',
    label: 'PRODUTO',
  },
  { id: 'quantity', label: 'QTD.' },
  { id: 'price', label: 'PREÇO' },
  { id: 'discount', label: 'DESC.' },
  { id: 'total', align: 'right', label: 'TOTAL' },
];

const useStyles = makeStyles(() => ({
  image: {
    borderRadius: 16,
    width: 52,
    height: 52,
  },
}));

const ContractTab = ({ show, tenant, subscription }) => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const theme = useTheme();

  const { authorized } = React.useContext(PermissionContext);

  if (!show) {
    return null;
  }

  const isExpired = subscription?.expires_at
    ? moment(subscription.expires_at).isBefore(moment())
    : false;

  const totalRecurrent =
    subscription?.contract_items.reduce((sum, item) => {
      if (item.contract_product.recurrency_type === 0) {
        return sum;
      }
      return sum + item.total;
    }, 0) || 0;

  const formattedTotalRecurrent = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(totalRecurrent);

  return (
    <Fade in>
      <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
        {tenant?.iugu_subscription_id ? (
          <>
            <Box
              mt={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography variant="h6">Próximo vencimento:</Typography>
              {subscription?.expires_at
                ? moment(subscription.expires_at).format('DD/MM/YYYY')
                : '-'}
              {isExpired && (
                <Box mr={2}>
                  <Chip
                    icon={<EventBusyIcon color="error" />}
                    label="ATRASADO"
                    type="error"
                    size="small"
                  />
                </Box>
              )}
            </Box>

            {tenant !== null && subscription !== null && (
              <Box display="flex" flexDirection="column">
                <Box mb={2} display="flex" alignItems="center">
                  <Box
                    flex={1}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                  >
                    {authorized('contracts.view') && (
                      <Box mr={2}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            history.push(
                              `/tenants/${params.id}/contracts/${subscription.id}`,
                            );
                          }}
                          startIcon={<DescriptionIcon />}
                        >
                          Acessar contrato
                        </Button>
                      </Box>
                    )}
                    {authorized('proposals.add') && (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={subscription?.payment_provider !== 1}
                          cursor="not-allowed"
                          onClick={() => {
                            if (
                              tenant.phone === null ||
                              tenant.email === null
                            ) {
                              showError(
                                'Cliente sem dados do responsável financeiro cadastrados',
                              );
                            } else {
                              history.push(`/tenants/${params.id}/amendment`);
                            }
                          }}
                          startIcon={<PostAddIcon />}
                        >
                          Alterar assinatura (com proposta)
                        </Button>
                        {/* 
                        {isExpired && (
                          <Typography variant="caption" color="error">
                            Alteração de assinatura apenas para tenants
                            adimplentes
                          </Typography>
                        )} */}

                        {subscription?.payment_provider !== 1 && (
                          <Typography variant="caption" color="error">
                            Alteração de assinatura apenas para migrados para a
                            Iugu.
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Typography variant="h6">Itens recorrentes</Typography>
                <Box mb={4} display="flex" flexDirection="column">
                  <TableContainer>
                    <Table
                      aria-labelledby="tableTitle"
                      size="medium"
                      aria-label="enhanced table"
                    >
                      <TableHead>
                        <TableRow>
                          {COLUMNS.map((column) => (
                            <ItemsTableCell
                              key={column.id}
                              align={column.align ? column.align : 'left'}
                            >
                              <Typography variant="subtitle2" color="primary">
                                {column.label}
                              </Typography>
                            </ItemsTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tenant === null &&
                          [1, 2, 3].map((value) => (
                            <TableRow tabIndex={-1} key={value}>
                              <ItemsTableCell>
                                <Box display="flex" alignItems="center">
                                  <Box mr={2}>
                                    <Skeleton
                                      variant="circle"
                                      width={24}
                                      height={24}
                                    />
                                  </Box>
                                  <Skeleton variant="text" width={80} />
                                </Box>
                              </ItemsTableCell>
                              <ItemsTableCell>
                                <Skeleton variant="text" />
                              </ItemsTableCell>
                              <ItemsTableCell>
                                <Skeleton variant="text" />
                              </ItemsTableCell>
                              <ItemsTableCell>
                                <Skeleton variant="text" />
                              </ItemsTableCell>
                              <ItemsTableCell align="right">
                                <Skeleton variant="text" />
                              </ItemsTableCell>
                            </TableRow>
                          ))}
                        {tenant !== null &&
                          subscription &&
                          subscription.contract_items
                            .filter((row) => row.recurrency_type !== 0)
                            .map((row) => {
                              const formattedDiscount = Intl.NumberFormat(
                                'pt-BR',
                                {
                                  style: 'currency',
                                  currency: 'BRL',
                                },
                              ).format(row.discount);

                              const formattedPrice = Intl.NumberFormat(
                                'pt-BR',
                                {
                                  style: 'currency',
                                  currency: 'BRL',
                                },
                              ).format(row.price);

                              const formattedTotal = Intl.NumberFormat(
                                'pt-BR',
                                {
                                  style: 'currency',
                                  currency: 'BRL',
                                },
                              ).format(row.total);

                              let measure = '';
                              if (row.measure === 1) {
                                measure = ' / mês';
                              } else if (row.measure === 2) {
                                measure = ' / hora';
                              } else if (row.measure === 3) {
                                measure = ' / usuário';
                              } else if (row.measure === 4) {
                                measure = ' / usuário / mês';
                              } else if (row.measure === 5) {
                                measure = ' / ano';
                              } else if (row.measure === 6) {
                                measure = ' / usuário / ano';
                              }

                              let recurrency = '';
                              if (row.recurrency_type === 1) {
                                recurrency = ' / mês';
                              } else if (row.recurrency_type === 2) {
                                recurrency = ' / ano';
                              }

                              return (
                                <React.Fragment key={row.id}>
                                  <TableRow tabIndex={-1}>
                                    <ItemsTableCell>
                                      <Box display="flex" alignItems="center">
                                        <Box mr={2}>
                                          <img
                                            src={row.contract_product.image}
                                            alt={row.name}
                                            className={classes.image}
                                          />
                                        </Box>
                                        <Box
                                          display="flex"
                                          flexDirection="column"
                                        >
                                          <Typography>{row.name}</Typography>
                                          {row.receivable_deadline !== null ? (
                                            <Typography variant="body2">{`Prazo dos recebíveis ${row.receivable_deadline}`}</Typography>
                                          ) : null}
                                          {row.credit_percentage !== null ? (
                                            <Typography variant="body2">{`Taxa de crédito ${row.credit_percentage}%`}</Typography>
                                          ) : null}
                                          {row.debit_percentage !== null ? (
                                            <Typography variant="body2">{`Taxa de débito ${row.debit_percentage}%`}</Typography>
                                          ) : null}
                                          {row.ecommerce_transaction_fee !==
                                          null ? (
                                            <Typography variant="body2">{`Valor da transação ${Intl.NumberFormat(
                                              'pt-BR',
                                              {
                                                style: 'currency',
                                                currency: 'BRL',
                                              },
                                            ).format(
                                              row.ecommerce_transaction_fee,
                                            )}`}</Typography>
                                          ) : null}
                                          {row.ecommerce_transaction_percentage !==
                                          null ? (
                                            <Typography variant="body2">{`Taxa ${row.ecommerce_transaction_percentage}%`}</Typography>
                                          ) : null}
                                        </Box>
                                      </Box>
                                    </ItemsTableCell>
                                    <ItemsTableCell>
                                      <Typography>{row.quantity}</Typography>
                                    </ItemsTableCell>
                                    <ItemsTableCell>
                                      <Typography>
                                        {formattedPrice + measure}
                                      </Typography>
                                    </ItemsTableCell>
                                    <ItemsTableCell>
                                      {row.discount > 0 &&
                                        row.discount_type === 0 && (
                                          <Typography>
                                            {row.discount}%
                                          </Typography>
                                        )}
                                      {row.discount > 0 &&
                                        row.discount_type === 1 && (
                                          <Typography>
                                            {formattedDiscount}
                                          </Typography>
                                        )}
                                    </ItemsTableCell>
                                    <ItemsTableCell align="right">
                                      <Typography>
                                        {formattedTotal + recurrency}
                                      </Typography>
                                    </ItemsTableCell>
                                  </TableRow>
                                  {row.observations &&
                                    row.observations.length > 0 && (
                                      <TableRow tabIndex={-1}>
                                        <DetailTableCell colSpan={5}>
                                          <Box
                                            p={1}
                                            pl={4}
                                            pr={4}
                                            bgcolor={fade(
                                              theme.palette.text.primary,
                                              0.1,
                                            )}
                                            dangerouslySetInnerHTML={{
                                              __html: row.observations,
                                            }}
                                          />
                                        </DetailTableCell>
                                      </TableRow>
                                    )}
                                </React.Fragment>
                              );
                            })}
                        {tenant !== null &&
                          subscription &&
                          subscription.contract_items.filter(
                            (row) => row.recurrency_type !== 0,
                          ).length === 0 && (
                            <TableRow>
                              <ItemsTableCell colSpan={5} align="center">
                                <Box p={2}>
                                  <Typography>
                                    Nenhum item encontrado
                                  </Typography>
                                </Box>
                              </ItemsTableCell>
                            </TableRow>
                          )}
                        {tenant !== null &&
                          subscription &&
                          subscription.contract_items.length > 0 && (
                            <TableRow>
                              <ItemsTableCell colSpan={4} />
                              <ItemsTableCell align="right">
                                <Typography variant="h6">
                                  Total {`${formattedTotalRecurrent}`}
                                </Typography>
                              </ItemsTableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <>
            <Box display="flex" flexDirection="column">
              <Box mb={2} display="flex" alignItems="center">
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {authorized('proposals.add') && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        if (tenant.phone === null || tenant.email === null) {
                          showError(
                            'Cliente sem dados do responsável financeiro cadastrados',
                          );
                        } else if (
                          tenant.company_owner_email === null ||
                          tenant.company_owner_phone === null ||
                          tenant.company_owner_name === null
                        ) {
                          showError(
                            'Cliente sem dados do responsável legal cadastrados',
                          );
                        } else {
                          history.push(`/tenants/${params.id}/subscription`);
                        }
                      }}
                      startIcon={<PostAddIcon />}
                    >
                      Criar Assinatura (COM PROPOSTA)
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>

            <Alert severity="info">O cliente não possui assinatura</Alert>
          </>
        )}
      </Box>
    </Fade>
  );
};

export default ContractTab;
