import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';

import Tabs from '../../components/Tabs';
import ChargeStatus from '../../components/ChargeStatus';

import { PermissionContext } from '../../contexts/PermissionContext';
import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import PaymentsTab from './PaymentsTab';
import ActivitiesTab from './ActivitiesTab';

import CancelChargeDialog from './CancelChargeDialog';
import EditChargeDialog from './EditChargeDialog';

const ChargeDetailPage = () => {
  const params = useParams();
  const history = useHistory();

  const { authorized } = React.useContext(PermissionContext);

  const [showEditChargeDialog, setShowEditChargeDialog] = React.useState(false);
  const [showCancelChargeDialog, setShowCancelChargeDialog] = React.useState(
    false,
  );

  const [loading, setLoading] = React.useState(true);
  const [charge, setCharge] = React.useState(null);

  const fetchCharge = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(
        `/billing/tenants/${params.tenant_id}/charges/${params.charge_id}`,
      );

      setCharge(response.data.charge);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchCharge();
  }, []);

  return (
    <>
      <Fade in>
        <Box flex={1}>
          <Paper square elevation={2}>
            <Box display="flex" flexDirection="column">
              <Box pt={6} p={4} display="flex" flexDirection="column">
                <Box alignSelf="flex-start">
                  <Button
                    onClick={() => history.push(`/tenants/${params.tenant_id}`)}
                    startIcon={<ChevronLeftIcon />}
                  >
                    Voltar para cliente
                  </Button>
                </Box>
                <Box mt={2} display="flex" flexDirection="column">
                  {loading ? (
                    <>
                      <Skeleton variant="text" height={42} width={180} />
                      <Skeleton variant="text" height={24} width={120} />
                    </>
                  ) : (
                    <>
                      <Box display="flex" alignItems="center">
                        <Box mr={2}>
                          <Typography variant="h4">{`Cobrança #${charge.id}`}</Typography>
                        </Box>
                        <Box mr={2}>
                          <ChargeStatus status={charge.status} />
                        </Box>
                        <Box
                          flex={1}
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          {authorized('charges.edit') && (
                            <Box mr={2}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setShowEditChargeDialog(true)}
                                startIcon={<EditIcon />}
                              >
                                Editar
                              </Button>
                            </Box>
                          )}
                          {charge &&
                            charge.status !== 4 &&
                            authorized('charges.cancel') && (
                              <Box mr={2}>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  startIcon={<BlockIcon />}
                                  onClick={() =>
                                    setShowCancelChargeDialog(true)
                                  }
                                >
                                  Cancelar
                                </Button>
                              </Box>
                            )}
                        </Box>
                      </Box>
                      <Box mt={1}>
                        <Typography variant="subtitle2">
                          {Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(charge.total)}
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography variant="body1">
                          {charge.description}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <Tabs>
                <PaymentsTab label="Pagamentos" charge={charge} />
                <ActivitiesTab label="Atividades" charge={charge} />
              </Tabs>
            </Box>
          </Paper>
        </Box>
      </Fade>
      <EditChargeDialog
        open={showEditChargeDialog}
        onClose={() => {
          setShowEditChargeDialog(false);
        }}
        onSubmit={() => {
          fetchCharge();
          setShowEditChargeDialog(false);
        }}
        charge={charge}
      />
      <CancelChargeDialog
        open={showCancelChargeDialog}
        onClose={() => {
          setShowCancelChargeDialog(false);
        }}
        onSubmit={() => {
          fetchCharge();
          setShowCancelChargeDialog(false);
        }}
      />
    </>
  );
};

export default ChargeDetailPage;
