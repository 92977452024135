import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import filter from 'lodash/filter';
import { ReactComponent as DevicesGroup } from '../assets/ic_device_group.svg';

import { showError, showSuccess } from '../utils/toast';
import gateway from '../utils/gateway';
import Loading from './Loading';

const DevicesGroupPage = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [newDeviceGroup, setNewDeviceGroup] = useState(null);
  const [devicesGroup, setDevicesGroup] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    const doFetch = async () => {
      const res = await gateway.get('/legacy/devicesGroup/');
      if (res.status !== 200) {
        setIsLoading(false);
        showError();
        return;
      }

      setDevicesGroup(res.data);
      setIsLoading(false);
    };
    doFetch();
  }, []);

  const addDeviceGroup = async () => {
    setIsLoading(true);

    const devicesGroupCheck = devicesGroup.find((devicesGroup) => {
      return devicesGroup === newDeviceGroup;
    });

    if (devicesGroupCheck) {
      showError('formErrors:devicesGroupAlreadyAdded');
      return;
    }

    const formData = new FormData();
    formData.append('devicesGroupName', newDeviceGroup);

    try {
      const res = await gateway.post('/legacy/devicesGroup/', formData);
      showSuccess();

      const savedDeviceGroup = res.data;
      setDevicesGroup(devicesGroup.concat(savedDeviceGroup));

      setNewDeviceGroup('');
      setIsLoading(false);
    } catch (e) {
      showError();
    }
  };

  const removeDeviceGroup = async (devicesGroupToRemove) => {
    const { id } = devicesGroupToRemove;

    setIsLoading(true);

    if (id) {
      try {
        await gateway.delete('/legacy/devicesGroup/', {
          params: { devicesGroupId: id },
        });
      } catch (e) {
        setIsLoading(false);
        showError(e.response.data);
        return;
      }

      showSuccess();
    }

    const newDeviceGroups = filter(devicesGroup, (devicesGroup) => {
      return devicesGroup !== devicesGroupToRemove;
    });

    setDevicesGroup(newDeviceGroups);
    setIsLoading(false);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      addDeviceGroup();
    }
  };

  return (
    <div className="flex align-i-center justify-center grow fh">
      <div
        className="eye-card mt0"
        style={{ width: '485px', padding: '25px 75px' }}
      >
        <span className="avenir-medium-gray-title uppercase">
          {t('common:devicesGroups')}
        </span>
        <div className="flex align-i-center mt-xl mb-xl">
          <DevicesGroup />
          <input
            type="text"
            autoComplete="none"
            value={newDeviceGroup}
            placeholder={t('common:deviceGroupName')}
            onKeyDown={onKeyDown}
            className="input-default uppercase-placeholder"
            style={{ width: '280px', paddingLeft: '10px' }}
            onChange={(e) => setNewDeviceGroup(e.target.value)}
          />
          <i
            className={clsx('ic-plus pointer', isLoading && 'disabled')}
            onClick={addDeviceGroup}
          />
        </div>
        <div className="flex flex-column fw">
          <Loading isSmall show={isLoading} />
          {devicesGroup.length === 0 && !isLoading && (
            <div className="flex align-i-center justify-center">
              <i className="ic-alert-blue ic-sm mr-sm" />
              <span className="normal-case" style={{ lineHeight: '2' }}>
                {t('common:noDevicesGroupAdded')}
              </span>
            </div>
          )}
          {devicesGroup.length > 0 &&
            !isLoading &&
            devicesGroup.map((devicesGroup) => {
              return (
                <div className="flex align-i-center mb-lg">
                  <i
                    className={clsx(
                      'ic-minus pointer mr-sm',
                      isLoading && 'disabled',
                    )}
                    onClick={() => removeDeviceGroup(devicesGroup)}
                  />
                  <span className="avenir-medium-gray-md uppercase">
                    {devicesGroup.name}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DevicesGroupPage;
