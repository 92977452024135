import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { setBreadcrumb } from '../store/modules/breadcrumb';

export const withBreadcrumb = (WrappedComponent) => {
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setBreadcrumb }, dispatch);

  class Main extends Component {
    componentDidMount() {
      this.setBreadcrumb();
    }

    componentDidUpdate(prevProps) {
      const { location } = this.props;

      const { location: prevLocation } = prevProps;

      if (
        location &&
        location.pathname !== prevLocation &&
        prevLocation.pathname
      ) {
        this.setBreadcrumb();
      }
    }

    setBreadcrumb = () => {
      const { setBreadcrumb, t } = this.props;
      const trimmedPath = this.getCurrentPath();

      if (!trimmedPath) {
        setBreadcrumb(t('breadcrumb:devices'));
        return;
      }

      if (trimmedPath.startsWith('proposals')) {
        setBreadcrumb(t('breadcrumb:proposals'));
        return;
      }

      if (trimmedPath.startsWith('tenants')) {
        setBreadcrumb(t('breadcrumb:tenants'));
        return;
      }

      setBreadcrumb(t(`breadcrumb:${trimmedPath}`));
    };

    getCurrentPath = () => {
      const { location } = this.props;

      return location.pathname.replace(/^\/+|\/+$/g, '');
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  Main.defaultProps = {
    children: undefined,
    location: undefined,
  };

  Main.propTypes = {
    t: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.node,
      PropTypes.array,
    ]),
    location: PropTypes.object,
    setBreadcrumb: PropTypes.func.isRequired,
  };

  const enhance = compose(
    withRouter,
    connect(
      null,
      mapDispatchToProps,
    ),
    withTranslation(),
  );

  return enhance(Main);
};
