import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import brown from '@material-ui/core/colors/brown';

export const blue = {
  50: '#e4f2f8',
  100: '#bcdeee',
  200: '#8fc9e3',
  300: '#62b3d7',
  400: '#40a2cf',
  500: '#1e92c6',
  600: '#1a8ac0',
  700: '#167fb9',
  800: '#1275b1',
  900: '#0a63a4',
  A100: '#d1eaff',
  A200: '#9ed2ff',
  A400: '#6bbaff',
  A700: '#51aeff',
  contrastDefaultColor: 'light',
  contrastDarkColors: [
    '50',
    '100',
    '200',
    '300',
    '400',
    'A100',
    'A200',
    'A400',
    'A700',
  ],
  contrastLightColors: ['500', '600', '700', '800', '900'],
};

const Main = createMuiTheme({
  palette: {
    primary: blue,
    secondary: red,
    success: green,
    warning: orange,
    danger: red,
    brown,
    text: {
      primary: '#3B4256',
      secondary: '#898C93',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: ['Avenir', 'sans-serif'].join(','),
    h4: {
      color: '#3B4256',
      fontWeight: 900,
    },
    h5: {
      color: '#3B4256',
      fontWeight: 900,
    },
    h6: {
      color: '#3B4256',
      fontWeight: 900,
    },
    subtitle1: {
      color: '#3B4256',
      fontWeight: 700,
    },
    subtitle2: {
      color: '#3B4256',
      fontWeight: 700,
    },
    body1: {
      color: '#3B4256',
    },
    body2: {
      color: '#898C93',
    },
  },
  overrides: {
    MUIRichTextEditor: {
      container: {
        margin: 0,
        display: 'flex',
        flexDirection: 'column-reverse',
      },
      editor: {
        padding: '27px 12px 10px',
      },
      toolbar: {
        padding: 8,
      },
      editorContainer: {
        margin: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
  },
});

export default Main;
