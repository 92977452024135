import LocalStorageManager from './LocalStorageManager';
import history from '../config/history';
import { generateSessionSignature } from './generateSessionSignature';

const doNotRefreshPaths = /\/auth|\/forgot/i;

const applyInterceptors = (gateway) => {
  // Session interceptor
  gateway.interceptors.request.use(async (config) => {
    const newConfig = config;
    const isAuth = /\/auth\/refresh/i.test(config.url);

    if (!isAuth) {
      const token = LocalStorageManager.getToken();

      if (token) {
        const signature = await generateSessionSignature({
          token,
          config: newConfig,
        });

        newConfig.headers['x-eyemobile-signature'] = signature;
        newConfig.headers['x-access-token'] = token;
      }
    }

    return newConfig;
  });

  gateway.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (doNotRefreshPaths.test(originalRequest.url)) {
        return Promise.reject(error);
      }
      if (error.response.status === 401 && !originalRequest.retried) {
        const refreshToken = LocalStorageManager.getRefreshToken();
        originalRequest.retried = true;
        const signature = await generateSessionSignature({
          token: refreshToken,
          config: originalRequest,
        });
        return gateway
          .post('/auth/refresh', null, {
            headers: {
              'x-refresh-token': refreshToken,
              'x-eyemobile-signature': signature,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              LocalStorageManager.setToken(res.headers['x-access-token']);
              LocalStorageManager.setSecret(res.headers['x-eyemobile-secret']);
              return gateway(originalRequest);
            }
          })
          .catch(async (err) => {
            if (err.response && err.response.status === 401) {
              LocalStorageManager.removeRefreshToken();
              LocalStorageManager.removeToken();
              history.push('/login', {
                errorMsg: 'Sessão expirada',
                redirectTo: window.location.href,
              });
            }
          });
      }

      // return Error object with Promise
      return Promise.reject(error);
    },
  );
};

export default applyInterceptors;
