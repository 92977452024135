export const encoder = new TextEncoder();
export const decoder = new TextDecoder();

export const encodeBase64 = (input) => {
  let unencoded = input;
  if (typeof unencoded === 'string') {
    unencoded = encoder.encode(unencoded);
  }
  const CHUNK_SIZE = 0x8000;
  const arr = [];
  for (let i = 0; i < unencoded.length; i += CHUNK_SIZE) {
    // @ts-expect-error
    arr.push(
      String.fromCharCode.apply(null, unencoded.subarray(i, i + CHUNK_SIZE)),
    );
  }
  return btoa(arr.join(''));
};

export const base64Url = (input) => {
  return encodeBase64(input)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};

export const decodeBase64 = (encoded) => {
  const binary = atob(encoded);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return bytes;
};

export const decode = (input) => {
  let encoded = input;
  if (encoded instanceof Uint8Array) {
    encoded = decoder.decode(encoded);
  }
  encoded = encoded
    .replace(/-/g, '+')
    .replace(/_/g, '/')
    .replace(/\s/g, '');
  try {
    return decodeBase64(encoded);
  } catch {
    throw new TypeError('The input to be decoded is not correctly encoded.');
  }
};
