import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import { uid } from 'react-uid';

import { tableWrapper } from './EyeTable.styles';
import LoadMoreScroll from '../LoadMoreScroll';

const EyeTable = ({
  columns,
  data,
  style,
  infinite,
  isLoading,
  handleScroll,
}) => {
  const [columnsWithKey, setColumnsWithKey] = useState([]);

  useEffect(() => {
    const columnsWithKey = columns.map((column) => ({
      ...column,
      key: uid(column),
    }));

    setColumnsWithKey(columnsWithKey);
  }, []);

  return (
    <table
      className={clsx(tableWrapper, 'vertical-scroll')}
      style={{
        tableLayout: 'fixed',
        ...style,
      }}
    >
      <thead>
        <tr>
          {columnsWithKey.map((column) => (
            <th key={column.key} style={column.rowStyle}>
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data && data.length > 0 ? (
          data.map((row) => (
            <tr key={row.key}>
              {columnsWithKey.map((column) => {
                const { render, dataKey, rowStyle, cellStyle } = column;

                return (
                  <td style={cellStyle || rowStyle}>
                    {render
                      ? render(row)
                      : row[dataKey] === undefined
                      ? '-'
                      : row[dataKey]}
                  </td>
                );
              })}
            </tr>
          ))
        ) : (
          <tr>
            {columnsWithKey.map(() => (
              <td>-</td>
            ))}
          </tr>
        )}
        {infinite && data && data.length > 0 && (
          <LoadMoreScroll isLoading={isLoading} action={handleScroll} />
        )}
      </tbody>
    </table>
  );
};

EyeTable.defaultProps = {
  style: undefined,
  infinite: false,
  isLoading: false,
  handleScroll: undefined,
};

EyeTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.object,
  infinite: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleScroll: PropTypes.func,
};

export default EyeTable;
