import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const Dictionary = ({ loading, dictionary, align = 'center' }) => {
  if (align === 'center') {
    return (
      <Box display="flex" flexDirection="column">
        {dictionary.map((item) => (
          <Box key={item.label} mb={1} display="flex" alignItems="center">
            <Box width={200} mr={2} flexShrink={0}>
              <Typography variant="subtitle1" align="right">
                {item.label}
              </Typography>
            </Box>
            {loading ? (
              <Skeleton variant="text" width={160} />
            ) : (
              <Typography variant="body1">{item.value}</Typography>
            )}
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      {dictionary.map((item) => (
        <Box
          key={item.label}
          mb={1}
          w={400}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Typography variant="subtitle1">{item.label}</Typography>
          {loading ? (
            <Skeleton variant="text" width={160} />
          ) : (
            <Typography variant="body1">{item.value}</Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default Dictionary;
