export const DISTRIBUTION_CENTER = 0;
export const EVENT = 1;
export const ALL = 2;

export const allocationTypesMap = {
  '0': '0 - Liberado',
  '1': '1 - Alocado',
  '2': '2 - Bloqueado',
};

const allocationTypes = [
  { value: '0', label: allocationTypesMap['0'] },
  { value: '1', label: allocationTypesMap['1'] },
  { value: '2', label: allocationTypesMap['2'] },
];

export default allocationTypes;
