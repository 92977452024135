import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { default as MuiChip } from '@material-ui/core/Chip';

const CONTRACT_STATUS = {
  5: 'EXPIRADO',
  6: 'CANCELADO',
  7: 'ATIVO',
  8: 'SUSPENSO',
};

const Chip = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 10,
    color: ({ status }) => {
      if (status === 5) return theme.palette.text[500];
      if (status === 6) return theme.palette.danger[500];
      if (status === 7) return theme.palette.success[500];
      if (status === 8) return theme.palette.warning[500];
      return theme.palette.text[500];
    },
    backgroundColor: ({ status }) => {
      if (status === 5) return theme.palette.text[50];
      if (status === 6) return theme.palette.danger[50];
      if (status === 7) return theme.palette.success[50];
      if (status === 8) return theme.palette.warning[50];
      return theme.palette.text[50];
    },
  },
}))(MuiChip);

export default function ContractStatus({ status, ...props }) {
  return <Chip label={CONTRACT_STATUS[status]} status={status} {...props} />;
}
