import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import Main from './themes/Main';
import history from './config/history';
import Routes from './config/routes';

import './App.css';

const App = () => {
  return (
    <MuiThemeProvider theme={Main}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale="pt-BR"
      >
        <Router history={history}>
          <Routes />
        </Router>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default hot(App);
